import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { SendEmailModalComponent } from '../send-email-modal/send-email-modal.component';
import { Constants } from '../../../common/constants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-total-lost-saves',
  templateUrl: './total-lost-saves.component.html',
  styleUrls: ['./total-lost-saves.component.scss']
})
export class TotalLostSavesComponent implements OnInit, AfterViewInit {
  selectedOption = '0';
  allActiveBrands: any[] = [];
  reportsColor: any[] = [];
  alerts: any[] = [];
  lastSyncTime: string;
  selBrand: number = 0;
  showLoadingSpinner = true;
  periodSubsChart: any;
  noDataAvailable = false;
  viewSubsReportsPrmsn = true;
  months: string[] = [];
  brandNames: string[] = [];
  planNames: string[] = [];
  subsChartData;
  churnedUsers;
  churnedData;
  bsModalRef: BsModalRef;
  tableData: { [brand: string]: { [month: string]: { [plan: string]: { totalLost: number; totalSaved: number; }; }; }; } = {}; // updated type


  @ViewChild('myCanvas') canvasRef!: ElementRef;

  private readonly monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private reportsService: ReportsService,
    private modalService: BsModalService
  ) {
    this.reportsColor = Constants.REPORTS_CONSTANT_COLORS;
    this.viewSubsReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_SUBS_RETENTION_REPORTS]);
    this.commonService.subNavSelect(Constants.NAV_DASHBOARD);
    this.getAllActiveBrands();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Subs Retention Report');
    this.commonService.setTitle('Subs Retention Report');

  }

  ngAfterViewInit(): void {
    // this.getNewSubsData(false);
  }

  getAllActiveBrands(): void {
    if (this.viewSubsReportsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res.code === 1 && res.status === 1) {
            this.allActiveBrands = res.result;
            this.getNewSubsData(false);
          } else {
            this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
        },
        error => {
          this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      );
    }
  }

  brandFilterChange(val): void {
    this.selBrand = val;
    this.getNewSubsData(false);
    //  this.generateSubsChartData( this.subsChartData, this.selBrand);
    //  this.churnedUsers = this.selBrand === 'all' ? this.churnedData : this.churnedData.filter(item => item.brand_name === this.selBrand);
    // alert(this.churnedUsers.length)
    // console.log(this.churnedUsers)
  }

  getNewSubsData(isRefreshed: boolean): void {
    this.showLoadingSpinner = true;
    this.noDataAvailable = false;
    this.reportsService.getLostSaveData(this.selBrand, isRefreshed).then(
      res => {
        if (res['code'] == 1 && res['status'] == 1) {
          if (res.result.periodSubs) {
            this.subsChartData = res.result.periodSubs;
            const processedData = this.processData(this.subsChartData, this.selBrand);
            this.generateChartData(processedData);
            this.churnedUsers = res.result.churnedUsers;
            console.log(res.result.lostuser_datas);
            this.churnedData = res.result.churnedUsers;
            //  const processDataTable = this.processDataTable(res.result.periodSubs);
            //  this.generateTableData(processDataTable);
          } else {
            this.noDataAvailable = true;
          }
        } else {
          this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
        this.showLoadingSpinner = false;
      }, error => {
        this.alerts = [{
          type: 'danger',
          msg: Constants.DASH_DATA_FAILURE_MSG,
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        this.showLoadingSpinner = false;
      });
  }

  generateSubsChartData(allData: any[], selBrand: string) {
    this.showLoadingSpinner = true;
    this.noDataAvailable = false;
    if (allData.length > 0) {
      this.showLoadingSpinner = false;
      const processedData = this.processData(allData, selBrand);
      this.generateChartData(processedData);
    } else {
      this.showLoadingSpinner = false;
      this.noDataAvailable = true;
    }
  }

  processData(allData: any[], selBrand): { labels: string[], brandData: { [key: string]: { [key: string]: { totalLost: number, totalSaved: number } } }, months: string[], brandNames: string[], planNames: string[] } {

    const labels: string[] = [];
    const brandData: { [key: string]: { [key: string]: { totalLost: number, totalSaved: number } } } = {};
    const months: string[] = [];
    const brandNames: string[] = [];
    const planNames: string[] = [];

    allData.forEach(item => {
      const month = item.date.substring(0, 7);
      const monthName = `${this.monthNames[parseInt(month.split('-')[1], 10) - 1]} ${month.split('-')[0]}`;
      if (!labels.includes(monthName)) labels.push(monthName);
      if (!months.includes(monthName)) months.push(monthName);

      item.data.forEach((brandDataItem: any) => {
        const brand = brandDataItem.brand;
        if (!brandNames.includes(brand)) brandNames.push(brand);

        let totalLost = 0;
        let totalSaved = 0;

        brandDataItem.plans.forEach(plan => {
          totalLost += plan.lost || 0;
          totalSaved += plan.saved || 0;
          if (!planNames.includes(plan.planName)) planNames.push(plan.planName);

        });

        if (!brandData[brand]) {
          brandData[brand] = {};
        }

        if (!brandData[brand][monthName]) {
          brandData[brand][monthName] = { totalLost: 0, totalSaved: 0 };
        }
        brandData[brand][monthName].totalLost += totalLost;
        brandData[brand][monthName].totalSaved += totalSaved;

      });
    });

    brandNames.sort();
    // If selBrand is 'all', return the current processed data
    /* if (selBrand === 'all') {
         return { labels, brandData, months, brandNames, planNames };
     } else {
         // If a specific brand is selected, filter the data to return only that brand's data
         const filteredBrands = { [selBrand]: brandData[selBrand] };
         return { labels, brandData: filteredBrands, months, brandNames: [selBrand], planNames };
     }*/
    return { labels, brandData, months, brandNames, planNames };
  }



  generateChartData(processedData: { labels: string[], brandData: { [key: string]: { [key: string]: { totalLost: number, totalSaved: number } } }, brandNames: string[] }): void {
    const { labels, brandData } = processedData;

    // console.log('labels' + labels + 'brandData' + JSON.stringify(brandData))
    const colors = this.reportsColor
    let colorIndex = 0;

    const datasetsLost = Object.keys(brandData).map(brand => {
      //console.log('brandData' + JSON.stringify(brandData) + 'brand' + brand)
      const color = colors[colorIndex % colors.length]; // Get color from the array in a loop
      colorIndex++;
      const brandDataValue = labels.map(label => brandData[brand][label] || { totalLost: 0, totalSaved: 0 })

      return {
        label: brand,
        data: brandDataValue.map(item => (-1 * item.totalLost)),
        backgroundColor: color,
      };
    });


    const datasetsSaved = Object.keys(brandData).map(brand => {
      //console.log('brandData' + JSON.stringify(brandData) + 'brand' + brand)
      const color = colors[colorIndex % colors.length]; // Get color from the array in a loop
      colorIndex++;

      const brandDataValue = labels.map(label => brandData[brand][label] || { totalLost: 0, totalSaved: 0 })

      return {
        label: brand + ' Saved',
        data: brandDataValue.map(item => item.totalSaved),
        backgroundColor: color,
      };
    });

    //console.log('datasets' + JSON.stringify(datasetsLost) + JSON.stringify(datasetsSaved))

    if (this.periodSubsChart) this.periodSubsChart.destroy();

    const ctx = this.canvasRef.nativeElement.getContext('2d');
    let legend = datasetsSaved.map(item => item.label) && datasetsLost.map(item => item.label)
    this.periodSubsChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          ...datasetsLost,
          ...datasetsSaved
        ]
      },
      options: {
        animation: {
          duration: 1000,
          easing: 'easeInOutQuad',
        },
        tooltips: {
          callbacks: {
            title: (tooltipItems) => {
              const label = tooltipItems[0].label;
              const [month, year] = label.split(' ');
              return `${month} ${year}`;
            }
          }
        },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{
            stacked: true, type: 'linear', title: {
              display: true,
              text: 'Your Y-axis Subname'
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          labels: {
            filter: function (legend, data) {
              const brand = legend.text.split(' ')[0];
              const index = data.datasets.findIndex(ds => ds.label === legend.text);
              return index === data.datasets.findIndex(ds => ds.label.split(' ')[0] === brand);
            }
          },
          position: 'bottom'
        },
      }
    });
  }

  getData(month: string, brand: string, plan: string) {
    return this.tableData[brand]?.[month]?.[plan] || { totalLost: 0, totalSaved: 0 };
  }

  getBrandDisplayName(brandName) {
    return this.reportsService.getBrandDisplayName(this.allActiveBrands, brandName);
  }


  /*********************************Click event to trigger Cancel event*********************/
  openModalWithComponent(consumerId) {
    console.log('userId' + consumerId)
    this.bsModalRef = this.modalService.show(SendEmailModalComponent, {
      initialState: {
        userId: consumerId
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    console.log(this.bsModalRef.content.userId)

  }



}
