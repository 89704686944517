export class DropdownConstants {
    public static COMPANY_SIZE_DATA : any[] = [
        {id:'< 50 people', name:'< 50 people'},
        {id:'50 - 250 people', name:'50 - 250 people'},
        {id:'250 - 500 people', name:'250 - 500 people'},
        {id:'> 500 people', name:'> 500 people'}
    ];

    public static INDUSTRIES_DATA : any[] = [
      {id:'Agriculture', name:'Agriculture'},
      {id:'Architecture', name:'Architecture'},
      {id:'Automotive', name:'Automotive'},
      {id:'Aviation', name:'Aviation'},
      {id:'Banking', name:'Banking'},
      {id:'Broadcast', name:'Broadcast'},
      {id:'Channel Distribution', name:'Channel Distribution'},
      {id:'Communications', name:'Communications'},
      {id:'Construction', name:'Construction'},
      {id:'Consumer Electronics / Electrical', name:'Consumer Electronics / Electrical'},
      {id:'Design', name:'Design'},
      {id:'Education & Training', name:'Education & Training'},
      {id:'Event Production & Management', name:'Event Production & Management'},
      {id:'Facilities Management', name:'Facilities Management'},
      {id:'Film', name:'Film'},
      {id:'Food & Beverage', name:'Food & Beverage'},
      {id:'Government', name:'Government'},
      {id:'Healthcare', name:'Healthcare'},
      {id:'Hospitality', name:'Hospitality'},
      {id:'Information Technology', name:'Information Technology'},
      {id:'Law', name:'Law'},
      {id:'Logistics', name:'Logistics'},
      {id:'Marketing / Publishing', name:'Marketing / Publishing'},
      {id:'Mechanical', name:'Mechanical'},
      {id:'Oil & Gas and Energy', name:'Oil & Gas and Energy'},
      {id:'Plant', name:'Plant'},
      {id:'Production & Post Production', name:'Production & Post Production'},
      {id:'Real Estate', name:'Real Estate'},
      {id:'Recruitment', name:'Recruitment'},
      {id:'Refining & Petrochemicals', name:'Refining & Petrochemicals'},
      {id:'Retail & Trading', name:'Retail & Trading'},
      {id:'Shipping & Maritime', name:'Shipping & Maritime'},
      {id:'Transportation', name:'Transportation'},
      {id:'Travel & Tourism', name:'Travel & Tourism'},
      {id:'Utilities & Infrastructure', name:'Utilities & Infrastructure'},
      {id:'Others', name:'Others'}
  ];

  public static JOB_TITLE_DATA : any[] = [
    {id:'Accountant', name:'Accountant'},
    {id:'Administrative Officer', name:'Administrative Officer'},
    {id:'Board Member', name:'Board Member'},
    {id:'Business Development Director', name:'Business Development Director'},
    {id:'Business Development Manager', name:'Business Development Manager'},
    {id:'Chairman', name:'Chairman'},
    {id:'Chief Commercial Officer (CCO)', name:'Chief Commercial Officer (CCO)'},
    {id:'Chief Compliance Officer', name:'Chief Compliance Officer'},
    {id:'Chief Corporate Officer', name:'Chief Corporate Officer'},
    {id:'Chief Executive Officer (CEO)', name:'Chief Executive Officer (CEO)'},
    {id:'Chief Financial Officer (CFO)', name:'Chief Financial Officer (CFO)'},
    {id:'Chief Information Officer (CIO)', name:'Chief Information Officer (CIO)'},
    {id:'Chief Marketing Officer (CMO)', name:'Chief Marketing Officer (CMO)'},
    {id:'Chief Operating Officer (COO)', name:'Chief Operating Officer (COO)'},
    {id:'Chief Planning Officer', name:'Chief Planning Officer'},
    {id:'Chief Strategy Officer (CSO)', name:'Chief Strategy Officer (CSO)'},
    {id:'Chief Technology Officer (CTO)', name:'Chief Technology Officer (CTO)'},
    {id:'Consultant', name:'Consultant'},
    {id:'Country / Regional Manager', name:'Country / Regional Manager'},
    {id:'Department Manager', name:'Department Manager'},
    {id:'Director', name:'Director'},
    {id:'Director of Human Resources', name:'Director of Human Resources'},
    {id:'Director of Sales / Sales Director', name:'Director of Sales / Sales Director'},
    {id:'Engineer', name:'Engineer'},
    {id:'Executive', name:'Executive'},
    {id:'Executive Director', name:'Executive Director'},
    {id:'Facilities Manager', name:'Facilities Manager'},
    {id:'Finance Director', name:'Finance Director'},
    {id:'Finance Manager', name:'Finance Manager'},
    {id:'Founder', name:'Founder'},
    {id:'General Manager', name:'General Manager'},
    {id:'Government Officer', name:'Government Officer'},
    {id:'Human Resources Manager', name:'Human Resources Manager'},
    {id:'IT Director', name:'IT Director'},
    {id:'Logistics Director', name:'Logistics Director'},
    {id:'Logistics Manager', name:'Logistics Manager'},
    {id:'Managing Director', name:'Managing Director'},
    {id:'Marketing Director', name:'Marketing Director'},
    {id:'Marketing Manager', name:'Marketing Manager'},
    {id:'Office Manager', name:'Office Manager'},
    {id:'Operations Director', name:'Operations Director'},
    {id:'Operations Manager', name:'Operations Manager'},
    {id:'Owner / Partner', name:'Owner / Partner'},
    {id:'President', name:'President'},
    {id:'Procurement Director', name:'Procurement Director'},
    {id:'Procurement Manager / Purchase Manager', name:'Procurement Manager / Purchase Manager'},
    {id:'Public Relations Director', name:'Public Relations Director'},
    {id:'Purchase Manager / Buyer', name:'Purchase Manager / Buyer'},
    {id:'Recruitment Manager', name:'Recruitment Manager'},
    {id:'Sales and Marketing Director', name:'Sales and Marketing Director'},
    {id:'Sales Director', name:'Sales Director'},
    {id:'Sales Manager', name:'Sales Manager'},
    {id:'Secretary', name:'Secretary'},
    {id:'Senior Vice President / Executive Vice President', name:'Senior Vice President / Executive Vice President'},
    {id:'Supply Chain Director Manager', name:'Supply Chain Director Manager'},
    {id:'Vice President', name:'Vice President'}
  ];

  public static COUNTRIES_DATA : any[] = [
    {id:'United Arab Emirates', name:'United Arab Emirates'},
    {id:'Australia', name:'Australia'},
    {id:'Afghanistan', name:'Afghanistan'},
    {id:'Aland Islands', name:'Aland Islands'},
    {id:'Albania', name:'Albania'},
    {id:'Algeria', name:'Algeria'},
    {id:'American Samoa', name:'American Samoa'},
    {id:'Andorra', name:'Andorra'},
    {id:'Angola', name:'Angola'},
    {id:'Anguilla', name:'Anguilla'},
    {id:'Antarctica', name:'Antarctica'},
    {id:'Antigua and Barbuda', name:'Antigua and Barbuda'},
    {id:'Argentina', name:'Argentina'},
    {id:'Armenia', name:'Armenia'},
    {id:'Aruba', name:'Aruba'},
    {id:'Austria', name:'Austria'},
    {id:'Azerbaijan', name:'Azerbaijan'},
    {id:'Bahamas', name:'Bahamas'},
    {id:'Bahrain', name:'Bahrain'},
    {id:'Bangladesh', name:'Bangladesh'},
    {id:'Barbados', name:'Barbados'},
    {id:'Belarus', name:'Belarus'},
    {id:'Belgium', name:'Belgium'},
    {id:'Belize', name:'Belize'},
    {id:'Benin', name:'Benin'},
    {id:'Bermuda', name:'Bermuda'},
    {id:'Bhutan', name:'Bhutan'},
    {id:'Bolivia', name:'Bolivia'},
    {id:'Bonaire', name:'Bonaire'},
    {id:'Bosnia and Herzegovina', name:'Bosnia and Herzegovina'},
    {id:'Botswana', name:'Botswana'},
    {id:'Bouvet Island', name:'Bouvet Island'},
    {id:'Brazil', name:'Brazil'},
    {id:'British Indian Ocean Territory', name:'British Indian Ocean Territory'},
    {id:'British Virgin Islands', name:'British Virgin Islands'},
    {id:'Brunei', name:'Brunei'},
    {id:'Bulgaria', name:'Bulgaria'},
    {id:'Burkina Faso', name:'Burkina Faso'},
    {id:'Burundi', name:'Burundi'},
    {id:'Cambodia', name:'Cambodia'},
    {id:'Cameroon', name:'Cameroon'},
    {id:'Canada', name:'Canada'},
    {id:'Cape Verde', name:'Cape Verde'},
    {id:'Cayman Islands', name:'Cayman Islands'},
    {id:'Central African Republic', name:'Central African Republic'},
    {id:'Chad', name:'Chad'},
    {id:'Chile', name:'Chile'},
    {id:'China', name:'China'},
    {id:'Christmas Island', name:'Christmas Island'},
    {id:'Cocos (Keeling) Islands', name:'Cocos (Keeling) Islands'},
    {id:'Colombia', name:'Colombia'},
    {id:'Comoros', name:'Comoros'},
    {id:'Congo, Democratic Republic of', name:'Congo, Democratic Republic of'},
    {id:'Congo, Republic of', name:'Congo, Republic of'},
    {id:'Cook Islands', name:'Cook Islands'},
    {id:'Costa Rica', name:'Costa Rica'},
    {id:'Croatia', name:'Croatia'},
    {id:'Cuba', name:'Cuba'},
    {id:'Curaçao', name:'Curaçao'},
    {id:'Cyprus', name:'Cyprus'},
    {id:'Czech Republic', name:'Czech Republic'},
    {id:'Denmark', name:'Denmark'},
    {id:'Djibouti', name:'Djibouti'},
    {id:'Dominica', name:'Dominica'},
    {id:'Dominican Republic', name:'Dominican Republic'},
    {id:'Ecuador', name:'Ecuador'},
    {id:'Egypt', name:'Egypt'},
    {id:'El Salvador', name:'El Salvador'},
    {id:'Equatorial Guinea', name:'Equatorial Guinea'},
    {id:'Eritrea', name:'Eritrea'},
    {id:'Estonia', name:'Estonia'},
    {id:'Ethiopia', name:'Ethiopia'},
    {id:'Falkland Islands', name:'Falkland Islands'},
    {id:'Faroe Islands', name:'Faroe Islands'},
    {id:'Fiji', name:'Fiji'},
    {id:'Finland', name:'Finland'},
    {id:'France', name:'France'},
    {id:'French Guiana', name:'French Guiana'},
    {id:'French Polynesia', name:'French Polynesia'},
    {id:'French Southern and Antarctic Lands', name:'French Southern and Antarctic Lands'},
    {id:'Gabon', name:'Gabon'},
    {id:'Gambia', name:'Gambia'},
    {id:'Georgia', name:'Georgia'},
    {id:'Germany', name:'Germany'},
    {id:'Ghana', name:'Ghana'},
    {id:'Gibraltar', name:'Gibraltar'},
    {id:'Greece', name:'Greece'},
    {id:'Greenland', name:'Greenland'},
    {id:'Grenada', name:'Grenada'},
    {id:'Guadeloupe', name:'Guadeloupe'},
    {id:'Guam', name:'Guam'},
    {id:'Guatemala', name:'Guatemala'},
    {id:'Guernsey', name:'Guernsey'},
    {id:'Guinea', name:'Guinea'},
    {id:'Guinea-Bissau', name:'Guinea-Bissau'},
    {id:'Guyana', name:'Guyana'},
    {id:'Haiti', name:'Haiti'},
    {id:'Heard Island and Mcdonald Islands', name:'Heard Island and Mcdonald Islands'},
    {id:'Honduras', name:'Honduras'},
    {id:'Hong Kong', name:'Hong Kong'},
    {id:'Hungary', name:'Hungary'},
    {id:'Iceland', name:'Iceland'},
    {id:'India', name:'India'},
    {id:'Indonesia', name:'Indonesia'},
    {id:'Iran', name:'Iran'},
    {id:'Iraq', name:'Iraq'},
    {id:'Ireland', name:'Ireland'},
    {id:'Isle of Man', name:'Isle of Man'},
    {id:'Israel', name:'Israel'},
    {id:'Italy', name:'Italy'},
    {id:'Ivory Coast', name:'Ivory Coast'},
    {id:'Jamaica', name:'Jamaica'},
    {id:'Japan', name:'Japan'},
    {id:'Jersey', name:'Jersey'},
    {id:'Jordan', name:'Jordan'},
    {id:'Kazakhstan', name:'Kazakhstan'},
    {id:'Kenya', name:'Kenya'},
    {id:'Kiribati', name:'Kiribati'},
    {id:'Korea, Democratic People\'s Republic of', name:'Korea, Democratic People\'s Republic of'},
    {id:'Korea, Republic of', name:'Korea, Republic of'},
    {id:'Kosovo', name:'Kosovo'},
    {id:'Kuwait', name:'Kuwait'},
    {id:'Kyrgyzstan', name:'Kyrgyzstan'},
    {id:'Laos', name:'Laos'},
    {id:'Latvia', name:'Latvia'},
    {id:'Lebanon', name:'Lebanon'},
    {id:'Lesotho', name:'Lesotho'},
    {id:'Liberia', name:'Liberia'},
    {id:'Libya', name:'Libya'},
    {id:'Liechtenstein', name:'Liechtenstein'},
    {id:'Lithuania', name:'Lithuania'},
    {id:'Luxembourg', name:'Luxembourg'},
    {id:'Macau', name:'Macau'},
    {id:'Macedonia', name:'Macedonia'},
    {id:'Madagascar', name:'Madagascar'},
    {id:'Malawi', name:'Malawi'},
    {id:'Malaysia', name:'Malaysia'},
    {id:'Maldives', name:'Maldives'},
    {id:'Mali', name:'Mali'},
    {id:'Malta', name:'Malta'},
    {id:'Marshall Islands', name:'Marshall Islands'},
    {id:'Martinique', name:'Martinique'},
    {id:'Mauritania', name:'Mauritania'},
    {id:'Mauritius', name:'Mauritius'},
    {id:'Mayotte', name:'Mayotte'},
    {id:'Mexico', name:'Mexico'},
    {id:'Micronesia', name:'Micronesia'},
    {id:'Moldova', name:'Moldova'},
    {id:'Monaco', name:'Monaco'},
    {id:'Mongolia', name:'Mongolia'},
    {id:'Montenegro', name:'Montenegro'},
    {id:'Montserrat', name:'Montserrat'},
    {id:'Morocco', name:'Morocco'},
    {id:'Mozambique', name:'Mozambique'},
    {id:'Myanmar', name:'Myanmar'},
    {id:'Namibia', name:'Namibia'},
    {id:'Nauru', name:'Nauru'},
    {id:'Nepal', name:'Nepal'},
    {id:'Netherlands', name:'Netherlands'},
    {id:'New Caledonia', name:'New Caledonia'},
    {id:'New Zealand', name:'New Zealand'},
    {id:'Nicaragua', name:'Nicaragua'},
    {id:'Niger', name:'Niger'},
    {id:'Nigeria', name:'Nigeria'},
    {id:'Niue', name:'Niue'},
    {id:'Norfolk Island', name:'Norfolk Island'},
    {id:'Northern Mariana Islands', name:'Northern Mariana Islands'},
    {id:'Norway', name:'Norway'},
    {id:'Oman', name:'Oman'},
    {id:'Pakistan', name:'Pakistan'},
    {id:'Palau', name:'Palau'},
    {id:'Palestinian', name:'Palestinian'},
    {id:'Panama', name:'Panama'},
    {id:'Papua New Guinea', name:'Papua New Guinea'},
    {id:'Paraguay', name:'Paraguay'},
    {id:'Peru', name:'Peru'},
    {id:'Philippines', name:'Philippines'},
    {id:'Pitcairn Islands', name:'Pitcairn Islands'},
    {id:'Poland', name:'Poland'},
    {id:'Portugal', name:'Portugal'},
    {id:'Puerto Rico', name:'Puerto Rico'},
    {id:'Qatar', name:'Qatar'},
    {id:'Réunion', name:'Réunion'},
    {id:'Romania', name:'Romania'},
    {id:'Russia', name:'Russia'},
    {id:'Rwanda', name:'Rwanda'},
    {id:'Saint Barthélemy', name:'Saint Barthélemy'},
    {id:'Saint Helena, Ascension, and Tristan da Cunha', name:'Saint Helena, Ascension, and Tristan da Cunha'},
    {id:'Saint Kitts and Nevis', name:'Saint Kitts and Nevis'},
    {id:'Saint Lucia', name:'Saint Lucia'},
    {id:'Saint Martin', name:'Saint Martin'},
    {id:'Saint Pierre and Miquelon', name:'Saint Pierre and Miquelon'},
    {id:'Saint Vincent and the Grenadines', name:'Saint Vincent and the Grenadines'},
    {id:'Samoa', name:'Samoa'},
    {id:'San Marino', name:'San Marino'},
    {id:'São Tomé and Príncipe', name:'São Tomé and Príncipe'},
    {id:'Saudi Arabia', name:'Saudi Arabia'},
    {id:'Senegal', name:'Senegal'},
    {id:'Serbia', name:'Serbia'},
    {id:'Seychelles', name:'Seychelles'},
    {id:'Sierra Leone', name:'Sierra Leone'},
    {id:'Singapore', name:'Singapore'},
    {id:'Sint Maarten', name:'Sint Maarten'},
    {id:'Slovakia', name:'Slovakia'},
    {id:'Slovenia', name:'Slovenia'},
    {id:'Solomon Islands', name:'Solomon Islands'},
    {id:'Somalia', name:'Somalia'},
    {id:'South Africa', name:'South Africa'},
    {id:'South Georgia', name:'South Georgia'},
    {id:'South Sudan', name:'South Sudan'},
    {id:'Spain', name:'Spain'},
    {id:'Sri Lanka', name:'Sri Lanka'},
    {id:'Sudan', name:'Sudan'},
    {id:'Suriname', name:'Suriname'},
    {id:'Svalbard and Jan Mayen', name:'Svalbard and Jan Mayen'},
    {id:'Swaziland', name:'Swaziland'},
    {id:'Sweden', name:'Sweden'},
    {id:'Switzerland', name:'Switzerland'},
    {id:'Syria', name:'Syria'},
    {id:'Taiwan', name:'Taiwan'},
    {id:'Tajikistan', name:'Tajikistan'},
    {id:'Tanzania', name:'Tanzania'},
    {id:'Thailand', name:'Thailand'},
    {id:'Timor-Leste', name:'Timor-Leste'},
    {id:'Togo', name:'Togo'},
    {id:'Tokelau', name:'Tokelau'},
    {id:'Tonga', name:'Tonga'},
    {id:'Trinidad and Tobago', name:'Trinidad and Tobago'},
    {id:'Tunisia', name:'Tunisia'},
    {id:'Turkey', name:'Turkey'},
    {id:'Turkmenistan', name:'Turkmenistan'},
    {id:'Turks and Caicos Islands', name:'Turks and Caicos Islands'},
    {id:'Tuvalu', name:'Tuvalu'},
    {id:'Uganda', name:'Uganda'},
    {id:'Ukraine', name:'Ukraine'},
    {id:'United Kingdom', name:'United Kingdom'},
    {id:'United States', name:'United States'},
    {id:'United States Minor Outlying Islands', name:'United States Minor Outlying Islands'},
    {id:'United States Virgin Islands', name:'United States Virgin Islands'},
    {id:'Uruguay', name:'Uruguay'},
    {id:'Uzbekistan', name:'Uzbekistan'},
    {id:'Vanuatu', name:'Vanuatu'},
    {id:'Vatican City', name:'Vatican City'},
    {id:'Venezuela', name:'Venezuela'},
    {id:'Vietnam', name:'Vietnam'},
    {id:'Wallis and Futuna', name:'Wallis and Futuna'},
    {id:'Western Sahara', name:'Western Sahara'},
    {id:'Yemen', name:'Yemen'},
    {id:'Zambia', name:'Zambia'}
  ];
}