<div class="login-wrapper ">
	<!-- START Login Background Pic Wrapper-->
	<div class="bg-pic">
		<!-- START Background Pic-->
		<div class="img-holder"  ></div>
		<!-- START Background Caption-->
		<div class="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
			<h1 class="semi-bold text-white">
				LEVEL4</h1>

			<p class="small">
				ITP Media Group reaches an audience of more than 104.6 million people across the Middle East and beyond through its leading websites, portals, social media platforms, events, conferences, award ceremonies, magazines, books and social media influencers agency.
			</p>
		</div>
		<!-- END Background Caption-->
	</div>
	<!-- END Login Background Pic Wrapper-->
	<!-- START Login Right Container-->
	<div class="login-container bg-white">
		<div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
			<img src="../assets/logo.png" alt="logo" pgRetina src1x="../assets/logo.png" src2x="../assets/logo.png"
			     width="200">

			<p class="p-t-35">Sign in to start your session</p>
            <div *ngFor="let alert of alerts">
                <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
            </div>
			<!-- START Login Form -->
			<form class="p-t-15" role="form" [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
				<!-- START Form Control-->
				<div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('accEmailAddFld').invalid && (getFormControl('accEmailAddFld').dirty || getFormControl('accEmailAddFld').touched)">
					<label>Login</label>
                    <input type="email" class="form-control" placeholder="Email" formControlName="accEmailAddFld" >
                    <div class="invalid-tooltip" *ngIf="getFormControl('accEmailAddFld').invalid && (getFormControl('accEmailAddFld').dirty || getFormControl('accEmailAddFld').touched)">
                        Email is required
                    </div>
				</div>

				<!-- END Form Control-->
				<!-- START Form Control-->
				<div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('accPwdFld').invalid && (getFormControl('accPwdFld').dirty || getFormControl('accPwdFld').touched)">
					<label>Password</label>
					<input type="password" class="form-control" placeholder="Password" formControlName="accPwdFld" [ngClass]="{ 'is-invalid': submitted && f.accPwdFld.errors }">
                    <div class="invalid-tooltip" *ngIf="getFormControl('accPwdFld').invalid && (getFormControl('accPwdFld').dirty || getFormControl('accPwdFld').touched)">
                        Password is required
                    </div>
				</div>

				<!-- START Form Control-->
				<div class="row">
					<div class="col-md-6 no-padding sm-p-l-10">
						<div class="form-check ">
							<input type="checkbox" value="1" id="checkbox1">
							<label for="checkbox1">Keep Me Signed in</label>
						</div>
					</div>
					<div class="col-md-6 d-flex align-items-center justify-content-end hide">
						<a href="javascript:void(0)" class="text-info small">Help? Contact Support</a>
					</div>
				</div>
				<!-- END Form Control-->
				<button class="btn btn-primary btn-cons m-t-10 login" type="submit" [class.disabled]="loginForm.invalid">Sign in <span class="loader" *ngIf="loaderShow"></span></button>
			</form>
			<!--END Login Form-->
			<div class="pull-bottom sm-pull-bottom hide">
				<div class="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
					<div class="col-sm-3 col-md-2 no-padding">
						<img alt="" class="m-t-5" height="30" src="../assets/favicon.png" width="30">
					</div>
					<div class="col-sm-9 no-padding m-t-10">
						<p>
							<small>
								Create a pages account. If you have a facebook account, log into it for this
								process. Sign in with <a href="javascript:void(0)" class="text-info">Facebook</a> or <a href="javascript:void(0)" class="text-info">Google</a>
							</small>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- END Login Right Container-->
</div>
