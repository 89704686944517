import { Injectable } from '@angular/core';
import { contentHeaders } from '../common/headers';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class SiteLicensesService {
  private url = environment.api_url;

  constructor(private http: HttpClient, private commonService : CommonService) { }

  /**********************************API to Get All Licenses**********************************************/
  getAllSiteLicenses(brandId:number){
    return this.http
      .get(`${this.url}/v1/licensees/all/${brandId}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Get Site Licensee details based on id**********************************************/
  getLicenseeDetail(licenseeId){
    return this.http
      .get(`${this.url}/v1/licensees/${licenseeId}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Create New Licensee**********************************************/
  createNewLicensee(dataObj){
    return this.http
      .post(`${this.url}/v1/licensees/add`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to edit Licensee**********************************************/
  updateLicensee(dataObj,licenseeId){
    return this.http
      .post(`${this.url}/v1/licensees/edit/${licenseeId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*************************************API to Update Site Licensee Status*********************************************/
  updateSiteLicStatus(licenseeId : number, isChecked: number): Promise<any> {
    return this.http
      .post(`${this.url}/v1/licensees/delete/${licenseeId}/${isChecked}`, '', {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*************************************API to Invite Users*********************************************/
  sendUserInvitation(dataObj, licenseeId): Promise<any> {
    return this.http
    .post(`${this.url}/v1/licensees/invite/${licenseeId}`, dataObj, {headers: contentHeaders})
    .toPromise()
    .then(response => {
      return response;
    })
    .catch(this.commonService.handleError);
  }
  /*************************************API to Generate Payment Link*********************************************/
  generatePayLink(dataObj, licenseeId): Promise<any> {
    return this.http
    .post(`${this.url}/v1/licensees/paylink/${licenseeId}`, dataObj, {headers: contentHeaders})
    .toPromise()
    .then(response => {
      return response;
    })
    .catch(this.commonService.handleError);
  }
  /*************************************API to View the User List based on Domain*********************************************/
  viewInvUsers(licenseeId, domainId): Promise<any> {
    return this.http
    .get(`${this.url}/v1/licensees/domainuser/${licenseeId}/${domainId}`, {headers: contentHeaders})
    .toPromise()
    .then(response => {
      return response;
    })
    .catch(this.commonService.handleError);
  }
  /*************************************API to View the User List based on Domain*********************************************/
  deleteDomainUser(licenseeId, emailId, dataObj): Promise<any> {
    return this.http
    .post(`${this.url}/v1/licensees/removeemail/${licenseeId}/${emailId}`, dataObj, {headers: contentHeaders})
    .toPromise()
    .then(response => {
      return response;
    })
    .catch(this.commonService.handleError);
  }
}
