<div class="modal-header">
    <h5 >Add <span class="semi-bold">New Card</span></h5>
</div>
<div class="modal-body">
  <div id="payment-form" class="form-group-attached">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group form-group-default required">
          <label for="card-number">CARD NUMBER</label>
          <card-number class="form-control"></card-number>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group form-group-default required">
          <label for="card-number">EXPIRY DATE</label>
          <expiry-date  class="form-control"></expiry-date>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group form-group-default required">
          <label for="card-number" >SECURITY CODE</label>
          <cvv class="form-control"></cvv>
        </div>
      </div>
    </div>  
  </div>  
  <div class="row">
    <div class="col-md-12 m-t-10">
      <div class="form-check primary">
        <input id="defPayChecked" type="checkbox" (click)="defPayChecked($event)"> 
        <label for="defPayChecked" class="def-checkbox">Use as default payment method</label>
      </div>
    </div> 
  </div>
  <button ion-button (click)="submitCard()" id="pay-button" disabled="" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30">SUBMIT</button>
  <p class="success-payment-message">{{ cardToken }}</p> 
  <div class="row">
    <div class="col-md-12 text-right">
      <button type="button" class="btn btn-danger fs-14 padding-10 p-r-20 p-l-20" (click)="bsModalRef.hide()">close</button>
    </div>
  </div>

</div>

