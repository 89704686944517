<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="editMsgTmpltPrmns">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/messagingtemplates/all" href="javascript:void(0)">Email Templates</a></li>
      <li class="breadcrumb-item active">  Edit</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <h5 class="mb-2"> Edit Email Templates</h5>
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <form *ngIf="!showLoadingSpinner" class="addNewBrandForm mx-auto" id="form-project" [formGroup]="editTmpltForm" (ngSubmit)="editTmpltFormSubmit()" novalidate>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required form-group-default-select2" [class.has-error]="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                        <label>Select Brand</label>
                        <pg-select style="width: 100%;" formControlName="brandName" [PlaceHolder]="'Select Brand'" AllowClear ShowSearch>
                          <pg-option *ngFor='let brand of allActiveBrands;let i = index' Label="{{brand.brand_name}}" Value="{{brand.id}}"></pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                          Brand is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required form-group-default-select2" [class.has-error]="getFormControl('emailType').invalid && (getFormControl('emailType').dirty || getFormControl('emailType').touched)">
                        <label>Email Type</label>
                        <pg-select style="width: 100%;" formControlName="emailType" [PlaceHolder]="'Select Type'" AllowClear ShowSearch>
                          <pg-option *ngFor='let emailType of allEmailTypes;let i = index' Label="{{emailType.email_temp_name}}" Value="{{emailType.id}}"></pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('emailType').invalid && (getFormControl('emailType').dirty || getFormControl('emailType').touched)">
                          Email Type is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('subject').invalid && (getFormControl('subject').dirty || getFormControl('subject').touched)">
                        <label>Subject</label>
                        <input type="text" class="form-control" formControlName="subject"  placeholder="Enter Subject">
                        <div class="invalid-tooltip" *ngIf="getFormControl('subject').invalid && (getFormControl('subject').dirty || getFormControl('subject').touched)">
                          Subject is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" class="primary" id="tmpltStatus" formControlName="tmpltStatus">
                          <label for="tmpltStatus">Status</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('template').invalid && (getFormControl('template').dirty || getFormControl('template').touched)">
                        <label class="p-b-10">Email Content</label>
                        <ckeditor [editor]="Editor"  [config]="config" type="text" class="ckeditor" formControlName="template"  ></ckeditor>
                        <div class="invalid-tooltip" *ngIf="getFormControl('template').invalid && (getFormControl('template').dirty || getFormControl('template').touched)">
                          Email Content is required
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div class="row p-t-20">
                  <div class=" col-md-12 text-right ">
                    <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10">Update</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                    <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/messagingtemplates/all">Cancel</button>
                  </div>
                </div>
              </form>
              <div *ngFor="let alert of alerts">
                <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
              </div>
        </div>
      </div>
    </div>
  </div>  
</div>    
<!-------No Access Block--------->
<app-no-access *ngIf="!editMsgTmpltPrmns"></app-no-access>