import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { MessagingTemplatesService } from '../../../services/messaging-templates.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-all-messaging-templates',
  templateUrl: './all-messaging-templates.component.html',
  styleUrls: ['./all-messaging-templates.component.scss']
})
export class AllMessagingTemplatesComponent implements OnInit {
  selectedOptions = '0'
  selectedOption = '1';
  rows: any[];
  tmp: any[];
  allEmailTypes: any[];
  alerts: any[];
  showLoadingSpinner = true;
  selectedType : number = 1;
  selectedBrand: number;//PMV temporary
  allActiveBrands: any[];
  //Role based permission check
  viewMsgTmpltPrmsn : boolean = true;
  addMsgTmpltPrmns : boolean = true;
  editMsgTmpltPrmns : boolean = true;

  constructor(private commonService: CommonService, 
    private cdr: ChangeDetectorRef,
    private messagingTemplatesService: MessagingTemplatesService) {
      /****************Permission check based on role******************/
      this.viewMsgTmpltPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_EMAIL_TEMPLATES, Constants.PERMISSION_ADD_EMAIL_TEMPLATES, Constants.PERMISSION_EDIT_EMAIL_TEMPLATES]);
      this.addMsgTmpltPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_EMAIL_TEMPLATES]);
      this.editMsgTmpltPrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_EMAIL_TEMPLATES]);
      this.getAllEmailTypes();
      this.getAllActiveBrands();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if (this.viewMsgTmpltPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allActiveBrands = res['result'];
            this.selectedBrand = 0;//All
            this.getAllTemplates(this.selectedBrand)
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }
  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    //this.userList = [];
    this.getAllTemplates(brandId);
  }  
  /**********************************API Method to Get All Email Types*********************/
  getAllEmailTypes() {
    if(this.viewMsgTmpltPrmsn) {
      this.messagingTemplatesService.getAllEmailTypes().then(
        res=>{
        if(res['code']==1 && res['status']==1) {
          this.allEmailTypes = res['result'];
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

  /**********************************API Method to Get All Templates*********************/
  getAllTemplates(brandId) {
    if(this.viewMsgTmpltPrmsn) {
      this.showLoadingSpinner = true;
      this.messagingTemplatesService.getAllEmailTemplates(brandId).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
            this.rows = res['result'];
            this.tmp = res['result'];
            this.getAllTemplatesByGroup(this.selectedType);
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        this.showLoadingSpinner = false;
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
            this.showLoadingSpinner = false;
        }
      );
    }
  }

  /******************************Method to update email template Status (Active/Inactive)***************************/
  updateTemplateStatus( templateId: number, event: any) {
    if(this.editMsgTmpltPrmns) {
      const isChecked = this.commonService.getStatusValue(event.target.checked);
      this.messagingTemplatesService.updateEmailTmpltStatus(templateId, isChecked).then(
      res => {
        if(res['code']==1 && res['status']==1) {//Success
          this.alerts = [{
            type: 'success',
            msg: Constants.DEL_EMAIL_TMPLT_SUCCESS_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        } else {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DEL_EMAIL_TMPLT_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
        //this.getAllTemplatesByGroup(this.selectedType);
      },
      error => {
        this.alerts = [{
          type: 'danger',
          msg: Constants.DEL_EMAIL_TMPLT_FAILURE_MSG,
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        // this.getAllTemplatesByGroup(this.selectedType);
      });
    }
  }

  getAllTemplatesByGroup(typeId) {
    this.rows = this.tmp;
    const temp = this.rows.filter(function (d) {
      return d.email_type_id == typeId;
    });
    this.rows = temp;
  }

  /******************************Method to filter name column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    if(val) {
      const temp = this.rows.filter(function (d) {
        return d.brand_name.toLowerCase().indexOf(val) !== -1 || d.email_type_id.toLowerCase().indexOf(val) !== -1 || d.subject.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
    } else {
      this.getAllTemplatesByGroup(this.selectedType);
    }
  }
}
