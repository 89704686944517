<div class="padding-20">
    <div class="card list" *ngIf="viewBrandsPrmsn">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12">  
                    <!--<h5 class="card-title d-none d-sm-block">Brands</h5>-->
                    <div class="btn-group-vertical">
                        <div class="dropdown dropdown-default p-r-15" dropdown>
                            <button class="btn dropdown-toggle text-center bg-transparent border-0 fs-16" type="button" dropdownToggle>
                                {{statusDropDownLbl}} 
                            </button>
                            <div id="brandsStatusFltr" *dropdownMenu class="dropdown-menu "
                            role="menu" aria-labelledby="button-basic" role="menu">
                                <a class="dropdown-item fs-16 p-t-5 p-b-5" href="javascript:void(0)" role="menuitem" value="1" (click)="filterStaus(1)" *ngIf="selStatus == 0">Active Brands</a>
                                <a class="dropdown-item fs-16 p-t-5 p-b-5" href="javascript:void(0)" role="menuitem" value="0" (click)="filterStaus(0)" *ngIf="selStatus == 1">Inactive Brands </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Brand/Domain" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv text-right" *ngIf="addBrandsPrmns">
                    <a class="btn btn-complete fs-15 padding-10" routerLink="/brands/add" role="button">+ Add Brand</a>
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable class="material container1" *ngIf="!showLoadingSpinner" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="6" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.75" cellClass="text-right" headerClass="text-right">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Brand" [flexGrow]="1" prop="brand_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a routerLink="/brands/edit/{{row.id}}">{{ row.brand_name }}</a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" [flexGrow]="3" prop="brand_display_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.brand_display_name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Domain URL" [flexGrow]="3" prop="domain_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.domain_name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Max. limit" [flexGrow]="1" prop="max_limit" cellClass="text-right" headerClass="text-right">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.max_limit | number}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Offered limit" [flexGrow]="1" prop="offered_limit" cellClass="text-right" headerClass="text-right">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.offered_limit | number}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Created on" [flexGrow]="1.8" prop="created_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.created_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Updated on" [flexGrow]="1.8" prop="updated_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.updated_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" [flexGrow]="1" prop="is_active">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        
                        <div class="form-check switch success m-b-0" *ngIf="editBrandsPrmns">
                            <input  [attr.id]="'updateBrandStatus' + row.id" type="checkbox" *ngIf="row.is_active == 1" class="primary" checked="checked" (change)="updateBrandStatus(row.id, $event)">
                            <input [attr.id]="'updateBrandStatus' + row.id" type="checkbox" *ngIf="row.is_active == 0" class="primary" (change)="updateBrandStatus(row.id, $event)">
                            <label [attr.for]="'updateBrandStatus' + row.id"></label>
                        </div>
                            
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action" [flexGrow]="1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a routerLink="/brands/edit/{{row.id}}" class="" title="View/Edit" *ngIf="editBrandsPrmns">
                            <i class="pg-icon text-info">edit</i>
                        </a>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>

    </div>
    <!-------No Access Block--------->
  <app-no-access *ngIf="!viewBrandsPrmsn"></app-no-access>
</div>