<div class="padding-20">
    <div class="card list" *ngIf="viewWidgetsPrmsn">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12">  
                    <div class="d-flex flex-row align-items-center">
                        <span class="m-r-5">Brands: </span>
                        <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="selectedOptions" (ngModelChange)="brandChange(selectedOptions)"  ShowSearch >
                            <pg-option  [Label]="'All'" [Value]="'0'" ></pg-option>
                            <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select" [Label]="brand.brand_name" [Value]="brand.id" >
                            </pg-option>
                        </pg-select>
                        <span class="m-r-5 m-l-15">Groups: </span>
                        <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Groups'" [(ngModel)]="selectedGroup" (ngModelChange)="getAllWidgetsByGroup(selectedGroup)"  ShowSearch >
                            <pg-option *ngFor='let group of allWidgetGroups;let i = index' [Label]="group.group_name" [Value]="group.id" >
                            </pg-option>
                        </pg-select>
                        <!-- <select class="form-control m-l-10 m-r-auto" [style.width.px]="300"  (change)="getAllWidgetsByGroup($event.target.value)" [(ngModel)]="selectedGroup">
                            <option *ngFor='let group of allWidgetGroups;let i = index' value="{{group.id}}">{{group.group_name}}</option>
                        </select> -->
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Name" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv text-right" *ngIf="addWidgetsPrmns">
                    <a class="btn btn-complete fs-15 padding-10" routerLink="/widgets/add" role="button">+ Add Widget</a>
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable *ngIf="!showLoadingSpinner" class="material container1" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="6" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.75" cellClass="text-right" headerClass="text-right">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Brand Name" [flexGrow]="1" prop="brand_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row.brand_name }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Type" [flexGrow]="1" prop="type">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.type == 1">Paywall</span>
                        <span *ngIf="row.type == 2">Notifier</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Display on" [flexGrow]="1.5" prop="action_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.metering_action_id == 5">{{row.action_name}} - {{row.custom_count}}</span>
                        <span *ngIf="row.metering_action_id != 5">{{row.action_name}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" [flexGrow]="2.5" prop="name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Logged In?" [flexGrow]="1" prop="is_logged_in" *ngIf="selectedGroup==2">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.is_logged_in == 0">No</span>
                        <span *ngIf="row.is_logged_in == 1">Yes</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Created on" [flexGrow]="1.7" prop="created_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.created_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Updated on" [flexGrow]="1.7" prop="updated_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.updated_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" [flexGrow]="1" prop="is_active">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="form-check switch success m-b-0" *ngIf="editWidgetsPrmns">
                            <input [attr.id]="'updateBrandStatus' + row.id" type="checkbox" *ngIf="row.is_active == 1" class="primary" checked="checked" (change)="updateWidgetStatus(row.id, $event)">
                            <input [attr.id]="'updateBrandStatus' + row.id" type="checkbox" *ngIf="row.is_active == 0" class="primary" (change)="updateWidgetStatus(row.id, $event)">
                            <label [attr.for]="'updateBrandStatus' + row.id"></label>
                        </div>
                        
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action" [flexGrow]="1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a routerLink="/widgets/edit/{{row.id}}" class="" title="View/Edit" *ngIf="editWidgetsPrmns">
                            <i class="pg-icon text-info">edit</i>
                        </a>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>

    </div>
    <!-------No Access Block--------->
  <app-no-access *ngIf="!viewWidgetsPrmsn"></app-no-access>
</div>


