<div class="container-fluid p-3 sm-padding-10 sm-gutter p-t-0">
  <div class="card card-transparent m-l-10">
    <div class="card-header mb-3 p-0">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="row align-items-center">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3 p-l-5">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label >Brand</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Brand'"
                          formControlName="selectedBrand"
                          (ngModelChange)="onBrandFilterChange(form.get('selectedBrand').value)" 
                          ShowSearch Mode="multiple">
                      <pg-option *ngFor="let brand of allActiveBrands" 
                              [Label]="brand.brand_display_name" 
                              [Value]="brand.id">
                      </pg-option>
                  </pg-select>
              </div>
            </div>
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Plan</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Plan'"
                          formControlName="selectedPlan"
                          ShowSearch Mode="multiple">
                          <pg-option *ngFor='let plan of selPlanArr' Label="{{plan.plan_name}}"
                          Value="{{plan.id}}">
                        </pg-option>
                  </pg-select>
              </div>
            </div>
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Subscribers</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Subscriber'"
                          formControlName="selectedSubscriber"
                          ShowSearch Mode="multiple">
                      <pg-option [Label]="'Datawall'" [Value]="'datawall'"></pg-option>
                      <pg-option [Label]="'Paywall'" [Value]="'paywall'"></pg-option>
                      <pg-option [Label]="'Churned'" [Value]="'churned'"></pg-option>
                  </pg-select>
              </div>
            </div>
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Industry</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Industry'"
                          formControlName="selectedIndustry"
                          ShowSearch Mode="multiple">
                      <pg-option *ngFor='let ind of industriesArr' 
                              [Label]="ind.name" 
                              [Value]="ind.id">
                      </pg-option>
                  </pg-select>
              </div>
            </div>
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Job Title</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Job Title'"
                          formControlName="selectedJobTtl"
                          ShowSearch Mode="multiple">
                      <pg-option *ngFor='let jobttl of jobTtlArr' 
                              [Label]="jobttl.name" 
                              [Value]="jobttl.id">
                      </pg-option>
                  </pg-select>
              </div>
            </div>
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Country</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Country'"
                          formControlName="selectedCountry"
                          ShowSearch Mode="multiple">
                      <pg-option *ngFor='let country of countriesArr' 
                              [Label]="country.name" 
                              [Value]="country.id">
                      </pg-option>
                  </pg-select>
              </div>
            </div>
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Company Size</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Company Size'"
                          formControlName="selectedCompSize"
                          ShowSearch Mode="multiple">
                      <pg-option *ngFor='let compSize of companySizeArr' 
                              [Label]="compSize.name" 
                              [Value]="compSize.id">
                      </pg-option>
                  </pg-select>
              </div>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default typehead">
                  <label>Company Name</label>
                  <input type="search" class="form-control" formControlName="selectedCompanyName" placeholder="Enter Company Name" (keyup)="filterCompanyStr($event)"  [typeahead]="companyNameArr"  [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="25">
              </div>
            </div>
            
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Subscription Type</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Subscription Type'"
                          formControlName="selectedSubType"
                          ShowSearch Mode="multiple">
                      <pg-option *ngFor='let role of userRolesArr' 
                              [Label]="role.access_role_name" 
                              [Value]="role.id">
                      </pg-option>
                  </pg-select>
              </div>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2">
                  <label>Promo Code</label>
                  <pg-select style="width: 100%;" [PlaceHolder]="'Select Promo Code'"
                          formControlName="selectedPromo"
                          ShowSearch Mode="multiple">
                      <pg-option *ngFor='let promo of allPromoCodes' 
                              [Label]="promo.promo_code" 
                              [Value]="promo.id">
                      </pg-option>
                  </pg-select>
              </div>
            </div>
        
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3">
              <button type="submit" class="btn btn-primary applyBtn m-r-10 btn-lg btn-larger">Apply</button>
              <button type="button" class="btn btn-light resetBtn btn-lg btn-larger"  (click)="onReset()">Reset</button>
            </div>
        
          </div>
        </form>
        
    </div>

    <!--<div class="form-group typehead">
      <input placeholder="States of USA" [(ngModel)]="selectedState" [typeahead]="states"  [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5" class="form-control">
      </div>

      <input [(ngModel)]="selected"
     [typeahead]="states"
     class="form-control">-->

    <div class="card-body p-0">
      <div class="d-flex align-items-center justify-content-end">
        <button (click)="exportToExcel()" class="btn btn-secondary m-b-20">Export as CSV <i class="pg-icon text-info">download</i></button>
      </div>
      <ngx-datatable
          class="material mine-data"
          [rows]="userList"
          [columns]="columns"
          [count]="totalUsers"
          [limit]="pageSize"
          [externalPaging]="true"
          [externalSorting]="true"
          [loadingIndicator]="showLoadingSpinner"
          [headerHeight]="50"
          [footerHeight]="50"
          [columnMode]="'force'"
          [scrollbarH]="true"
          rowHeight="auto"
          (sort)="onSort($event)"
          (page)="onPage($event)">

          <!-- Email column with custom template -->
          <ngx-datatable-column name="Email" [width]="250"> <!-- Specify width -->
            <ng-template ngx-datatable-cell-template let-row="row">
              <ng-container *ngIf="row.subscriber === 'Datawall' || row.subscriber === 'Churned'">
                <a [href]="'/users/edit/' + row.id + '/' + row.brand_id">{{ row.email }}</a>
              </ng-container>
              <ng-container *ngIf="row.subscriber === 'Paywall'">
                <a [href]="'/users/subs/edit/' + row.id + '/' + row.brand_id">{{ row.email }}</a>
              </ng-container>
              <ng-container *ngIf="row.subscriber !== 'Datawall' && row.subscriber !== 'Churned' && row.subscriber !== 'Paywall'">
                {{ row.email }}
              </ng-container>
            </ng-template>
          </ngx-datatable-column>

          <!-- Other columns -->
          <ng-container *ngFor="let column of columns">
            <ngx-datatable-column *ngIf="column.prop !== 'email' && column.prop !== 'brand_id'" [prop]="column.prop" [name]="column.name">
            </ngx-datatable-column>
          </ng-container>

        </ngx-datatable>

      


      <div class="limitCtr">
        <label for="pageLimit">Rows per page:</label>
        <select id="pageLimit" [(ngModel)]="pageSize" (change)="onLimitChange()" class="m-l-5">
          <option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</option>
        </select>
      </div>

      <!--<div class="table-responsive">
          <table class="table dataTable listUserTbl table-hover">
              <thead>
                  <tr>
                      <th scope="sorting sortable" scope="col" (click)="sortUserTable($event, 'id')">User Id</th>
                      <th class="sorting_asc sortable" scope="col" (click)="sortUserTable($event, 'email')">Email</th>
                      <th class="sorting sortable" scope="col" (click)="sortUserTable($event, 'brand')">Brand</th>
                      <th class="sorting sortable" scope="col" (click)="sortUserTable($event, 'first_name')">First Name</th>
                      <th class="sorting sortable" scope="col" (click)="sortUserTable($event, 'last_name')">Last Name</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngIf="totalUsers == 0">
                      <td colspan="8" class="text-center">No data available.</td>
                  </tr>
                  <tr *ngFor="let user of userList; index as i">
                      <td>{{ user.id }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.brand_name}}</td>
                      <td>{{ user.first_name }}</td>
                      <td>{{ user.last_name }}</td>
                  </tr>
              </tbody>
          </table>-->
          

          <!--<div class="card-footer" *ngIf="!showLoadingSpinner && totalUsers > 0">
            <div class="totCount">{{totalUsers}} total</div>
            <nav>
                <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-end">
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link" (click)="setPage(1)">First</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link" (click)="setPage(pager.currentPage - 1)">❮</a>
                    </li>
                    <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{activeLnk:pager.currentPage === page}">
                        <a class="page-link" (click)="setPage(page)">{{page}}</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage(pager.currentPage + 1)">❯</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                    </li>
                </ul>
            </nav>
        </div>
      </div>    -->   
  </div>
  </div>
</div>
<app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>
