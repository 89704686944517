import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-datawall',
  templateUrl: './datawall.component.html',
  styleUrls: ['./datawall.component.scss']
})
export class DatawallComponent implements OnInit {
  selectedOption = 'all';
  allActiveBrands: any[] = [];
  reportsColor: any[] = [];
  alerts: any[] = [];
  lastSyncTime: string;
  selBrand = 'all';
  showLoadingSpinner = true;
  periodSubsChart: any;
  noDataAvailable = false;
  viewSubsReportsPrmsn = true;
  periodSubData;
  months: string[] = [];
  brandNames: string[] = [];
  tableData: { [key: string]: { [key: string]: number } } = {};
  private readonly monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
 
  @ViewChild('myCanvas') canvasRef: ElementRef;

  constructor(
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private reportsService: ReportsService
  ) {
    this.reportsColor = Constants.REPORTS_CONSTANT_COLORS;
    this.viewSubsReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_DATAWALL_REPORTS]);
    this.commonService.subNavSelect(Constants.NAV_DASHBOARD);
    this.getAllActiveBrands();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Datawall Overview');
    this.commonService.setTitle('Datawall Overview');

  }

  getAllActiveBrands(): void {
    if (this.viewSubsReportsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res.code === 1 && res.status === 1) {
            this.allActiveBrands = res.result;
            this.getDatawallData(false);
          } else {
            this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
        },
        error => {
          this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      );
    }
  }

  brandFilterChange(val: string): void {
    this.selBrand = val;
    this.generateDatawallData( this.periodSubData, this.selBrand);
  }

  getDatawallData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      this.showLoadingSpinner = true;
      this.noDataAvailable = false;
      this.reportsService.getDatawallData(0, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res.result.periodSubs) {
              this.periodSubData = res.result.periodSubs;
              this.lastSyncTime = res['result'].last_sync_time
             this. generateDatawallData( this.periodSubData, this.selBrand)
            } else {
              this.noDataAvailable = true;
            }
          } else {
            this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
          this.showLoadingSpinner = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showLoadingSpinner = false;
        });
      }
  }

  generateDatawallData(allData: any[], selBrand: string) {
      this.showLoadingSpinner = true;
      this.noDataAvailable = false;
      if(allData.length>0) {
        this.showLoadingSpinner = false;
        const processedData = this.processData(allData, selBrand);
        this.generateChartData(processedData);
        this.generateTableData(processedData);
      } else {
        this.showLoadingSpinner = false;
        this.noDataAvailable = true;
      }
  }

  processData(allData: any[], selBrand: string): { labels: string[], brands: { [key: string]: { [key: string]: number } }, months: string[], brandNames: string[] } {
    const labels: string[] = [];
    const brands: { [key: string]: { [key: string]: number } } = {};
    const months: string[] = [];
    const brandNames: string[] = [];

    allData.forEach(item => {
      const month = item.date.substring(0, 7);
      const monthName = `${this.monthNames[parseInt(month.split('-')[1], 10) - 1]} ${month.split('-')[0]}`;
      if (!labels.includes(monthName)) labels.push(monthName);

      if (!months.includes(monthName)) months.push(monthName);

      Object.entries(item).forEach(([brand, value]) => {
        if (brand !== 'date') {
          if (!brands[brand]) brands[brand] = {};
          brands[brand][monthName] = (brands[brand][monthName] || 0) + (value as number);

          if (!brandNames.includes(brand)) brandNames.push(brand);
        }
      });
    });

    brandNames.sort();
    // If selBrand is 'all', return the current processed data
    if (selBrand === 'all') {
        return { labels, brands, months, brandNames };
    } else {
        // If a specific brand is selected, filter the data to return only that brand's data
        const filteredBrands = { [selBrand]: brands[selBrand] };
        return { labels, brands: filteredBrands, months, brandNames: [selBrand] };
    }
}


  generateChartData(processedData: { labels: string[], brands: { [key: string]: { [key: string]: number } } }): void {
    const { labels, brands } = processedData;

    const colors = this.reportsColor;
    let colorIndex = 0;

    const datasets = Object.keys(brands).map(brand => {
        const color = colors[colorIndex % colors.length]; // Get color from the array in a loop
        colorIndex++;
        return {
            label: brand,
            data: labels.map(label => brands[brand][label] || 0),
            backgroundColor: color
        };
    });
    if (this.periodSubsChart) this.periodSubsChart.destroy();

    const ctx = this.canvasRef.nativeElement.getContext('2d');
    this.periodSubsChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        animation: {
          duration: 1000,
          easing: 'easeInOutQuad',
        },
        tooltips: {
          callbacks: {
            title: (tooltipItems) => {
              const label = tooltipItems[0].label;
              const [month, year] = label.split(' ');
              return `${month} ${year}`;
            }
          }
        },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                return value.toLocaleString(); // Convert value to comma-separated string
              }
            },
            type: 'linear'
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
      }
    });
  }

  generateTableData(processedData: { months: string[], brands: { [key: string]: { [key: string]: number } }, brandNames: string[] }): void {
    const { months, brands, brandNames } = processedData;
    this.months = months;
    this.brandNames = brandNames;
    this.tableData = {};

    months.forEach(month => {
      this.tableData[month] = {};
      brandNames.forEach(brand => {
        const brandInfo = brands[brand]?.[month]; // Safeguard: ?. to handle undefined
        this.tableData[month][brand] = brandInfo ? brandInfo : 0;
      });
    });
  }

  getData(month: string, brand: string): number {
    return this.tableData[month] ? this.tableData[month][brand] || 0 : 0;
  }

  getBrandDisplayName(brandName) {
    return this.reportsService.getBrandDisplayName(this.allActiveBrands, brandName);
  }
}
