import { Component, OnInit, ChangeDetectorRef, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../../../services/common.service';
import { Router } from '@angular/router';
import { Constants } from '../../../common/constants';
import * as CustomBuild from '../ckeditor5-build-classic/build/ckeditor';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})
export class AddTemplateComponent implements OnInit {
  @ViewChild('codemirrorHTML') codemirrorHTML: CodemirrorComponent;
  @ViewChild('codemirrorCSS') codemirrorCSS: CodemirrorComponent;
  @ViewChild('output') outputDiv: ElementRef<HTMLDivElement>;

  codeMirrorOptions: any = {
    mode: "htmlmixed",
    indentWithTabs: true,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: true,
    extraKeys: { "Ctrl-Space": "autocomplete" },
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  };
  codeMirrorOptionsCss: any = {
    mode: "text/css",
    indentWithTabs: true,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: true,
    extraKeys: { "Ctrl-Space": "autocomplete" },
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true
  };



  addNewWidgetForm: FormGroup;
  submitted = false;
  loading = false;
  alerts: any[];
  allActiveBrands: any[];
  widgetTypeArr: any[] = Constants.WIDGET_TYPE_DATA;
  widgetActionArr: any[];
  widgetActionFltrArr: any[];
  widgetGroupArr: any[];
  widgetCntTypeArr: any[];
  widgetCntCatArr: any[];
  selWidgetAction: number;
  selWidgetType: number = Constants.WIDGET_TYPE_PAYWALL;
  public Editor = CustomBuild;
  showLoadingSpinner = true;
  selectedOption;
  //Role based permission check
  addWidgetsPrmns: boolean = true;

  public config = {
    toolbar: ['heading', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'imageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', 'undo', 'redo', 'sourceEditing'],
    language: 'en',
    startupMode: 'sourceEditing'

  };

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private _ngZone: NgZone) {
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  run() {
    const codeMirrorValue = this.codemirrorHTML.codeMirror.getValue() ;
    const codeMirrorCSSValue =  this.codemirrorCSS.codeMirror.getValue();
    const code = `<html>
                    <head>
                        <meta charset="utf-8" />
                        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                        <meta name="description" content="" />
                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <link rel="stylesheet" href="">
                        <style>`+ codeMirrorCSSValue +`</style>
                    </head>
                    <body>`+ codeMirrorValue + `</body>
                  </html>`;
                  const iframe = this.outputDiv.nativeElement as HTMLIFrameElement;
                  const doc = iframe.ownerDocument; // This is just to read the document
                  if (doc) {
                      const body = doc.body;
                      if (body) {
                          body.innerHTML = code;
                      }
                  }
   // this.outputDiv.nativeElement.contents().find("body").innerHTML(codeMirrorValue);
  }



  ngOnInit(): void {
    /****************Add Widget Form Validation****************** */
    this.addNewWidgetForm = this.formBuilder.group({
      widgetType: [Constants.WIDGET_TYPE_PAYWALL, [Validators.required]],
      widgetDesc: ['', [Validators.required]],
      widgetName: ['', [Validators.required]],
      brandName: ['', [Validators.required]],
      widgetStatus: [true],
      widgetContent: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addNewWidgetForm.controls; }

  getFormControl(name) {
    return this.addNewWidgetForm.controls[name];
  }

  isFieldValid(field: string) {
    return !this.addNewWidgetForm.get(field).valid && this.addNewWidgetForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

}
