<div class="container-fluid p-3 sm-padding-10 sm-gutter p-t-0">
    <div class="card card-transparent m-l-10">
      <div class="card-header mb-3 p-0">
        <p class="mb-4">This report details users who have gained access to free content over the past 12 months. It provides insights into user acquisition patterns, helping to analyze engagement and content access trends. This report is essential for understanding audience growth.</p>
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
            <div class="d-flex align-items-center">
              <span class="m-r-5">Brands: </span>
              <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'"
                         [(ngModel)]="selectedOption" (ngModelChange)="brandFilterChange(selectedOption)" ShowSearch>
                <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
              </pg-select>
            </div>
          </div>
          <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 p-b-15 d-flex justify-content-end align-items-center">
            <a (click)="getDatawallData(true)" class="hover m-r-5" title="Refresh">
              <i class="pg-icon">refresh</i>
            </a>
            <span class="text-muted font-size-13">Last synced on: {{ lastSyncTime }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
  
      <div class="card-body p-0">
        <div class="col-sm-12 mt-4 mb-4 chart-container">
            <canvas #myCanvas width="500" height="400">{{ periodSubsChart }}</canvas>
        </div>
        <ng-container *ngIf="!showLoadingSpinner">
          <ng-container *ngIf="!noDataAvailable; else noDataMessage">
            <div class="col-sm-12 my-2 d-flex">
              <div class="card full-height no-border no-margin widget-11 widget-loader-bar">
                <div class="card-body no-padding">
                  <div class="widget-11-table auto-overflow">
                    <div class="table-container">
                      <table class="table table-condensed1 table-hover" id="datawallTbl">
                        <thead>
                          <tr>
                            <th>Brand</th>
                            <th *ngFor="let month of months">{{ month }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let brand of brandNames">
                            <td>{{ getBrandDisplayName(brand) }}</td>
                            <td *ngFor="let month of months">{{ getData(month, brand) | number}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noDataMessage>
            <h4 class="text-center">No data available for the selected brand.</h4>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
  <app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>
  