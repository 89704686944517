<div class="padding-20">     
    <div class="card list" *ngIf="viewContCatPrmsn">
        <div class="card-header hide">
            <div class="row justify-content-between">
                <div class="col-lg-7 col-sm-12">  
                    <h5 class="card-title d-none d-sm-block">All Content Categories</h5>
                </div>
                <div class="col-lg-5 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Name" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable class="material container1" *ngIf="!showLoadingSpinner" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="10" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.5" cellClass="text-right" headerClass="text-right">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" [flexGrow]="3" prop="category_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.category_name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Created on" [flexGrow]="1.5" prop="created_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.created_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Updated on" [flexGrow]="1.5" prop="updated_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.updated_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" [flexGrow]="1" prop="is_active">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{commonService.getValueFromArr(defStatusArr,row.is_active,'name')}}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>

    </div>
    <!-------No Access Block--------->
    <app-no-access *ngIf="!viewContCatPrmsn"></app-no-access>
</div>    