<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="editBrandsPrmns || viewBrandsPrmsn">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li routerLink="/brands/all"  class="breadcrumb-item"><a  href="javascript:void(0)">BRANDS</a></li>
      <li class="breadcrumb-item active" *ngIf="editBrandsPrmns">EDIT BRAND</li>
      <li class="breadcrumb-item active" *ngIf="!editBrandsPrmns && viewBrandsPrmsn">VIEW BRAND</li>
    </ol>
  </div>
  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <h5 class="mb-2" *ngIf="editBrandsPrmns">Edit Brand</h5>
            <h5 class="mb-2" *ngIf="!editBrandsPrmns && viewBrandsPrmsn">View Brand</h5>
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <form class="addNewBrandForm mx-auto" id="form-project" *ngIf="!showLoadingSpinner" [formGroup]="editBrandForm" (ngSubmit)="editBrandFormSubmit()" novalidate>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                        <label>Brand Name</label>
                        <input type="text" class="form-control" formControlName="brandName"  placeholder="Enter Brand Name" >
                        <div class="invalid-tooltip" *ngIf="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                          Brand Name is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('brandDisplayName').invalid && (getFormControl('brandDisplayName').dirty || getFormControl('brandDisplayName').touched)">
                        <label>Brand Display Name</label>
                        <input type="text" class="form-control" formControlName="brandDisplayName"  placeholder="Enter Brand Display Name" >
                        <div class="invalid-tooltip" *ngIf="getFormControl('brandDisplayName').invalid && (getFormControl('brandDisplayName').dirty || getFormControl('brandDisplayName').touched)">
                          Brand Display Name is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div  pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('domainName').invalid && (getFormControl('domainName').dirty || getFormControl('domainName').touched)">
                        <label>Domain URL <br/><small>(Domain URL should be in this format - www.example.com)</small></label>
                        <input type="text" class="form-control" formControlName="domainName" placeholder="Enter Domain Name" appDomainValidation>
                        <div class="invalid-tooltip" *ngIf="getFormControl('domainName').invalid && (getFormControl('domainName').dirty || getFormControl('domainName').touched)">
                          Domain URL is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div  pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('supportEmail').invalid && (getFormControl('supportEmail').dirty || getFormControl('supportEmail').touched)">
                        <label>From Address</label>
                        <input type="text" class="form-control" formControlName="supportEmail"  placeholder="Enter Support Email">
                        <div class="invalid-tooltip" *ngIf="getFormControl('supportEmail').invalid && (getFormControl('supportEmail').dirty || getFormControl('supportEmail').touched)">
                          Support Email is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div  pgFormGroupDefault class="form-group form-group-default required" [class.has-error]="getFormControl('senderName').invalid && (getFormControl('senderName').dirty || getFormControl('senderName').touched)">
                        <label>Sender Name</label>
                        <input type="text" class="form-control" formControlName="senderName" placeholder="Enter Sender Name">
                        <div class="invalid-tooltip" *ngIf="getFormControl('senderName').invalid && (getFormControl('senderName').dirty || getFormControl('senderName').touched)">
                          Sender Name is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('maxLim').invalid && (getFormControl('maxLim').dirty || getFormControl('maxLim').touched)">
                        <label>Max. Limit</label>
                        <input type="number" class="form-control" formControlName="maxLim" placeholder="Enter Max. Limit">
                        <div class="invalid-tooltip" *ngIf="getFormControl('maxLim').invalid && (getFormControl('maxLim').dirty || getFormControl('maxLim').touched)">
                          Max. Limit is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('offLim').invalid && (getFormControl('offLim').dirty || getFormControl('offLim').touched)">
                        <label>Offered Limit</label>
                        <input type="number" class="form-control" formControlName="offLim" placeholder="Enter Offered Limit">
                        <div class="invalid-tooltip" *ngIf="getFormControl('offLim').invalid && (getFormControl('offLim').dirty || getFormControl('offLim').touched)">
                          Offered Limit is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default  form-group-default-select2  required" [class.has-error]="getFormControl('meteringPeriod').invalid && (getFormControl('meteringPeriod').dirty || getFormControl('meteringPeriod').touched)">
                        <label>Rule Expiry Period</label>
                        <pg-select style="width: 100%;" formControlName="meteringPeriod" [PlaceHolder]="'Please Select Metering Period'" AllowClear ShowSearch >
                          <pg-option *ngFor='let meteringPeriod of meteringPeriods' [Label]="meteringPeriod.label" [Value]="meteringPeriod.value">
                          </pg-option>
                      </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('meteringPeriod').invalid && (getFormControl('meteringPeriod').dirty || getFormControl('meteringPeriod').touched)">
                          Rule Expiry Period is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div  class="form-group form-group-default ">
                        <label>Subscription Alert Emails</label>
                        <input type="text" class="form-control" formControlName="subAlertEmails" placeholder="Emails separated by comma (,)">
                        
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" id="switchSample" checked="checked" formControlName="brandStatus">
                          <label for="switchSample">Status</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row p-t-20">
                  <div class=" col-md-12 text-right ">
                    <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10" *ngIf="editBrandsPrmns">Update</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                    <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/brands/all" *ngIf="editBrandsPrmns">Cancel</button>
                    <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/brands/all" *ngIf="!editBrandsPrmns && viewBrandsPrmsn">Back</button>
                  </div>
                </div>
              </form>
              <div *ngFor="let alert of alerts" class="p-t-20">
                <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
              </div>
        </div>
      </div>
    </div>
  </div>  

</div>  
<!-------No Access Block--------->
<app-no-access *ngIf="!editBrandsPrmns && !viewBrandsPrmsn"></app-no-access>
