<div class="container-fluid p-3 sm-padding-10 sm-gutter p-t-0">
  <div class="card card-transparent m-l-10">
    <div class="card-header mb-3 p-0">
      <p class="mb-4">This report details all new paywall users acquired over the past 12 months. It provides insights into user acquisition trends, allowing for evaluation of subscription growth and effectiveness of marketing strategies.</p>
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-b-15 d-flex justify-content-end align-items-center">
          <a (click)="getNewSubsData(true)" class="hover m-r-5" title="Refresh">
            <i class="pg-icon">refresh</i>
          </a>
          <span class="text-muted font-size-13">Last synced on: {{ lastSyncTime }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
    <div class="card-body p-0">
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
      </div>
      <h4 *ngIf="noDataAvailable && !showLoadingSpinner" class="text-center">No data available.</h4>
        <div class="align-items-center m-b-40" *ngIf="!showLoadingSpinner && !noDataAvailable" >
            <div class="row clearfix"> 
              <div class="col-sm-12 col-md-3 col-xl-3">
                <div class="card m-b-10 ">
                  <div class="card-header top-left top-right">
                    <div class="card-title">
                      <span class="font-montserrat fs-11 all-caps">
                        Total new subs
                      </span>
                    </div>
                  </div>
                  <div class="card-body no-padding">
                    <div class="container-xs-height full-height">
                      <div class="row-xs-height">
                        <div class="col-xs-height col-top">
                          <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                            <h4 class="no-margin p-b-5"> {{newSubs | number}} </h4>
                            <p class="p-t-20"><small>(As of Today)</small></p>
                          </div>
                        </div>
                      </div>
                      <div class="row-xs-height">
                        <div class="col-xs-height col-bottom">
                          <div class="progress progress-small m-b-0">
                            <div class="progress-bar progress-bar-green" style="width: 100%;background-color: green;">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3 col-xl-3">
                <div class="card m-b-10 ">
                  <div class="card-header top-left top-right">
                    <div class="card-title">
                      <span class="font-montserrat fs-11 all-caps">
                        Total lost
                      </span>
                    </div>
                  </div>
                  <div class="card-body no-padding">
                    <div class="container-xs-height full-height">
                      <div class="row-xs-height">
                        <div class="col-xs-height col-top">
                          <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                            <h4 class="no-margin p-b-5">{{lost | number}}</h4>
                            <p class="p-t-20"><small>(Last 12 Months)</small></p>
                          </div>
                        </div>
                      </div>
                      <div class="row-xs-height">
                        <div class="col-xs-height col-bottom">
                          <div class="progress progress-small m-b-0">
                            <div class="progress-bar progress-bar-danger" style="width: 100%;">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3 col-xl-3">
                <div class="card m-b-10 ">
                  <div class="card-header top-left top-right">
                    <div class="card-title hover">
                      <span class="font-montserrat fs-11 all-caps">
                        Total churn rate <span title="Total Lost / Net New Subs" class="hover"><svg
                            xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                            class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path
                              d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                          </svg></span>
                      </span>
                    </div>
                  </div>
                  <div class="card-body no-padding">
                    <div class="container-xs-height full-height">
                      <div class="row-xs-height">
                        <div class="col-xs-height col-top">
                          <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                            <h4 class="no-margin p-b-5"> {{churnRate}}</h4>
                            <p class="p-t-20"><small>(Last 12 Months)</small></p>
                          </div>
                        </div>
                      </div>
                      <div class="row-xs-height">
                        <div class="col-xs-height col-bottom">
                          <div class="progress progress-small m-b-0">
                            <div class="progress-bar progress-bar-warning" style="width: 100%;">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3 col-xl-3">
                <div class="card m-b-10">
                  <div class="card-header top-left top-right">
                    <div class="card-title">
                      <span class="font-montserrat fs-11 all-caps">
                        Net new subs
                      </span>
                    </div>
                  </div>
                  <div class="card-body no-padding">
                    <div class="container-xs-height full-height">
                      <div class="row-xs-height">
                        <div class="col-xs-height col-top">
                          <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                            <h4 class="no-margin p-b-5">{{netSubs | number}}</h4>
                            <p class="p-t-20"><small>(Last 12 Months)</small></p>
                          </div>
                        </div>
                      </div>
                      <div class="row-xs-height">
                        <div class="col-xs-height col-bottom">
                          <div class="progress progress-small m-b-0">
                            <div class="progress-bar progress-bar-success" style="width: 100%;">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center m-t-40">
              <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
                <div class="d-flex align-items-center">
                  <span class="m-r-5">Brands: </span>
                  <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'"
                              [(ngModel)]="selectedOption" (ngModelChange)="brandFilterChange(selectedOption)" ShowSearch>
                    <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                    <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
                  </pg-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 mt-4 mb-4 chart-container">
            <canvas #myCanvas width="500" height="400">{{ periodSubsChart }}</canvas>
          </div>
          <div class="col-sm-12 my-2 d-flex" *ngIf="!showLoadingSpinner && !noDataAvailable">
            <div class="card full-height no-border no-margin widget-11 widget-loader-bar">
              <div class="card-body no-padding">
                <div class="widget-11-table auto-overflow">
                  <div class="table-container">
                    <div class="table-wrapper">
                  <table class="table table-condensed1 table-hover" id="datawallTbl">
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>Plan</th>
                        <th *ngFor="let month of months">{{ month }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let brand of brandNames">
                        <tr *ngFor="let plan of planNames">
                          <td>{{ getBrandDisplayName(brand) }}</td>
                          <td>{{ plan }}</td>
                          <td *ngFor="let month of months">{{ getData(month, brand, plan) | number}}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>
</div>
<app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>
