import { Component, OnInit, ChangeDetectorRef, TemplateRef,ViewChild,  Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { ActivatedRoute,Router} from '@angular/router';
import { CustomersService } from '../../../services/customers.service';
import { Constants } from '../../../common/constants';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CancelModalSubComponent } from './cancel-modal/cancel-modal.component';
import { ChangeCardModalSubComponent } from './change-card-modal/change-card-modal.component';
import { returnModalSubComponent } from './return-modal/return-modal.component';
import { AddNewCardSubComponent } from './add-new-card/add-new-card.component';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-subscriber-order-details',
  templateUrl: './subscriber-order-details.component.html',
  styleUrls: ['./subscriber-order-details.component.scss']
})
export class SubscriberOrderDetailsComponent implements OnInit {
  customerId : string;
  brandId : string;
  orderId : string;
  alerts: any[];
  showLoadingSpinner = true;
  userTransHistArr: any[];
  transStatusArr : any[] = Constants.TRANSACTION_STATUS;
  purchaseStatusArr : any[] = Constants.PURCHASE_STATUS;
  orderStatusArr : any[] = Constants.ORDER_STATUS;
  recentTransArr : any[];
  userOrderObj: any;
  tmp: any[];
  bsModalRef: BsModalRef;
  bssModalRef: BsModalRef;
  bsssModalRef: BsModalRef;
  showCancelButton: boolean = true;
  showCancelEndButton: boolean = true;
  CancelRequestType: number = 0;
  //Role based permission check
  cancelSubsPrmsn : boolean = true;
  refundSubsPrmns : boolean = true;
  allAccessPrmns : boolean = true;
  booksInvoiceNumber;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private customersService: CustomersService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService) {
      this.cancelSubsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_CANCEL_SUBSCRIBERS]);
      this.refundSubsPrmns = commonService.getSecPermissions([Constants.PERMISSION_REFUND_SUBSCRIBERS]);
      this.allAccessPrmns = commonService.getSecPermissions([Constants.PERMISSION_ALL_ACCESS]);
  }

  ngOnInit(): void {
    this.customerId = this.route.snapshot.paramMap.get('userid');
    this.brandId = this.route.snapshot.paramMap.get('brandid');
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.getCustomerTransHistDetail(this.customerId,this.brandId,this.orderId);
  }

  /**********************************API Method to Get All Transaction History details based on order id passed*********************/
  getCustomerTransHistDetail(customerId, brandId, orderId) {
    this.customersService.getCustomerTransHistDetail(customerId, brandId, orderId).then(
      res=>{
       if(res['code']==1 && res['status']==1) {
          this.userTransHistArr = res.result.transObj;
          this.showLoadingSpinner = false;
          this.recentTransArr = [ ...this.userTransHistArr ].pop();
          this.userOrderObj = res.result.orderObj[0];
          this.booksInvoiceNumber =  this.userTransHistArr[0].books_invoice_number;
          if(this.userOrderObj.status == 0) {
            this.showCancelButton = false;
          } else if(this.userOrderObj.status == 2) {
            this.showCancelEndButton = false;
          }
       } else {
          this.alerts = [{
            type: 'danger',
            msg: res['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
       }
      },error=>{
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
      }
    );
  }

  /**********************************Click event to trigger view invoice event*********************/
  viewInvoice(transId) {
    this.customersService.getInvoiceDetail(this.orderId, transId, this.customerId, this.brandId).then(
      res=>{
        let data = res['body'];
        
        if(data.length === 0) {
          this.alerts = [{
            type: 'danger',
            msg: Constants.INVOICE_NOT_FOUND_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        } else {
          const contentDisposition = res.headers.get('content-disposition');
          if(contentDisposition) {
            var contentDispositionName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            console.log(contentDispositionName.length)
            var filename = contentDispositionName != '""' ? contentDispositionName : this.booksInvoiceNumber;
          }
          const blob = new Blob([data],
            { type: 'application/pdf' });
            FileSaver.saveAs(blob,filename);
        }

      }, error=>{
          this.alerts = [{
            type: 'danger',
            msg: Constants.INVOICE_DOWNLOAD_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
      }
    );

  }

  /**********************************Click event to trigger refund payment API call*********************/
  refundPayment(transId) {
    if(this.refundSubsPrmns) {
      const initialState = {
        customerId: this.customerId,
        transaction_id : transId,
        orderId : this.orderId,
        brandId: this.brandId,
      };
      this.bssModalRef = this.modalService.show(returnModalSubComponent, {initialState});
      this.bssModalRef.content.closeBtnName = 'Close';
    }
  }
/*********************************Click event to trigger add new card event*********************/
  addNewCard($event) {
    const initialState = {
      customerId: this.customerId
    };
    this.bsssModalRef = this.modalService.show(AddNewCardSubComponent, {initialState});
    this.bsssModalRef.content.closeBtnName = 'Close';
    this.bsssModalRef.content.requestType.subscribe(res => {
      console.log("---add new card-----"+JSON.stringify(res));
       if(res.data['code']==1 && res.data['status']==1) {//success
         this.alerts = [{
           type: 'success',
           msg: Constants.CARD_ADDED_SUCCESS_MSG,
           timeout: Constants.DEF_ALERT_MSG_TIMEOUT
         }];
       
       } else {
         this.alerts = [{
           type: 'danger',
           msg: Constants.CARD_ADDED_FAILURE_MSG,
           timeout: Constants.DEF_ALERT_MSG_TIMEOUT
         }];
       }
     },error=>{
       this.alerts = [{
         type: 'danger',
         msg: Constants.CARD_ADDED_FAILURE_MSG,
         timeout: Constants.DEF_ALERT_MSG_TIMEOUT
       }];
     });
  }

  /*********************************Click event to trigger change card event*********************/
  changeCard($event) {
    const initialState = {
      customerId: this.customerId
    };
    this.bssModalRef = this.modalService.show(ChangeCardModalSubComponent, {initialState});
    this.bssModalRef.content.closeBtnName = 'Close';

    this.bssModalRef.content.requestType.subscribe(res => {
       if(res.data['code']==1 && res.data['status']==1) {//success
         this.alerts = [{
           type: 'success',
           msg: Constants.CARD_UPDATE_SUCCESS_MSG,
           timeout: Constants.DEF_ALERT_MSG_TIMEOUT
         }];
       
       } else {
         this.alerts = [{
           type: 'danger',
           msg: Constants.CARD_UPDATE_FAILURE_MSG,
           timeout: Constants.DEF_ALERT_MSG_TIMEOUT
         }];
       }
     },error=>{
       this.alerts = [{
         type: 'danger',
         msg: Constants.CARD_UPDATE_FAILURE_MSG,
         timeout: Constants.DEF_ALERT_MSG_TIMEOUT
       }];
     });

  }

  /*********************************Click event to trigger Cancel event*********************/
  openModalWithComponent($event) {
    if(this.cancelSubsPrmsn) {
      const initialState = {
        crValue: $event.target.attributes.value.nodeValue,
        customerId: this.customerId,
        orderId: this.orderId
      };
      this.bsModalRef = this.modalService.show(CancelModalSubComponent, {initialState});
      this.bsModalRef.content.closeBtnName = 'Close';

      this.bsModalRef.content.requestType.subscribe(res => {
        if(res.data['code']==1 && res.data['status']==1) {//success
          this.CancelRequestType =  res.data.rqType;
          this.alerts = [{
            type: 'success',
            msg: Constants.CANCEL_REQUEST_SUCCESS_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.getCustomerTransHistDetail(this.customerId,this.brandId,this.orderId);
        
        } else {
          this.alerts = [{
            type: 'danger',
            msg: Constants.CANCEL_REQUEST_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      },error=>{
        this.alerts = [{
          type: 'danger',
          msg: Constants.CANCEL_REQUEST_FAILURE_MSG,
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
      });
    }
  }


}
