import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CkoFrames } from 'frames-angular';
import { CustomersService } from '../../../../services/customers.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-add-new-card',
  templateUrl: './add-new-card.component.html',
  styleUrls: ['./add-new-card.component.scss']
})
export class AddNewCardComponent implements OnInit {
  private Frames;
  private errors = {};
  public cardToken: string;
  public requestType: EventEmitter<any> = new EventEmitter();
  customerId : string;
  isDefPayChecked : number = 0;

  constructor(public bsModalRef: BsModalRef,
    private customersService: CustomersService) {
    this.errors["card-number"] = "Please enter a valid card number";
    this.errors["expiry-date"] = "Please enter a valid expiry date";
    this.errors["cvv"] = "Please enter a valid cvv code";
   }

  ngOnInit(): void {
    this.Frames = new CkoFrames({
      publicKey: environment.pay_key,
      // debug: true,
      // localization: {
      //   cardNumberPlaceholder: "CARD NUMBER",
      //   expiryMonthPlaceholder: "MM",
      //   expiryYearPlaceholder: "YY",
      //   cvvPlaceholder: "CVV",
      // },
      cardValidationChanged: this.onCardValidationChanged.bind(this),
      frameValidationChanged: this.onValidationChanged.bind(this),
      cardTokenizationFailed: this.onCardTokenizationFailed.bind(this),
      paymentMethodChanged: this.onPaymentMethodChanged.bind(this)
    });
    this.Frames.init();
  }

  //FRAME_VALIDATION_CHANGED
  onValidationChanged(event) {

    const e = event.element;
    if (event.isValid || event.isEmpty) {
      if (e == "card-number" && !event.isEmpty) {
        this.showPaymentMethodIcon(null, null);
      }
      this.clearErrorMessage(e);
    } else {
      if (e == "card-number") {
        this.clearPaymentMethodIcon(null);
      }
      this.setErrorMessage(e);
    }
  }

  clearErrorMessage(el) {
    const selector = ".error-message__" + el;
    const message = <HTMLInputElement>document.querySelector(selector);
    message.textContent = "";
  }

  showPaymentMethodIcon(parent, pm) {
    if (parent) parent.classList.add("show");

    if (pm) {
      const name = pm.toLowerCase();
    }
  }

  clearPaymentMethodIcon(parent) {
    if (parent) parent.classList.remove("show");
  }

  setErrorMessage(el) {
    const selector = ".error-message__" + el;
    const message = <HTMLInputElement>document.querySelector(selector);
    message.textContent = this.errors[el];
  }

  //CARD_VALIDATION_CHANGED
  onCardValidationChanged(event) {
    const button = <HTMLInputElement>document.getElementById('pay-button');
    const errorMessage = <HTMLInputElement>document.querySelector(".error-message");
    button.disabled = !this.Frames.getFrames().isCardValid();
    if (!this.Frames.getFrames().isCardValid()) {
      errorMessage.textContent = this.getErrorMessage(event);
    }
  }

  getErrorMessage(event) {
    if (event.isValid || event.isEmpty) {
      return '';
    }
    return this.errors[event.element];
  }

  //CARD_TOKENIZATION_FAILED
  onCardTokenizationFailed(error) {
    this.Frames.enableSubmitForm();
  }

  // Method send to its parent
  triggerEvent(value: number) {
    this.requestType.emit({ data: value , res:200 });
  }

  //Default payment method checkbox check event
  defPayChecked(event:any) {
    this.isDefPayChecked = event.target.checked ? 1 : 0;
  }

  async submitCard() {
    let payload = await this.Frames.getTokenisedCard();
  //  this.cardToken = 'The card token: ' + payload.token;
    let dataObj = {
      'payment_token': payload.token,
      'payment_primary': this.isDefPayChecked
    };
    this.customersService.addNewCard(this.customerId, dataObj).then(
      res => {
          this.bsModalRef.hide();
          this.triggerEvent(res);
      });
  }

  //PAYMENT_METHOD_CHANGED
  onPaymentMethodChanged(event) {
    var pm = event.paymentMethod;
    let container = <HTMLInputElement>document.querySelector(".icon-container.payment-method");

    if (!pm) {
      this.clearPaymentMethodIcon(container);
    } else {
      this.showPaymentMethodIcon(container, pm);
    }
  }

}
