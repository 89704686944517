<div class="container-fluid p-l-25 p-r-25 p-t-0 p-b-25 sm-padding-10 sm-gutter">
    <!-- START ROW -->
    <div class="card card-transparent">
      <div class="card-header mb-3 p-l-0 p-r-0">

      </div>

  
      <div class="card-body p-l-0 p-r-0">

        <div class="col-sm-12 m-t-60 m-b-40 chart-container">
            <div class="chart-funnel">
                <div class="funnel"></div>
            </div>
        </div>

      </div>
    </div>
  </div>
