import { Component, OnInit, Input , HostListener,ViewEncapsulation, HostBinding } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { Constants } from '../../common/constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  host: {'class': 'page-sidebar'},
  animations: [
    trigger('toggleHeight', [
      state(
        'close',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'open',
        style({
          height: '*',
          
        })
      ),
      transition('close => open', animate('140ms ease-in')),
      transition('open => close', animate('140ms ease-out'))
    ])
  ],encapsulation: ViewEncapsulation.None
})


export class SideNavComponent implements OnInit {
  // limit = 4;
  
  title = 'Collapse Side Panel';
  isSelected: boolean = false;
  activeLink: string = "brands";
  clicked: boolean = false;
  timer;
  currentItem:any = null;
  isPerfectScrollbarDisabled = false;
  public config: PerfectScrollbarConfigInterface = {};
  userPermission =  JSON.parse(localStorage.getItem('pw_access')!);
  

  filteredMenuList : any[] = [];
  allMenuList : any[] = [
    {
      label: 'Dashboard',
      routerLink: '/dashboard',
      iconType: 'fi',
      iconName: 'shield',
      access: [Constants.PERMISSION_VIEW_DASHBOARD]
    },
    {
      label: 'Brands',
      details: '',
      routerLink: '/brands/all',
      iconType: 'fi',
      iconName: 'tag',
      access: [Constants.PERMISSION_VIEW_BRANDS,Constants.PERMISSION_ADD_BRANDS, Constants.PERMISSION_EDIT_BRANDS]
    },
    {
      label: 'Experience',
      routerLink: '/experience/all',
      iconType: 'fi',
      iconName: 'grid',
      access: [Constants.PERMISSION_VIEW_WIDGETS]
    },
    {
      label: 'Templates',
      routerLink: '/templates/all',
      iconType: 'fi',
      iconName: 'grid',
      access: [Constants.PERMISSION_VIEW_WIDGETS]
    },
    {
      label: 'Widgets',
      routerLink: '/widgets/all',
      iconType: 'fi',
      iconName: 'grid',
      access: [Constants.PERMISSION_VIEW_WIDGETS, Constants.PERMISSION_ADD_WIDGETS, Constants.PERMISSION_EDIT_WIDGETS]
    },
    {
      label: 'Subscribers-old',
      iconType: 'fi',
      iconName: 'users',
      toggle: 'close',
      access: [Constants.PERMISSION_VIEW_CUSTOMERS,Constants.PERMISSION_ADD_CUSTOMERS, Constants.PERMISSION_EDIT_CUSTOMERS,Constants.PERMISSION_VIEW_SUBSCRIBERS],
      submenu: [
        {
          label: 'Datawall',
          routerLink: '/users/all',
          iconType: 'letter',
          iconName: 'dw'
        },
        {
          label: 'Paywall',
          routerLink: '/users/subscribers',
          iconType: 'letter',
          iconName: 'pw'
        },
        {
          label: 'Churned',
          routerLink: '/users/unsubscribers',
          iconType: 'letter',
          iconName: 'cu'
        }
      ]
    },
    {
      label: 'Subscribers',
      iconType: 'fi',
      iconName: 'users',
      toggle: 'close',
      access: [Constants.PERMISSION_VIEW_CUSTOMERS,Constants.PERMISSION_ADD_CUSTOMERS, Constants.PERMISSION_EDIT_CUSTOMERS,Constants.PERMISSION_VIEW_SUBSCRIBERS],
      submenu: [
        {
          label: 'Datawall',
          routerLink: '/subs/all/datawall',
          iconType: 'letter',
          iconName: 'dw'
        },
        {
          label: 'Paywall',
          routerLink: '/subs/all/paywall',
          iconType: 'letter',
          iconName: 'pw'
        },
        {
          label: 'Churned',
          routerLink: '/subs/all/churned',
          iconType: 'letter',
          iconName: 'cu'
        }
      ]
    },
    {
      label: 'Products',
      routerLink: '/products/all',
      iconType: 'fi',
      iconName: 'package',
      access: [Constants.PERMISSION_VIEW_PRODUCTS, Constants.PERMISSION_ADD_PRODUCTS, Constants.PERMISSION_EDIT_PRODUCTS]
    },
    {
      label: 'Payment Plans',
      routerLink: '/plans/all',
      iconType: 'fi',
      iconName: 'credit-card',
      access: [Constants.PERMISSION_VIEW_PLANS, Constants.PERMISSION_ADD_PLANS, Constants.PERMISSION_EDIT_PLANS]
    },
    {
      label: 'Discounts',
      routerLink: '/discounts/all',
      iconType: 'fi',
      iconName: 'percent',
      access: [Constants.PERMISSION_VIEW_DISCOUNTS, Constants.PERMISSION_ADD_DISCOUNTS, Constants.PERMISSION_EDIT_DISCOUNTS]
    },
    {
      label: 'Promo Codes',
      routerLink: '/promocodes/all',
      iconType: 'fi',
      iconName: 'bookmark',
      access: [Constants.PERMISSION_VIEW_PROMOCODES, Constants.PERMISSION_ADD_PROMOCODES, Constants.PERMISSION_EDIT_PROMOCODES]
    },
    {
      label: 'Group Subs ',
      routerLink: '/corpsub/all',
      iconType: 'fi',
      iconName: 'shield',
      access: [Constants.PERMISSION_VIEW_SITE_LICENSES, Constants.PERMISSION_ADD_SITE_LICENSES, Constants.PERMISSION_EDIT_SITE_LICENSES]
    },
    {
      label: 'Email Templates',
      routerLink: '/messagingtemplates/all',
      iconType: 'fi',
      iconName: 'mail',
      access: [Constants.PERMISSION_VIEW_EMAIL_TEMPLATES, Constants.PERMISSION_ADD_EMAIL_TEMPLATES, Constants.PERMISSION_EDIT_EMAIL_TEMPLATES]
    },
    {
      label: 'Reports',
      iconType: 'fi',
      iconName: 'database',
      toggle: 'close',
      access: [Constants.PERMISSION_VIEW_REPORTS, Constants.PERMISSION_DOWNLOAD_REPORTS, Constants.PERMISSION_VIEW_SUBSCRIPTIONS_REPORTS, Constants.PERMISSION_VIEW_DATAWALL_REPORTS, Constants.PERMISSION_VIEW_NEW_SUBS_REPORTS, Constants.PERMISSION_VIEW_REVENUE_REPORTS, Constants.PERMISSION_VIEW_SUBS_RETENTION_REPORTS, Constants.PERMISSION_VIEW_CONT_METRICS_REPORTS, Constants.PERMISSION_VIEW_SUBS_KEY_METRICS_REPORTS, Constants.PERMISSION_VIEW_SCREEN_DASHBOARD, Constants.PERMISSION_VIEW_MINE_DATA],
      submenu: [
        {
          label: 'All',
          routerLink: '/reports/all',
          iconType: 'letter',
          iconName: 're'
        },
        {
          label: 'Subs Performance',
          routerLink: '/reports/subscription',
          iconType: 'letter',
          iconName: 'sp'
        },
        {
          label: 'Datawall Overview',
          routerLink: '/reports/datawall',
          iconType: 'letter',
          iconName: 'dw'
        },
        {
          label: 'New Subscribers',
          routerLink: '/reports/newsubs',
          iconType: 'letter',
          iconName: 'ns'
        },
        {
          label: 'Revenue',
          routerLink: '/reports/revenue',
          iconType: 'letter',
          iconName: 're'
        },
        {
          label: 'Subs Retention',
          routerLink: '/reports/totlostsave',
          iconType: 'letter',
          iconName: 'sr'
        },
        {
          label: 'Content Metrics',
          routerLink: '/reports/content',
          iconType: 'letter',
          iconName: 'cm'
        },
        {
          label: 'Subs Key Metrics',
          routerLink: '/reports/subsmetrics',
          iconType: 'letter',
          iconName: 'sm'
        },
        {
          label: 'Mine Data',
          routerLink: '/reports/mineData',
          iconType: 'letter',
          iconName: 'md'
        },
        {
          label: 'Screen Dashboard',
          routerLink: '/reports/screenDashboard',
          iconType: 'letter',
          iconName: 'sd'
        }
      ]
    },
    {
      label: 'Configurations',
      iconType: 'fi',
      iconName: 'settings',
      toggle: 'close',
      access: [Constants.PERMISSION_VIEW_CURRENCIES, Constants.PERMISSION_VIEW_CONTENT_CATEGORIES, Constants.PERMISSION_VIEW_CONTENT_TYPES, Constants.PERMISSION_VIEW_ACTION_TYPES, Constants.PERMISSION_VIEW_PERIODS, Constants.PERMISSION_VIEW_USER_ROLES, Constants.PERMISSION_VIEW_CANCEL_REASONS, Constants.PERMISSION_VIEW_PAYMENT_TYPES],
      submenu: [
        {
          label: 'Currencies',
          routerLink: '/config/currency/all',
          iconType: 'letter',
          iconName: 'cr'
        },
        {
          label: 'Content Categories',
          routerLink: '/config/contcat/all',
          iconType: 'letter',
          iconName: 'cc'
        },
        {
          label: 'Content Types',
          routerLink: '/config/conttype/all',
          iconType: 'letter',
          iconName: 'ct'
        },
        {
          label: 'Met. Action Types',
          routerLink: '/config/metactiontype/all',
          iconType: 'letter',
          iconName: 'at'
        },
        {
          label: 'Periods',
          routerLink: '/config/periods/all',
          iconType: 'letter',
          iconName: 'pr'
        },{
          label: 'User Roles',
          routerLink: '/config/userrole/all',
          iconType: 'letter',
          iconName: 'ur'
        },{
          label: 'Cancel Reasons',
          routerLink: '/config/cancelreason/all',
          iconType: 'letter',
          iconName: 'cr'
        },{
          label: 'Payment Types',
          routerLink: '/config/paymenttype/all',
          iconType: 'letter',
          iconName: 'pt'
        }
      ]
    },{
      label: 'Settings',
      iconType: 'fi',
      iconName: 'layout',
      toggle: 'close',
      access: [Constants.PERMISSION_VIEW_MANAGE_ADMIN, Constants.PERMISSION_ADD_ADMIN, Constants.PERMISSION_EDIT_ADMIN, Constants.PERMISSION_CHANGE_ADMIN_PASSWORD],
      submenu: [
        {
          label: 'Manage Admin',
          routerLink: '/settings/admin/all',
          iconType: 'letter',
          iconName: 'ma'
        }
      ]
    },
  ];
  expandNavigation(){
    const bodyElement = document.body;
    let boydClass = bodyElement.classList.contains('menu-pin');
    bodyElement.classList.toggle('menu-pin');
    if(boydClass === true ){
      this.title = 'Show Side Panel'
    }else{
      this.title = 'Collapse Side Panel'
    }
    setTimeout(()=>{ 
      window.dispatchEvent(new Event('resize'));
    }, 500);

  }
  mouseEnter(){
    const bodyElement = document.body;
    let boydClass = bodyElement.classList.contains('menu-pin');
    if(boydClass === false ){
      bodyElement.classList.add('sidebar-visible');
      (document.querySelector('.page-sidebar') as HTMLElement).style.transform = "translate3d(210px, 0px, 0px)"
    }
  }
  mouseOut(){
    const bodyElement = document.body;
    let boydClass = bodyElement.classList.contains('menu-pin');
    if(boydClass === false ){
      bodyElement.classList.remove('sidebar-visible');
      (document.querySelector('.page-sidebar') as HTMLElement).style.transform = "translate3d(0px, 0px, 0px)"
    }
  }

  constructor(private commonService: CommonService) { 
      this.filteredMenuList = this.userPermission == Constants.PERMISSION_ALL_ACCESS ? this.allMenuList : this.validateMenuAccess();
      // console.log("-------filteredArray-----------"+JSON.stringify(this.filteredMenuList));
  }

  ngOnInit(): void {  
    this.commonService.activeLink$.subscribe((activeLink: string) => {
      this.activeLink = activeLink;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.togglePerfectScrollbar();
    });
  }

  toggleSideNav() {
    this.isSelected = !this.isSelected;
  }

 //Menu Toggle 
  @HostListener('window:resize', [])
  onResize() {
    this.togglePerfectScrollbar();
  }

  togglePerfectScrollbar() {
    this.isPerfectScrollbarDisabled = window.innerWidth < 1025;
  }

 toggleNavigationSub(event, item) {
   console.log('currentItem' + item)
   event.preventDefault();
   this.clicked = false;
   if (this.currentItem && this.currentItem !== item) {
     this.clicked = true;
     this.currentItem['toggle'] = 'close';
   }
   this.currentItem = item;
   item.toggle = item.toggle === 'close' ? 'open' : 'close';
 } 


 menuPermissionCheck(allMenuList, userPermission) {
    return allMenuList.filter(obj => {
      return userPermission.some(value => obj.access.includes(value));
  });
}

validateMenuAccess() {
  return this.menuPermissionCheck(this.allMenuList, this.userPermission);
}

}
