<div class="row">
	<a href="/reports/content"><img src="../assets/logo.png" width="150" class="brand"></a>
</div>
<div class="row">
	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div class="widget-8 card  bg-success no-margin widget-loader-bar">
			<div class="card-header top-left top-right">
				<div class="card-title">
					<span class="font-montserrat fs-11 all-caps">
						LAST WEEK
					</span>
				</div>
			</div>
			<div class="card-body no-padding">
				<div class="container-xs-height full-height">
					<div class="row-xs-height">
						<div class="col-xs-height col-top">
							<div class="p-l-20 p-t-50 p-b-40 p-r-20">
								<h3 class="no-margin p-b-5">$900</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div class="widget-8 card  bg-primary no-margin widget-loader-bar">
			<div class="card-header top-left top-right">
				<div class="card-title">
					<span class="font-montserrat fs-11 all-caps">
						LAST MONTH
					</span>
				</div>
			</div>
			<div class="card-body no-padding">
				<div class="container-xs-height full-height">
					<div class="row-xs-height">
						<div class="col-xs-height col-top">
							<div class="p-l-20 p-t-50 p-b-40 p-r-20">
								<h3 class="no-margin p-b-5">$25,000</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div class="widget-8 card  bg-complete no-margin widget-loader-bar">
			<div class="card-header top-left top-right">
				<div class="card-title">
					<span class="font-montserrat fs-11 all-caps">
						LAST 12 MONTH
					</span>
				</div>
			</div>
			<div class="card-body no-padding">
				<div class="container-xs-height full-height">
					<div class="row-xs-height">
						<div class="col-xs-height col-top">
							<div class="p-l-20 p-t-50 p-b-40 p-r-20">
								<h3 class="no-margin p-b-5">$300,000</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div class="widget-8 card bg-contrast-medium no-margin widget-loader-bar">
			<div class="card-header top-left top-right">
				<div class="card-title">
					<span class="font-montserrat fs-11 all-caps">
						TILL DATE
					</span>
				</div>
			</div>
			<div class="card-body no-padding">
				<div class="container-xs-height full-height">
					<div class="row-xs-height">
						<div class="col-xs-height col-top">
							<div class="p-l-20 p-t-50 p-b-40 p-r-20">
								<h3 class="no-margin p-b-5">$800,000</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="row ">
	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div #swiperContainer1 class="plain-live-widget swiperContainer">
			<pgcard
				AdditionalClasses="bg-complete1 widget-11 widget-11-3 full-width social-card share card no-border no-margin"
				MinimalHeader="true" BodyClass="no-padding" HeaderClass="hidden">
				<swiper [config]="config1">
					<div class="slide-front tiles slide active">
						<div class="card-header bg-complete1 clearfix">
							<h5 class="no-margin all-caps">New Subscribers</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-40 p-l-15">
							<div class="pull-left p-l-0">
								<h4 class="no-margin p-b-5 text-danger1 semi-bold all-caps">User Test1</h4>
								<div class="d-flex align-items-center small">
									<span>user.test1@gmail.com</span>
								</div>
							</div>
							<div class="pull-right">
								<h2 class="text-success">$199.99</h2>
							</div>
							<div class="clearfix"></div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-cwo font-montserrat">CWO</span>
							</div>
							<div class="pull-right hint-text">
								<span class="label font-montserrat fs-11">ANNUAL</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
					<div class="slide-back tiles">
						<div class="card-header bg-complete1 clearfix">
							<h5 class="no-margin all-caps">New Subscribers</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-40 p-l-15">
							<div class="pull-left p-l-0">
								<h4 class="no-margin p-b-5 text-danger1 semi-bold all-caps">User Test2</h4>
								<div class="d-flex align-items-center small">
									<span>user.test2@gmail.com</span>
								</div>
							</div>
							<div class="pull-right">
								<h2 class="text-success">$39.99</h2>
							</div>
							<div class="clearfix"></div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-hme font-montserrat">HME</span>
							</div>
							<div class="pull-right hint-text">
								<span class="label font-montserrat fs-11">MONTHLY</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
				</swiper>
			</pgcard>
		</div>
	</div>
	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div #swiperContainer2 class="plain-live-widget swiperContainer">
			<pgcard
				AdditionalClasses="bg-success1 widget-11 widget-11-3 full-width social-card share card no-border no-margin"
				MinimalHeader="true" BodyClass="no-padding" HeaderClass="hidden">
				<swiper [config]="config2">
					<div class="slide-front tiles slide active">
						<div class="card-header bg-success1 clearfix">
							<h5 class="no-margin all-caps">Content Performance</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-20 p-l-15">
							<div class="row">
								<div class="col-sm-12 col-lg-8 p-l-0">
									<h6 class="no-margin p-b-5 text-danger1 semi-bold">Here are the top 14 construction
										contracts awarded in July 2024 - Construction Week Online</h6>
								</div>
								<div class="col-sm-12 col-lg-4 playfair-display-sc-black text-center">
									<p class="playfair-display-sc-black rank-font">1</p>
								</div>
							</div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-cwo font-montserrat">CWO</span>
							</div>
							<div class="pull-right">
								<span class="label bg-success font-montserrat text-white">30,967</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
					<div class="slide-back tiles">
						<div class="card-header bg-success1 clearfix">
							<h5 class="no-margin all-caps">Content Performance</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-20 p-l-15">
							<div class="row">
								<div class="col-sm-12 col-lg-8 p-l-0">
									<h5 class="no-margin p-b-5 text-danger1 semi-bold">Abu Dhabi National Hotel appoints
										three leaders to its Kempinski hotels in Dubai - Hotelier Middle E</h5>
								</div>
								<div class="col-sm-12 col-lg-4 text-center">
									<p class="playfair-display-sc-black rank-font">2</p>
								</div>
							</div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-hme font-montserrat">HME</span>
							</div>
							<div class="pull-right">
								<span class="label bg-success font-montserrat text-white">5,133</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
				</swiper>
			</pgcard>
		</div>
	</div>
	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div #swiperContainer3 class="plain-live-widget swiperContainer">
			<pgcard
				AdditionalClasses="bg-success1 widget-11 widget-11-3 full-width social-card share card no-border no-margin"
				MinimalHeader="true" BodyClass="no-padding" HeaderClass="hidden">
				<swiper [config]="config3">
					<div class="slide-front tiles slide active">
						<div class="card-header bg-success1 clearfix">
							<h5 class="no-margin all-caps">Authors</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-40 p-l-15">
							<div class="row">
								<div class="col-sm-12 col-lg-8 p-l-0">
									<h4 class="no-margin p-b-5 text-danger1 semi-bold">Julianne Tolentino</h4>
								</div>
								<div class="col-sm-12 col-lg-4 playfair-display-sc-black text-center">
									<p class="playfair-display-sc-black rank-font">1</p>
								</div>
							</div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-cwo font-montserrat">CWO</span>
							</div>
							<div class="pull-right">
								<span class="label bg-success font-montserrat text-white">39,981</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
					<div class="slide-back tiles">
						<div class="card-header bg-success1 clearfix">
							<h5 class="no-margin all-caps">Authors</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-40 p-l-15">
							<div class="row">
								<div class="col-sm-12 col-lg-8 p-l-0">
									<h4 class="no-margin p-b-5 text-danger1 semi-bold">Misbaah Dilawar Mansoori</h4>
								</div>
								<div class="col-sm-12 col-lg-4 playfair-display-sc-black text-center">
									<p class="playfair-display-sc-black rank-font">2</p>
								</div>
							</div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-hme font-montserrat">HME</span>
							</div>
							<div class="pull-right">
								<span class="label bg-success font-montserrat text-white">11,946</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
				</swiper>
			</pgcard>
		</div>
	</div>

	<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
		<div #swiperContainer4 class="plain-live-widget swiperContainer">
			<pgcard
				AdditionalClasses="bg-success1 widget-11 widget-11-3 full-width social-card share card no-border no-margin"
				MinimalHeader="true" BodyClass="no-padding" HeaderClass="hidden">
				<swiper [config]="config4">
					<div class="slide-front tiles slide active">
						<div class="card-header bg-success1 clearfix">
							<h5 class="no-margin all-caps">Sections</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-40 p-l-15">
							<div class="row">
								<div class="col-sm-12 col-lg-8 p-l-0">
									<h4 class="no-margin p-b-5 text-danger1 semi-bold">Features</h4>
								</div>
								<div class="col-sm-12 col-lg-4 playfair-display-sc-black text-center">
									<p class="playfair-display-sc-black rank-font">1</p>
								</div>
							</div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-cwo font-montserrat">CWO</span>
							</div>
							<div class="pull-right">
								<span class="label bg-success font-montserrat text-white">39,981</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
					<div class="slide-back tiles">
						<div class="card-header bg-success1 clearfix">
							<h5 class="no-margin all-caps">Sections</h5>
							<div class="clearfix"></div>
						</div>
						<div class="card-body p-t-40 p-l-15">
							<div class="row">
								<div class="col-sm-12 col-lg-8 p-l-0">
									<h4 class="no-margin p-b-5 text-danger1 semi-bold">News</h4>
								</div>
								<div class="col-sm-12 col-lg-4 playfair-display-sc-black text-center">
									<p class="playfair-display-sc-black rank-font">2</p>
								</div>
							</div>
						</div>
						<div class="card-footer clearfix">
							<div class="pull-left">
								<span class="label bg-hme font-montserrat">HME</span>
							</div>
							<div class="pull-right">
								<span class="label bg-success font-montserrat text-white">11,946</span>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
				</swiper>
			</pgcard>
		</div>
	</div>
</div>


<div class="row">
	<div class="col-sm-12 col-md-12 col-lg-9">
		<div class="row">
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-cwo">
					<div class="card-header clearfix p-0 bg-cwo">
						<div class="d-flex align-items-center">
							<!--<span class="icon-thumbnail bg-warning-light pull-left ">CWO</span>-->
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/cwo_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-hme">
					<div class="card-header clearfix p-0 bg-hme">
						<div class="d-flex align-items-center">
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/HME_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-cat">
					<div class="card-header clearfix p-0 bg-cat">
						<div class="d-flex align-items-center">
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/CAT_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-oag">
					<div class="card-header clearfix p-0 bg-oag">
						<div class="d-flex align-items-center">
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/OAG_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-cid">
					<div class="card-header clearfix p-0 bg-cid">
						<div class="d-flex align-items-center">
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/CID_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-log">
					<div class="card-header clearfix p-0 bg-log">
						<div class="d-flex align-items-center">
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/LOG_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-fmme">
					<div class="card-header clearfix p-0 bg-fmme">
						<div class="d-flex align-items-center">
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/FMME_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin bg-mep">
					<div class="card-header clearfix p-0 bg-mep">
						<div class="d-flex align-items-center">
							<div class="flex-1 full-width logo-ctr">
								<img width="auto" height="auto" src="../../../../assets/images/logo/MEP_logo.png" alt="">
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-sm-12 col-md-12 col-lg-3">
		<div class="m-b-20">
			<div class="widget-11-3-table auto-overflow">
				<table class="table table-condensed table-hover brand-overview-tbl">
					<thead>
						<tr>
							<th>Brand</th>
							<th>Active Subs</th>
							<th>Revenue <br /> (Till Date)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="bg-avb">
								<div class="flex-1 full-width logo-ctr">
									<img width="auto" height="auto" src="../../../../assets/images/logo/AVB_logo.png" alt="">
								</div>
							</td>
							<td class="bg-avb text-white">200</td>
							<td class="bg-avb">
								<h5 class="semi-bold no-margin text-white"><sup><small class="semi-bold">$</small>
									</sup> 102,967
								</h5>
							</td>
						</tr>
						<tr>
							<td class="bg-utme">
								<div class="flex-1 full-width logo-ctr">
									<img width="auto" height="auto" src="../../../../assets/images/logo/UTME_logo.png" alt="">
								</div>
							</td>
							<td class="bg-utme text-white">200</td>
							<td class="bg-utme">
								<h5 class="semi-bold no-margin text-white"><sup><small class="semi-bold">$</small>
									</sup> 102,967
								</h5>
							</td>
						</tr>
						<tr>
							<td class="bg-pmv">
								<div class="flex-1 full-width logo-ctr">
									<img width="auto" height="auto" src="../../../../assets/images/logo/PMV_logo.png" alt="">
								</div>
							</td>
							<td class="bg-pmv text-white">200</td>
							<td class="bg-pmv">
								<h5 class="semi-bold no-margin text-white"><sup><small class="semi-bold">$</small>
									</sup> 102,967
								</h5>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-sm-12 col-md-12 col-lg-9">
		<div class="row">
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<!--<h5 class="text-success pull-left fs-12 d-flex align-items-center">Construction Week Middle East - CWO</h5>-->
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-cwo pull-left ">CWO</span>
							<div class="flex-1 full-width">
								<h5 class="no-margin">Construction Week Middle East</h5>
							</div>
						</div>
						<!--<div class="pull-right small hint-text d-flex align-items-center">
							5,345 (USERS)
						</div>-->
						<div class="clearfix"></div>
					</div>
					<div class="card-content flex-1"
						data-pages-bg-image="https://www.constructionweekonline.com/cloud/2022/01/06/cwo_logo_new.png"
						style="background-image: url(&quot;https://www.constructionweekonline.com/cloud/2022/01/06/cwo_logo_new.png&quot;);">

						<div class="bg-overlay" style="opacity: 0;"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-hme pull-left ">HME</span>
							<div class="flex-1 full-width ">
								<h5 class="no-margin  ">Hotelier Middle East</h5>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-cat pull-left ">CAT</span>
							<div class="flex-1 full-width ">
								<h5 class="no-margin  ">Caterer Middle East</h5>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-oag pull-left ">OAG</span>
							<div class="flex-1 full-width ">
								<h5 class="no-margin  ">Oil and Gas Middle East</h5>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-20">
									<h3 class="no-margin">200</h3>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-20">
									<h4 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-cid pull-left ">CID</span>
							<div class="flex-1 full-width ">
								<h5 class="no-margin  ">Commercial Interior Design</h5>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-10">
									<h4 class="no-margin">200</h4>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-10">
									<h5 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-log pull-left ">LOG</span>
							<div class="flex-1 full-width ">
								<h5 class="no-margin  ">Logistics Middle East</h5>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-10">
									<h4 class="no-margin">200</h4>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-10">
									<h5 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-fmme pull-left ">FMME</span>
							<div class="flex-1 full-width ">
								<h5 class="no-margin  ">Facilities Management Middle East
								</h5>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-10">
									<h4 class="no-margin">200</h4>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-10">
									<h5 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="widget-11 widget-11-3 full-width social-card share card no-border no-margin">
					<div class="card-header clearfix p-0">
						<div class="d-flex pull-left align-items-center">
							<span class="icon-thumbnail bg-mep pull-left ">MEP</span>
							<div class="flex-1 full-width ">
								<h5 class="no-margin  ">Mechanical Electrical and Plumbing Middle East</h5>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="card-description p-0 text-center">
						<div class="row">
							<div class="col-md-6 col-sm-12 border-right">
								<div class="padding-10">
									<h4 class="no-margin">200</h4>
									<p>(ACTIVE SUBS)</p>
								</div>
							</div>
							<div class="col-md-6 col-sm-12">
								<div class="padding-10">
									<h5 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
										</sup> 102,967
										<p>(TILL DATE)</p>
									</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-sm-12 col-md-12 col-lg-3">
		<div class="m-b-20">
			<div class="widget-11-3-table auto-overflow">
				<table class="table table-condensed table-hover brand-overview-tbl">
					<thead>
						<tr>
							<th>Brand</th>
							<th>Active Subs</th>
							<th>Revenue <br /> (Till Date)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><span class="icon-thumbnail bg-avb pull-left ">AVB</span></td>
							<td>200</td>
							<td>
								<h5 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
									</sup> 102,967
								</h5>
							</td>
						</tr>
						<tr>
							<td><span class="icon-thumbnail bg-utme pull-left ">UTME</span></td>
							<td>200</td>
							<td>
								<h5 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
									</sup> 102,967
								</h5>
							</td>
						</tr>
						<tr>
							<td><span class="icon-thumbnail bg-pmv pull-left ">PMV</span></td>
							<td>200</td>
							<td>
								<h5 class="semi-bold no-margin"><sup><small class="semi-bold">$</small>
									</sup> 102,967
								</h5>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<div class="row hide">
	<div class="col-sm-12 col-md-12 col-lg-9">
		<div class="row">
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<div class="ar-1-1">
					<pgcard TimeOut="3000"
						class="widget-2 card no-border bg-primary widget widget-loader-circle-lg no-margin full-height"
						MinimalHeader="true" RefreshColor="light">
						<div class="pull-bottom bottom-left bottom-right padding-25">
							<span class="label font-montserrat fs-11">NEWS</span>
							<br>
							<h3 class="text-white">So much more to see at a glance.</h3>
							<p
								class="text-white hint-text d-none d-lg-block d-xl-block d-none d-lg-block d-xl-block no-margin">
								Learn More at Project Pages</p>
						</div>
					</pgcard>
				</div>
			</div>
			<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 m-b-20">
				<pgcard class="widget-18 card no-border  no-margin " HeaderClass="hidden" BodyClass="no-padding">
					<div class="padding-15">
						<div class="item-header clearfix">
							<div class="thumbnail-wrapper d32 circular">
								<img width="40" height="40" src="../../../../assets/images/3x.jpg" alt="">
							</div>
							<div class="inline m-l-10">
								<p class="no-margin">
									<strong>Anne Simons</strong>
								</p>
								<p class="no-margin hint-text">Shared a link
									<span class="location semi-bold"><i class="pg-icon">map</i> NY center</span>
								</p>
							</div>
						</div>
					</div>
					<div class="relative">
						<ul class="buttons pull-top top-right list-inline p-r-10 p-t-10">
							<li>
								<a class="text-white" href="javascript:void(0)"><i class="pg-icon">expand</i>
								</a>
							</li>
							<li>
								<a class="text-white" href="javascript:void(0)"><i class="pg-icon">heart_outline</i>
								</a>
							</li>
						</ul>
						<div class="widget-18-post bg-black no-overflow">
						</div>
					</div>
					<div class="padding-15">
						<div class="hint-text pull-left fs-13">few seconds ago</div>
						<ul class="list-inline pull-right no-margin">
							<li><a class="text-color hint-text fs-13 d-flex" href="#"><i
										class="pg-icon m-r-5">comment_alt</i> 5,345</a>
							</li>
							<li><a class="text-color hint-text fs-13 d-flex" href="#"><i class="pg-icon m-r-5">heart</i>
									23K</a>
							</li>
						</ul>
						<div class="clearfix"></div>
					</div>
				</pgcard>

			</div>
		</div>
	</div>
</div>