import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-new-subs',
  templateUrl: './new-subs.component.html',
  styleUrls: ['./new-subs.component.scss']
})
export class NewSubsComponent implements OnInit, AfterViewInit {
  selectedOption = 'all';
  selBrand = 'all';
  allActiveBrands: any[] = [];
  reportsColor: any[] = [];
  alerts: any[] = [];
  lastSyncTime: string;
  showLoadingSpinner = true;
  allNewSubData;
  churnRateData;
  periodSubsChart: any;
  noDataAvailable = false;
  viewSubsReportsPrmsn = true;
  months: string[] = [];
  brandNames: string[] = [];
  planNames: string[] = [];
  tableData: { [brand: string]: { [month: string]: { [plan: string]: number } } } = {}; // updated type
  newSubs: number;
  lost: number;
  netSubs: number;
  churnRate: string;
  churnRataData: { [key: string]: { totalChurnRate: any, count: number } } = {};

  @ViewChild('myCanvas') canvasRef!: ElementRef;

  private readonly monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private reportsService: ReportsService
  ) {
    this.reportsColor = Constants.REPORTS_CONSTANT_COLORS;
    this.viewSubsReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_NEW_SUBS_REPORTS]);
    this.commonService.subNavSelect(Constants.NAV_DASHBOARD);
    this.getAllActiveBrands();
  }

  ngOnInit(): void {
    this.titleService.setTitle('New Subscribers');
    this.commonService.setTitle('New Subscribers');

  }

  ngAfterViewInit(): void {
    //this.getNewSubsData(false);
  }

  getAllActiveBrands(): void {
    if (this.viewSubsReportsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res.code === 1 && res.status === 1) {
            this.allActiveBrands = res.result;
            this.getNewSubsData(false);
          } else {
            this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
        },
        error => {
          this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      );
    }
  }

  brandFilterChange(val: string): void {
    this.selBrand = val;
    this.generateNewSubsData(this.allNewSubData, this.churnRateData, this.selBrand);
  }

  getNewSubsData(isRefreshed: boolean): void {
    this.showLoadingSpinner = true;
    this.noDataAvailable = false;
    this.reportsService.getNewSubsData(0, isRefreshed).then(
      res => {
        if (res['code'] == 1 && res['status'] == 1) {
          if (res.result.periodSubs) {
           /* const processedData = this.processData(res.result.periodSubs);
            const processDataTable = this.processDataTable(res.result.periodSubs);
            const churnRate = res.result.churnRates;
            this.generateChartData(processedData, churnRate);
            this.generateTableData(processDataTable);*/
            this.allNewSubData = res.result.periodSubs;
            this.churnRateData = res.result.churnRates;
            this.generateNewSubsData(this.allNewSubData, this.churnRateData, this.selBrand);
            let dashboardData = res['result'].subs;
            this.newSubs = dashboardData['newSubs'];
            this.lost = dashboardData['lost'];
            this.netSubs = dashboardData['netSubs'];
            this.lastSyncTime = res['result'].last_sync_time
            this.churnRate = dashboardData['churRate'] ;
          } else {
            this.noDataAvailable = true;
          }
        } else {
          this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
        this.showLoadingSpinner = false;
      }, error => {
        this.alerts = [{
          type: 'danger',
          msg: Constants.DASH_DATA_FAILURE_MSG,
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        this.showLoadingSpinner = false;
      });
  }

  generateNewSubsData(allData: any[], churnData : any[], selBrand: string) {
    this.showLoadingSpinner = true;
    this.noDataAvailable = false;
    if(allData.length>0) {
      this.showLoadingSpinner = false;
      const processedData = this.processData(allData, selBrand);
      const processDataTable = this.processDataTable(allData, selBrand);
      this.generateChartData(processedData, churnData);
      this.generateTableData(processDataTable);
    } else {
      this.showLoadingSpinner = false;
      this.noDataAvailable = true;
    }
  }

  processData(allData: any[], selBrand): { labels: string[], brandData: { [key: string]: { [key: string]: number } }, totalChurnRates: number[], months: string[], brandNames: string[], planNames: string[] } {
    const labels: string[] = [];
    const brandData: { [key: string]: { [key: string]: number } } = {};
    const totalChurnRates: number[] = [];
    const months: string[] = [];
    const brandNames: string[] = [];
    const planNames: string[] = [];

    //allData = {"code":1,"status":1,"message":"Data found","result":{"periodSubs":[{"date":"2023-08-22","data":[{"brand":"HME","plans":[{"plan_name":"Annual","subs":3}]}]},{"date":"2023-08-24","data":[{"brand":"FMME","plans":[{"plan_name":"Annual","subs":1}]},{"brand":"HME","plans":[{"plan_name":"Monthly","subs":1}]}]},{"date":"2023-08-25","data":[{"brand":"CID","plans":[{"plan_name":"Monthly","subs":1}]}]},{"date":"2023-08-26","data":[{"brand":"CAT","plans":[{"plan_name":"Annual","subs":1}]}]},{"date":"2023-08-27","data":[{"brand":"LOG","plans":[{"plan_name":"Monthly","subs":1}]}]},{"date":"2023-08-28","data":[{"brand":"CWO","plans":[{"plan_name":"Monthly","subs":1}]},{"brand":"LOG","plans":[{"plan_name":"Monthly","subs":1}]}]},{"date":"2023-08-29","data":[{"brand":"CAT","plans":[{"plan_name":"Annual","subs":1}]},{"brand":"CID","plans":[{"plan_name":"Monthly","subs":1}]}]},{"date":"2023-08-30","data":[{"brand":"CAT","plans":[{"plan_name":"Annual","subs":1}]},{"brand":"CWO","plans":[{"plan_name":"Monthly","subs":1}]},{"brand":"MEP","plans":[{"plan_name":"Monthly","subs":1}]}]},{"date":"2023-08-31","data":[{"brand":"CID","plans":[{"plan_name":"Annual","subs":1}]},{"brand":"CWO","plans":[{"plan_name":"Annual","subs":1}]},{"brand":"HME","plans":[{"plan_name":"Annual","subs":1}]}]},{"date":"2023-09-01","data":[{"brand":"CID","plans":[{"plan_name":"Monthly","subs":1}]},{"brand":"PMV","plans":[{"plan_name":"Corporate Subscription","subs":14}]}]},{"date":"2023-09-04","data":[{"brand":"CWO","plans":[{"plan_name":"Monthly","subs":1}]},{"brand":"FMME","plans":[{"plan_name":"Annual","subs":1}]},{"brand":"OAG","plans":[{"plan_name":"Annual","subs":1}]}]}]}};
    allData.forEach(item => {

      const month = item.date.substring(0, 7);
      const monthName = `${this.monthNames[parseInt(month.split('-')[1], 10) - 1]} ${month.split('-')[0]}`;
      if (!labels.includes(monthName)) labels.push(monthName);
      if (!months.includes(monthName)) months.push(monthName);

      let totalSubsForMonth = 0;
      let totalChurnRateForMonth = 0;
      let churnRateCount = 0;
      
      item.data.forEach((brandDataItem: any) => {
        const brand = brandDataItem.brand;
        if (!brandNames.includes(brand)) brandNames.push(brand);

        let totalSubs = 0;
        let planName;
        brandDataItem.plans.forEach(plan => {
          planName = plan.plan_name;
          totalSubs += plan.subs;
          totalChurnRateForMonth += plan.churn_rate;
          churnRateCount += 1;
        });
        this.planNames = planName;
        totalSubsForMonth += totalSubs;

        if (!brandData[brand]) {
          brandData[brand] = {};
        }

        // Accumulate subscriptions if the month already exists for the brand
        if (brandData[brand][monthName]) {
          brandData[brand][monthName] += totalSubs;
        } else {
          brandData[brand][monthName] = totalSubs;
        }
      });
        const avgChurnRateForMonth = churnRateCount > 0 ? totalChurnRateForMonth / churnRateCount : 0;
        totalChurnRates.push(avgChurnRateForMonth);
    });

    brandNames.sort();
    console.log("-----labels----------"+JSON.stringify(labels))
    console.log("-----brandData----------"+JSON.stringify(brandData))
    console.log("-----months----------"+JSON.stringify(months))
    console.log("-----brandNames----------"+JSON.stringify(brandNames))
    console.log("-----planNames----------"+JSON.stringify(this.planNames))
    console.log("-----totalChurnRates----------"+JSON.stringify(totalChurnRates))

    if (selBrand === 'all') {
      return { labels, brandData, totalChurnRates, months, brandNames, planNames };
    } else {
        const filteredBrands = { [selBrand]: brandData[selBrand] };
        return { labels, brandData: filteredBrands, totalChurnRates, months, brandNames: [selBrand], planNames };
    }
  }
  
  generateChartData(processedData: { labels: string[], brandData: { [key: string]: { [key: string]: number } }, brandNames: string[]}, churnRate): void {
    const { labels, brandData} = processedData;
    const colors = this.reportsColor;

    const months: string[] = [];
    churnRate.forEach(item => {
    
      const month = item.date.substring(0, 7);
      const monthName = `${this.monthNames[parseInt(month.split('-')[1], 10) - 1]} ${month.split('-')[0]}`;
      if (!labels.includes(monthName)) labels.push(monthName);
      if (!months.includes(monthName)) months.push(monthName);

    
      if (!this.churnRataData[monthName]) {
        this.churnRataData[monthName] = { totalChurnRate: 0, count: 0 };
      }
      this.churnRataData[monthName].totalChurnRate += parseFloat(item.ChurnRate);
      this.churnRataData[monthName].count += 1;
    });

    Object.keys(this.churnRataData).forEach(month => {
      const data = this.churnRataData[month];
      data.totalChurnRate /= data.count;
    });
      
   

    const lineArrayData  =  Object.values(this.churnRataData).map(item => parseFloat(item.totalChurnRate).toFixed(2) );
    console.log(lineArrayData)


    
    let colorIndex = 0;

    const datasets = Object.keys(brandData).map(brand => {
        const color = colors[colorIndex % colors.length]; // Get color from the array in a loop
        colorIndex++;
        return {
            label: brand,
            data: labels.map(label => brandData[brand][label] || 0),
            backgroundColor: color
        };
    });



    if (this.periodSubsChart) this.periodSubsChart.destroy();

    const ctx = this.canvasRef.nativeElement.getContext('2d');
    this.periodSubsChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Churn Rate (%)',
            data: lineArrayData , // Example data for the line chart
            type: 'line', // Specify the type as 'line'
            borderColor: 'rgba(75, 192, 192, 1)', // Color of the line
            backgroundColor: 'rgba(75, 192, 192, 0.5)', // Fill color under the line
            fill: false, 
            borderWidth: 2,
             
          },
          ...datasets// existing bar chart datasets
        ]
      },
      options: {
        animation: {
          duration: 1000,
          easing: 'easeInOutQuad',
        },
        tooltips: {
          callbacks: {
            title: (tooltipItems) => {
              const label = tooltipItems[0].label;
              const [month, year] = label.split(' ');
              return `${month} ${year}`;
            }
          }
        },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                return value.toLocaleString(); // Convert value to comma-separated string
              }
            },
            type: 'linear'
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
      }
    });
  }

  processDataTable(allData: any[], selBrand): { months: string[], brandData: { [key: string]: { [key: string]: { [key: string]: number } } }, brandNames: string[], planNames: string[] } {
    const labels: string[] = [];
    const brandData: { [key: string]: { [key: string]: { [key: string]: number } } } = {};
    const months: string[] = [];
    const brandNames: string[] = [];
    const planNames: string[] = [];

    allData.forEach(item => {
        const month = item.date.substring(0, 7);
        const monthName = `${this.monthNames[parseInt(month.split('-')[1], 10) - 1]} ${month.split('-')[0]}`;
        if (!labels.includes(monthName)) labels.push(monthName);
        if (!months.includes(monthName)) months.push(monthName);

        item.data.forEach((brandDataItem: any) => {
            const brand = brandDataItem.brand;
            if (!brandNames.includes(brand)) brandNames.push(brand);

            brandDataItem.plans.forEach(plan => {
                const planName = plan.plan_name;
                if (!planNames.includes(planName)) planNames.push(planName);

                if (!brandData[brand]) brandData[brand] = {};
                if (!brandData[brand][monthName]) brandData[brand][monthName] = {};

                // Accumulate subscriptions if the plan already exists for the brand and month
                if (brandData[brand][monthName][planName]) {
                    brandData[brand][monthName][planName] += plan.subs;
                } else {
                    brandData[brand][monthName][planName] = plan.subs;
                }
            });
        });
    });

    brandNames.sort();

    if (selBrand === 'all') {
        return { months, brandData, brandNames, planNames };
    } else {
        const filteredBrands = { [selBrand]: brandData[selBrand] };
        return { months, brandData: filteredBrands, brandNames: [selBrand], planNames };
    }
  }

  generateTableData(processDataTable: { months: string[], brandData: { [key: string]: { [key: string]: { [key: string]: number } } }, brandNames: string[], planNames: string[] }): void {
    const { months, brandData, brandNames, planNames } = processDataTable;
    this.months = months;
    this.brandNames = brandNames;
    this.planNames = planNames;
    this.tableData = brandData;
  }

  getData(month: string, brand: string, plan: string): number {
    return this.tableData[brand]?.[month]?.[plan] ?? 0; // Safeguard: ?. and ?? to handle undefined
  }

  getBrandDisplayName(brandName) {
    return this.reportsService.getBrandDisplayName(this.allActiveBrands, brandName);
  }
}
