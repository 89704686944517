import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ExperienceComponent } from './experience/experience.component';
import { AllExperiencesComponent } from './all-experiences/all-experiences.component';
import { AddExperienceComponent } from './add-experience/add-experience.component';
import { EditExperienceComponent } from './edit-experience/edit-experience.component';

import { AuthGuard } from '../../common/auth.guard';

const routes: Routes = [
    {
        path: 'experience',
        component: ExperienceComponent,
        canActivate: [AuthGuard],
        children: [
            {
              path: 'all',
              component: AllExperiencesComponent,
            },
            {
              path: 'add',
              component: AddExperienceComponent,
            },
            {
              path: 'edit/:id',
              component: EditExperienceComponent
            },
            { path: '', redirectTo: 'experience/all', pathMatch: 'full' }
        ]
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExperienceRoutingModule { }