import { Component, OnInit, ViewEncapsulation,  ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-screen-dashboard',
  templateUrl: './screen-dashboard.component.html',
  styleUrls: ['./screen-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ScreenDashboardComponent implements OnInit {
  @ViewChild('swiperContainer1') swiperContainer1!: ElementRef;
  @ViewChild('swiperContainer2') swiperContainer2!: ElementRef;
  @ViewChild('swiperContainer3') swiperContainer3!: ElementRef;
  @ViewChild('swiperContainer4') swiperContainer4!: ElementRef;
  config1 = {
    direction: 'vertical',
    slidesPerView: 1,
    autoplay: {delay: 3000},
    
  }
  config2 = {
    direction: 'vertical',
    slidesPerView: 1,
    autoplay: {delay: 4000},
    
  }
  config3 = {
    direction: 'vertical',
    slidesPerView: 1,
    autoplay: {delay: 3000},
    
  }
  config4 = {
    direction: 'vertical',
    slidesPerView: 1,
    autoplay: {delay: 4000},
    
  }

  constructor(private commonService: CommonService, private cdr: ChangeDetectorRef, private titleService: Title, private reportsService: ReportsService, private elementRef: ElementRef) {}

  ngOnInit() {
    this.getScreenDashboardData(false);
  }

  getScreenDashboardData(isRefreshed) {
    this.reportsService.getScreenDashboardData(isRefreshed);
  }

}
