import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';
import { Chart, ChartConfiguration } from 'chart.js';

interface MonthRev {
  month: string;
  brand_id: string;
  brand_name: string;
  MRR: number;
};
interface AnnualRev {
  year: string;
  brand_id: string;
  brand_name: string;
  MRR: number;
}

@Component({
  selector: 'app-subs-metrics',
  templateUrl: './subs-metrics.component.html',
  styleUrls: ['./subs-metrics.component.scss']
})
export class SubsMetricsComponent implements OnInit {
  allActiveBrands: any[] = [];
  reportsColor: any[] = [];
  alerts: any[] = [];
  lastSyncTime: string;
  selBrand = 0;
  avgRevTblData : any[] = [];
  custChurnRateTblData : any[] = [];
  revChurnRateTblData : any[] = [];
  lifeTimeValTblData : any[] = [];
  
  showLoadingSpinner = true;
  showMonthlyMrrldngSpnr = true;
  showAnnualMrrldngSpnr = true;
  showAvgRevldngSpnr = true;
  showCustChurnRateldngSpnr = true;
  showRevChurnRateldngSpnr = true;
  showLifeTimeValldngSpnr = true;

  monthlyMrrBrand = 'all';
  annualMrrBrand = 'all';
  arpuBrand = 'all';
  ltvBrand = 'all';
  custChurnRateBrand = 'all';
  revChurnRateBrand = 'all';

  periodSubsChart: any;
  monthlyMrrChart: any;
  annualMrrChart: any;
  custChurnRateChart : any;
  revChurnRateChart : any;

  monthlyMrrData;
  annualMrrData;
  arpuData;
  ltvData;
  custChurnRateData;
  revChurnRateData;

  mrrMonthTblData: any = {};
  mrrMonthTblBrands: string[] = [];
  mrrMonthTblMonths: string[] = [];
  mrrAnnualTblData: any = {};
  mrrAnnualTblBrands: string[] = [];
  mrrAnnualTblYears: string[] = [];

  noDataAvailable = false;
  noMonthlyMrrDataAvailable = false;
  noAnnualMrrDataAvailable = false;
  noAvgRevDataAvailable = false;
  noCustChurnRateDataAvailable = false;
  noRevChurnRateDataAvailable = false;
  noLifeTimeValAvailable = false;

  viewSubsReportsPrmsn = true;
  months: string[] = [];
  brandNames: string[] = [];
  tableData: { [key: string]: { [key: string]: number } } = {};
  private readonly monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
 
  @ViewChild('myCanvas') canvasRef: ElementRef;
  @ViewChild('monthlyMrrCanvas') monthlyMrrCanvasRef: ElementRef;
  @ViewChild('annualMrrCanvas') annualMrrCanvasRef: ElementRef;
  @ViewChild('custChurnRateCanvas') custChurnRateCanvasRef: ElementRef;
  @ViewChild('revChurnRateCanvas') revChurnRateCanvasRef: ElementRef;

  constructor(
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private reportsService: ReportsService
  ) {
    this.reportsColor = Constants.REPORTS_CONSTANT_COLORS;
    this.viewSubsReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_SUBS_KEY_METRICS_REPORTS]);
    this.commonService.subNavSelect(Constants.NAV_DASHBOARD);
    this.getAllActiveBrands();
    //this.getSubsKeyMetricsMonthlyData(false);
  }

  ngOnInit(): void {
    this.titleService.setTitle('Subs Key Metrics');
    this.commonService.setTitle('Subs Key Metrics');

  }

  getAllActiveBrands(): void {
    if (this.viewSubsReportsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res.code === 1 && res.status === 1) {
            this.allActiveBrands = res.result;
            this.loadAllCharts(false);
          } else {
            this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
        },
        error => {
          this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      );
    }
  }

  monthlyMrrBrandFltrChage(val: string): void {
    this.monthlyMrrBrand = val;
    this.generateMonthlyMrrData( this.monthlyMrrData, this.monthlyMrrBrand);
  }

  annualMrrBrandFltrChage(val: string): void {
    this.annualMrrBrand = val;
    this.generateAnnualMrrData( this.annualMrrData, this.annualMrrBrand);
  }

  arpuBrandFltrChage(val: string) : void {
    this.arpuBrand = val;
    this.generateArpuData(this.arpuData, this.arpuBrand);
  }
  
  ltvBrandFltrChage(val: string) : void {
    this.ltvBrand = val;
    this.generateLtvData(this.ltvData, this.ltvBrand);
  }

  custChurnRtBrandFltrChage(val: string) : void {
    this.custChurnRateBrand = val;
    this.generatecustChurnRateData(this.custChurnRateData, this.custChurnRateBrand);
  }

  revChurnRtBrandFltrChage(val: string) : void {
    this.revChurnRateBrand = val;
    this.generateRevChurnRateData(this.revChurnRateData, this.revChurnRateBrand);
  }

  loadAllCharts(isRefreshed) {
    this.getSubsKeyMetricsMonthlyMRRData(isRefreshed);
    this.getSubsKeyMetricsAnnualMRRData(isRefreshed);
    this.getSubsKeyMetricsAvgRevData(isRefreshed);
    this.getSubsKeyMetricsChurnRateData(isRefreshed);
    this.getSubsKeyMetricRevChurnRateData(isRefreshed);
    this.getSubsKeyMetricLifeTimeValData(isRefreshed);
  }

  getSubsKeyMetricsMonthlyMRRData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      this.showMonthlyMrrldngSpnr = true;
      this.noMonthlyMrrDataAvailable = false;
      this.reportsService.getSubsKeyMetricsMonthlyData(this.selBrand, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res.result.monthRev) {
              this.monthlyMrrData = res.result.monthRev;
              this.generateMonthlyMrrData(this.monthlyMrrData, this.monthlyMrrBrand);
            } else {
              this.noMonthlyMrrDataAvailable = true;
            }
          } else {
            this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
          this.showMonthlyMrrldngSpnr = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showMonthlyMrrldngSpnr = false;
        });
      }
  }

  getSubsKeyMetricsAnnualMRRData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      this.showAnnualMrrldngSpnr = true;
      this.noAnnualMrrDataAvailable = false;
      this.reportsService.getSubsKeyMetricsAnnualData(this.selBrand, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res.result.annualRev) {
              this.annualMrrData = res.result.annualRev;
              this.generateAnnualMrrData(this.annualMrrData, this.annualMrrBrand);
            } else {
              this.noAnnualMrrDataAvailable = true;
            }
          } else {
            this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
          this.showAnnualMrrldngSpnr = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showAnnualMrrldngSpnr = false;
        });
      }
  }

  generateMonthlyMrrData(allData: any[], selBrand: string) {
    this.showMonthlyMrrldngSpnr = true;
    this.noMonthlyMrrDataAvailable = false;
    if(allData.length>0) {
      this.showMonthlyMrrldngSpnr = false;
      const processedData = this.processMrrData(allData, selBrand, 'month');
      this.generateMrrChartMonthly(processedData);
      const { transformedData, lblArray } = this.processMrrTblData(allData, selBrand, 'month');
      this.mrrMonthTblData = transformedData;
      this.mrrMonthTblMonths = lblArray;
      this.mrrMonthTblBrands = Object.keys(this.mrrMonthTblData);
    } else {
      this.showMonthlyMrrldngSpnr = false;
      this.noMonthlyMrrDataAvailable = true;
    }
  }

  generateAnnualMrrData(allData: any[], selBrand: string) {
    this.showAnnualMrrldngSpnr = true;
    this.noAnnualMrrDataAvailable = false;
    if(allData.length>0) {
      this.showAnnualMrrldngSpnr = false;
      const processedData = this.processMrrData(allData, selBrand, 'annual');
      this.generateMrrChartAnnual(processedData);
      const { transformedData, lblArray } = this.processMrrTblData(allData, selBrand, 'annual');
      this.mrrAnnualTblData = transformedData;
      this.mrrAnnualTblYears = lblArray;
      this.mrrAnnualTblBrands = Object.keys(this.mrrAnnualTblData);
    } else {
      this.showAnnualMrrldngSpnr = false;
      this.noAnnualMrrDataAvailable = true;
    }
  }

  processMrrData(data, selBrand, type) {
    const brands = {};
    data.forEach(item => {
      if (!brands[item.brand_name]) {
        brands[item.brand_name] = [];
      }
      type === 'month' ? brands[item.brand_name].push({ month: item.month, MRR: item.MRR }) : brands[item.brand_name].push({ year: item.year, MRR: item.MRR });
    });
    if (selBrand === 'all') {
      return brands;
    } else {
      let obj = {};
      obj[selBrand] = brands[selBrand];
      return obj;
    }
  }

  generateMrrChartMonthly(data) {
    const ctx = this.monthlyMrrCanvasRef.nativeElement.getContext('2d');
    const datasets: ChartConfiguration<'line'>['data']['datasets'] = [];

    // Collect unique months from all data points
    const labelsSet = new Set();
    Object.keys(data).forEach(brand => {
        data[brand].forEach(item => labelsSet.add(item.month));
    });
    const labels = Array.from(labelsSet).sort(); // Sort to ensure the months are in order
    const colors = this.reportsColor;
    let colorIndex = 0;

    Object.keys(data).forEach(brand => {
      const color = colors[colorIndex % colors.length]; // Get color from the array in a loop
      colorIndex++;

      // Convert the color to an RGBA string
      const rgbaColor = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.5)`;

      // Create an array of MRR values aligned with the sorted labels
      const brandData = labels.map(month => {
          const entry = data[brand].find(item => item.month === month);
          return entry ? entry.MRR : null; // Use null or 0 if data is missing for that month
      });

      datasets.push({
          label: brand,
          data: brandData,
          fill: true, // This will fill the area under the line
          backgroundColor: rgbaColor, // Use the RGBA color for the fill
          borderColor: color,
          tension: 0.1
      });
  });

    if (this.monthlyMrrChart) this.monthlyMrrChart.destroy();
    this.monthlyMrrChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {
            responsive: true,
            scales: {
                xAxes: {
                    type: 'category',
                    title: {
                        display: true,
                        text: 'Month'
                    }
                },
                yAxes: [{
                    title: {
                        display: true,
                        text: 'MRR'
                    },
                    ticks: {
                        beginAtZero: true,
                        callback: (value) => '$'+value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    },
                    type: 'linear'
                }]
            },
            animation: {
                duration: 1000,
                easing: 'easeInOutQuad',
            },
            tooltips: {
                callbacks: {
                    title: (tooltipItems) => {
                        const label = tooltipItems[0].label;
                        return `${label}`;
                    },
                    label: (tooltipItem) => {
                        const dataset = tooltipItem.datasetIndex !== undefined ? datasets[tooltipItem.datasetIndex] : null;
                        const label = dataset ? dataset.label : 'Unknown';
                        const value = '$'+tooltipItem.yLabel.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        return `${label}: ${value}`;
                    }
                }
            },
        }
    });
}

generateMrrChartAnnual(data) {
  const ctx = this.annualMrrCanvasRef.nativeElement.getContext('2d');
  const datasets: ChartConfiguration<'line'>['data']['datasets'] = [];

  // Collect unique years from all data points
  const labelsSet = new Set();
  Object.keys(data).forEach(brand => {
      data[brand].forEach(item => labelsSet.add(item.year));
  });
  const labels = Array.from(labelsSet).sort(); // Sort to ensure the years are in order
  const colors = this.reportsColor;
  let colorIndex = 0;

  Object.keys(data).forEach(brand => {
    const color = colors[colorIndex % colors.length]; // Get color from the array in a loop
    colorIndex++;

    // Convert the color to an RGBA string
    const rgbaColor = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.5)`;

    // Create an array of MRR values aligned with the sorted labels
    const brandData = labels.map(year => {
        const entry = data[brand].find(item => item.year === year);
        return entry ? entry.MRR : null; // Use null or 0 if data is missing for that month
    });

    datasets.push({
        label: brand,
        data: brandData,
        fill: true, // This will fill the area under the line
        backgroundColor: rgbaColor, // Use the RGBA color for the fill
        borderColor: color,
        tension: 0.1
    });
});

  if (this.annualMrrChart) this.annualMrrChart.destroy();
  this.annualMrrChart = new Chart(ctx, {
      type: 'line',
      data: {
          labels: labels,
          datasets: datasets
      },
      options: {
          responsive: true,
          scales: {
              xAxes: {
                  type: 'category',
                  title: {
                      display: true,
                      text: 'Year'
                  }
              },
              yAxes: [{
                  title: {
                      display: true,
                      text: 'MRR'
                  },
                  ticks: {
                      beginAtZero: true,
                      callback: (value) => '$'+value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                  },
                  type: 'linear'
              }]
          },
          animation: {
              duration: 1000,
              easing: 'easeInOutQuad',
          },
          tooltips: {
              callbacks: {
                  title: (tooltipItems) => {
                      const label = tooltipItems[0].label;
                      return `${label}`;
                  },
                  label: (tooltipItem) => {
                      const dataset = tooltipItem.datasetIndex !== undefined ? datasets[tooltipItem.datasetIndex] : null;
                      const label = dataset ? dataset.label : 'Unknown';
                      const value = '$'+tooltipItem.yLabel.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                      return `${label}: ${value}`;
                  }
              }
          },
      }
  });
}

  processMrrTblData(data, selBrand, type) {
    let filteredData = data;
    if (selBrand !== 'all') {
      filteredData = data.filter(item => item.brand_name === selBrand);
    }

    let transformedData = {};
    let labelSet = new Set<string>();

    filteredData.forEach(item => {
      let data = item.month;
      if(type === 'annual') {
        data = item.year;
      }
      // Collect unique months
      labelSet.add(data);

      if (!transformedData[item.brand_name]) {
        transformedData[item.brand_name] = {};
      }
      transformedData[item.brand_name][data] = item.MRR;
    });

    let lblArray = Array.from(labelSet).sort(); // Sort months if needed

    return { transformedData, lblArray };
  }

  getSubsKeyMetricsAvgRevData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      this.showAvgRevldngSpnr = true;
      this.noAvgRevDataAvailable = false;
      this.reportsService.getSubsKeyMetricsAvgRevData(this.selBrand, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res.result.avgRev) {
              this.arpuData = res.result.avgRev;
              this.generateArpuData(this.arpuData, this.arpuBrand);
            }
          } else {
            this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
          this.showAvgRevldngSpnr = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showAvgRevldngSpnr = false;
        });
      }
  }

  generateArpuData(allData : any[], selBrand) {
    if (selBrand === 'all') {
      this.avgRevTblData = allData;
    } else {
      const temp = allData.filter(function (d) {
        return d.brand_name == selBrand;
      });
      this.avgRevTblData = temp;
    }
  }

  getSubsKeyMetricsChurnRateData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      this.showCustChurnRateldngSpnr = true;
      this.noCustChurnRateDataAvailable = false;
      this.reportsService.getSubsKeyMetricChurnRateData(this.selBrand, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res.result.custChurn) {
              this.custChurnRateData = res.result.custChurn;
              this.generatecustChurnRateData(this.custChurnRateData, this.custChurnRateBrand);
            } else {
              this.noCustChurnRateDataAvailable = true;
            }
          } else {
            this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
          this.showCustChurnRateldngSpnr = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showCustChurnRateldngSpnr = false;
        });
      }
  }

  generatecustChurnRateData(allData: any[], selBrand: string) {
    this.showCustChurnRateldngSpnr = true;
    this.noCustChurnRateDataAvailable = false;
    if(allData.length>0) {
      this.showCustChurnRateldngSpnr = false;
      const processedData = this.processCustChurnRateData(allData, selBrand);
      this.generateCustChurnRateChart(processedData);
      this.custChurnRateTblData = this.filterBrandTblData(allData, selBrand);
    } else {
      this.showCustChurnRateldngSpnr = false;
      this.noCustChurnRateDataAvailable = true;
    }
  }

  getSubsKeyMetricRevChurnRateData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      this.showRevChurnRateldngSpnr = true;
      this.noRevChurnRateDataAvailable = false;
      this.reportsService.getSubsKeyMetricRevChurnRateData(this.selBrand, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res.result.revChurn) {
              this.revChurnRateData = res.result.revChurn;
              this.generateRevChurnRateData(this.revChurnRateData, this.revChurnRateBrand);
            } else {
              this.noRevChurnRateDataAvailable = true;
            }
          } else {
            this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
          this.showRevChurnRateldngSpnr = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showRevChurnRateldngSpnr = false;
        });
      }
  }

  generateRevChurnRateData(allData: any[], selBrand: string) {
    this.showRevChurnRateldngSpnr = true;
    this.noRevChurnRateDataAvailable = false;
    if(allData.length>0) {
      this.showRevChurnRateldngSpnr = false;
      const processedData = this.processRevChurnRateData(allData, selBrand);
      this.generateRevChurnRateChart(processedData);
      this.revChurnRateTblData = this.filterBrandTblData(allData, selBrand);
    } else {
      this.showRevChurnRateldngSpnr = false;
      this.noRevChurnRateDataAvailable = true;
    }
  }

  getSubsKeyMetricLifeTimeValData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      this.showLifeTimeValldngSpnr = true;
      this.noLifeTimeValAvailable = false;
      this.reportsService.getSubsKeyMetricLifeTimeValData(this.selBrand, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res.result.lifeTime) {
              this.ltvData = res.result.lifeTime;
              this.generateLtvData(this.ltvData, this.ltvBrand);
            }
          } else {
            this.noLifeTimeValAvailable = true;
          }
          this.showLifeTimeValldngSpnr = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showLifeTimeValldngSpnr = false;
        });
      }
  }

  generateLtvData(allData : any[], selBrand) {
    this.lifeTimeValTblData = this.filterBrandTblData(allData, selBrand);
  }

  filterBrandTblData(allData : any[], selBrand) {
      if (selBrand === 'all') {
        return allData;
      } else {
        const temp = allData.filter(function (d) {
          return d.brand_name == selBrand;
        });
        return temp;
      }
  }

  processCustChurnRateData(data: any[], selBrand): any {
    const monthData = data.reduce((acc, item) => {
      // This check runs for either 'all' brands or the specific selected brand
      if (selBrand === 'all' || item.brand_name === selBrand) {
          if (!acc[item.month]) {
              acc[item.month] = {
                  total_churn_rate: 0,
                  voluntary_churn_rate: 0,
                  involuntary_churn_rate: 0,
                  count: 0
              };
          }
          acc[item.month].total_churn_rate += parseFloat(item.total_churn_rate);
          acc[item.month].voluntary_churn_rate += parseFloat(item.voluntary_churn_rate);
          acc[item.month].involuntary_churn_rate += parseFloat(item.involuntary_churn_rate);
          acc[item.month].count += 1;
      }
      return acc;
  }, {});

    const labels = Object.keys(monthData);
    const totalChurnRate = labels.map(month => parseFloat((monthData[month].total_churn_rate / monthData[month].count).toFixed(2)));
    const voluntaryChurnRate = labels.map(month => parseFloat((monthData[month].voluntary_churn_rate / monthData[month].count).toFixed(2)));
    const involuntaryChurnRate = labels.map(month => parseFloat((monthData[month].involuntary_churn_rate / monthData[month].count).toFixed(2)));
  
    return {
      labels,
      datasets: [
        {
          label: 'Voluntary Churn Rate',
          data: voluntaryChurnRate,
          borderColor: this.reportsColor[4],
          backgroundColor: this.reportsColor[4],
          fill: true
        },
        {
          label: 'Involuntary Churn Rate',
          data: involuntaryChurnRate,
          borderColor: this.reportsColor[5],
          backgroundColor: this.reportsColor[5],
          fill: true
        },
        {
          label: 'Total Churn Rate',
          data: totalChurnRate,
          borderColor: this.reportsColor[3],
          backgroundColor: this.reportsColor[3],
          fill: true
        }
      ]
    };
  }

  processRevChurnRateData(data: any[], selBrand): any {
    const monthData = data.reduce((acc, item) => {
      if (selBrand === 'all' || item.brand_name === selBrand) {
        if (!acc[item.month]) {
          acc[item.month] = {
            total_churned_revenue_rate: 0,
            voluntary_churned_revenue_rate: 0,
            involuntary_churned_revenue_rate: 0,
            count: 0
          };
        }
        acc[item.month].total_churned_revenue_rate += parseFloat(item.total_churned_revenue_rate);
        acc[item.month].voluntary_churned_revenue_rate += parseFloat(item.voluntary_churned_revenue_rate);
        acc[item.month].involuntary_churned_revenue_rate += parseFloat(item.involuntary_churned_revenue_rate);
        acc[item.month].count += 1;
      }
      return acc;
    }, {});
    const labels = Object.keys(monthData);
    const totalChurnRevRate = labels.map(month => parseFloat((monthData[month].total_churned_revenue_rate / monthData[month].count).toFixed(2)));
    const voluntaryChurnRevRate = labels.map(month => parseFloat((monthData[month].voluntary_churned_revenue_rate / monthData[month].count).toFixed(2)));
    const involuntaryChurnRevRate = labels.map(month => parseFloat((monthData[month].involuntary_churned_revenue_rate / monthData[month].count).toFixed(2)));
  
    return {
      labels,
      datasets: [
        {
          label: 'Voluntary Churned Revenue Rate',
          data: voluntaryChurnRevRate,
          borderColor: this.reportsColor[7],
          backgroundColor: this.reportsColor[7],
          fill: true
        },
        {
          label: 'Involuntary Churned Revenue Rate',
          data: involuntaryChurnRevRate,
          borderColor: this.reportsColor[8],
          backgroundColor: this.reportsColor[8],
          fill: true
        },
        {
          label: 'Total Churned Revenue Rate',
          data: totalChurnRevRate,
          borderColor: this.reportsColor[6],
          backgroundColor: this.reportsColor[6],
          fill: true
        }
      ]
    };
  }


  generateCustChurnRateChart(chartData: any): void {
    if (this.custChurnRateChart) {
      this.custChurnRateChart.destroy(); // Destroy the old chart instance if it exists
    }
  
    const context = (this.custChurnRateCanvasRef.nativeElement as HTMLCanvasElement).getContext('2d');
  
    this.custChurnRateChart = new Chart(context, {
      type: 'line',
      data: chartData,
      options: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Month'
            }
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'Churn Rate (%)'
            }
          }
        }
      }
    });
  }

  generateRevChurnRateChart(chartData: any): void {
    if (this.revChurnRateChart) {
      this.revChurnRateChart.destroy(); // Destroy the old chart instance if it exists
    }
  
    const context = (this.revChurnRateCanvasRef.nativeElement as HTMLCanvasElement).getContext('2d');
  
    this.revChurnRateChart = new Chart(context, {
      type: 'line',
      data: chartData,
      options: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Month'
            }
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'Churn Rate (%)'
            }
          }
        }
      }
    });
  }

  processData(allData: any[]): { labels: string[], brands: { [key: string]: { [key: string]: number } }, months: string[], brandNames: string[] } {
    const labels: string[] = [];
    const brands: { [key: string]: { [key: string]: number } } = {};
    const months: string[] = [];
    const brandNames: string[] = [];

    allData.forEach(item => {
      const month = item.date.substring(0, 7);
      const monthName = `${this.monthNames[parseInt(month.split('-')[1], 10) - 1]} ${month.split('-')[0]}`;
      if (!labels.includes(monthName)) labels.push(monthName);

      if (!months.includes(monthName)) months.push(monthName);

      Object.entries(item).forEach(([brand, value]) => {
        if (brand !== 'date') {
          if (!brands[brand]) brands[brand] = {};
          brands[brand][monthName] = (brands[brand][monthName] || 0) + (value as number);

          if (!brandNames.includes(brand)) brandNames.push(brand);
        }
      });
    });

    brandNames.sort();
    return { labels, brands, months, brandNames };
  }

  generateChartData(processedData: { labels: string[], brands: { [key: string]: { [key: string]: number } } }): void {
    const { labels, brands } = processedData;

    const colors = this.reportsColor;
    let colorIndex = 0;

    const datasets = Object.keys(brands).map(brand => {
        const color = colors[colorIndex % colors.length]; // Get color from the array in a loop
        colorIndex++;
        return {
            label: brand,
            data: labels.map(label => brands[brand][label] || 0),
            backgroundColor: color
        };
    });
    if (this.periodSubsChart) this.periodSubsChart.destroy();

    const ctx = this.canvasRef.nativeElement.getContext('2d');
    this.periodSubsChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        animation: {
          duration: 1000,
          easing: 'easeInOutQuad',
        },
        tooltips: {
          callbacks: {
            title: (tooltipItems) => {
              const label = tooltipItems[0].label;
              const [month, year] = label.split(' ');
              return `${month} ${year}`;
            }
          }
        },
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{ stacked: true, ticks: { beginAtZero: true }, type: 'linear' }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom' },
      }
    });
  }

  generateTableData(processedData: { months: string[], brands: { [key: string]: { [key: string]: number } }, brandNames: string[] }): void {
    const { months, brands, brandNames } = processedData;
    this.months = months;
    this.brandNames = brandNames;
    this.tableData = {};

    months.forEach(month => {
      this.tableData[month] = {};
      brandNames.forEach(brand => {
        const brandInfo = brands[brand]?.[month]; // Safeguard: ?. to handle undefined
        this.tableData[month][brand] = brandInfo ? brandInfo : 0;
      });
    });
  }

  getData(month: string, brand: string): number {
    return this.tableData[month] ? this.tableData[month][brand] || 0 : 0;
  }

  getBrandDisplayName(brandName) {
    return this.reportsService.getBrandDisplayName(this.allActiveBrands, brandName);
  }
}
