import { Component, OnInit, Input, EventEmitter, Output  } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute} from '@angular/router';
import { CustomersService } from '../../../../services/customers.service';
import { Constants } from '../../../../common/constants';
import { CustomerOrderDetailsComponent} from '../customer-order-details.component'

@Component({
  selector: 'app-return-modal',
  templateUrl: './return-modal.component.html',
  styleUrls: ['./return-modal.component.scss']
})
export class returnModalComponent  implements OnInit {
  customerId : string;
  transaction_id: any;
  orderId: string;
  brandId : string;
  alerts: any[];
  userTransHistArr: any[];
  showLoadingSpinner = true;
  recentTransArr : any[];
  userOrderObj: any;
  showCancelButton: boolean = true;
  showCancelEndButton: boolean = true;
  dataObj: any;

  constructor(public bsModalRef: BsModalRef,
  private customersService: CustomersService,
  private route: ActivatedRoute,) {}

  ngOnInit(): void {
    //this.getCustomerTransHistDetail(this.customerId,this.brandId,this.orderId);
  }

  bsModalRefhide(){
    this.bsModalRef.hide();
  }


  /**********************************API Method to Get All Transaction History details based on order id passed*********************/
  getCustomerTransHistDetail(customerId, brandId, orderId) {
      this.customersService.getCustomerTransHistDetail(customerId, brandId, orderId).then(
        res=>{
         if(res['code']==1 && res['status']==1) {
            this.userTransHistArr = res.result.transObj;
            this.showLoadingSpinner = false;
            this.recentTransArr = [ ...this.userTransHistArr ].pop();
            this.userOrderObj = res.result.orderObj[0];
            if(this.userOrderObj.status == 0) {
              this.showCancelButton = false;
            } else if(this.userOrderObj.status == 2) {
              this.showCancelEndButton = false;
            }
         } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
         }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
  }
  
  refundPaymentsConfirm(){
    console.log('customerId' + this.customerId + '    brandId' + this.brandId+ '    orderId' + this.orderId +  '  this.transaction_id' + this.transaction_id);
    const dataObj = {
      transaction_id : this.transaction_id,
      order_id   : this.orderId
    }
    this.customersService.refundPayment(this.customerId, dataObj).then(
      res=>{
       if(res['code']==1 && res['status']==1) {
        this.getCustomerTransHistDetail(this.customerId,this.brandId,this.orderId);
        this.alerts = [{
          type: 'success',
          msg: Constants.REFUND_PAYMENT_SUCCESS_MSG,
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        setTimeout(()=>{
          this.bsModalRef.hide();
        }, 3000);
       } else {
          this.alerts = [{
            type: 'danger',
            msg: Constants.REFUND_PAYMENT_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
       }
      },error=>{
          this.alerts = [{
            type: 'danger',
            msg: Constants.REFUND_PAYMENT_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
      }
    );

  }

}
