import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { ActivatedRoute,Router,NavigationEnd} from '@angular/router';
import { CustomersService } from '../../../services/customers.service';
import { Constants } from '../../../common/constants';
import { DropdownConstants } from '../../../common/dropdown_constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-all-subscribers',
  templateUrl: './all-subscribers.component.html',
  styleUrls: ['./all-subscribers.component.scss']
})
export class AllSubscribersComponent implements OnInit {
  pageType : string;

  selectedOption = '0';
  rows: any[];
  tmp: any[];
  activeData: [];
  inactiveData: [];
  allActiveBrands: any[];
  alerts: any[];
  showLoadingSpinner = true;
  selectedBrand : number = 0;
  defPager : number = Constants.DEF_TABLE_PAGER;
  defPageCount : number = Constants.DEF_TABLE_DATA_COUNT;
  searchStr : string = "";
  //Role based permission check
  viewUsersPrmsn : boolean = true;
  addUsersPrmns : boolean = true;
  editUsersPrmns : boolean = true;

  userList = [];
  totalUsers = 0;
  pageSize = 10; // or whatever default you prefer
  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  sortColumn = 'id';
  sortOrder = Constants.SORT_ORDER_ASC;

  /*columns = [
    { prop: 'id', name: 'User Id' },
    { prop: 'email', name: 'Email' },
    { prop: 'brand_name', name: 'Brand' },
    { prop: 'first_name', name: 'First Name' },
    { prop: 'last_name', name: 'Last Name' },
    { prop: 'brand_id', name: 'Brand id' },
    {prop: 'status', name: 'Status'}
  ];*/


  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private customersService: CustomersService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router) { 
      this.router.events.subscribe(event => {
        if(event.constructor.name === "NavigationEnd") {
          this.pageType = this.route.snapshot.paramMap.get('type');
          this.loadPageData(this.pageType);
        }
      });
      this.getAllActiveBrands();
    }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.pageType = this.route.snapshot.paramMap.get('type');
    this.loadPageData(this.pageType);
  }

  loadPageData(type: string) {
    let title = "Users";
    if(type=="datawall"){
      title = "Datawall Users";
    } else if(type=="paywall"){
      title = "Paywall Users";
    } else if(type=="churned"){
      title = "Churned Users";
    }
    this.commonService.setTitle(title);
    this.titleService.setTitle(title);
    this.setPage({ offset: 0 });
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if(this.viewUsersPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
          this.allActiveBrands = res['result'];
          this.selectedBrand = 0;//All
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

  onLimitChange() {
    this.setPage({ offset: 0 });
  }

  onSort(event) {
    this.sortColumn = event.sorts[0].prop;
    this.sortOrder = event.sorts[0].dir.toUpperCase();
    this.setPage({ offset: 0 });
  }

  onPage(event) {
    this.setPage({ offset: event.offset });
  }

  setPage(pageInfo : any) {
    const currentPage = pageInfo.offset + 1;
    this.getAllUsers(this.selectedBrand, currentPage, this.pageSize);
  }

  /***********************************Method to get All Users List***********************************************/
  getAllUsers(brandId, currentPage: number, pageSize : number) {
    if(this.viewUsersPrmsn) {
      this.showLoadingSpinner = true;
      let dataObj = {
        'pageNumber' : currentPage-1,
        'pageSize' : pageSize,
        'sortingColumn' : this.sortColumn,
        'sortingOrder' : this.sortOrder,
        'searchKeys' : this.searchStr
      };
      this.customersService.getAllUsers(brandId, dataObj, this.pageType).then(
        res => {
          this.showLoadingSpinner = false;
          if (res.userList.length > 0) {
            this.userList = res.userList;
            this.totalUsers = res.totalUsers;
          } else {
            this.userList = [];
            this.totalUsers = 0;
          }
        },
        error => {
          this.showLoadingSpinner = false;
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        });
      }
  }

  /******************************Method to filter the brand based customer data***************************/
  fetchCustomersData(brandId: number) {
    this.rows = this.tmp;
   /* const temp = this.rows.filter(function (d) {
      return d.is_active == status;
    });
    this.rows = temp;*/
  }

  /******************************Method to filter email/firstName/lastName column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    console.log(val);
    if(val) {
      const temp = this.rows.filter(function (d) {
        // return d.first_name.toLowerCase().indexOf(val) !== -1 || d.last_name.toLowerCase().indexOf(val) !== -1 || d.email.toLowerCase().indexOf(val) !== -1 || !val;
        let textValue = d.first_name + d.last_name + d.email + d.id;
        return textValue.toLowerCase().indexOf(val) > -1;
      });
      this.rows = temp;
    } else {
      this.fetchCustomersData(this.selectedBrand);
    }
  }

  /******************************Method to filter user list based on the given search string***************************/
  searchUserList(searchStr : string ) {
      this.searchStr = searchStr;
      this.getAllUsers(this.selectedBrand, this.defPager, this.defPageCount)
  }

  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    this.selectedBrand = brandId;
    this.setPage({ offset: 0 });
  }

}
