<div class="padding-20">
    <div class="card ">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">  
                    <div class="d-flex flex-row align-items-center">
                        <span>Brands: </span>
                        <select class="form-control m-l-10 m-r-auto" [style.width.px]="300" >
                            <option value="1">HME</option>
                            <option value="2">PMV</option>
                            <option value="3">CAT</option>
                            <option value="4">CWO</option>
                            <option value="5">CID</option>
                            <option value="6">MEP</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Name" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv text-right">
                    <a class="btn btn-complete fs-15 padding-10" routerLink="/experience/add" role="button">+ Add New Experience</a>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ngx-datatable class="material container1 striped" [columnMode]="'flex'" [headerHeight]="50" [limit]="10" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.75">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" [flexGrow]="1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                       Default experience
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Brand" [flexGrow]="1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                       HME
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Created by" [flexGrow]="1.7">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                       padma@itpshare.com
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Updated by" [flexGrow]="1.7">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                       padma@itpshare.com
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Created on" [flexGrow]="1.7">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        Aug 10 2023, 8:40 AM
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Updated on" [flexGrow]="1.7">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        May 10 2024, 10:26 AM
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" [flexGrow]="1" prop="is_active">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="form-check switch success">
                            <input [attr.id]="updateBrandStatus" type="checkbox" class="primary" checked="checked">
                            <label [attr.for]="updateBrandStatus"></label>
                        </div>
                        
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action" [flexGrow]="1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a routerLink="/experience/edit/1" class="" title="View/Edit">
                            <i class="pg-icon text-info">edit</i>
                        </a>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>
