<div class="container-fluid p-3 sm-padding-10 sm-gutter p-t-0">
  <div class="card card-transparent m-l-10">
    <div class="card-header mb-3 p-0">
      <p class="mb-4">This report provides an in-depth analysis of the users retained through our churn prevention tool,
        specifically focusing on those saved from churn due to payment failures. This report highlights the
        effectiveness of our strategies in maintaining subscriber loyalty and ensuring continuous revenue flow.</p>
      <div class="row align-items-center">
          <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
            <div class="d-flex align-items-center">
              <span class="m-r-5">Brands: </span>
              <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'"
                        [(ngModel)]="selectedOption" (ngModelChange)="brandFilterChange(selectedOption)" ShowSearch>
                  <pg-option [Label]="'All'" [Value]="'0'"></pg-option>
                  <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select"
                    [Label]="brand.brand_display_name" [Value]="brand.id">
                  </pg-option>
              </pg-select>
            </div>
          </div>
          <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 p-b-15 d-flex justify-content-end align-items-center">
            <a (click)="getNewSubsData(true)" class="hover m-r-5" title="Refresh">
              <i class="pg-icon">refresh</i>
            </a>
            <span class="text-muted font-size-13">Last synced on: {{ lastSyncTime }}</span>
          </div>
      </div>
    </div>
    <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
    <div class="card-body p-0">
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
      </div>
      <h4 *ngIf="noDataAvailable && !showLoadingSpinner" class="text-center">No data available.</h4>
      <div class="col-sm-12 mt-4 mb-4 chart-container">
        <canvas #myCanvas width="500" height="400">{{ periodSubsChart }}</canvas>
      </div>
      <div class="col-sm-12 my-2" *ngIf="!showLoadingSpinner">
        <h4>Churned Users</h4>
        <div class="card full-height no-border no-margin widget-11 widget-loader-bar">
          <div class="card-body no-padding">
            <div class="widget-11-table auto-overflow">
              <div class="table-container">
                <table class="table table-condensed1 table-hover" id="datawallTbl" >
                  <thead>
                    <tr>
                      <th>Brand Name</th>
                      <th>Email</th>
                      <th>Company</th>
                      <th>Cancelled Date</th>
                      <th>Reason</th>
                      <th>Status</th>
                      <th style="width:110px;"></th>
                    </tr>
                  </thead>
                  <tbody *ngIf="churnedUsers && churnedUsers.length > 0; else noChurnedUsers">
                      <tr *ngFor="let data of churnedUsers;" >
                        <td>{{ getBrandDisplayName(data.brand_name) }}</td>
                        <td>{{ data.email }}</td>
                        <td>{{ data.comp || '-' }}</td>
                        <td>{{ data.cancelled_date }}</td>
                        <td>{{ data.reason || '-' }}</td>
                        <td>{{ data.status }}</td>
                        <td ><a  *ngIf="data.brand_name === 'CAT' || data.brand_name === 'HME'" class="hover" (click)="openModalWithComponent(data.user_id)"> Send offer</a></td>
                      </tr>
                  </tbody>
                  <ng-template #noChurnedUsers>
                    <tbody>
                      <tr>
                        <td>No data available.</td>
                      </tr>
                    </tbody>
                  </ng-template>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>
</div>
<app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>