<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="addStLicensePrmns">
    <div class="inner">
      <ol class="breadcrumb sm-p-b-5">
        <li class="breadcrumb-item"><a routerLink="/corpsub/all" href="javascript:void(0)">Group Subscriptions</a></li>
        <li class="breadcrumb-item active"> CREATE NEW</li>
      </ol>
    </div>
  
    <div class="row">
      <div class="col-lg-9 col-xl-7 mx-auto">
        <div class="card ">
          <div class="card-body">
              <h5 class="mb-2">Add Corporate Subscription</h5>
              <form class="addNewBrandForm mx-auto" id="form-project"  [formGroup]="addNewLicenseeForm" (ngSubmit)="addNewLicenseeFormSubmit()" novalidate>
                  <div class="">
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('licenseeName').invalid && (getFormControl('licenseeName').dirty || getFormControl('licenseeName').touched)">
                          <label>Subscription Name <br/> <small>(Only letters, digits, underscore or dash. No whitespaces or symbols)</small></label>
                          <input type="text" class="form-control" formControlName="licenseeName" placeholder="Enter Subscription Name" appNameValidation>
                          <div class="invalid-tooltip" *ngIf="getFormControl('licenseeName').invalid && (getFormControl('licenseeName').dirty || getFormControl('licenseeName').touched)">
                            Subscription Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default" >
                          <label>Description</label>
                          <input type="text" class="form-control" formControlName="licenseeDesc" placeholder="Enter Description">
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('repEmail').invalid && (getFormControl('repEmail').dirty || getFormControl('repEmail').touched)">
                          <label>Representative Email</label>
                          <input type="text" class="form-control" formControlName="repEmail" placeholder="Enter Representative Email">
                          <div class="invalid-tooltip" *ngIf="getFormControl('repEmail').invalid && (getFormControl('repEmail').dirty || getFormControl('repEmail').touched)">
                            Representative Email is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 class="m-b-15 m-t-15">Contract details</h6>
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <div pgFormGroupDefault  class="form-group form-group-default required form-group-default-select2" [class.has-error]="getFormControl('brand').invalid && (getFormControl('brand').dirty || getFormControl('brand').touched)">
                          <label>Brand</label>
                          <pg-select style="width: 100%;" formControlName="brand" [PlaceHolder]="'Select Brand'" AllowClear ShowSearch>
                            <pg-option *ngFor='let brand of allActiveBrands;let i = index' Label="{{brand.brand_name}}" Value="{{brand.id}}"></pg-option>
                          </pg-select>
                          <div class="invalid-tooltip" *ngIf="getFormControl('brand').invalid && (getFormControl('brand').dirty || getFormControl('brand').touched)">
                            Brand is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div pgFormGroupDefault  class="form-group form-group-default required form-group-default-select2" [class.has-error]="getFormControl('contractType').invalid && (getFormControl('contractType').dirty || getFormControl('contractType').touched)">
                          <label>Contract Type</label>
                          <pg-select style="width: 100%;" formControlName="contractType" (change)="onContractTypeChange()" AllowClear ShowSearch>
                            <pg-option *ngFor='let contractType of contractTypes' [Label]="contractType.label" [Value]="contractType.value"></pg-option>
                          </pg-select>
                          <div class="invalid-tooltip" *ngIf="getFormControl('contractType').invalid && (getFormControl('contractType').dirty || getFormControl('contractType').touched)">
                            Contract Type is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('noOfSeats').invalid && (getFormControl('noOfSeats').dirty || getFormControl('noOfSeats').touched)">
                          <label>Number of Seats</label>
                          <input type="number" class="form-control" formControlName="noOfSeats" placeholder="Enter Number of Seats">
                          <div class="invalid-tooltip" *ngIf="getFormControl('noOfSeats').invalid && (getFormControl('noOfSeats').dirty || getFormControl('noOfSeats').touched)">
                            This Field is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="col-md-6">
                        <div pgFormGroupDefault  class="input-group form-group form-group-default required" [class.has-error]="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                          <label>Start Date</label>
                          <div class="input-group">
                            <pg-datepicker formControlName="startDate"  Placeholder="Select Start Date" [Format]="'DD/MM/YYYY'"></pg-datepicker>
                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="pg-icon">calendar</i>
                              </span>
                            </div>
                          </div>
                          <!-- <input type="date" class="form-control" formControlName="startDate"  placeholder="Select Start Date"> -->
                          <div class="invalid-tooltip" *ngIf="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                            Start Date is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div pgFormGroupDefault  class="input-group form-group form-group-default required" [class.has-error]="getFormControl('endDate').invalid && (getFormControl('endDate').dirty || getFormControl('endDate').touched)">
                          <label>End Date</label>
                          <div class="input-group">
                            <pg-datepicker formControlName="endDate"  Placeholder="Select End Date" [Format]="'DD/MM/YYYY'"></pg-datepicker>
                            <div class="input-group-append">
                              <span class="input-group-text">
                                <i class="pg-icon">calendar</i>
                              </span>
                            </div>
                          </div>
                          <!-- <input type="date" class="form-control" formControlName="endDate"  placeholder="Select End Date"> -->
                          <div class="invalid-tooltip" *ngIf="getFormControl('endDate').invalid && (getFormControl('endDate').dirty || getFormControl('endDate').touched)">
                            End Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
  
                  <div class="row p-t-20">
                    <div class=" col-md-12 text-right ">
                      <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10">Add</button>
                      <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
  
                      <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/corpsub/all">Cancel</button>
                    </div>
                  </div>
  
                </form>
                <div *ngFor="let alert of alerts" class="p-t-20">
                  <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
                </div>
          </div>
        </div>
      </div>
    </div>  
  </div>  
<!-------No Access Block--------->
<app-no-access *ngIf="!addStLicensePrmns"></app-no-access>
