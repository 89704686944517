<div class="padding-20">      
    <div class="card list" *ngIf="viewAllReportsPrmsn">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between m-t-20 m-b-20">
                <div class="col-lg-7 col-sm-12">  
                    <h5 class="card-title d-none d-sm-block">All Reports</h5>
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable class="material container1 " *ngIf="!showLoadingSpinner" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="10" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.5" cellClass="text-right" headerClass="text-right">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Title" [flexGrow]="1.5" prop="title">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.title}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Description" [flexGrow]="2" prop="description">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.description}}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Action" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button class="btn btn-secondary downloadBtn" title="Download CSV" (click)="downloadReport(row.id)" *ngIf="downloadAllReportsPrmns">
                        <i  class="pg-icon text-info">download</i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
    <!-------No Access Block--------->
    <app-no-access *ngIf="!viewAllReportsPrmsn"></app-no-access>
</div>    
    