import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService} from '../../services/login.service';
import { Constants } from '../../common/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  returnUrl: string;
  alerts: any[];
  loaderShow:boolean = false;
  defaultUrl: string = "dashboard"; //Added for temporary check
  role = localStorage.getItem("pw_role");

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private loginService : LoginService) { 
        // redirect to home if already logged in
        if (this.loginService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {

        this.loginForm = this.formBuilder.group({
            accEmailAddFld: ['', [Validators.required, Validators.email]],
            accPwdFld: ['', [Validators.required]]
        });

        // get return url from route parameters or default to '/'
        this.defaultUrl = (this.role == "1" || this.role == "2") ? "dashboard" : "corpsub/all";
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.defaultUrl;
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get p() { return this.loginForm.controls; }
    getFormControl(name) {
      return this.loginForm.controls[name];
    }
  
    isFieldValid(field: string) {
      return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
    }
  
    displayFieldCss(field: string) {
      return {
        'has-error': this.isFieldValid(field),
        'has-feedback': this.isFieldValid(field)
      };
    } 
    onLoginFormSubmit() {
      this.submitted = true;
      this.loaderShow = true;
      // stop here if form is invalid
      for (const i in this.loginForm.controls) {
        this.loginForm.controls[i].markAsDirty();
      }
      if (this.loginForm.invalid) {
        this.loaderShow = false;  
        return;
      }
      this.loading = true;
    
		this.loginService.login(this.f.accEmailAddFld.value, this.f.accPwdFld.value).then(
            res => {
                if(res['checksum']) {
                   window.location.href= this.returnUrl;
                } else {
                    this.alerts = [{
                        type: 'danger',
                        msg: res.message,
                        timeout: 10000
                    }];
                    this.loading = false;
                    this.loaderShow = false;  
                }
            },
            error => {
                this.alerts = [{
                    type: 'danger',
                    msg: error.json().message,
                    timeout: 10000
                }];
                this.loading = false;
                this.loaderShow = false;  
            });
      //this.loginService.login(this.f.accEmailAddFld.value, this.f.accPwdFld.value);
  }

}
