
<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="addPlansPrmns">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/plans/all" href="javascript:void(0)">Payment Plans</a></li>
      <li class="breadcrumb-item active"> CREATE NEW</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <h5 class="mb-2">Add New Payment Plan</h5>
            <form class="addNewBrandForm mx-auto" id="form-project"  [formGroup]="addNewPlanForm" (ngSubmit)="addNewPlanFormSubmit()" novalidate>
                <div class="">

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('planName').invalid && (getFormControl('planName').dirty || getFormControl('planName').touched)">
                        <label>Plan Name <br/><small>(Only letters, digits, underscore or dash. No whitespaces or symbols)</small></label>
                        <input  type="text" class="form-control" placeholder="Enter Plan Name" formControlName="planName" appNameValidation>
                        <div class="invalid-tooltip" *ngIf="getFormControl('planName').invalid && (getFormControl('planName').dirty || getFormControl('planName').touched)">
                          Plan Name is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('displayName').invalid && (getFormControl('displayName').dirty || getFormControl('displayName').touched)">
                        <label>Display Name <br/><small>(Name to be displayed on the front-end)</small></label>
                        <input type="text" class="form-control" formControlName="displayName"  placeholder="Enter Display Name">
                        <div class="invalid-tooltip" *ngIf="getFormControl('displayName').invalid && (getFormControl('displayName').dirty || getFormControl('displayName').touched)">
                          Display Name is required
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('proDesc').invalid && (getFormControl('proDesc').dirty || getFormControl('proDesc').touched)">
                        <label>Description</label>
                        <input type="text" class="form-control" formControlName="proDesc"  placeholder="Enter Description">
                        <div class="invalid-tooltip" *ngIf="getFormControl('proDesc').invalid && (getFormControl('proDesc').dirty || getFormControl('proDesc').touched)">
                          Description is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" id="planStatus" checked="checked" formControlName="planStatus">
                          <label for="planStatus">Status</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group  form-group-default p-t-10 p-b-10 d-flex align-items-center">
                          <label for="features" >Features -</label>
                          <div class="col-md-9 col-lg-5">
                            <button type="button" class="btn m-r-20 p-r-20 p-l-20 btn-primary active" (click)="addFeature()">+ Add</button>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix" *ngFor="let feature of getFeaturesFormControls(); let i = index">  
                    <div class="col-md-12">
                      <div class="form-group form-group-default d-flex align-items-center">
                        <input [formControl]="feature"  type="text" class="featureFld form-control">
                        <a (click)="removeFeature(i)" class="hover">
                          <i class="pg-icon text-info">close</i>
                        </a> 
                      </div>
                    </div>
                  </div>
                </div>

                <h6 class="m-b-15 m-t-15">Plan details</h6>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('paymentType').invalid && (getFormControl('paymentType').dirty || getFormControl('paymentType').touched)">
                        <label>Payment Type</label>
                        <!-- <select class="form-control" formControlName="paymentType" >
                          <option value="CARD">CARD</option>
                        </select> -->
                        <pg-select style="width: 100%;" formControlName="paymentType" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                          <pg-option *ngFor='let paymentType of paymentTypes' [Label]="paymentType.label" [Value]="paymentType.value">
                          </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('paymentType').invalid && (getFormControl('paymentType').dirty || getFormControl('paymentType').touched)">
                          Payment Type is required
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('contractLength').invalid && (getFormControl('contractLength').dirty || getFormControl('contractLength').touched)">
                        <label>Contract Length</label>
                        <!-- <select class="form-control" formControlName="contractLength" >
                          <option *ngFor='let period of periodsFltrArr;let i = index' value="{{period.disp_id}}">{{period.display_name}}</option>
                        </select> -->
                        <pg-select style="width: 100%;" formControlName="contractLength" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                            <pg-option *ngFor='let period of periodsFltrArr;let i = index' Label="{{period.display_name}}" Value="{{period.disp_id}}">
                            </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('contractLength').invalid && (getFormControl('contractLength').dirty || getFormControl('contractLength').touched)">
                          Contract Length is required
                        </div>
                      </div>
                    </div>
                  </div>  

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" id="planAutoRenew" checked="checked" formControlName="planAutoRenew">
                          <label for="planAutoRenew">Auto-renew</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('renewalPlan').invalid && (getFormControl('renewalPlan').dirty || getFormControl('renewalPlan').touched)">
                        <label>Renewal Plan</label>
                        <!-- <select class="form-control" formControlName="renewalPlan" >
                          <option value="0">---same plan----</option>
                          <option *ngFor='let plan of allProPlansData;let i = index' value="{{plan.id}}">{{plan.plan_name}}</option>
                        </select> -->
                        <pg-select style="width: 100%;" formControlName="renewalPlan" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                          <pg-option Label="---same plan----" Value="0"></pg-option>
                          <pg-option *ngFor='let plan of allProPlansData;let i = index' Label="{{plan.plan_name}}" Value="{{plan.id}}">
                          </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('renewalPlan').invalid && (getFormControl('renewalPlan').dirty || getFormControl('renewalPlan').touched)">
                          Renewal Plan is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">

                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('frequency').invalid && (getFormControl('frequency').dirty || getFormControl('frequency').touched)">
                        <label>Frequency</label>
                        <!-- <select class="form-control" formControlName="frequency" >
                          <option *ngFor='let period of periodsFltrArr;let i = index' value="{{period.disp_id}}">{{period.display_name}}</option>
                        </select> -->
                        <pg-select style="width: 100%;" formControlName="frequency" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                          <pg-option *ngFor='let period of periodsFltrArr;let i = index' Label="{{period.display_name}}" Value="{{period.disp_id}}">
                          </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('frequency').invalid && (getFormControl('frequency').dirty || getFormControl('frequency').touched)">
                          Frquency is required
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2" >
                        <label>Offset</label>
                        <!-- <select class="form-control" formControlName="offset">
                          <option *ngFor='let period of periodsFltrArr;let i = index' value="{{period.disp_id}}">{{period.display_name}}</option>
                        </select> -->
                        <pg-select style="width: 100%;" formControlName="offset" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                          <pg-option *ngFor='let period of periodsFltrArr;let i = index' Label="{{period.display_name}}" Value="{{period.disp_id}}">
                          </pg-option>
                        </pg-select>
                      </div>
                    </div>

                  </div>
                  
                </div>  


                <h6 class="m-b-15 m-t-15">Price details</h6>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('currency').invalid && (getFormControl('currency').dirty || getFormControl('currency').touched)">
                        <label>Currency</label>

                        <pg-select style="width: 100%;" formControlName="currency" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                          <pg-option *ngFor='let currency of currenciesFltrArr;let i = index' Label="{{currency.description}}" Value="{{currency.disp_id}}">
                          </pg-option>
                        </pg-select>
                        <!-- <select class="form-control" formControlName="currency" >
                          <option *ngFor='let currency of currenciesFltrArr;let i = index' value="{{currency.disp_id}}">{{currency.description}}</option>
                        </select> -->
                        <div class="invalid-tooltip" *ngIf="getFormControl('currency').invalid && (getFormControl('currency').dirty || getFormControl('currency').touched)">
                          Currency is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('basicPrice').invalid && (getFormControl('basicPrice').dirty || getFormControl('basicPrice').touched)">
                        <label>Basic Price</label>
                        <input type="text" class="form-control" formControlName="basicPrice" (input)="onPriceChange()"  placeholder="Enter Basic Price" appTwoDigitDecimaNumber />
                        <div class="invalid-tooltip" *ngIf="getFormControl('basicPrice').invalid && (getFormControl('basicPrice').dirty || getFormControl('basicPrice').touched)">
                          Basic Price is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 " >
                        <label>Discount</label>
                        <pg-select style="width: 100%;" formControlName="discount"  [(ngModel)]="selectedOption" [PlaceHolder]="'Please Select'" (OpenChange)="calculateTrialPrice(selectedOption)" AllowClear ShowSearch >
                          <pg-option Label="No Discount" Value="0"></pg-option>
                          <pg-option *ngFor='let discount of tmpAllDiscountsData;let i = index' Label="{{discount.discout_name}}" Value="{{discount.id}}">
                          </pg-option>
                        </pg-select>
                        
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('taxType').invalid && (getFormControl('taxType').dirty || getFormControl('taxType').touched)">
                        <label>Tax Type</label>
                        <pg-select style="width: 100%;" formControlName="taxType" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                          <pg-option *ngFor='let taxType of taxTypes' [Label]="taxType.label" [Value]="taxType.value">
                          </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('taxType').invalid && (getFormControl('taxType').dirty || getFormControl('taxType').touched)">
                          Tax Type is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                      <div class="col-md-6">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('taxValue').invalid && (getFormControl('taxValue').dirty || getFormControl('taxValue').touched)">
                          <label>Tax Value</label>
                          <input type="text" class="form-control"  formControlName="taxValue"  (input)="onPriceChange()"  placeholder="Enter Tax Value" appTwoDigitDecimaNumber />
                          <div class="invalid-tooltip" *ngIf="getFormControl('taxValue').invalid && (getFormControl('taxValue').dirty || getFormControl('taxValue').touched)">
                            Tax Value is required
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('taxCode').invalid && (getFormControl('taxCode').dirty || getFormControl('taxCode').touched)">
                          <label>Tax Code</label>
                          <input type="text" class="form-control" formControlName="taxCode"  placeholder="Enter Tax Code">
                          <div class="invalid-tooltip" *ngIf="getFormControl('taxCode').invalid && (getFormControl('taxCode').dirty || getFormControl('taxCode').touched)">
                            Tax Code is required
                          </div>
                        </div>
                      </div>
                  </div>

                  <div class="row clearfix" *ngIf="showTrialPrice">
                    <div class="col-md-12" >
                      <div pgFormGroupDefault  class="form-group form-group-default" >
                        <label>Trial Price</label>
                        <input type="text" numberOnly class="form-control" formControlName="trialPrice" />
                      </div>
                    </div>
                  </div>  
                  <div class="row clearfix">  

                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default" >
                        <label>Final Price</label>
                        <input type="text" class="form-control"  formControlName="finalPrice" />
                      </div>
                    </div>
                  </div> 

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2" >
                        <label>Promo discounts</label>
                        <div class="inpt d-flex align-items-center">
                          <pg-select style="width: 100%;" formControlName="promoCodeDiscounts" [PlaceHolder]="'Please Select'" AllowClear ShowSearch >
                            <pg-option *ngFor='let disc of allPromoDiscData;let i = index' Label="{{disc.discout_name}}" Value="{{disc.id}}">
                            </pg-option>
                          </pg-select>
                          <button class="btn m-r-10 p-r-20 p-l-20 btn-primary active" type="button" (click)="addPromoDiscount()">Add</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="form-group row">
                  <div class="table-responsive">
                    <table class="table dataTable selTbl">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Display Name</th>
                                <th scope="col">Value</th>
                                <th scope="col">Period</th>
                                <th scope="col" class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let discount of selPromoDiscArr; let i = index;">
                              <td>
                                <a routerLink="/discounts/edit/{{discount.id}}">{{discount.discout_name}}</a>
                              </td>
                              <td>
                                {{discount.display_name}}
                              </td>
                              <td>
                                <span *ngIf="discount.discount_type=='PERCENTAGE'">{{discount.discount_value}}%</span>
                                <span *ngIf="discount.discount_type=='AMOUNT'">{{discount.symbol}}{{discount.discount_value}}</span>
                              </td>
                              <td>
                                {{discount.frequency}}
                              </td>
                              <td class="text-center">
                                <a (click)="deletePromoDiscountRow(i,discount.id)" class="hover">
                                  <!-- <img src="assets/close.svg"/> -->
                                  <i class="pg-icon text-info">close</i>
                                </a>
                              </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                </div>



                <h6 class="m-b-15 m-t-35">Complimentary gift details</h6>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" id="compGiftStatus"  formControlName="compGiftStatus">
                          <label for="compGiftStatus">Enable Free Gift?</label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" id="compGiftConsentStatus" formControlName="compGiftConsentStatus">
                          <label for="compGiftConsentStatus">Show Consent?</label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default ">
                        <label>Gift Description</label>
                        <input type="text" class="form-control" formControlName="compGiftDesc" placeholder="Enter Gift Description">
                      </div>
                    </div>

                  </div>
                </div> 
                
                <div class="row p-t-20">
                  <div class=" col-md-12 text-right ">
                    <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10">Add</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                    <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/plans/all">Cancel</button>
                  </div>
                </div>

              </form>
              <div *ngFor="let alert of alerts" class="p-t-20">
                <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
              </div>
        </div>
      </div>
    </div>
  </div>  
</div>    

<!-------No Access Block--------->
<app-no-access *ngIf="!addPlansPrmns"></app-no-access>