<div class="modal-header change-card-detail">
  <h5>Change Card Details</h5>
</div>
<div class="modal-body change-card-detail">
  <div class="container">
    <div class="row">
      <div class="col-md-12" *ngIf="userCardsArr?.length > 0">
        <h6 class="text-center">Change Card</h6>
        <p class="text-center"> Select one card from the list of available cards: </p>
      </div>
      <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
      <div *ngIf="!showLoadingSpinner" class="row">
        <div class="col-md-6"  *ngFor="let card of userCardsArr; let i = index;">
          <div [class.selected]="selectedCard === i || (card.primary === 1 && isCardClicked === 0)">
            <div class="card card-detail full-width bg-primary padding-15 ">
              <label>Card Number</label>
              <input class="bg-transparent border-0" type="text" value="**** **** **** {{card.last4}}" placeholder="Crad Number" disabled>
              <label>Expiry Date</label>
              <input class="bg-transparent border-0" type="text" value="{{card.expiry}}" placeholder="Expiry Date" disabled>
            </div>
            <button type="button" class="btn btn-default select" *ngIf="userCardsArr?.length > 1" (click)="onCardSelect(i,card.last4)">Select</button>
          </div>
        </div>
        <p *ngIf="userCardsArr?.length == 0">No card available.</p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer change-card-detail">
  <button type="button" class="btn btn-primary me-md-2 fs-14 padding-10 p-r-20 p-l-20" (click)="updateDefaultCard()" *ngIf="userCardsArr?.length > 1">Submit</button>
  <button type="button" class="btn btn-danger fs-14 padding-10 p-r-20 p-l-20" (click)="bsModalRefhide()">Cancel</button>
</div>