import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ReportsComponent } from './reports/reports.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AllReportsComponent } from './all-reports/all-reports.component';

import { ReportsRoutingModule } from './reports-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ReportsService } from '../../services/reports.service';

import { CommonTempModule } from '../common-temp/common.module';
import { pgSelectModule } from '../../components/select/select.module';
import { RevenueComponent } from './revenue/revenue.component';
import { ContentComponent } from './content/content.component';
import { DatawallComponent } from './datawall/datawall.component';
import { NewSubsComponent } from './new-subs/new-subs.component';
import { TotalLostSavesComponent } from './total-lost-saves/total-lost-saves.component';
import { FunnelReportComponent } from './funnel-report/funnel-report.component';
import { SubsMetricsComponent } from './subs-metrics/subs-metrics.component';
import { ScreenDashboardComponent } from './screen-dashboard/screen-dashboard.component';
import { pgCardModule } from '../../components/card/card.module';
import { PlainLiveWidgetComponent } from '../../widgets/plain-live-widget/plain-live-widget.component';
import { SendEmailModalComponent } from './send-email-modal/send-email-modal.component'
//import { PlainLiveWidgetModule } from 'path-to-widget-module/plain-live-widget.module';

import { SwiperModule,SwiperConfigInterface , SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { MineDataComponent } from './mine-data/mine-data.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'vertical',
  slidesPerView: 1
};

@NgModule({
  declarations: [ReportsComponent, SubscriptionComponent, AllReportsComponent, RevenueComponent, ContentComponent, DatawallComponent, NewSubsComponent, TotalLostSavesComponent, FunnelReportComponent, SubsMetricsComponent, ScreenDashboardComponent, PlainLiveWidgetComponent, MineDataComponent, SendEmailModalComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    pgSelectModule,
    BrowserAnimationsModule,
    CommonTempModule,
    pgCardModule,
    SwiperModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule,
    AlertModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [
    BsModalRef,
    ReportsService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class ReportsModule { }
