<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="editPromoPrmns || viewPromoPrmsn">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/promocodes/all" href="javascript:void(0)">Promo codes</a></li>
      <li class="breadcrumb-item active" *ngIf="editPromoPrmns"> Edit Promo Code</li>
      <li class="breadcrumb-item active" *ngIf="!editPromoPrmns && viewPromoPrmsn"> View Promo Code</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
          <h5 class="mw-80 mb-2" *ngIf="editPromoPrmns">Edit Promo Code</h5>
          <h5 class="mw-80 mb-2" *ngIf="!editPromoPrmns && viewPromoPrmsn">View Promo Code</h5>
          <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
          <form *ngIf="!showLoadingSpinner" class="addNewBrandForm mx-auto" id="form-project"
            [formGroup]="editPromocodeForm" (ngSubmit)="editPromocodeFormSubmit()" novalidate>
            <div class="">
              <div class="row clearfix">
                <div class="col-md-12">
                  <div pgFormGroupDefault class="form-group form-group-default required"
                    [class.has-error]="getFormControl('promoCode').invalid && (getFormControl('promoCode').dirty || getFormControl('promoCode').touched)">
                    <label>Promo code</label>
                    <input type="text" class="form-control" formControlName="promoCode" placeholder="Enter Promo code"
                      appNameValidation>
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('promoCode').invalid && (getFormControl('promoCode').dirty || getFormControl('promoCode').touched)">
                      Promo Code is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-12">
                  <div pgFormGroupDefault class="form-group form-group-default required"
                    [class.has-error]="getFormControl('promoName').invalid && (getFormControl('promoName').dirty || getFormControl('promoName').touched)">
                    <label>Name <br /><small>(Only letters, digits, underscore or dash. No whitespaces or
                        symbols)</small></label>
                    <input type="text" class="form-control" formControlName="promoName" placeholder="Enter Promo Name"
                      appNameValidation>
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('promoName').invalid && (getFormControl('promoName').dirty || getFormControl('promoName').touched)">
                      Promo Name is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-12">
                  <div pgFormGroupDefault class="form-group form-group-default required"
                    [class.has-error]="getFormControl('promoDesc').invalid && (getFormControl('promoDesc').dirty || getFormControl('promoDesc').touched)">
                    <label>Description</label>
                    <input type="text" class="form-control" formControlName="promoDesc" placeholder="Enter Description">
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('promoDesc').invalid && (getFormControl('promoDesc').dirty || getFormControl('promoDesc').touched)">
                      Description is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="input-group form-group form-group-default required"
                    [class.has-error]="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                    <label>Start Date</label>
                    <div class="input-group">
                      <pg-datepicker formControlName="startDate" Placeholder="Select Start Date"
                        [Format]="'DD/MM/YYYY'"></pg-datepicker>
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="pg-icon">calendar</i>
                        </span>
                      </div>
                    </div>
                    <!-- <input type="date" class="form-control" formControlName="startDate" placeholder="Select Start Date"> -->
                    <div class="invalid-tooltip" *ng
                      If="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                      Start Date is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div pgFormGroupDefault class="input-group form-group form-group-default">
                    <label>End Date</label>
                    <div class="input-group">
                      <pg-datepicker formControlName="endDate" Placeholder="Select End Date"
                        [Format]="'DD/MM/YYYY'"></pg-datepicker>
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="pg-icon">calendar</i>
                        </span>
                      </div>
                    </div>
                    <!-- <input type="date" class="form-control" formControlName="endDate"> -->
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-12">
                  <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2  required"
                    [class.has-error]="getFormControl('promoUserType').invalid && (getFormControl('promoUserType').dirty || getFormControl('promoUserType').touched)">
                    <label>Promo User Type</label>
                    <pg-select style="width: 100%;" formControlName="promoUserType" [PlaceHolder]="'Promo User Type'" [(ngModel)]="selectedOptions" 
                      AllowClear ShowSearch>
                      <pg-option *ngFor='let promoUser of promoUserType;let i = index' Label="{{promoUser.name}}"
                        Value="{{promoUser.id}}">
                      </pg-option>
                    </pg-select>

                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('promoUserType').invalid && (getFormControl('promoUserType').dirty || getFormControl('promoUserType').touched)">
                      Promo User Type is required
                    </div>
                  </div>
                </div>

              </div>
              <div class="row clearfix">
                <div class="col-md-12">
                  <div class="form-group form-group-default">
                    <label for="promocodeStatus">Status</label>
                    {{promoStatus}}
                  </div>
                </div>

              </div>
            </div>

            <div class="row p-t-20">
              <div class=" col-md-12 text-right ">
                <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10"
                  *ngIf="editPromoPrmns">Update</button>
                <img *ngIf="loading"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/promocodes/all"
                  *ngIf="editPromoPrmns">Cancel</button>
                <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/promocodes/all"
                  *ngIf="!editPromoPrmns && viewPromoPrmsn">Back</button>
              </div>
            </div>
          </form>
          <div *ngFor="let alert of alerts" class="p-t-20">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-------No Access Block--------->
<app-no-access *ngIf="!editPromoPrmns && !viewPromoPrmsn"></app-no-access>