import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomersService } from '../../../../services/customers.service';
import { Constants } from '../../../../common/constants';

@Component({
  selector: 'app-change-card-modal',
  templateUrl: './change-card-modal.component.html',
  styleUrls: ['./change-card-modal.component.scss']
})
export class ChangeCardModalSubComponent implements OnInit {
  customerId : string;
  alerts: any[];
  public requestType: EventEmitter<any> = new EventEmitter();
  selectedCard : number;
  showLoadingSpinner = true;
  userCardsArr: any[];
  selCardDigit : number;
  isCardClicked : number = 0;

  constructor(public bsModalRef: BsModalRef,
    private customersService: CustomersService) {
  
  }

  ngOnInit(): void {
    this.getCustomerCardDetails();
  }
  
  bsModalRefhide(){
    this.bsModalRef.hide();
  }

  /**********************************API Method to get all the card details*********************/
  getCustomerCardDetails() {
    this.customersService.getCustomerCardDetails(this.customerId).then(
      res=>{
        if(res['code']==1 && (res['status']==1 || res['status']==2)) {
          this.userCardsArr = res.result;
          this.showLoadingSpinner = false;
        } else {
          //alert(res['message']);
        }
      },error=>{
          alert(Constants.VIEW_CARD_FAILURE_MSG);
          this.bsModalRef.hide();
      }
    );
  }

  /**********************************Click event to highlight the selected card*********************/
  onCardSelect(i, digit) {
    this.selectedCard = i;
    this.selCardDigit = digit;
    this.isCardClicked = 1;
  }

  // Method send to its parent
  triggerEvent(value: number) {
    this.requestType.emit({ data: value , res:200 });
  }

  /**********************************API method to submit the update card details*********************/
  updateDefaultCard() {
    let cardDigit = this.selCardDigit;
    if(cardDigit) { 
      let dataObj = {
        'last_four': cardDigit
      };
      this.customersService.UpdateDefaultCard(this.customerId, dataObj).then(
        res => {
            console.log("---cancel 1-----"+JSON.stringify(res));
            this.bsModalRef.hide();
            this.triggerEvent(res);
        });
    } else {
      alert(Constants.NO_CARD_SELECTED);
    }
  }


}
