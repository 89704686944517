import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { TemplatesComponent } from './templates/templates.component';
import { AllTemplatesComponent } from './all-templates/all-templates.component';
import { AddTemplateComponent } from './add-template/add-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';

import { AuthGuard } from '../../common/auth.guard';

const routes: Routes = [
    {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AuthGuard],
        children: [
            {
              path: 'all',
              component: AllTemplatesComponent,
            },
            {
              path: 'add',
              component: AddTemplateComponent,
            },
            {
              path: 'edit/:id',
              component: EditTemplateComponent
            },
            { path: '', redirectTo: 'templates/all', pathMatch: 'full' }
        ]
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TemplatesRoutingModule { }