import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { CustomersService } from '../../../services/customers.service';
import { Constants } from '../../../common/constants';
import { CommonService } from '../../../services/common.service';
import { Country } from 'country-state-city';
import { PlansService } from '../../../services/plans.service';

@Component({
  selector: 'app-add-experience',
  templateUrl: './add-experience.component.html',
  styleUrls: ['./add-experience.component.scss']
})

export class AddExperienceComponent implements OnInit {
  experienceForm: FormGroup;
  showWhereEvent: boolean = false;
  showWhereAction: boolean = false;
  userRolesArr: any[];
  slectedPlan: any[];
  alerts: any[];
  allActiveBrands: any[];
  selectedCountry;
  selectedOption = '31';
  states = null;
  countries = Country.getAllCountries();
  isMeterActItemAdded = false; 
  isaddWhereActAdded = false;

  ngOnInit() {
    this.experienceForm = this.fb.group({
      expBrand: [{value: ''}, [Validators.required]],
      expName: "",
      expDesc: "",
      where: this.fb.group({
        allpages: [true],
        data: this.fb.array([])
      }),
      condObj: this.fb.group({
        who: this.fb.group({
          allUsers: [true],
          data: this.fb.array([]),
          eventObj: this.fb.group({
            metFor: ['maxLim'],
            meterAct: this.fb.array([]),
            /* eventAct: this.fb.group({
               type: ['showTemplate'],
               value: ['require-login-form']
             })*/
          }),
          actionObj: this.fb.array([])
        }),
        /* condAction: this.fb.group({
           type: ['showTemplate'],
           value: ['require-login-form']
         })*/
      })
    });

    this.addWhereDataItem();
    this.addWhoDataItem();
    //this.addWhereEvent();
    //this.addWhereAction();
  }
  
  constructor(private fb: FormBuilder,
    private customersService: CustomersService,
    private plansService: PlansService,   
    private commonService: CommonService ) {
    this.getAllActiveBrands();  
    this.getAllCustomerRoles();
    this.getAllPlans(31)
  }

  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    //this.userList = [];
    this.getAllPlans(brandId);
  }

  // Helper method to access form arrays
  get whereData() {
    return (this.experienceForm.get('where.data') as FormArray);
  }

  get whoData() {
    return (this.experienceForm.get('condObj.who.data') as FormArray);
  }

  get meterAct() {
    return (this.experienceForm.get('condObj.who.eventObj.meterAct') as FormArray);
  }

  get actionObj() {
    return (this.experienceForm.get('condObj.who.actionObj') as FormArray);
  }

  get allPagesControl() {
    this.resetExpValueForAllItems();
    return this.experienceForm.get('where.allpages');
  }

  get allUsersControl() {
    this.resetAllUsers();
    return this.experienceForm.get('condObj.who.allUsers');
  }

  // allActiveBrands = [
  //   { value: 'HME', label: 'HME' },
  //   { value: 'CAT', label: 'CAT' },
  //   { value: 'LOG', label: 'LOG' },
  //   { value: 'CID', label: 'CID' },
  //   { value: 'MEP', label: 'MEP' },
  // ];

  expCondOption = [
    { value: 'and', label: 'AND' },
    { value: 'or', label: 'OR' }
  ];

  expFieldOption = [
    { value: 'url', label: 'URL' },
    { value: 'metaTag', label: 'Meta Tag' },
    { value: 'cat', label: 'Category' },
    { value: 'contType', label: 'Content Type' },
    { value: 'tags', label: 'Tags' }
  ];

  expDataCondOption = [
    { value: 'includes', label: 'Includes' },
    { value: 'excludes', label: 'Excludes' },
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Not Equals' }
  ];

  whoFieldOption = [
    { value: 'plan', label: 'Plan' },
    { value: 'subType', label: 'Subscription Type' },
    { value: 'licStatus', label: 'License Status' },
    { value: 'userRole', label: 'User Role' },
    { value: 'logStatus', label: 'Login Status' },
    { value: 'geoLoc', label: 'Geo Location' }
  ];

  whoDataCondOption = [
    { value: 'includes', label: 'Includes' },
    { value: 'excludes', label: 'Excludes' },
    { value: 'equals', label: 'Equals' },
    { value: 'notEquals', label: 'Not Equals' }
  ];

  whoValueOption = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ];

  metForOption = [
    { value: 'maxLim', label: 'Max. Limit' },
    { value: 'offLim', label: 'Offered Limit' }
  ];

  metDataCondOption = [
    { value: 'is', label: 'is' },
    { value: 'not', label: 'not' }
  ];

  metActValOption = [
    { value: 'expired', label: 'Expired' },
    { value: 'custom', label: 'Custom Count' }
  ];

  metActCondValOption = [
    { value: 'hme-stickybar1', label: 'hme-stickybar1' },
    { value: 'hme-stickybar2', label: 'hme-stickybar2' },
    { value: 'hme-stickybar1-loggedin', label: 'hme-stickybar1-loggedin' },
    { value: 'hme-stickybar2-loggedin', label: 'hme-stickybar2-loggedin' },
    { value: 'hme-requirelogin', label: 'hme-requirelogin' },
    { value: 'hme-requireentitlement', label: 'hme-requireentitlement' },
    { value: 'hme-requireloginwithentitlement', label: 'hme-requireloginwithentitlement' },
    { value: 'hme-requireentitlementpremium', label: 'hme-requireentitlementpremium' },
    { value: 'hme-requireloginwithentitlementpremium', label: 'hme-requireloginwithentitlementpremium' },
    { value: 'requireentitlementemagazine', label: 'hme-requireentitlementemagazine' },
    { value: 'hme-requireloginwithentitlement-emagazine', label: 'hme-requireloginwithentitlement-emagazine' },
  ];

  metActCondTypeOption = [
    { value: 'modal', label: 'Modal' },
    { value: 'inline', label: 'Inline' }
  ];

  licStatusOption = [
    { value: '0', label: 'CANCELLED' },
    { value: '1', label: 'ACTIVE' },
    { value: '2', label: 'CANCEL_PENDING' },
    { value: '3', label: 'ACTIVE_PENDING' },
    { value: '4', label: 'COMPLETED' },
    { value: '5', label: 'FAILED_PENDING' },
    { value: '6', label: 'FAILED' }
  ];


  /**********************************API Method to Get All Customer roles*********************/
  getAllCustomerRoles() {
    this.customersService.getAllCustomerRoles(Constants.STATUS_ACTIVE).then(
      res => {
        if (res['code'] == 1 && res['status'] == 1) {
          this.userRolesArr = res['result'];
          console.log('USER Role' + JSON.stringify(this.userRolesArr))
        } else {
          this.alerts = [{
            type: 'danger',
            msg: res['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      }, error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
      }
    );
  }

  /**********************************API Method to Get Plan based on brand*********************/
  getAllPlans(brandId) {
    this.plansService.getAllPlans(brandId).then(
      res => {
        if (res['code'] == 1 && res['status'] == 1) {
          this.slectedPlan = res['result'];
          console.log('Plan' + JSON.stringify(this.slectedPlan))
        } else {
          this.alerts = [{
            type: 'danger',
            msg: res['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      }, error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
      }
    );
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
      res => {
        if (res['code'] == 1 && res['status'] == 1) {
          this.allActiveBrands = res['result'];
        } else {
          this.alerts = [{
            type: 'danger',
            msg: res['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      }, error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
      }
    );
  }

  resetExpValueForAllItems() {
    const allPagesControl = this.experienceForm.get('where.allpages');
    const dataArray = this.whereData;

    if (allPagesControl?.value === true && dataArray.length > 1) {
      dataArray.removeAt(1);

      if (dataArray.length > 0) {
        const firstGroup = dataArray.at(0) as FormGroup;
        const expValueControl = firstGroup.get('expValue') as FormControl;
        if (expValueControl) {
          expValueControl.setValue('');
        }
      }
    }
  }

  // Add and remove methods for form arrays
  addWhereDataItem() {
    this.whereData.push(this.fb.group({
      expField: ['url'],
      expCusFld: [''],
      expDataCond: ['includes'],
      expValue: [''],
      expCond: ['and']
    }));
  }


  removeWhereDataItem(index: number) {
    this.whereData.removeAt(index);
  }

  resetAllUsers() {
    const allUserControl = this.experienceForm.get('condObj.who.allUsers');
    const dataArray = this.whoData;
    if (allUserControl?.value === true && dataArray.length > 1) {
      dataArray.removeAt(1);
      if (dataArray.length > 0) {
        const firstGroup = dataArray.at(0) as FormGroup;
        const expValueControl = firstGroup.get('whoValue') as FormControl;
        if (expValueControl) {
          expValueControl.setValue('');
        }
      }
    }
  }

  addWhoDataItem() {
    this.whoData.push(this.fb.group({
      whoField: ['plan'],
      whoDataCond: ['includes'],
      whoValue: [''],
      whoCond: ['and']
    }));
  }

  removeWhoDataItem(index: number) {
    this.whoData.removeAt(index);
  }
  addMeterActItem() {
    this.meterAct.push(this.fb.group({
      metDataCond: ['is'],
      metActVal: ['expired'],
      metCustcount: [''],
      metActCondVal: ['reqlogin'],
      metActCondType: ['modal'],
      metActCondId: ['']
    }));

  }

  addWhereActObj() {
    this.actionObj.push(this.fb.group({
      finalActCondVal: [''],
      finalActCondType: [''],
      finalActCondId: ['']
    }));
  }

  removeMeterActItem(index: number) {
    this.meterAct.removeAt(index);
  }

  addWhereEvent() {
  }

  addMeteringEvent() {
    if (!this.isMeterActItemAdded) {  // Step 2: Check the flag
      this.addMeterActItem();
      this.isMeterActItemAdded = true;  // Step 3: Set the flag to true
    }
    this.showWhereEvent = true;
  }

  removeWhereEvent() {
    this.showWhereEvent = false;
  }

  addWhereAction() {
    if (!this.isaddWhereActAdded) {  // Step 2: Check the flag
      this.addWhereActObj();
      this.isaddWhereActAdded = true;  // Step 3: Set the flag to true
    }
    this.showWhereAction = true;
    
  }

  removeWhereAction() {
    this.showWhereAction = false;
  }

  /*********************************Method to show/hide tag name custom filed based on the type selected******************************************** */
  expFieldChange(value, index: number) {
    let ctrId = "expCusFldCtr_" + index;
    if (value == "metaTag" && ctrId) {
      if (document.getElementById(ctrId)) {
        document.getElementById(ctrId)?.classList.remove("d-none");
      } else {
        document.getElementById(ctrId)?.classList.add("d-none");
      }
    } else {
      document.getElementById(ctrId)?.classList.add("d-none");
    }
  }

  onExperienceFormSubmit() {

  }

  onExperienceFormReset() {

  }
}
