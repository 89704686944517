import { Component, ChangeDetectorRef, HostListener, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { LoginService} from '../../services/login.service';
import { pagesToggleService } from '../../services/toggler.service';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent {
  title : string = "Dashboard";
  isSelected: boolean = false;
  alerts: any[];
  _mobileSidebar: boolean = false;
  @ViewChild('inputValueData', { static: true }) inputValueData: ElementRef;

 
  ueserNameWelcome = "Welcome" + " " + localStorage.getItem('pw_fname')+ " " + localStorage.getItem('pw_lname');
  ueserName =  localStorage.getItem('pw_fname')+ " " + localStorage.getItem('pw_lname');
  changePwdPrmsn : boolean = true;
  constructor(public commonService: CommonService, private cdr: ChangeDetectorRef, private loginService : LoginService, public toggler: pagesToggleService) { }

  @HostListener('mouseleave') closeDropdown() {
    this.isSelected = false;
  }

  toggleMobileSidebar() {
    const bodyElement = document.body;
    let bodyClass = bodyElement.classList.contains('sidebar-open');
    let sideBar = bodyElement.querySelector('.page-sidebar');
    if(sideBar)
      if (bodyClass === true) {
        sideBar.classList.remove('visible');
        bodyElement.classList.remove('sidebar-open');
      } else {
        sideBar.classList.add('visible');
        bodyElement.classList.add('sidebar-open');
      }

  }

  logout() {
    this.loginService.logout();
  }

  functionClick($event) {
    if(this.isSelected) {
      this.isSelected = false;
    }
  }

  ngAfterViewInit() {
    this.commonService.currentPage.subscribe(title => {
      this.title = title;
      this.cdr.detectChanges();
    }, err => {
      console.log(err);
    });
    
  }

}
