
  <div class="modal-body pt-4">
    Are you sure you want to cancel the purchase <span *ngIf="crValue == 2">at the end of the Billing period</span>?
    <form class="CancelRequestForm mt-4" [formGroup]="CancelRequestForm" (ngSubmit)="CancelRequestFormSubmit()">
        <div class="form-group row" >
            <!-- <label for="reason" class="col-xs-12 col-form-label"><b>Reason for Cancellation</b>(Optional)<b>:</b></label>
            <div class="col-xs-12">
              <select class="form-control" formControlName="cancelRequestReason">
                <option value="">Select a reason</option>
                <option *ngFor='let reason of cancelReason' value="{{reason.id}}">{{reason.reason}}</option>
              </select>
            </div> -->
            <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('cancelRequestReason').invalid && (getFormControl('cancelRequestReason').dirty || getFormControl('cancelRequestReason').touched)">
              <label>Reason for Cancellation</label>
              <pg-select style="width: 100%;" formControlName="cancelRequestReason" [PlaceHolder]="'Select a reason'" AllowClear ShowSearch >
                  <pg-option *ngFor='let reason of cancelReason' [Label]="reason.reason" [Value]="reason.id">
                  </pg-option>
              </pg-select>
              <div class="invalid-tooltip" *ngIf="getFormControl('cancelRequestReason').invalid && (getFormControl('cancelRequestReason').dirty || getFormControl('cancelRequestReason').touched)">
                Reason for Cancellation is required
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-danger">Cancel</button>
            <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Close</button>
          </div>
    </form>
  </div>