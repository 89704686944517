import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { CustomersService } from '../../../services/customers.service';
import { Constants } from '../../../common/constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-all-customers',
  templateUrl: './all-customers.component.html',
  styleUrls: ['./all-customers.component.scss']
})
export class AllCustomersComponent implements OnInit {
  selectedOption = '0';
  rows: any[];
  tmp: any[];
  activeData: [];
  inactiveData: [];
  allActiveBrands: any[];
  alerts: any[];
  showLoadingSpinner = true;
  selectedBrand : number;//PMV temporary
  sortColumn : string = Constants.DEF_USERS_SORT_COLUMN;
  sortOrder : string = Constants.SORT_ORDER_ASC;
  currentPage : number = Constants.DEF_TABLE_START_PAGE;
  totalUsers : number = 1; //set initial value to '1' to hide empty user page section on load
  index1 : number = Constants.DEF_TABLE_START_INDEX;
  defPager : number = Constants.DEF_TABLE_PAGER;
  defPageCount : number = Constants.DEF_TABLE_DATA_COUNT;
  userList : any[];
  searchStr : string = "";
  pager: any = {};
  //Role based permission check
  viewUsersPrmsn : boolean = true;
  addUsersPrmns : boolean = true;
  editUsersPrmns : boolean = true;

  constructor(private commonService: CommonService, 
    private cdr: ChangeDetectorRef, 
    private titleService: Title,
    private customersService: CustomersService) {
      /****************Permission check based on role******************/
      this.viewUsersPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_CUSTOMERS, Constants.PERMISSION_VIEW_SUBSCRIBERS, Constants.PERMISSION_ADD_CUSTOMERS, Constants.PERMISSION_EDIT_CUSTOMERS]);
      this.addUsersPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_CUSTOMERS]);
      this.editUsersPrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_CUSTOMERS]);
      this.getAllActiveBrands();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
    this.titleService.setTitle("Datawall Users");
    this.commonService.setTitle("Datawall Users");
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if(this.viewUsersPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
          this.allActiveBrands = res['result'];
          this.selectedBrand = 0;//All
          this.getAllUsers(this.selectedBrand, this.currentPage, this.defPageCount);
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

  /***********************************Method to get All Users List***********************************************/
  getAllUsers(brandId, currentPage: number, pageSize : number) {
    if(this.viewUsersPrmsn) {
      this.showLoadingSpinner = true;
      let dataObj = {
        'pageNumber' : currentPage-1,
        'pageSize' : pageSize,
        'sortingColumn' : this.sortColumn,
        'sortingOrder' : this.sortOrder,
        'searchKeys' : this.searchStr
      };
      this.customersService.getAllCustomers(brandId, dataObj).then(
        res => {
          this.showLoadingSpinner = false;
          if(res.userList.length>0) {
            this.userList = res.userList;
            this.currentPage = currentPage;
            this.index1 = currentPage*Constants.DEF_TABLE_DATA_COUNT - 9; //calculation based on selected pager for S.No
            this.totalUsers = res.totalUsers;
            this.pager = this.commonService.getPaginationDetails(currentPage, this.totalUsers, this.defPageCount);
          } else {
            this.userList = [];
            this.totalUsers = 0;
          }
        },
        error => {
          this.showLoadingSpinner = false;
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        });
      }
  }

  /******************************Method to filter the brand based customer data***************************/
  fetchCustomersData(brandId: number) {
    this.rows = this.tmp;
   /* const temp = this.rows.filter(function (d) {
      return d.is_active == status;
    });
    this.rows = temp;*/
  }

  /******************************Method to filter email/firstName/lastName column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    console.log(val);
    if(val) {
      const temp = this.rows.filter(function (d) {
        // return d.first_name.toLowerCase().indexOf(val) !== -1 || d.last_name.toLowerCase().indexOf(val) !== -1 || d.email.toLowerCase().indexOf(val) !== -1 || !val;
        let textValue = d.first_name + d.last_name + d.email + d.id;
        return textValue.toLowerCase().indexOf(val) > -1;
      });
      this.rows = temp;
    } else {
      this.fetchCustomersData(this.selectedBrand);
    }
  }

  /******************************Method to filter user list based on the given search string***************************/
  searchUserList(searchStr : string ) {
      this.searchStr = searchStr;
      this.getAllUsers(this.selectedBrand, this.defPager, this.defPageCount)
  }

  /*************************Pagination button click event**************************************************/
  setPage(currentPage: number) {
    this.userList = [];
    this.getAllUsers(this.selectedBrand, currentPage, this.defPageCount)
  }

  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    this.userList = [];
    this.getAllUsers(brandId, this.defPager, this.defPageCount);
    this.selectedBrand = brandId
  }

  /**************************Method to get sorted user list*******************************/
  sortUserTable(event: any, name : string) {
    this.sortColumn = name;
    const classList = event.target.classList;
    const classes = event.target.className;
    
    if(classes.includes("sorting_asc")) {
      event.target.classList.remove('sorting_asc');
      event.target.classList.add('sorting_desc');
      this.sortOrder = Constants.SORT_ORDER_DESC;
    }
    else {
      event.target.classList.remove('sorting_desc');
      event.target.classList.add('sorting_asc');
      this.sortOrder = Constants.SORT_ORDER_ASC;
    }

    const allHeaders = Array.from(document.querySelectorAll('th'));

    allHeaders.forEach(elem => {
      if(elem.classList.contains("sortable") && elem != event.target) {
        elem.classList.remove('sorting_desc');
        elem.classList.remove('sorting_asc');
        elem.classList.add('sorting');
      }
    })

    event.target.classList.remove('sorting');
    this.setPage(Constants.DEF_TABLE_START_PAGE);
  }

}
