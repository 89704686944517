import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { DashboardService} from '../../../services/dashboard.service';
import { Constants } from '../../../common/constants';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.scss']
})
export class AllReportsComponent implements OnInit {

  rows: any[];
  tmp: any[];
  alerts: any[];
  showLoadingSpinner = true;
  defStatusArr : any[] = Constants.DEF_STATUS;
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  //Role based permission check
  viewAllReportsPrmsn : boolean = true;
  downloadAllReportsPrmns : boolean = true;

  constructor(private commonService: CommonService, private cdr: ChangeDetectorRef, private titleService: Title, private dashboardService : DashboardService) { 
    /****************Permission check based on role******************/
    this.viewAllReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_REPORTS, Constants.PERMISSION_DOWNLOAD_REPORTS]);
    this.downloadAllReportsPrmns = commonService.getSecPermissions([Constants.PERMISSION_DOWNLOAD_REPORTS]);
    this.commonService.subNavSelect(Constants.NAV_REPORTS);
    this.getAllReportTypes();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
    this.titleService.setTitle("Download Reports");
    this.commonService.setTitle("Download Reports");
  }

   /**********************************API Method to Get All Reports*********************/
   getAllReportTypes() {
    if(this.viewAllReportsPrmsn) {
      this.commonService.getAllReportTypes().then(
        res=>{
        if(res['code']==1 && res['status']==1) {
            this.rows = res['result'];
            this.tmp = res['result'];
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        this.showLoadingSpinner = false;
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
            this.showLoadingSpinner = false;
        }
      );
    }
  }

  /******************************Method to filter title/description column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    if(val) {
      const temp = this.rows.filter(function (d) {
        return d.title.toLowerCase().indexOf(val) !== -1 || d.description.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
    } else {
      this.rows = this.tmp;
    }
  }

  /******************************Method to update Brand Status (Active/Inactive)***************************/
  downloadReport(type: number) {
    if(this.downloadAllReportsPrmns) {
      this.dashboardService.downloadReport(type).then(
      res => {
        if(res['code']==1 && res['status']==1) {//Success
          const file_name = type == 1 ? 'subscribed_users_list' : type == 2 ? 'transactions_list' : 'datawall_users';
          this.exportAsExcelFile(res['result'], file_name);
          this.alerts = [{
            type: 'success',
            msg: Constants.REPORT_DOWNLOAD_SUCCESS,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          
        } else {//invalid report type
          this.alerts = [{
            type: 'danger',
            msg: Constants.REPORT_DOWNLOAD_FAILED,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      },
      error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
      });
    }
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
  }

}
