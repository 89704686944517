<div class="container-fluid p-l-25 p-r-25 p-t-0 p-b-25 sm-padding-10 sm-gutter">
  <!-- START ROW -->
  <div class="card card-transparent">
    <div class="card-header mb-3 p-0">
      <p class="mb-4">This report provides a comprehensive analysis of paywall performance, focusing on user metrics. It highlights active, new, failed, and canceled subscriptions, alongside churn rate, offering insights into user engagement and subscription health. This report aids in understanding trends and making informed strategic decisions.</p>
      <div class="row align-items-center">
        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 p-b-15">
          <div class="d-flex flex-row align-items-center">
            <span class="m-r-5">Brands: </span>
            <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Brands'"
              [(ngModel)]="selectedOption" (ngModelChange)="brandFilterChange(selectedOption)" ShowSearch>
              <pg-option [Label]="'All'" [Value]="'0'"></pg-option>
              <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select"
                [Label]="brand.brand_display_name" [Value]="brand.id">
              </pg-option>
            </pg-select>
          </div>
        </div>
        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 p-b-15">
          <div class="d-flex flex-row align-items-center">
            <span class="p-r-10">Period: </span>
            <input type="text" placeholder="Last 30 Days" class="form-control" (bsValueChange)="dateRangeChange($event)"
              bsDaterangepicker
              [bsConfig]="{ ranges: ranges, showCustomRangeLabel: false, containerClass:'theme-dark-blue' }"
              [bsValue]="bsValue">
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-b-15 d-flex justify-content-end align-items-center">
          <a (click)="getSubscriptionsData(true)" class="hover m-r-5" title="Refresh">
            <i class="pg-icon m-20">refresh</i>
          </a>
          <span class="text-muted font-size-13">Last synced on: {{ lastSyncTime }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>

    <div class="card-body p-l-0 p-r-0">
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
      </div>
      <h4 *ngIf="noDataAvailable && !showLoadingSpinner" class="text-center">No data available for the selected
        period/brand.</h4>
      <div class="align-items-center m-b-40" *ngIf="!noDataAvailable && !showLoadingSpinner">
        <div class="row clearfix">
          <div class="col-sm-12 col-md-4 col-xl-2">
            <div class="card m-b-10 ">
              <div class="card-header top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps">
                    Active Subs 
                  </span>
                </div>
              </div>
              <div class="card-body no-padding">
                <div class="container-xs-height full-height">
                  <div class="row-xs-height">
                    <div class="col-xs-height col-top">
                      <div class="p-l-20 p-t-50 p-b-0  p-r-20">
                        <h4 class="no-margin p-b-5">{{activeSubs | number}}</h4>
                        <p class="p-t-20"><small >(As of Today)</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="row-xs-height">
                    <div class="col-xs-height col-bottom">
                      <div class="progress progress-small m-b-0">
                        <div class="progress-bar progress-bar-default" style="width: 100%;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-2">
            <div class="card m-b-10 ">
              <div class="card-header top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps">
                    Total new subs
                  </span>
                </div>
              </div>
              <div class="card-body no-padding">
                <div class="container-xs-height full-height">
                  <div class="row-xs-height">
                    <div class="col-xs-height col-top">
                      <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                        <h4 class="no-margin p-b-5"> {{newSubs | number}} </h4>
                        <p class="p-t-20"><small>({{ datePlaceholder }})</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="row-xs-height">
                    <div class="col-xs-height col-bottom">
                      <div class="progress progress-small m-b-0">
                        <div class="progress-bar progress-bar-green" style="width: 100%;background-color: green;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-2">
            <div class="card m-b-10 ">
              <div class="card-header top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps">
                    Total lost
                  </span>
                </div>
              </div>
              <div class="card-body no-padding">
                <div class="container-xs-height full-height">
                  <div class="row-xs-height">
                    <div class="col-xs-height col-top">
                      <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                        <h4 class="no-margin p-b-5">{{lost | number}}</h4>
                        <p class="p-t-20"><small>({{ datePlaceholder }})</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="row-xs-height">
                    <div class="col-xs-height col-bottom">
                      <div class="progress progress-small m-b-0">
                        <div class="progress-bar progress-bar-danger" style="width: 100%;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-2">
            <div class="card m-b-10 ">
              <div class="card-header top-left top-right">
                <div class="card-title hover">
                  <span class="font-montserrat fs-11 all-caps">
                    Total churn rate <span title="Total Lost / Net New Subs" class="hover"><svg
                        xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                        class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path
                          d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                      </svg></span>
                  </span>
                </div>
              </div>
              <div class="card-body no-padding">
                <div class="container-xs-height full-height">
                  <div class="row-xs-height">
                    <div class="col-xs-height col-top">
                      <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                        <h4 class="no-margin p-b-5"> {{churnRate}}</h4>
                        <p class="p-t-20"><small>({{ datePlaceholder }})</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="row-xs-height">
                    <div class="col-xs-height col-bottom">
                      <div class="progress progress-small m-b-0">
                        <div class="progress-bar progress-bar-warning" style="width: 100%;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-2">
            <div class="card m-b-10">
              <div class="card-header top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps">
                    Net new subs
                  </span>
                </div>
              </div>
              <div class="card-body no-padding">
                <div class="container-xs-height full-height">
                  <div class="row-xs-height">
                    <div class="col-xs-height col-top">
                      <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                        <h4 class="no-margin p-b-5">{{netSubs | number}}</h4>
                        <p class="p-t-20"><small>({{ datePlaceholder }})</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="row-xs-height">
                    <div class="col-xs-height col-bottom">
                      <div class="progress progress-small m-b-0">
                        <div class="progress-bar progress-bar-success" style="width: 100%;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-2">
            <div class="card m-b-10 ">
              <div class="card-header top-left top-right">
                <div class="card-title">
                  <span class="font-montserrat fs-11 all-caps">
                    Total Amount
                  </span>
                </div>
              </div>
              <div class="card-body no-padding">
                <div class="container-xs-height full-height">
                  <div class="row-xs-height">
                    <div class="col-xs-height col-top">
                      <div class="p-l-20 p-t-50 p-b-0 p-r-20">
                        <h4 class="no-margin p-b-5">${{paid | number}}</h4>
                        <p class="p-t-20"><small>({{ datePlaceholder }})</small></p>
                      </div>
                    </div>
                  </div>
                  <div class="row-xs-height">
                    <div class="col-xs-height col-bottom">
                      <div class="progress progress-small m-b-0">
                        <div class="progress-bar progress-bar-brown" style="width: 100%;background-color: #810402;">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---------------chart section--------------->

      <div class="col-sm-12 m-b-40 d-flex justify-content-between" (click)="onButtonGroupClick($event)" *ngIf="!noDataAvailable && !showLoadingSpinner">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="d-flex flex-row align-items-center">
            <span class="m-r-5">Datasets: </span>
            <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Dataset'"
              [(ngModel)]="selectedDataset" (ngModelChange)="datasetFilterChange(selectedDataset)" ShowSearch>
              <pg-option [Label]="'New Subscriptions'" [Value]="'newSubs'"></pg-option>
              <pg-option [Label]="'Active Subscriptions'" [Value]="'activeSubs'"></pg-option>
              <pg-option [Label]="'Total Payments'" [Value]="'totPay'"></pg-option>
              <pg-option [Label]="'Total Lost'" [Value]="'totLost'"></pg-option>
            </pg-select>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 text-right">
          <button class="btn btn-secondary p-l-15 p-r-15" (click)="daily()" title="Daily"  >Daily</button>
          <button class="btn btn-secondary p-l-15 p-r-15" (click)="weekly()" title="Weekly" >Weekly</button>
          <button class="btn btn-secondary p-l-15 p-r-15" (click)="monthly()" title="Monthly" >Monthly</button>
          <button class="btn btn-secondary p-l-15 p-r-15" (click)="quaterly()" title="Quaterly" >Quaterly</button>
          <button class="btn btn-secondary p-l-15 p-r-15" (click)="yearly()" title="Yearly" >Yearly</button>
        </div>
      </div>

      <div class="col-sm-12 m-t-60 m-b-40 chart-container">
        
        <canvas #myCanvas width="500" height="400" *ngIf="!noDataAvailable">{{ periodSubsChart }}</canvas>
      </div>
      <div class="d-flex align-items-center justify-content-between" *ngIf="!noDataAvailable && !showLoadingSpinner">
        <!-- <h4>Last 30 days</h4> -->
        <h5>{{ datePlaceholder }}</h5>
        <button class="btn btn-secondary downloadBtn m-l-10" title="Export as CSV" (click)="downloadCSV()">
          Export as CSV <i class="pg-icon text-info">download</i>
        </button>
      </div>
      <div class="col-sm-12 m-t-10 m-b-10 d-flex" *ngIf="!noDataAvailable && !showLoadingSpinner">
        <div class="card  full-height no-border no-margin widget-11 widget-loader-bar">
          <div class="card-body no-padding">
            <div class="widget-11-table auto-overflow ng-tns-c30-37">
             <div class="table-container">
              <table class="table table-condensed1 table-hover" id="subscriptionTbl">
                <thead>
                  <tr>
                    <th>Plans</th>
                    <th class="text-right">New Subs</th>
                    <th class="text-right">Active Pending</th>
                    <th class="text-right">Cancelled </th>
                    <th class="text-right">Cancel Pending</th>
                    <th class="text-right">Failed</th>
                    <th class="text-right">Failed Pending</th>
                    <th class="text-right">Chrun Rate
                      <span class="hover"
                        title="(Cancelled + Cacel Pending + Failed + Failed Pending) / (New Subs + Active Pending)"><svg
                          xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                          class="bi bi-exclamation-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path
                            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                        </svg></span>
                    </th>
                    <th class="text-right">Net New Subs</th>
                    <th class="text-right">Active subs</th>
                    <th class="text-right">First time payments </th>
                    <th class="text-right">Renewal payments</th>
                    <th class="text-right">Total Payments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sub of planSubs; index as i">
                    <td>{{sub.plan_name}}</td>
                    <td class="text-right">{{sub.newSubs | number}}</td>
                    <td class="text-right">{{sub.activePending | number}}</td>
                    <td class="text-right">{{sub.cancelled | number}}</td>
                    <td class="text-right">{{sub.cancelPending | number}}</td>
                    <td class="text-right">{{sub.failed | number}}</td>
                    <td class="text-right">{{sub.failedPending | number}}</td>
                    <td class="b-r b-dashed b-grey text-right">{{sub.planchurRate}}%</td>
                    <td class="b-r b-dashed b-grey text-right">{{ (sub.newSubs - (sub.activePending + sub.cancelled +
                      sub.cancelPending + sub.failed + sub.failedPending)) | number }}</td>
                    <td class="b-r b-dashed b-grey text-right text-primary">{{sub.activeSubs | number}}</td>
                    <td class="text-right">{{sub.newSubs | number}}</td>
                    <td class="b-r b-dashed b-grey text-right">{{sub.renewPayment | number}}</td>
                    <td class="text-right">{{sub.newSubs + sub.renewPayment | number}}</td>
                  </tr>
                  <tr *ngIf="planSubsTot">
                    <td class="b-r b-dashed b-grey"><strong>TOTAL</strong></td>
                    <td class="text-right bold">{{planSubsTot.total_newSubs | number}}</td>
                    <td class="text-right bold">{{planSubsTot.total_activePending | number}}</td>
                    <td class="text-right bold">{{planSubsTot.total_cancelled | number}}</td>
                    <td class="text-right bold">{{planSubsTot.total_cancelPending | number}}</td>
                    <td class="text-right bold">{{planSubsTot.total_failed | number}}</td>
                    <td class="text-right bold">{{planSubsTot.total_failedPending | number}}</td>
                    <td class="b-r b-dashed b-grey text-right"></td>
                    <td class="b-r b-dashed b-grey text-right bold">{{ (planSubsTot.total_newSubs - (planSubsTot.total_activePending + planSubsTot.total_cancelled +
                      planSubsTot.total_cancelPending + planSubsTot.total_failed + planSubsTot.total_failedPending)) | number }}</td>
                    <td class="b-r b-dashed b-grey text-right text-primary bold">{{planSubsTot.total_plan_active | number}}</td>
                    <td class="text-right bold">{{planSubsTot.total_newSubs | number}}</td>
                    <td class="b-r b-dashed b-grey text-right bold">{{planSubsTot.total_renewal_payments | number}}</td>
                    <td class="text-right bold">{{planSubsTot.total_newSubs + planSubsTot.total_renewal_payments | number}}</td>
                  </tr>
                </tbody>
              </table>
             </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
<!-------No Access Block--------->
<app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>