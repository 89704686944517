<div class="padding-20">
    <div class="card " *ngIf="viewUsersPrmsn">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12"> 
                    <div class="d-flex flex-row align-items-center"> 
                    <span class="m-r-5">Brands: </span>

                    <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="selectedOption" (ngModelChange)="brandChange(selectedOption)"  ShowSearch >
                        <pg-option  [Label]="'All'" [Value]="'0'" ></pg-option>
                        <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select" [Label]="brand.brand_name" [Value]="brand.id" >
                        </pg-option>
                    </pg-select>
                    <!-- <select class="form-control m-l-10 m-r-auto" [style.width.px]="300" (change)="brandChange($event.target.value)" [(ngModel)]="selectedBrand">
                        <option value="0">All</option>
                        <option *ngFor='let brand of allActiveBrands;let i = index' value="{{brand.id}}">{{brand.brand_display_name}}</option>
                    </select> -->
                    </div>    
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search User Id/Email" (input)="searchStr=$event.target.value" #searchFld aria-label="Search" (keyup.enter)="searchUserList(searchFld.value)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv text-right" *ngIf="addUsersPrmns">
                    <a class="btn btn-complete fs-15 padding-10" routerLink="/users/add" role="button">+ Add User</a>
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <!--<div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>-->
        <div class="card-body p-0">
            <ngx-datatable
                class="material user-data"
                [rows]="userList"
                [count]="totalUsers"
                [limit]="pageSize"
                [externalPaging]="true"
                [externalSorting]="true"
                [loadingIndicator]="showLoadingSpinner"
                [headerHeight]="50"
                [footerHeight]="50"
                [columnMode]="'flex'"
                rowHeight="auto"
                (sort)="onSort($event)"
                (page)="onPage($event)">
                <ngx-datatable-column name="User Id" [flexGrow]="0.75"  prop="id">
                  <ng-template let-row="row" ngx-datatable-cell-template> 
                      {{row.id}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Email" [flexGrow]="3" prop="email">
                  <ng-template ngx-datatable-cell-template let-row="row">
                    <ng-container>
                      <a routerLink="/subs/view/{{pageType}}/{{row.id}}/{{row.brand_id}}">{{ row.email }}</a>
                    </ng-container>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Brand" [flexGrow]="1" prop="brand_name">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.brand_name}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="First Name" [flexGrow]="2" prop="first_name">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.first_name}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Last Name" [flexGrow]="2" prop="last_name">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.last_name}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" prop="status" [flexGrow]="1">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <ng-container *ngIf="row.status===1">Active</ng-container>
                    <ng-container *ngIf="row.status===0">Inactive</ng-container>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action" [flexGrow]="1">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                      <a routerLink="'/subs/view/'+pageType+'' + row.id + '/' + row.brand_id" class="" title="View/Edit">
                          <i class="pg-icon text-info">edit</i>
                      </a>
                  </ng-template>
                </ngx-datatable-column>
      
                <!-- Other columns -->
                <!--<ng-container *ngFor="let column of columns">
                  <ngx-datatable-column *ngIf="column.prop !== 'email' && column.prop !== 'brand_id'" [prop]="column.prop" [name]="column.name">
                  </ngx-datatable-column>
                </ng-container>-->
      
              </ngx-datatable>
      
            
      
      
            <div class="limitCtr">
              <label for="pageLimit">Rows per page:</label>
              <select id="pageLimit" [(ngModel)]="pageSize" (change)="onLimitChange()" class="m-l-5">
                <option *ngFor="let size of pageSizeOptions" [value]="size">{{ size }}</option>
              </select>
            </div>
        </div>

    </div>
    <!-------No Access Block--------->
  <app-no-access *ngIf="!viewUsersPrmsn"></app-no-access>
</div>
    