import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';
import * as FunnelGraph from 'funnel-graph-js';
import { FunnelGraphLoaderServiceService } from '../../../services/funnel-service-loader.service.service';

@Component({
  selector: 'app-funnel-report',
  templateUrl: './funnel-report.component.html',
  styleUrls: ['./funnel-report.component.scss']
})
export class FunnelReportComponent implements OnInit {
  dataExample2 = {
    labels: ['Datawall', 'Paywall', 'Register/Login', 'Buy Now', 'Plan Selected', 'Subscribed'],
    colors: ['#F38181', '#FCE38A', '#EAFFD0', '#F08A5D', '#B83B5E', '#6A2C70'],
    values: [19421, 8754, 1365, 216, 99, 13]
  };

  constructor(
    private commonService: CommonService, private cdr: ChangeDetectorRef, private titleService: Title, private reportsService: ReportsService, private elementRef: ElementRef, private dynamicScriptLoader: FunnelGraphLoaderServiceService
  ) {}


  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle("Funnel Report");
    this.commonService.setTitle("Funnel Report");
    this.loadScripts();
    this.dashboardFunnel();
  }

  dashboardFunnel() {
    const graph = new FunnelGraph({
      container: '.funnel',
      gradientDirection: 'horizontal',
      data: this.dataExample2,
      displayPercent: true,
      direction: 'horizontal',
    });

    graph.draw();
  }


  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('chartjs', 'random-num').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }
}
