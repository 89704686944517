<div class="padding-20">       
    <div class="card list" *ngIf="viewActionTypePrmsn">
        <div class="card-header hide">
            <div class="row justify-content-between">
                <div class="col-lg-7 col-sm-12">  
                    <h5 class="card-title d-none d-sm-block">All Metering Action Types</h5>
                </div>
                <div class="col-lg-5 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Name" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable class="material container1" *ngIf="!showLoadingSpinner" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="10" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.5" cellClass="text-right" headerClass="text-right">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Type" [flexGrow]="1" prop="type">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{commonService.getValueFromArr(metActTypeArr,row.type,'name')}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" [flexGrow]="1.5" prop="name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.name}}
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>

    </div>
    <!-------No Access Block--------->
    <app-no-access *ngIf="!viewActionTypePrmsn"></app-no-access>
</div>    
    