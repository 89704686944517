import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomersComponent } from './customers/customers.component';
import { AllCustomersComponent } from './all-customers/all-customers.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { EditUnSubscribersComponent } from './edit-unsubscribers/edit-unsubscribers.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component';
import { CustomerOrderDetailsComponent } from './customer-order-details/customer-order-details.component';
import { AllSubscribersComponent } from './all-subscribers/all-subscribers.component';
import { EditSubscriberComponent } from './edit-subscriber/edit-subscriber.component';
import { SubscriberOrderDetailsComponent } from './subscriber-order-details/subscriber-order-details.component';
import { UnSubscribersComponent } from './un-subscribers/un-subscribers.component'

import { AuthGuard } from '../../common/auth.guard';

const routes: Routes = [
    {
        path: 'users',
        component: CustomersComponent,
        canActivate: [AuthGuard],
        children: [
            {
              path: 'all',
              component: AllCustomersComponent
            },
            {
              path: 'subscribers',
              component: AllSubscribersComponent
            },
            {
              path: 'unsubscribers',
              component: UnSubscribersComponent
            },
            {
              path: 'add',
              component: AddCustomerComponent
            },
            {
              path: 'edit/:id/:brandid',
              component: EditCustomerComponent
            },            {
              path: 'unsubs/edit/:id/:brandid',
              component: EditUnSubscribersComponent
            },
            {
              path: 'subs/edit/:id/:brandid',
              component: EditSubscriberComponent
            },
            {
              path: 'subs/order/:id/account/:userid/brand/:brandid',
              component: SubscriberOrderDetailsComponent
            },
            {
              path: 'change-pwd/:id/:brandid',
              component: ChangePwdComponent
            },
            {
              path: 'order/:id/account/:userid/brand/:brandid',
              component: CustomerOrderDetailsComponent
            },
            { path: '', redirectTo: 'users/all', pathMatch: 'full' }
        ]
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }