import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-site-licenses',
  templateUrl: './site-licenses.component.html',
  styleUrls: ['./site-licenses.component.scss']
})
export class SiteLicensesComponent implements OnInit {

  constructor(private commonService: CommonService, private cdr: ChangeDetectorRef, private titleService: Title) {
    this.commonService.subNavSelect(Constants.NAV_SITE_LICENSES);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle("Group Subscriptions");
    this.commonService.setTitle("Group Subscriptions");
  }

}
