import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ExperienceComponent } from './experience/experience.component';
import { AllExperiencesComponent } from './all-experiences/all-experiences.component';
import { AddExperienceComponent } from './add-experience/add-experience.component';
import { EditExperienceComponent } from './edit-experience/edit-experience.component';

import { ExperienceRoutingModule } from './experience-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//Services
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { FormGroupDefaultModule } from '../../directives/form-group-default.module';
import { pgSelectModule } from '../../components/select/select.module';

import { CommonTempModule } from '../common-temp/common.module';

import {  pgCardModule } from '../../components/card/card.module';

@NgModule({
  declarations: [ExperienceComponent, AllExperiencesComponent, AddExperienceComponent, EditExperienceComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    ExperienceRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    FormGroupDefaultModule,
    pgSelectModule,
    BrowserAnimationsModule,
    CommonTempModule,
    pgCardModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot()
  ]
})
export class ExperienceModule { }
