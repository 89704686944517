
<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0">
    <div class="inner">
      <ol class="breadcrumb sm-p-b-5 m-l-15">
        <li class="breadcrumb-item"><a  routerLink="/templates/all" href="javascript:void(0)">TEMPLATES</a></li>
        <li class="breadcrumb-item active"> CREATE NEW</li>
      </ol>
    </div>
  
    <div class="row">
      <div class="col-lg-12 col-xl-12 mx-auto">
        <div class="card ">
          <div class="card-body">
              <h5 class="mb-2">Add New Template</h5>
              <form class="addNewWidgetForm mx-auto" [formGroup]="addNewWidgetForm" novalidate>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                        <label>Brand</label>
                        <pg-select style="width: 100%;" formControlName="brandName" [PlaceHolder]="'Select Brand'" AllowClear ShowSearch >
                          <pg-option Label="HME"   Value="1">
                          </pg-option>
                          <pg-option Label="PMV"   Value="2">
                        </pg-option>
                        <pg-option Label="MEP"   Value="3">
                        </pg-option>
                        <pg-option Label="CWO"   Value="4">
                        </pg-option>
                        </pg-select>
                        
                        <div class="invalid-tooltip" *ngIf="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                          Brand is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('widgetType').invalid && (getFormControl('widgetType').dirty || getFormControl('widgetType').touched)">
                        <label>Widget Type</label>
                        <pg-select style="width: 100%;" formControlName="widgetType" [PlaceHolder]="'Select Group'" AllowClear ShowSearch>
                          <pg-option Label="Metering"   Value="1">
                          </pg-option>
                          <pg-option Label="Offer"   Value="2">
                            </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('widgetType').invalid && (getFormControl('widgetType').dirty || getFormControl('widgetType').touched)">
                            Group is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('widgetName').invalid && (getFormControl('widgetName').dirty || getFormControl('widgetName').touched)">
                        <label>Template Name</label>
                        <input type="text" class="form-control" formControlName="widgetName" [ngClass]="{ 'is-invalid': submitted && f.widgetName.errors }" placeholder="Enter Template Name">
                        <div class="invalid-tooltip" *ngIf="getFormControl('widgetName').invalid && (getFormControl('widgetName').dirty || getFormControl('widgetName').touched)">
                            Template Name is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('widgetDesc').invalid && (getFormControl('widgetDesc').dirty || getFormControl('widgetDesc').touched)">
                        <label>Description</label>
                        <input type="text" class="form-control" formControlName="widgetDesc"   placeholder="Template Description">
                        <div class="invalid-tooltip" *ngIf="getFormControl('widgetDesc').invalid && (getFormControl('widgetDesc').dirty || getFormControl('widgetDesc').touched)">
                          Description is required
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row clearfix p-t-5 p-b-5">
                    <div class="col-md-12" >
                      <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0">
                          <input type="checkbox" id="switchSample" checked="checked" formControlName="widgetStatus">
                          <label for="switchSample">Status</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <!-- <div class="col-md-12">
                      <div pgFormGroupDefault    class="form-group form-group-default required"  [class.has-error]="getFormControl('widgetContent').invalid && (getFormControl('widgetContent').dirty || getFormControl('widgetContent').touched)">
                        <label class="p-b-10">Widget Content</label>
                        <ckeditor [editor]="Editor" [config]="config" type="text" class="ckeditor" formControlName="widgetContent" [ngClass]="{ 'is-invalid': submitted && f.widgetContent.errors }" placeholder="Enter Widget Content" ></ckeditor>
                        <div class="invalid-tooltip" *ngIf="getFormControl('widgetContent').invalid && (getFormControl('widgetContent').dirty || getFormControl('widgetContent').touched)">
                          Template Content is required
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-6">
                      <button (click)="run()">Run</button>
                      <tabset class="templates-tab">
                        <tab heading="HTML" id="tab1">
                          <ngx-codemirror #codemirrorHTML [options]="codeMirrorOptions"></ngx-codemirror>
                        </tab>
                        <tab heading="CSS">
                          <ngx-codemirror #codemirrorCSS [options]="codeMirrorOptionsCss"></ngx-codemirror>
                        </tab>
                      </tabset>

                    </div>
                    <div class="col-md-6">
                      <div >
                        <iframe #output></iframe>
                      </div>
                    </div>
                  </div>
                </div>  
                <div class="row p-t-20">
                  <div class=" col-md-12 text-right ">
                    <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10">Add</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/templates/all">Cancel</button>
                  </div>
                </div>
              </form> 
              <div *ngFor="let alert of alerts">
                <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
              </div> 
          </div>
        </div>
      </div>
    </div>  
  </div>   
  