import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { AllSubscribersComponent } from './all-subscribers/all-subscribers.component';

import { SubscribersRoutingModule } from './subscribers-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


//Services
import { CustomersService } from '../../services/customers.service';

import { FormGroupDefaultModule } from '../../directives/form-group-default.module';
import { pgSelectModule } from '../../components/select/select.module';

import { CommonTempModule } from '../common-temp/common.module';
import { EditSubscriberComponent } from './edit-subscriber/edit-subscriber.component';
import { pgTabsModule } from '../../components/tabs/tabs.module';
@NgModule({
  declarations: [SubscribersComponent, AllSubscribersComponent, EditSubscriberComponent],
  imports: [
    CommonModule,
    SubscribersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormGroupDefaultModule,
    pgSelectModule,
    NgxDatatableModule,
    pgTabsModule,
    BrowserAnimationsModule,
    CommonTempModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot()
  ],
  providers: [CustomersService]
})
export class SubscribersModule { }
