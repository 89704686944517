import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ChangepwdComponent } from './components/changepwd/changepwd.component';
import { ConfigurationsComponent } from './components/configurations/configurations.component';
import { AuthGuard } from './common/auth.guard';

var route: string = "dashboard"; //Added for temporary check
const role = localStorage.getItem("pw_role");

if (role == "1" || role == "2") {
//level4admin & super admin role
 route = "dashboard";
} else {
  route = "corpsub/all";
}

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'changepwd', component: ChangepwdComponent, canActivate: [AuthGuard]},
  {path: 'config', component: ConfigurationsComponent, canActivate: [AuthGuard]},
  { path: '', redirectTo: route, pathMatch: 'full', canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
