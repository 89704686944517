import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SiteLicensesComponent } from './site-licenses/site-licenses.component';
import { AddSiteLicenseeComponent } from './add-site-licensee/add-site-licensee.component';
import { AllSiteLicensesComponent } from './all-site-licenses/all-site-licenses.component';
import { EditSiteLicenseeComponent } from './edit-site-licensee/edit-site-licensee.component';

import { SiteLicensesRoutingModule } from './site-licenses-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { SiteLicensesService } from '../../services/site-licenses.service';
import { TwoDigitDecimaNumberDirective } from '../../directives/two-digit-decima-number.directive';

import { DirectivesModule } from '../../directives/directives.module';

import { FormGroupDefaultModule } from '../../directives/form-group-default.module';
import { pgSelectModule } from '../../components/select/select.module';
import { pgDatePickerModule } from '../../components/datepicker/datepicker.module';

import { CommonTempModule } from '../common-temp/common.module';

@NgModule({
  declarations: [SiteLicensesComponent, AddSiteLicenseeComponent, AllSiteLicensesComponent, EditSiteLicenseeComponent],
  imports: [
    CommonModule,
    SiteLicensesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    FormGroupDefaultModule,
    pgDatePickerModule,
    pgSelectModule,
    DirectivesModule,
    CommonTempModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    TabsModule.forRoot()
  ]
})
export class SiteLicensesModule { }
