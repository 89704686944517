
<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="addWidgetsPrmns">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a  routerLink="/widgets/all" href="javascript:void(0)">WIDGETS</a></li>
      <li class="breadcrumb-item active"> CREATE NEW</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <h5 class="mb-2">Add New Widgets</h5>
            <form class="addNewWidgetForm mx-auto" [formGroup]="addNewWidgetForm" (ngSubmit)="addNewWidgetFormSubmit()" novalidate>
              <div class="">
                <div class="row clearfix">
                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                      <label>Brand</label>
                      <pg-select style="width: 100%;" formControlName="brandName" [PlaceHolder]="'Select Brand'" AllowClear ShowSearch >
                        <pg-option *ngFor='let brand of allActiveBrands;let i = index' Label="{{brand.brand_name}}"   Value="{{brand.id}}">
                        </pg-option>
                      </pg-select>
                      
                      <div class="invalid-tooltip" *ngIf="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                        Brand is required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('widgetType').invalid && (getFormControl('widgetType').dirty || getFormControl('widgetType').touched)">
                      <label>Widget Type</label>
                      <pg-select style="width: 100%;" formControlName="widgetType" [(ngModel)]="selectedOption"  [PlaceHolder]="'Select Widget Type'" AllowClear ShowSearch (OpenChange)="onWidgetTypeChange(selectedOption)" >
                        <pg-option *ngFor='let widgetType of widgetTypeArr;let i = index' Label="{{widgetType.name}}"   Value="{{widgetType.id}}">
                        </pg-option>
                      </pg-select>
                      <div class="invalid-tooltip" *ngIf="getFormControl('widgetType').invalid && (getFormControl('widgetType').dirty || getFormControl('widgetType').touched)">
                        Widget Type is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('widgetName').invalid && (getFormControl('widgetName').dirty || getFormControl('widgetName').touched)">
                      <label>Widget Name</label>
                      <input type="text" class="form-control" formControlName="widgetName" [ngClass]="{ 'is-invalid': submitted && f.widgetName.errors }" placeholder="Enter Widget Name">
                      <div class="invalid-tooltip" *ngIf="getFormControl('widgetName').invalid && (getFormControl('widgetName').dirty || getFormControl('widgetName').touched)">
                        Widget Name is required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('widgetDesc').invalid && (getFormControl('widgetDesc').dirty || getFormControl('widgetDesc').touched)">
                      <label>Description</label>
                      <input type="text" class="form-control" formControlName="widgetDesc"   placeholder="Enter Description">
                      <div class="invalid-tooltip" *ngIf="getFormControl('widgetDesc').invalid && (getFormControl('widgetDesc').dirty || getFormControl('widgetDesc').touched)">
                        Description is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('actionType').invalid && (getFormControl('actionType').dirty || getFormControl('actionType').touched)">
                      <label>Action Type</label>
                      <pg-select style="width: 100%;"  formControlName="actionType"  [(ngModel)]="widgetAction" (OpenChange)="onWidgetActionChange(widgetAction)" [PlaceHolder]="'Select Action Type'" AllowClear ShowSearch >
                        <pg-option *ngFor='let widgetAction of widgetActionFltrArr;let i = index' Label="{{widgetAction.name}}"    Value="{{widgetAction.id}}">
                        </pg-option>
                      </pg-select>
                      <div class="invalid-tooltip" *ngIf="getFormControl('actionType').invalid && (getFormControl('actionType').dirty || getFormControl('actionType').touched)">
                        Action Type is required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('widgetGroup').invalid && (getFormControl('widgetGroup').dirty || getFormControl('widgetGroup').touched)">
                      <label>Group</label>
                      <pg-select style="width: 100%;" formControlName="widgetGroup" [PlaceHolder]="'Select a Group'" AllowClear ShowSearch >
                        <pg-option *ngFor='let widgetGroup of widgetGroupArr;let i = index' Label="{{widgetGroup.group_name}}"   Value="{{widgetGroup.id}}">
                        </pg-option>
                      </pg-select>
                      <div class="invalid-tooltip" *ngIf="getFormControl('widgetGroup').invalid && (getFormControl('widgetGroup').dirty || getFormControl('widgetGroup').touched)">
                        Widget Group is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-12" *ngIf="selWidgetAction==5">
                    <div pgFormGroupDefault  class="form-group form-group-default" >
                      <label>Custom count</label>
                      <input type="number" class="form-control" formControlName="customCount" placeholder="Enter Custom count">
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 ">
                      <label>Content Type</label>
                      <pg-select style="width: 100%;" formControlName="contentType" [PlaceHolder]="'Select Content Type'" AllowClear ShowSearch >
                        <pg-option *ngFor='let contentType of widgetCntTypeArr;let i = index' Label="{{contentType.type}}"   Value="{{contentType.id}}">
                        </pg-option>
                      </pg-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 " >
                      <label>Content Category</label>
                      <pg-select style="width: 100%;" formControlName="contentCategory" [PlaceHolder]="'Select Content Category'" AllowClear ShowSearch >
                        <pg-option *ngFor='let contentCategory of widgetCntCatArr;let i = index' Label="{{contentCategory.category_name}}"   Value="{{contentCategory.id}}">
                        </pg-option>
                      </pg-select>
                    </div>
                  </div>
                </div>
                <div class="row clearfix p-t-5 p-b-5">
                  <div class="col-md-12" *ngIf="selWidgetType==2">
                    <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                      <div class="form-check switch switch-lg success full-width right m-b-0">
                        <input type="checkbox" id="isLoggedIn" checked="checked" formControlName="isLoggedIn">
                        <label for="isLoggedIn">Is Logged In?</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix p-t-5 p-b-5">
                  <div class="col-md-12" >
                    <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                      <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                        <input type="checkbox" id="widgetStatus" checked="checked" formControlName="widgetStatus">
                        <label for="widgetStatus">Status</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-12">
                    <div pgFormGroupDefault    class="form-group form-group-default required"  [class.has-error]="getFormControl('widgetContent').invalid && (getFormControl('widgetContent').dirty || getFormControl('widgetContent').touched)">
                      <label class="p-b-10">Widget Content</label>
                      <ckeditor [editor]="Editor" [config]="config" type="text" class="ckeditor" formControlName="widgetContent" [ngClass]="{ 'is-invalid': submitted && f.widgetContent.errors }" placeholder="Enter Widget Content" ></ckeditor>
                      <div class="invalid-tooltip" *ngIf="getFormControl('widgetContent').invalid && (getFormControl('widgetContent').dirty || getFormControl('widgetContent').touched)">
                        Widget Content is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
              <div class="row p-t-20">
                <div class=" col-md-12 text-right ">
                  <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10">Add</button>
                  <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/widgets/all">Cancel</button>
                </div>
              </div>
            </form> 
            <div *ngFor="let alert of alerts">
              <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
            </div> 
        </div>
      </div>
    </div>
  </div>  
</div>   
<!-------No Access Block--------->
<app-no-access *ngIf="!addWidgetsPrmns"></app-no-access>
