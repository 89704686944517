import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../../../services/common.service';
import { ActivatedRoute,Router} from '@angular/router';
import { PromoCodesService } from '../../../services/promo-codes.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-edit-promo-code',
  templateUrl: './edit-promo-code.component.html',
  styleUrls: ['./edit-promo-code.component.scss']
})
export class EditPromoCodeComponent implements OnInit {
  editPromocodeForm: FormGroup;
  submitted = false;
  loading = false;
  promoId : string;
  alerts: any[];
  showLoadingSpinner = true;
  promoStatus : string;
  promoUserType;
  selectedOptions;
  //Role based permission check
  viewPromoPrmsn : boolean = true;
  editPromoPrmns : boolean = true;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private promoCodeService: PromoCodesService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router) {
      /****************Permission check based on role******************/
      this.promoUserType = Constants.Promo_User_Type;
      console.log(this.promoUserType)
      this.viewPromoPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_PROMOCODES]);
      this.editPromoPrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_PROMOCODES]);
    }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
    this.promoId = this.route.snapshot.paramMap.get('id');    
    /****************Edit Promo Code Form Validation****************** */
    this.editPromocodeForm = this.formBuilder.group({
      promoCode: ['', [Validators.required]],
      promoName: ['', [Validators.required]],
      promoDesc: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: [''],
      promoUserType: ['', [Validators.required]],
    });
    this.getPromocodeDetail(this.promoId);    
  }

  // convenience getter for easy access to form fields
  get f() { return this.editPromocodeForm.controls; }

  getFormControl(name) {
    return this.editPromocodeForm.controls[name];
  }

  isFieldValid(field: string) {
    return !this.editPromocodeForm.get(field).valid && this.editPromocodeForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  } 
  /**************************Method to get promo code detail to prefill the form*******************************/

  getPromocodeDetail(promoId) {
    if(this.viewPromoPrmsn || this.editPromoPrmns) {
      this.promoCodeService.getPromocodeDetail(promoId).then(
        res => {
          if(res['code']==1 && res['status']==1) {//success
              this.showLoadingSpinner = false;
              let data = res.result; //console.log('NN ' + data['is_active']);           
              this.editPromocodeForm.controls['promoCode'].setValue(data['promo_code']);
              this.editPromocodeForm.controls['promoName'].setValue(data['promo_name']);
              this.editPromocodeForm.controls['promoDesc'].setValue(data['description']);
              this.editPromocodeForm.controls['startDate'].setValue(this.commonService.formatDate(data['start_date'])); //console.log(this.commonService.formatDate(data['start_date']));
              this.editPromocodeForm.controls['endDate'].setValue(this.commonService.formatDate(data['end_date'])); //this.commonService.formatDate(data['end_date'])
              this.selectedOptions = data['user_type'];
              //console.log(this.promoUserType);
              this.promoStatus = this.commonService.getStatusLabel(parseInt(data['is_active']));

              //this.editPromocodeForm.controls['promocodeStatus'].setValue(data['is_active'])
          } else {
            this.alerts = [{
              type: 'danger',
              msg: Constants.VIEW_BRAND_FAILURE_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        },
        error => {
            this.alerts = [{
              type: 'danger',
              msg: error.json().message,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        });
      }
  }  

  /*******************************Method to submit edit promo code form***************************************** */
  editPromocodeFormSubmit() {
    if(this.editPromoPrmns) {
      this.submitted = true;
      // stop here if form is invalid
      for (const i in this.editPromocodeForm.controls) {
        this.editPromocodeForm.controls[i].markAsDirty();
      }

      if (this.editPromocodeForm.invalid) {
          return;
      }
      this.loading = true;
      let dataObj = {};
      dataObj['promo_code'] = this.f.promoCode.value;
      dataObj['promo_name'] = this.f.promoName.value;
      dataObj['promo_desc'] = this.f.promoDesc.value;
      dataObj['start_date'] = this.commonService.formatDate(this.f.startDate.value);
      dataObj['end_date'] = this.commonService.formatDate(this.f.endDate.value);
      dataObj['promo_user_type'] = this.f.promoUserType.value;
      //dataObj['status'] = this.f.promocodeStatus.value == true ? Constants.STATUS_ACTIVE : Constants.STATUS_INACTIVE;
      this.promoCodeService.editPromocode(this.promoId, dataObj).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.alerts = [{
                type: 'success',
                msg: Constants.UPDATE_PROMOS_SUCCESS_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
              /*setTimeout(()=>{
                this.router.navigate(['/promocodes/all']);
              },2000);*/
            } else {
              let errorMsg = Constants.UPDATE_PROMOS_FAILURE_MSG;
              if(resStatus==3) {
                errorMsg = Constants.UPDATE_PROMOS_EXISTS_MSG;
              }
              this.alerts = [{
                type: 'danger',
                msg: errorMsg,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.UPDATE_PROMOS_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
            this.loading = false;
        });  
      }  
  }
}
