<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0">
    <div class="inner">
        <ol class="breadcrumb sm-p-b-5">
            <li routerLink="/subs/all/datawall" class="breadcrumb-item"><a href="javascript:void(0)">SUBSCRIBERS</a>
            </li>
            <li class="breadcrumb-item active">EDIT {{pageType}} SUBSCRIBER</li>
        </ol>
    </div>

    <div class="row">
        <div class="col-lg-9 col-xl-7 mx-auto">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <h5>test@yopmail.com</h5>
                            <p>(LTV - AED500)</p>
                        </div>
                        <div class="col-sm-12 col-md-6">

                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between m-b-20">
                <div>
                    User ID: 1245
                </div>
                <div>
                    Registered on: Mar 11, 2024
                </div>
                <div>
                    Last loggedin: Mar 11, 2024
                </div>
                <div>
                    Brand: CWO
                </div>
                <div>
                    AED370.00 spent
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="card card-borderless">
                        <pg-tabset tabAnimation="slide-left" Type="simple" ShowPagination="true">
                            <pg-tab>
                              <ng-template #TabHeading>
                                Overview
                              </ng-template>
                              <div class="row column-seperation">
                                  <div class="col-lg-6">
                                    <h3>
                                      <span class="semi-bold">Sometimes</span> Small things in life means the most
                                    </h3>
                                  </div>
                                  <div class="col-lg-6">
                                    <h3 class="semi-bold">great tabs</h3>
                                    <p>Native boostrap tabs customized to Pages look and feel, simply changing class name you can change color as well as its animations</p>
                                  </div>
                                </div>
                            </pg-tab>
                            <pg-tab>
                              <ng-template #TabHeading>
                                  Profile
                              </ng-template>
                              <div class="row">
                                  <div class="col-lg-12">
                                    <h3>“ Nothing is
                                      <span class="semi-bold">impossible</span>, the word itself says 'I'm
                                      <span class="semi-bold">possible</span>'! ”
                                    </h3>
                                    <p>A style represents visual customizations on top of a layout. By editing a style, you can use Squarespace's visual interface to customize your...</p>
                                    <br>
                                    <p class="pull-right">
                                      <button type="button" class="btn btn-default btn-cons">White</button>
                                      <button type="button" class="btn btn-success btn-cons">Success</button>
                                    </p>
                                  </div>
                              </div>
                            </pg-tab>
                            <pg-tab>
                                <ng-template #TabHeading>
                                    Access
                                </ng-template>
                                <div class="row">
                                    <div class="col-lg-12">
                                      <h3>Follow us &amp; get updated!</h3>
                                      <p>Instantly connect to what's most important to you. Follow your friends, experts, favorite celebrities, and breaking news.</p>
                                      <br>
                                    </div>
                                  </div>
                            </pg-tab>
                            <pg-tab>
                                <ng-template #TabHeading>
                                    Subscriptions
                                </ng-template>
                                <div class="row">
                                    <div class="col-lg-12">
                                      <h3>Follow us &amp; get updated!</h3>
                                      <p>Instantly connect to what's most important to you. Follow your friends, experts, favorite celebrities, and breaking news.</p>
                                      <br>
                                    </div>
                                  </div>
                            </pg-tab>
                            <pg-tab>
                                <ng-template #TabHeading>
                                    Transactions
                                </ng-template>
                                <div class="row">
                                    <div class="col-lg-12">
                                      <h3>Follow us &amp; get updated!</h3>
                                      <p>Instantly connect to what's most important to you. Follow your friends, experts, favorite celebrities, and breaking news.</p>
                                      <br>
                                    </div>
                                  </div>
                            </pg-tab>
                            <pg-tab>
                                <ng-template #TabHeading>
                                    Wallet
                                </ng-template>
                                <div class="row">
                                    <div class="col-lg-12">
                                      <h3>Follow us &amp; get updated!</h3>
                                      <p>Instantly connect to what's most important to you. Follow your friends, experts, favorite celebrities, and breaking news.</p>
                                      <br>
                                    </div>
                                  </div>
                            </pg-tab>
                            <pg-tab>
                                <ng-template #TabHeading>
                                    History
                                </ng-template>
                                <div class="row">
                                    <div class="col-lg-12">
                                      <h3>Follow us &amp; get updated!</h3>
                                      <p>Instantly connect to what's most important to you. Follow your friends, experts, favorite celebrities, and breaking news.</p>
                                      <br>
                                    </div>
                                  </div>
                            </pg-tab>
                            <pg-tab>
                                <ng-template #TabHeading>
                                    Content
                                </ng-template>
                                <div class="row">
                                    <div class="col-lg-12">
                                      <h3>Follow us &amp; get updated!</h3>
                                      <p>Instantly connect to what's most important to you. Follow your friends, experts, favorite celebrities, and breaking news.</p>
                                      <br>
                                    </div>
                                  </div>
                            </pg-tab>
                            <pg-tab>
                                <ng-template #TabHeading>
                                    Logs
                                </ng-template>
                                <div class="row">
                                    <div class="col-lg-12">
                                      <h3>Follow us &amp; get updated!</h3>
                                      <p>Instantly connect to what's most important to you. Follow your friends, experts, favorite celebrities, and breaking news.</p>
                                      <br>
                                    </div>
                                  </div>
                            </pg-tab>
                        </pg-tabset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>