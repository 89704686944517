<header class="header">

    <a href="javascript:void(0);" class="btn-icon-link toggle-sidebar d-lg-none" (click)="toggleMobileSidebar()">
      <i class="pg-icon">menu</i>
    </a>
    <div class="d-flex align-items-center p-t-5">

        <!-- END EMAIL MOBILE TOGGLE -->
        <div class="brand" >
          <img src="../assets/logo.png" alt="logo" width="78" height="22">
        </div>
        <h1 class="title-text m-l-30 d-lg-inline-flex d-xl-inline-flex d-none">{{title}}</h1>
        <!--<a href="#" class="btn btn-link text-primary m-l-20 d-none d-lg-inline-flex d-xl-inline-flex">Add New Item</a>
        <a href="javascript:void(0)" class="search-link d-none d-lg-block d-xl-block" ><i
            class="pg-icon">search</i>Type anywhere to <span class="bold">search</span></a>-->
            
    </div>
    <div class="d-flex align-items-center p-t-5">
      <!-- START NOTIFICATION LIST -->
      <ul class="d-lg-inline-block d-none notification-list no-margin  b-grey b-l b-r no-style p-l-20 p-r-20 hide">
        <li class="p-r-10 inline">
          <div class="dropdown" dropdown>
            <a href="javascript:;" id="notification-center" class="header-icon btn-icon-link" dropdownToggle>
              <i class="pg-icon">world</i>
              <span class="bubble"></span>
            </a>
            <!-- START Notification Dropdown -->
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right notification-toggle" >
              <!-- START Notification -->
              <div class="notification-panel">
                <!-- START Notification Body-->
                <div class="notification-body scrollable">
                  <!-- START Notification Item-->
                  <div class="notification-item unread clearfix">
                    <!-- START Notification Item-->
                    <div class="heading open">
                      <a href="#" class="text-complete pull-left d-flex align-items-center">
                        <i class="pg-icon m-r-10">map</i>
                        <span class="bold">Carrot Design</span>
                        <span class="fs-12 m-l-10">David Nester</span>
                      </a>
                      <div class="pull-right">
                        <div class="thumbnail-wrapper d16 circular inline m-t-15 m-r-10 toggle-more-details">
                          <div><i class="pg-icon">chevron_down</i>
                          </div>
                        </div>
                        <span class=" time">few sec ago</span>
                      </div>
                      <div class="more-details">
                        <div class="more-details-inner">
                          <h5 class="semi-bold fs-16">“Apple’s Motivation - Innovation <br>
                            distinguishes between <br>
                            A leader and a follower.”</h5>
                          <p class="small hint-text">
                            Commented on john Smiths wall.
                            <br> via pages framework.
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- END Notification Item-->
                    <!-- START Notification Item Right Side-->
                    <div class="option" tooltip="mark as read" placement="right">
                      <a href="javascript:void(0)" class="mark"></a>
                    </div>
                    <!-- END Notification Item Right Side-->
                  </div>
                  <!-- START Notification Body-->
                  <!-- START Notification Item-->
                  <div class="notification-item  clearfix">
                    <div class="heading">
                      <a href="javascript:void(0)" class="text-danger pull-left">
                        <i class="pg-icon m-r-10">alert_warning</i>
                        <span class="bold">98% Server Load</span>
                        <span class="fs-12 m-l-10">Take Action</span>
                      </a>
                      <span class="pull-right time">2 mins ago</span>
                    </div>
                    <!-- START Notification Item Right Side-->
                    <div class="option">
                      <a href="javascript:void(0)" class="mark"></a>
                    </div>
                    <!-- END Notification Item Right Side-->
                  </div>
                  <!-- END Notification Item-->
                  <!-- START Notification Item-->
                  <div class="notification-item  clearfix">
                    <div class="heading">
                      <a href="javascript:void(0)" class="text-warning pull-left">
                        <i class="pg-icon m-r-10">alert_warning</i>
                        <span class="bold">Warning Notification</span>
                        <span class="fs-12 m-l-10">Buy Now</span>
                      </a>
                      <span class="pull-right time">yesterday</span>
                    </div>
                    <!-- START Notification Item Right Side-->
                    <div class="option">
                      <a href="javascript:void(0)" class="mark"></a>
                    </div>
                    <!-- END Notification Item Right Side-->
                  </div>
                  <!-- END Notification Item-->
                  <!-- START Notification Item-->
                  <div class="notification-item unread clearfix">
                    <div class="heading">
                      <div class="thumbnail-wrapper d24 circular b-white m-r-5 b-a b-white m-t-10 m-r-10">
                        <img width="30" height="30" src2x="assets/img/profiles/1x.jpg" pgRetina
                          src1x="assets/img/profiles/1.jpg" alt="" src="assets/img/profiles/1.jpg">
                      </div>
                      <a href="javascript:void(0)" class="text-complete pull-left">
                        <span class="bold">Revox Design Labs</span>
                        <span class="fs-12 m-l-10">Owners</span>
                      </a>
                      <span class="pull-right time">11:00pm</span>
                    </div>
                    <!-- START Notification Item Right Side-->
                    <div class="option" tooltip="mark as read" placement="right">
                      <a href="javascript:void(0)" class="mark"></a>
                    </div>
                    <!-- END Notification Item Right Side-->
                  </div>
                  <!-- END Notification Item-->
                </div>
                <!-- END Notification Body-->
                <!-- START Notification Footer-->
                <div class="notification-footer text-center">
                  <a href="javascript:void(0)" class="">Read all notifications</a>
                  <a data-toggle="refresh" class="portlet-refresh text-black pull-right" href="javascript:void(0)">
                    <i class="pg-refresh_new"></i>
                  </a>
                </div>
                <!-- START Notification Footer-->
              </div>
              <!-- END Notification -->
            </div>
            <!-- END Notification Dropdown -->
          </div>
        </li>
      </ul>
      <!-- END NOTIFICATIONS LIST -->
      <!-- START User Info-->
      <div class="pull-left p-r-10 fs-14 font-heading d-lg-inline-block d-none m-l-20">
        <span class="semi-bold text-capitalize">{{ueserNameWelcome}} </span> 
        <!-- <span class="text-color">Nest</span> -->
      </div>
      <div class="dropdown pull-right d-lg-block" dropdown>
        <button class="profile-dropdown-toggle" type="button" dropdownToggle id="profileDropdown"
          aria-label="profile dropdown">
          <span class="thumbnail-wrapper d32 circular inline">
            <img src="../assets/user_icon_placeholder.png" alt=""  width="32" height="32">
          </span>
        </button>

        <div class="dropdown-menu dropdown-menu-right profile-dropdown" *dropdownMenu role="menu">
          <a href="#" class="dropdown-item"><span>Signed in as <br /><b class="text-capitalize">{{ueserName}}</b></span></a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" routerLink="/changepwd" routerLinkActive="active-link">Change Password</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" (click)="logout()">Logout</a>
          <div class="dropdown-divider hide"></div>
          <span class="dropdown-item fs-12 hint-text hide">Last edited by David<br />on Friday at 5:27PM</span>
        </div>
      </div>
      <!-- END User Info-->
      <a href="javascript:void(0)" class="header-icon btn-icon-link m-l-5 sm-no-margin d-inline-block btn-icon-link  d-none"
        >
        <i class="pg-icon">menu_add</i>
      </a>
    </div>

    

  </header>