import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteLicensesComponent } from './site-licenses/site-licenses.component';
import { AddSiteLicenseeComponent } from './add-site-licensee/add-site-licensee.component';
import { AllSiteLicensesComponent } from './all-site-licenses/all-site-licenses.component';
import { EditSiteLicenseeComponent } from './edit-site-licensee/edit-site-licensee.component';


import { AuthGuard } from '../../common/auth.guard';

const routes: Routes = [
    {
        path: 'corpsub',
        component: SiteLicensesComponent,
        canActivate: [AuthGuard],
        children: [
            {
              path: 'all',
              component: AllSiteLicensesComponent,
            },
            {
                path: 'add',
                component: AddSiteLicenseeComponent,
            },
            {
              path: 'edit/:id',
              component: EditSiteLicenseeComponent
            },
            { path: '', redirectTo: 'plans', pathMatch: 'full' }
        ]
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SiteLicensesRoutingModule { }