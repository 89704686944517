import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { SettingsService } from '../../../services/settings.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-st-all-admins',
  templateUrl: './st-all-admins.component.html',
  styleUrls: ['./st-all-admins.component.scss']
})
export class StAllAdminsComponent implements OnInit {
  rows: any[];
  tmp: any[];
  activeData: [];
  inactiveData: [];
  statusDropDownLbl : string = "Active Members";
  selStatus: number = Constants.STATUS_ACTIVE;
  alerts: any[];
  showLoadingSpinner = true;
  curruserId : number = parseInt(localStorage.getItem('pw_id')!);
  adminRoleArr : any[];
  //Role based permission check
  viewManageAdminPrmsn : boolean = true;
  addManageAdminPrmns : boolean = true;
  editManageAdminPrmns : boolean = true;
  chPwdManageAdminPrmns : boolean = true;

  constructor(private commonService: CommonService, 
    private settingsService: SettingsService,
    private cdr: ChangeDetectorRef) {
      /****************Permission check based on role******************/
      this.viewManageAdminPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_MANAGE_ADMIN, Constants.PERMISSION_ADD_ADMIN, Constants.PERMISSION_EDIT_ADMIN, Constants.PERMISSION_CHANGE_ADMIN_PASSWORD]);
      this.addManageAdminPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_ADMIN]);
      this.editManageAdminPrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_ADMIN]);
      this.chPwdManageAdminPrmns = commonService.getSecPermissions([Constants.PERMISSION_CHANGE_ADMIN_PASSWORD]);
      this.getAllAdminRoles();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
  }

  /**************************Method to get active plans dropdown values*******************************/
  getAllAdminRoles(){
    if(this.viewManageAdminPrmsn) {
      this.settingsService.getAllAdminRoles().then(
        res => {
          if(res['code']==1 && res['status']==1) {
              this.adminRoleArr = res['result'];
              this.getAllCompanyAdmins();
          } else {
              this.alerts = [{
                type: 'danger',
                msg: res['message'],
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
          }
        },
        error => {
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        });
      }
  }

  /**********************************API Method to Get All Company Admin List*********************/
  getAllCompanyAdmins() {
    if(this.viewManageAdminPrmsn) {
      this.settingsService.getAllCompanyAdmins().then(
        res=>{
        if(res['code']==1 && res['status']==1) {
            this.rows = res['result'];
            this.tmp = res['result'];
            this.fetchManageAdminsData(this.selStatus);
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        this.showLoadingSpinner = false;
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
            this.showLoadingSpinner = false;
        }
      );
    }
  }

  /******************************Method to filter the status based company admin data***************************/
  fetchManageAdminsData(status: number) {
    this.rows = this.tmp;
    const temp = this.rows.filter(function (d) {
      return d.is_active == status;
    });
    this.rows = temp;
  }

  /******************************Method to set selected admin status dropdown***************************/
  filterStaus(status : number) {
    if(status == 1) {
      this.selStatus = status;
      this.statusDropDownLbl = "Active Users"
      this.fetchManageAdminsData(status);
    } else {
      this.selStatus = 0;
      this.statusDropDownLbl = "Inactive Users"
      this.fetchManageAdminsData(status);
    }
  }

  /******************************Method to update Admin User Status (Active/Inactive)***************************/
  updateAdminStatus( adminId: number, event: any) {
    if(this.editManageAdminPrmns) {
      const isChecked = this.commonService.getStatusValue(event.target.checked);
      this.settingsService.updateCompanyAdminStatus(adminId, isChecked).then(
      res => {
        if(res['code']==1 && res['status']==1) {//Success
          this.alerts = [{
            type: 'success',
            msg: Constants.DEL_ADMIN_SUCCESS_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        } else {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DEL_ADMIN_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
        this.getAllCompanyAdmins();
      },
      error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        this.getAllCompanyAdmins();
      });
    }
  }

  /******************************Method to filter column based search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    if(val) {
      const temp = this.rows.filter(function (d) {
        return d.first_name.toLowerCase().indexOf(val) !== -1 || d.last_name.toLowerCase().indexOf(val) !== -1 || d.email.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
    } else {
      this.fetchManageAdminsData(this.selStatus);
    }
  }

  getAdminRoleName(id){
    let roleObj = this.adminRoleArr.find(x => x.id == id);
    return roleObj['role_name'];
  }
}
