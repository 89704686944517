<div class="padding-20">
    <div class="card list" *ngIf="viewManageAdminPrmsn">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12">  
                    <div class="btn-group-vertical">
                        <div class="dropdown dropdown-default p-r-15" dropdown>
                            <button class="btn dropdown-toggle text-center bg-transparent border-0 fs-16" type="button" dropdownToggle>
                                {{statusDropDownLbl}} 
                            </button>
                            <div id="manageAdminStatusFltr" *dropdownMenu class="dropdown-menu "
                            role="menu" aria-labelledby="button-basic" role="menu">
                                <a class="dropdown-item fs-16 p-t-5 p-b-5" href="javascript:void(0)" role="menuitem" value="1" (click)="filterStaus(1)" *ngIf="selStatus == 0">Active Members</a>
                                <a class="dropdown-item fs-16 p-t-5 p-b-5" href="javascript:void(0)" role="menuitem" value="0" (click)="filterStaus(0)" *ngIf="selStatus == 1">Inactive Members </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Name/Email" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv" *ngIf="addManageAdminPrmns">
                    <a class="btn btn-primary setAddNewBtn" routerLink="/settings/admin/add" role="button">+ Add Member</a>
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable class="material container1" *ngIf="!showLoadingSpinner" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="10" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.75" cellClass="text-right" headerClass="text-right">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="First Name" [flexGrow]="1" prop="first_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{ row.first_name }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Last Name" [flexGrow]="1" prop="last_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.last_name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Email" [flexGrow]="2" prop="email">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.email}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Role" [flexGrow]="1" prop="role">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{getAdminRoleName(row.role)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Created on" [flexGrow]="1.25" prop="created_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.created_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Updated on" [flexGrow]="1.25" prop="updated_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.updated_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" [flexGrow]="1" prop="is_active">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="form-check switch success m-b-0" *ngIf="editManageAdminPrmns">
                            <input  [attr.id]="'updateAdminStatus' + row.id" type="checkbox" *ngIf="row.is_active == 1" class="primary" checked="checked" (change)="updateAdminStatus(row.id, $event)">
                            <input [attr.id]="'updateAdminStatus' + row.id" type="checkbox" *ngIf="row.is_active == 0" class="primary" (change)="updateAdminStatus(row.id, $event)">
                            <label [attr.for]="'updateAdminStatus' + row.id"></label>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action" [flexGrow]="1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a routerLink="/settings/admin/edit/{{row.id}}" class="me-2" title="Edit" *ngIf="editManageAdminPrmns">
                            <i class="pg-icon text-info">edit</i>
                        </a>
                        <a routerLink="/settings/admin/changepwd/{{row.id}}" class="" title="Change Password" *ngIf="chPwdManageAdminPrmns">
                            <i class="pg-icon text-info">lock</i>
                        </a>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>

    </div>
    <!-------No Access Block--------->
  <app-no-access *ngIf="!viewManageAdminPrmsn"></app-no-access>
</div>