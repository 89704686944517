import { Injectable } from '@angular/core';
import { contentHeaders, blobHeaders } from '../common/headers';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private url = environment.api_url;

  constructor(private http: HttpClient, private commonService : CommonService) { }
  
   /*********************************API to Get all subscribers details**************************************** */
   getAllUsers(brandId:number, dataObj, type) {
    if(type==="datawall") {
      return this.http
      .post(`${this.url}/v1/users/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
    } else if(type==="paywall") {
      return this.http
      .post(`${this.url}/v1/users/subscribers/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
    } else if(type==="churned") {
      return this.http
      .post(`${this.url}/v1/users/unsubscribers/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
    }
  }
  /*********************************API to Get all customer details**************************************** */
  getAllCustomers(brandId:number, dataObj) {
    return this.http
      .post(`${this.url}/v1/users/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*********************************API to Get all un subscrbe customer details**************************************** */
  getAllUnSubscrbeCustomers(brandId:number, dataObj) {
    return this.http
      .post(`${this.url}/v1/users/unsubscribers/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get all subscribers details**************************************** */
  getAllSubscribers(brandId:number, dataObj) {
    return this.http
      .post(`${this.url}/v1/users/subscribers/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Add New Customer**********************************************/
  addNewCustomer(dataObj){
    return this.http
      .post(`${this.url}/v1/admin/customer/add`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*************************************API to Get Customer Details*************************************** */
  getCustomerDetail(customerId,brandId) : Promise<any> {
    return this.http
      .get(`${this.url}/v1/admin/customer/${customerId}/${brandId}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*************************************API to Get Customer Roles*************************************** */
  getAllCustomerRoles(status:number) : Promise<any> {
    return this.http
      .get(`${this.url}/v1/constants/roles/${status}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Edit Customer details**********************************************/
  editCustomer(customerId,brandId,dataObj){
    return this.http
      .post(`${this.url}/v1/admin/customer/${customerId}/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Get Customer Transaction History details**********************************************/
  getCustomerTransHistDetail(customerId,brandId, orderId){
    return this.http
      .get(`${this.url}/v1/orders/${orderId}/account/${customerId}/brand/${brandId}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        //console.log("------trans headers----"+JSON.stringify(response))
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to update Cancel Request**********************************************/
  cancelRequest(dataObj) {
    return this.http
    .post(`${this.url}/v1/orders/cancel`, dataObj, {headers: contentHeaders})
    .toPromise()
    .then(response => {
      return response;
    })
    .catch(this.commonService.handleError);
  }

  /*************************************API to View/Download Invoice****************************************/
  getInvoiceDetail(orderId, transId, customerId, brandId): Promise<any> {
    return this.http
      .get(`${this.url}/v1/orders/invoice/${orderId}/${transId}/${customerId}/${brandId}`, { headers: blobHeaders,responseType: 'blob' , observe: 'response' })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Get Customer Card details**********************************************/
  getCustomerCardDetails(customerId){
    return this.http
      .get(`${this.url}/v1/users/viewCard/${customerId}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        //console.log("------trans headers----"+JSON.stringify(response))
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Update default Card details**********************************************/
  UpdateDefaultCard(customerId, dataObj){
    return this.http
      .post(`${this.url}/v1/users/selectCard/${customerId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        //console.log("------trans headers----"+JSON.stringify(response))
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Add New Card**********************************************/
  addNewCard(customerId, dataObj){
    return this.http
      .post(`${this.url}/v1/users/changeCard/${customerId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        //console.log("------trans headers----"+JSON.stringify(response))
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /**********************************API to Refund Payment**********************************************/
  refundPayment(customerId, dataObj) {
    return this.http
      .post(`${this.url}/v1/orders/refund/${customerId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        //console.log("------trans headers----"+JSON.stringify(response))
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /**********************************API to Update Customer password**********************************************/
  editCustomerPwd(customerId , brandId,  dataObj){
    console.log(dataObj);
    return this.http
      .post(`${this.url}/v1/users/changePwd/${customerId}/${brandId}`, dataObj, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
}
