<div class="padding-20">
    <div class="card list" *ngIf="viewStLicensePrmsn">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                    <div class="d-flex flex-row align-items-center"> 
                        <span class="m-r-5">Brands: </span>
                        <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="selectedOption" (ngModelChange)="brandChange(selectedOption)"  ShowSearch >
                            <pg-option  [Label]="'All'" [Value]="'0'" ></pg-option>
                            <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select" [Label]="brand.brand_name" [Value]="brand.id" >
                            </pg-option>
                        </pg-select>
                        <div class="btn-group-vertical m-l-5">
                            <div class="dropdown dropdown-default p-r-15" dropdown>
                                <button class="btn dropdown-toggle text-center bg-transparent border-0 fs-16" type="button" dropdownToggle>
                                    {{statusDropDownLbl}}
                                </button>
                                <div id="brandsStatusFltr" *dropdownMenu class="dropdown-menu "
                                role="menu" aria-labelledby="button-basic" role="menu">
                                    <a class="dropdown-item fs-16 p-t-5 p-b-5" href="javascript:void(0)" role="menuitem" value="1" (click)="filterStaus(1)" *ngIf="selStatus == 0">Active Subscriptions</a>
                                    <a class="dropdown-item fs-16 p-t-5 p-b-5" href="javascript:void(0)" role="menuitem" value="0" (click)="filterStaus(0)" *ngIf="selStatus == 1">Inactive Subscriptions </a>
                                </div>
                            </div>
                        </div>
                    </div>  

                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Subscription Name/Brand" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv text-right" *ngIf="addStLicensePrmns">
                    <a class="btn btn-complete fs-15 padding-10" routerLink="/corpsub/add" role="button">+ Add Corporate Sub</a>
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable *ngIf="!showLoadingSpinner" class="material container1" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="10" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.75" cellClass="text-right" headerClass="text-right">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Name" [flexGrow]="1.3" prop="licensee_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a routerLink="/corpsub/edit/{{row.id}}">{{ row.licensee_name }}</a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Brand" [flexGrow]="0.75" prop="brand_name">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.brand_name}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Number of Seats" [flexGrow]="1.2" prop="seats" cellClass="text-right" headerClass="text-right">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.seats | number}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Allocated Users" [flexGrow]="1.2" prop="allocated_users" cellClass="text-right" headerClass="text-right">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.contract_type=='Email'">{{row.allocated_users | number}}</span>
                        <span *ngIf="row.contract_type=='Domain'">—</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Redeemed Users" [flexGrow]="1.2" prop="redeemed_users" cellClass="text-right" headerClass="text-right">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.redeemed_users | number}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Start Date" [flexGrow]="1" prop="start_date" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.start_date | date: 'dd-MMM-yy'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="End Date" [flexGrow]="1" prop="end_date" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.end_date | date: 'dd-MMM-yy'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Created On" [flexGrow]="1.5" prop="created_on" cellClass="text-center" headerClass="text-center">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.created_on | date: 'dd-MMM-yy, h:mm a'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Is Paid?" [flexGrow]="1" prop="is_paid">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngIf="row.is_paid == 1">Yes</span>
                        <span *ngIf="row.is_paid == 0">No</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Status" [flexGrow]="1" prop="is_active">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="form-check switch success m-b-0" *ngIf="editStLicensePrmns">
                            <input  [attr.id]="'updateSiteLicStatus' + row.id" type="checkbox" *ngIf="row.is_active == 1" class="primary" checked="checked" (change)="updateSiteLicStatus(row.id, $event)">
                            <input [attr.id]="'updateSiteLicStatus' + row.id" type="checkbox" *ngIf="row.is_active == 0" class="primary" (change)="updateSiteLicStatus(row.id, $event)">
                            <label [attr.for]="'updateSiteLicStatus' + row.id"></label>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Action" [flexGrow]="1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a routerLink="/corpsub/edit/{{row.id}}" class="" title="View/Edit" *ngIf="editStLicensePrmns">
                            <i _ngcontent-vpf-c116="" class="pg-icon text-info">edit</i>
                        </a>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>
        </div>

    </div>
    <!-------No Access Block--------->
    <app-no-access *ngIf="!viewStLicensePrmsn"></app-no-access>
</div>