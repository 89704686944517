import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { DiscountService } from '../../../services/discount.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-all-discounts',
  templateUrl: './all-discounts.component.html',
  styleUrls: ['./all-discounts.component.scss']
})
export class AllDiscountsComponent implements OnInit {
  selectedOption = '0';
  rows: any[];
  tmp: any[];
  activeData: [];
  inactiveData: [];
  selectedBrand: number;//PMV temporary
  allActiveBrands: any[];
  statusDropDownLbl : string = "Active Discounts";
  selStatus: number = Constants.STATUS_ACTIVE;
  alerts: any[];
  showLoadingSpinner = true;
  //Role based permission check
  viewDiscountsPrmsn : boolean = true;
  addDiscountsPrmns : boolean = true;
  editDiscountsPrmns : boolean = true;

  constructor(private commonService: CommonService, 
    private discountService: DiscountService,
    private cdr: ChangeDetectorRef) {
      /****************Permission check based on role******************/
      this.viewDiscountsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_DISCOUNTS, Constants.PERMISSION_ADD_DISCOUNTS, Constants.PERMISSION_EDIT_DISCOUNTS]);
      this.addDiscountsPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_DISCOUNTS]);
      this.editDiscountsPrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_DISCOUNTS]);
      this.getAllActiveBrands();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
  }
  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if (this.viewDiscountsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allActiveBrands = res['result'];
            this.selectedBrand = 0;//All
            this.getAllDiscounts(this.selectedBrand);
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }
  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    //this.userList = [];
    this.getAllDiscounts(brandId);
  }
  /**********************************API Method to Get All the Products*********************/
  getAllDiscounts(brandId) {
    if(this.viewDiscountsPrmsn) {
      this.discountService.getAllDiscounts(brandId).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
            console.log(res['result'])
            this.rows = res['result'];
            this.tmp = res['result'];
            this.fetchDiscountsData(this.selStatus);
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        this.showLoadingSpinner = false;
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
            this.showLoadingSpinner = false;
        }
      );
    }
  }

    /******************************Method to filter the status based Discount data***************************/
  fetchDiscountsData(status: number) {
    this.rows = this.tmp;
    const temp = this.rows.filter(function (d) {
      return d.is_active == status;
    });
    this.rows = temp;
  }

  /******************************Method to set selected Discount status dropdown***************************/
  filterStaus(status : number) {
    if(status == 1) {
      this.selStatus = status;
      this.statusDropDownLbl = "Active Discounts"
      this.fetchDiscountsData(status);
    } else {
      this.selStatus = 0;
      this.statusDropDownLbl = "Inactive Discounts"
      this.fetchDiscountsData(status);
    }
  }

  /******************************Method to update Discount Status (Active/Inactive)***************************/
  updateDiscountStatus( discountId: number, event: any) {
    if(this.editDiscountsPrmns) {
      this.selectedBrand = 0;//All
      const isChecked = this.commonService.getStatusValue(event.target.checked);
      this.discountService.updateDiscountStatus(discountId, isChecked).then(
      res => {
        if(res['code']==1 && res['status']==1) {//Success
          this.alerts = [{
            type: 'success',
            msg: Constants.DEL_DISCOUNT_SUCCESS_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        } else {
            let errorMsg = Constants.DEL_DISCOUNT_FAILURE_MSG;
            let planArr = [];
            if(res['status']==2) {
              planArr = this.commonService.getIdsFromArr(planArr, res.result);
              errorMsg = Constants.DEL_DISCOUNT_FAILURE_ALREADY_MAPPED_MSG+" '"+planArr+"'";
            }
            this.alerts = [{
              type: 'danger',
              msg: errorMsg,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        this.getAllDiscounts(this.selectedBrand);
      },
      error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        this.getAllDiscounts(this.selectedBrand);
      });
    }
  }

  /******************************Method to filter Discount name column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    if(val) {
      const temp = this.rows.filter(function (d) {
        return d.discout_name.toLowerCase().indexOf(val) !== -1 || d.display_name.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
    } else {
      this.fetchDiscountsData(this.selStatus);
    }
  }

}
