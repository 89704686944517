
<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="addDiscountsPrmns">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/discounts/all" href="javascript:void(0)">Discounts</a></li>
      <li class="breadcrumb-item active"> CREATE NEW </li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <h5 class="mw-80 mb-2">Add New Discount</h5>
            <form class="addNewBrandForm mx-auto" id="form-project" [formGroup]="addNewDiscountForm" (ngSubmit)="addNewDiscountFormSubmit()" novalidate>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('discountName').invalid && (getFormControl('discountName').dirty || getFormControl('discountName').touched)">
                        <label>Name <br/> <small>(Only letters, digits, underscore or dash. No whitespaces or symbols)</small></label>
                        <input type="text" class="form-control" formControlName="discountName" placeholder="Enter Discount Name" appNameValidation>
                        <div class="invalid-tooltip" *ngIf="getFormControl('discountName').invalid && (getFormControl('discountName').dirty || getFormControl('discountName').touched)">
                          Discount Name is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('displayName').invalid && (getFormControl('displayName').dirty || getFormControl('displayName').touched)">
                        <label>Display Name <br/> <small>(Name to be displayed on the front-end)</small></label>
                        <input type="text" class="form-control" formControlName="displayName"  placeholder="Enter Display Name">
                        <div class="invalid-tooltip" *ngIf="getFormControl('displayName').invalid && (getFormControl('displayName').dirty || getFormControl('displayName').touched)">
                          Display Name is required
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('discountType').invalid && (getFormControl('discountType').dirty || getFormControl('discountType').touched)">
                        <label>Discount Type</label>
                        <pg-select style="width: 100%;" formControlName="discountType" [PlaceHolder]="'Please Select'" AllowClear
                        ShowSearch >
                            <pg-option *ngFor='let discountType of discountTypes' [Label]="discountType.label" [Value]="discountType.value">
                            </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('discountType').invalid && (getFormControl('discountType').dirty || getFormControl('discountType').touched)">
                          Discount Type is required
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2" >
                        <label>Currency</label>
                        <pg-select style="width: 100%;" formControlName="currency" [PlaceHolder]="'- Select a currency -'" AllowClear ShowSearch >
                            <pg-option *ngFor='let currency of currenciesFltrArr' [Label]="currency.description" [Value]="currency.disp_id">
                            </pg-option>
                        </pg-select>
                      </div>
                    </div>

                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('discountValue').invalid && (getFormControl('discountValue').dirty || getFormControl('discountValue').touched)">
                        <label>Discount Value</label>
                        <input type="text" class="form-control" formControlName="discountValue"  placeholder="Enter Discount Value" appTwoDigitDecimalNumbers/>
                        <div class="invalid-tooltip" *ngIf="getFormControl('discountValue').invalid && (getFormControl('discountValue').dirty || getFormControl('discountValue').touched)">
                          Discount Value is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default" >
                        <label>Description</label>
                        <input type="text" class="form-control" formControlName="discountDesc" placeholder="Enter Discount Description">
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div  pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('discountPeriod').invalid && (getFormControl('discountPeriod').dirty || getFormControl('discountPeriod').touched)">
                        <label class="fade">Discount Period</label>
                        <pg-select style="width: 100%;" formControlName="discountPeriod" [PlaceHolder]="'Please Select'" AllowClear
                        ShowSearch >
                            <pg-option *ngFor='let period of periodsFltrArr;let i = index' Label="{{period.display_name}}"   Value="{{period.disp_id}}">
                            </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('discountPeriod').invalid && (getFormControl('discountPeriod').dirty || getFormControl('discountPeriod').touched)">
                          Discount Period is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group form-group-default form-check-group d-flex align-items-center">
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" id="discountStatus" checked="checked" formControlName="discountStatus">
                          <label for="discountStatus">Status</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 " >
                        <label>Promo codes</label>
                        <div class="inpt d-flex align-items-center">
                          <pg-select style="width: 100%;" formControlName="promoCodes" [PlaceHolder]="'please select'" AllowClear ShowSearch >
                            <pg-option *ngFor='let promo of allPromoCodeData;let i = index' Label="{{promo.promo_code}}" Value="{{promo.id}}">
                            </pg-option>
                          </pg-select>
                          <button class="btn m-r-10 p-r-20 p-l-20 btn-primary active" type="button" (click)="addPromoCode()">Add</button>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
                <div class="form-group row">
                  <div class="table-responsive">
                    <table class="table dataTable selTbl">
                        <thead>
                            <tr>
                                <th scope="col">Promo code</th>
                                <th scope="col">Display Name</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col" class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let promo of selPromoCodeArr; let i = index;">
                              <td>
                                <a routerLink="/promocodes/edit/{{promo.id}}">{{promo.promo_code}}</a>
                              </td>
                              <td>
                                {{promo.promo_name}}
                              </td>
                              <td>
                                {{promo.start_date | date: 'dd-MMM-yy'}}
                              </td>
                              <td>
                                {{promo.end_date | date: 'dd-MMM-yy'}}
                              </td>
                              <td class="text-center">
                                <a (click)="deletePromoRow(i,promo.id)" class="hover">
                                  <!-- <img src="assets/close.svg"/> -->
                                  <i class="pg-icon text-info">close</i>
                                </a>
                              </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                </div>

                <div class="row p-t-20">
                  <div class=" col-md-12 text-right ">
                    <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10">Add</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                    <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/discounts/all">Cancel</button>
                  </div>
                </div>

            </form>
            <div *ngFor="let alert of alerts" class="p-t-20">
              <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
            </div>
        </div>
      </div>
    </div>
  </div>  
</div>    
<!-------No Access Block--------->
<app-no-access *ngIf="!addDiscountsPrmns"></app-no-access>