import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { Router} from '@angular/router';
import { SiteLicensesService } from '../../../services/site-licenses.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-add-site-licensee',
  templateUrl: './add-site-licensee.component.html',
  styleUrls: ['./add-site-licensee.component.scss']
})
export class AddSiteLicenseeComponent implements OnInit {
  allActiveBrands: any[];
  addNewLicenseeForm: FormGroup;
  disableBtn = false;
  submitted = false;
  loading = false;
  alerts: any[];
  invalidEmails : any = [];
  invalidDomains : any = [];
  validEmails : any = [];
  validDomains : any = [];
  selContractType : string = "Email";//Email
  seatLimExceedError : string = "";
  //Role based permission check
  addStLicensePrmns : boolean = true;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private siteLicensesService: SiteLicensesService,
    private cdr: ChangeDetectorRef,
    private router: Router) {
        /****************Permission check based on role******************/
        this.addStLicensePrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_SITE_LICENSES]);
    }

    contractTypes = [
      {label: 'Email', value: 'Email' },
      {label: 'Domain', value: 'Domain'}
    ]
    

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
    this.getAllActiveBrands();
    /****************Add New Plan Form Validation****************** */
    this.addNewLicenseeForm = this.formBuilder.group({
      licenseeName: ['', [Validators.required]],
      licenseeDesc: [''],
      repEmail: ['', [Validators.required]],
      brand: ['', [Validators.required]],
      contractType: ['Email', [Validators.required]],
      noOfSeats: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      userEmail: [''],
      userDomain: [''],
    })
  }


  // convenience getter for easy access to form fields
  get f() { return this.addNewLicenseeForm.controls; }
  getFormControl(name) {
    return this.addNewLicenseeForm.controls[name];
  }

  isFieldValid(field: string) {
    return !this.addNewLicenseeForm.get(field).valid && this.addNewLicenseeForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  } 
 
  /*******************************Method to submit add new plan form***************************************** */
  addNewLicenseeFormSubmit() {
    if(this.addStLicensePrmns) {
      this.submitted = true;
      // stop here if form is invalid
      for (const i in this.addNewLicenseeForm.controls) {
        this.addNewLicenseeForm.controls[i].markAsDirty();
      }
      if (this.addNewLicenseeForm.invalid) {
          return;
      }
      let dataObj = { 
        'licensee_name' : this.f.licenseeName.value, 
        'licensee_desc' : this.f.licenseeDesc.value, 
        'rep_email': this.f.repEmail.value, 
        'brand_id': this.f.brand.value, 
        'contract_type': this.f.contractType.value,
        'seats': this.f.noOfSeats.value, 
        'start_date': this.f.startDate.value, 
        'end_date': this.f.endDate.value
      /* 'user_emails' : this.selContractType == "Email" ? this.validEmails : [],
        'comp_domains' : this.selContractType == "Domain" ? this.validDomains : []*/
      };
      console.log("---dataObj---"+JSON.stringify(dataObj));
      this.siteLicensesService.createNewLicensee(dataObj).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.alerts = [{
                type: 'success',
                msg: Constants.ADD_LICENSEE_SUCCESS_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
              setTimeout(()=>{
                this.router.navigate(['/corpsub/all']);
              }, 2000);
            } else {
              this.alerts = [{
                type: 'danger',
                msg: Constants.ADD_LICENSEE_FAILURE_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.ADD_LICENSEE_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
            this.loading = false;
        });
      }

  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if(this.addStLicensePrmns) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
          this.allActiveBrands = res['result'];
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

/**********************************Method to Validate Email Adrress*********************/
validateMultipleEmails(emailInput) {
    // Get value on emails input as a string
    var emails = emailInput;
    this.invalidEmails = [];
   // this.validEmails = [];
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     
    let email;
    for (var i = 0; i < emails.length; i++) {
        // Trim whitespaces from email address
        email = emails[i].trim();
        if(this.validEmails.indexOf(email)>=0) {
          this.disableBtn = true;
          this.seatLimExceedError = "Email already exists.";
        } else {
          // Check email against our regex to determine if email is valid
          if( email == "" || ! regex.test(email)){
              this.invalidEmails.push(email);
          } else {
            this.validEmails.push(email);
          }
        }
    }
    if(this.invalidEmails != 0) {
      this.disableBtn = true;
       return false ;
    }
    else { 
      this.disableBtn = false;
      return true;
    }
  }

  /**********************************Method to add user emails*********************/
  addUserEmail() {
    let userEmails = Array.from(new Set(this.f.userEmail.value.split(",")));
    if(this.f.noOfSeats.value) {
      if(userEmails.length <= this.f.noOfSeats.value && this.validEmails.length < this.f.noOfSeats.value) {
        this.seatLimExceedError = "";
        this.validateMultipleEmails(userEmails);
      } else {
        this.disableBtn = true;
        this.seatLimExceedError = "User count exceeds the number of seats booked.";
      }
    } else {
      this.disableBtn = true;
      this.seatLimExceedError = "Please fill the number of seats and click on add again.";
    }
  }

  /**********************************Method to Remove Email Adrress*********************/
  deleteUserEmailRow(index:any) {
    this.validEmails.splice(index, 1);
  }

  /**********************************Method to Validate Email Domains*********************/
  validateMultipleDomains(domainInput) {
    // Get value on domain input as a string
    var domains = domainInput;
    this.invalidDomains = [];
    this.validDomains = [];
    // Split string by comma into an array
    domains = domains.split(",");
    var regex = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;
     
    let domain;
    for (var i = 0; i < domains.length; i++) {
        // Trim whitespaces from email address
        domain = domains[i].trim();
        // Check email against our regex to determine if domain is valid
        if( domain == "" || ! regex.test(domain)){
            this.invalidDomains.push(domain);
        } else {
          this.validDomains.push(domain);
        }
    }
    if(this.invalidDomains != 0) {
      this.disableBtn = true;
      return false;
    }else {
      this.disableBtn = false;
      return true;
    }
  }

  /**********************************Method to Add Email Domains*********************/
  addUserDomain() {
    let userDomains = this.f.userDomain.value;
    if(userDomains) 
      this.validateMultipleDomains(userDomains);
  }

  /**********************************Method to Remove Email Domain*********************/
  deleteUserDomainRow(index:any) {
    this.validDomains.splice(index, 1);
  }

  /**********************************Method to Show/Hide Email/Type add fields based on change*********************/
  onContractTypeChange() {
    this.selContractType = this.f.contractType.value;
  }

}
