<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="editStLicensePrmns || viewStLicensePrmsn">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/corpsub/all">Group Subscriptions</a></li>
      <li class="breadcrumb-item active" *ngIf="editStLicensePrmns"> Edit Corporate Subscription</li>
      <li class="breadcrumb-item active" *ngIf="!editStLicensePrmns && viewStLicensePrmsn"> View Corporate Subscription</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <tabset>
              <tab heading="Licensee Details" id="tab1">
                <h5 class="mb-2" *ngIf="editStLicensePrmns">Edit Corporate Subscription</h5>
                <h5 class="mb-2" *ngIf="!editStLicensePrmns && viewStLicensePrmsn">View Corporate Subscription</h5>
                <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
                <form *ngIf="!showLoadingSpinner" class="addNewBrandForm mx-auto" id="form-project" [formGroup]="editNewLicenseeForm" (ngSubmit)="editNewLicenseeFormSubmit()" novalidate>
                    <div class="">
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('licenseeName').invalid && (getFormControl('licenseeName').dirty || getFormControl('licenseeName').touched)">
                            <label>Subscription Name <br/> <small>(Only letters, digits, underscore or dash. No whitespaces or symbols)</small></label>
                            <input type="text" class="form-control" formControlName="licenseeName" placeholder="Enter Subscription Name" appNameValidation>
                            <div class="invalid-tooltip" *ngIf="getFormControl('licenseeName').invalid && (getFormControl('licenseeName').dirty || getFormControl('licenseeName').touched)">
                              Subscription Name is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default" >
                            <label>Description</label>
                            <input type="text" class="form-control" formControlName="licenseeDesc" placeholder="Enter Description">
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('repEmail').invalid && (getFormControl('repEmail').dirty || getFormControl('repEmail').touched)">
                            <label>Representative Email</label>
                            <input type="text" class="form-control" formControlName="repEmail" placeholder="Enter Representative Email">
                            <div class="invalid-tooltip" *ngIf="getFormControl('repEmail').invalid && (getFormControl('repEmail').dirty || getFormControl('repEmail').touched)">
                              Representative Email is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 class="m-b-15 m-t-15"> Contract details </h6>
                    <div class=""> 
                      <div class="row clearfix">  
                        <div class="col-md-6">
                          <div pgFormGroupDefault  class="form-group form-group-default" >
                            <label>Contract ID</label>
                            <input type="text" class="form-control" formControlName="contractId" placeholder="Contract ID" readonly>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('contractType').invalid && (getFormControl('contractType').dirty || getFormControl('contractType').touched)">
                            <label>Contract Type</label>
                            <select class="form-control" formControlName="contractType" disabled >
                              <option value="Email">Email</option>
                              <option value="Domain">Domain</option>
                            </select>
                            <div class="invalid-tooltip" *ngIf="getFormControl('contractType').invalid && (getFormControl('contractType').dirty || getFormControl('contractType').touched)">
                              Contract Type is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">  
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default required form-group-default-select2" [class.has-error]="getFormControl('brand').invalid && (getFormControl('brand').dirty || getFormControl('brand').touched)">
                            <label>Brand</label>
                            <pg-select style="width: 100%;" formControlName="brand" [PlaceHolder]="'Select Brand'" AllowClear ShowSearch>
                              <pg-option *ngFor='let brand of allActiveBrands;let i = index' Label="{{brand.brand_name}}" Value="{{brand.id}}"></pg-option>
                            </pg-select>
                            <div class="invalid-tooltip" *ngIf="getFormControl('brand').invalid && (getFormControl('brand').dirty || getFormControl('brand').touched)">
                              Brand is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">  
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default" >
                            <label>Invitation URL</label>
                            <div class="inpt d-flex align-items-center">
                              <input type="text" class="form-control" formControlName="invUrl" #invUrlValue  placeholder="Invitation URL" readonly>
                              <button class="btn m-l-10 p-r-20 p-l-20 btn btn-secondary" type="button" (click)="copyToClipboard(invUrlValue.value)">Copy</button>
                            </div>
                           
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('noOfSeats').invalid && (getFormControl('noOfSeats').dirty || getFormControl('noOfSeats').touched)">
                            <label>Number of Seats</label>
                            <input type="number" class="form-control" formControlName="noOfSeats" placeholder="Enter Number of Seats">
                            <div class="invalid-tooltip" *ngIf="getFormControl('noOfSeats').invalid && (getFormControl('noOfSeats').dirty || getFormControl('noOfSeats').touched)">
                              This Field is required
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="row clearfix">
                        <div class="col-md-6">
                          <div pgFormGroupDefault  class="input-group form-group form-group-default required" [class.has-error]="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                            <label>Start Date</label>
                            <div class="input-group">
                              <pg-datepicker formControlName="startDate"  Placeholder="Select Start Date" [Format]="'DD/MM/YYYY'"></pg-datepicker>
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="pg-icon">calendar</i>
                                </span>
                              </div>
                            </div>
                            <div class="invalid-tooltip" *ngIf="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                              Start Date is required
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div pgFormGroupDefault  class="input-group form-group form-group-default required" [class.has-error]="getFormControl('endDate').invalid && (getFormControl('endDate').dirty || getFormControl('endDate').touched)">
                            <label>End Date</label>
                            <div class="input-group">
                              <pg-datepicker formControlName="endDate"  Placeholder="Select End Date" [Format]="'DD/MM/YYYY'"></pg-datepicker>
                              <div class="input-group-append">
                                <span class="input-group-text">
                                  <i class="pg-icon">calendar</i>
                                </span>
                              </div>
                            </div>
                            <div class="invalid-tooltip" *ngIf="getFormControl('endDate').invalid && (getFormControl('endDate').dirty || getFormControl('endDate').touched)">
                              End Date is required
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                            <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                              <input type="checkbox" class="primary" id="payStatusChange" formControlName="isPaid" (change)="payStatusChange($event)">
                              <label for="payStatusChange">Is Paid?</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix" *ngIf="selContractType == 'Email' && isPaid">
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default required p-t-10 p-b-10" [class.has-error]="getFormControl('userEmail').invalid && (getFormControl('userEmail').dirty || getFormControl('userEmail').touched)">
                            <label>User Emails</label>
                            <div class="inpt" class="inpt d-flex align-items-center">
                              <input type="text" class="form-control" formControlName="userEmail" placeholder="Enter Emails (comma separated)">
                              <button class="btn m-l-10 p-r-20 p-l-20 btn-primary active" type="button" (click)="addUserEmail()" *ngIf="editStLicensePrmns">Add</button>
                            </div>
                            <div class="invalid-tooltip" *ngIf="invalidEmails?.length > 0">
                              Invalid emails: {{invalidEmails}}
                            </div>
                            <div class="invalid-tooltip" *ngIf="seatLimExceedError?.length > 0">
                              {{seatLimExceedError}}
                            </div>
                            <div class="invalid-tooltip" *ngIf="getFormControl('userEmail').invalid && (getFormControl('userEmail').dirty || getFormControl('userEmail').touched)">
                              Email is required
                            </div>
                          </div>
                        </div>
                      </div>  
                      <div class="row clearfix" *ngIf="selContractType == 'Domain' && isPaid">
                        <div class="col-md-12">
                          <div pgFormGroupDefault  class="form-group form-group-default required p-t-10 p-b-10" [class.has-error]="getFormControl('userDomain').invalid && (getFormControl('userDomain').dirty || getFormControl('userDomain').touched)">
                            <label>Email Domains</label>
                            <div class="inpt" class="inpt d-flex align-items-center">
                              <input type="text" class="form-control" formControlName="userDomain" placeholder="Enter Domains (comma separated)">
                              <button class="btn m-l-10 p-r-20 p-l-20 btn-primary active" type="button" (click)="addUserDomain()" *ngIf="editStLicensePrmns">Add</button>
                            </div>
                            <div class="invalid-tooltip" *ngIf="invalidDomains?.length > 0">
                              Invalid domain: {{invalidDomains}}
                            </div>
                            <div class="invalid-tooltip" *ngIf="domainExistsError?.length > 0">
                              {{domainExistsError}}
                            </div>
                            <div class="invalid-tooltip" *ngIf="getFormControl('userDomain').invalid && (getFormControl('userDomain').dirty || getFormControl('userDomain').touched)">
                              Domain is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="selContractType == 'Email' && isPaid">
                      <div class="table-responsive">
                          <table class="table dataTable selTbl">
                              <thead>
                                  <tr>
                                      <th scope="col">Email</th>
                                      <th scope="col">Status</th>
                                      <th scope="col" class="text-center">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let obj of validEmails; let i = index;">
                                  <td>
                                      {{obj.email}}
                                  </td>
                                  <td>
                                    {{getInvStatusVal(obj.invitation_status)}}
                                  </td>
                                  <td class="text-center">
                                      <a (click)="deleteUserEmailRow(i,obj.email,obj.id,obj.invitation_status)" class="hover" *ngIf="editStLicensePrmns">
                                        <i class="pg-icon text-info">close</i>
                                      </a>
                                  </td>
                                  <td class="text-center" *ngIf="obj.id">
                                    <a (click)="sendUserInv(obj.email,obj.id)" class="hover" *ngIf="obj.invitation_status==0 && editStLicensePrmns">
                                      Invite
                                    </a>
                                    <span *ngIf="obj.invitation_status==1">Invited</span>
                                </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="selContractType == 'Domain' && isPaid">
                        <div class="table-responsive col-6">
                            <table class="table dataTable selTbl">
                                <thead>
                                    <tr>
                                        <th scope="col">Domain</th>
                                        <th scope="col" class="text-center">Users</th>
                                        <th scope="col" class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let obj of validDomains; let i = index;">
                                    <td>
                                        {{obj.domain}}
                                    </td>
                                    <td class="text-center">
                                      <a (click)="viewInvUsers(obj.id)" class="hover" *ngIf="obj.id">
                                        View
                                      </a>
                                    </td>
                                    <td class="text-center">
                                        <a (click)="deleteUserDomainRow(i,obj.domain,obj.id,status=0)" class="hover" *ngIf="editStLicensePrmns">
                                          <i class="pg-icon text-info">close</i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive col-6 domainUserTblCtr">
                          <table class="table dataTable selTbl">
                              <thead>
                                  <tr>
                                      <th scope="col">Email</th>
                                      <th scope="col">Status</th>
                                      <th scope="col" class="text-center">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let obj of domainUsersArr; let i = index;">
                                  <td>
                                      {{obj.email}}
                                  </td>
                                  <td>
                                    {{getDomainStatusVal(obj.is_active)}}
                                  </td>
                                  <td class="text-center">
                                      <a (click)="deleteDomainUser(obj.id, obj.email)" class="hover" *ngIf="obj.is_active==1 && editStLicensePrmns">
                                        Remove
                                      </a>
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                    <div class="row p-t-20">
                      <div class=" col-md-12 text-right ">
                        <button type="submit" [disabled]="loading || disableBtn" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10" *ngIf="editStLicensePrmns">Update</button>
                        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    
                        <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/corpsub/all" *ngIf="editStLicensePrmns">Cancel</button>
                        <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/corpsub/all" *ngIf="!editStLicensePrmns && viewStLicensePrmsn">Back</button>
                      </div>
                    </div>
    
                  </form>
              </tab>
              <tab heading="Payment Link Details">
                <form class="addNewBrandForm mx-auto" id="form-project" [formGroup]="payDetailsForm" (ngSubmit)="payDetailsFormSubmit()" novalidate>
                  <div class="">
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required form-group-default-select2">
                          <label>Currency</label>
                          <pg-select style="width: 100%;" formControlName="currency" [PlaceHolder]="'Select Currency'" AllowClear ShowSearch>
                            <pg-option *ngFor='let currency of currenciesFltrArr;let i = index' Label="{{currency.description}}" Value="{{currency.disp_id}}"></pg-option>
                          </pg-select>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required" >
                          <label>Final Price</label>
                          <input type="number" class="form-control" formControlName="finalPrice" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row p-t-20">
                    <div class=" col-md-12 text-right ">
                      <button type="submit" [disabled]="loading || disableBtn" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10" *ngIf="editStLicensePrmns">Generate</button>
                      <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
  
                      <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/corpsub/all" *ngIf="editStLicensePrmns">Cancel</button>
                      <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/corpsub/all" *ngIf="!editStLicensePrmns && viewStLicensePrmsn">Back</button>
                    </div>
                  </div>
                  <div class="row clearfix m-t-30">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required p-t-10 p-b-10" >
                        <label>Payment Link</label>
                        <div class="inpt" class="inpt d-flex align-items-center">
                          <input type="text" class="form-control" formControlName="payLink" #payLinkValue placeholder="Payment Link"  readonly id="payLink">
                          <button class="btn m-l-10 p-r-20 p-l-20 btn btn-secondary" type="button" (click)="copyToClipboard(payLinkValue.value)">Copy</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </tab>
            </tabset>
            <div *ngFor="let alert of alerts" class="p-t-20">
              <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
            </div>
        </div>
      </div>
    </div>
  </div>  
</div>  
<!-------No Access Block--------->
<app-no-access *ngIf="!editStLicensePrmns && !viewStLicensePrmsn"></app-no-access>
