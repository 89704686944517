import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';


@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss']
})
export class SendEmailModalComponent implements OnInit {
  alerts: any[];
  fullName: string;
  userId;
  offerDetails;
  offeredDatas;
  promoCode;
  promo_Datas;
  selectedPromoCode;
  modalRef?: BsModalRef;
  public requestType: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef,
    private reportsService: ReportsService) {

  }

  ngOnInit(): void {
    this.sendEmail()
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  sendEmail() {
    this.reportsService.offer(this.userId).then(
      res => {
        if (res.code === 1 && res.status === 1) {
          this.offerDetails = res.result.user_Subs;
          this.promo_Datas = res.result.promo_Datas;
          this.offeredDatas = res.result.offered_Datas;
          this.fullName = this.offerDetails?.first_name + ' ' + this.offerDetails?.last_name;
          this.selectedPromoCode = res.result.promo_Datas[0].promo_code;
        } else {
          this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      },
      error => {
        this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
      }
    );
  }


  SendOffer() {
    this.selectedPromoCode;
    let dataObj = {
      'userId': this.userId,
      'promoCode': this.selectedPromoCode
    }
    this.reportsService.sendOffer(dataObj).then(
      res => {
        if (res.code === 1 && res.status === 1) {
          this.alerts = [{
            type: 'success',
            msg: res.message,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          setTimeout(() => {
            this.bsModalRef.hide();
          }, 3000);
        } else {
          this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      },
      error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
      });
  }


  // // Method send to its parent
  // triggerEvent(value: number) {
  //   //this.requestType.emit({ data: value , res:200 });
  // }


}
