import { Injectable } from '@angular/core';
import { contentHeaders } from '../common/headers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private url = environment.api_url;
  private bq_url = environment.bq_url;

  constructor(private http: HttpClient, private commonService: CommonService) { }


  /*********************************API to Get Subscriptions data**************************************** */
  sendOffer(dataObj): Promise<any> {
    return this.http
      .post(`${this.url}/v1/reports/sendoffer`, dataObj, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get Subscriptions data**************************************** */
  offer(userId) {
    return this.http
      .get(`${this.url}/v1/reports/offer/${userId}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get Subscriptions data**************************************** */
  getSubscriptionsData(brand: number, startDate, endDate, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/subs/${brand}/range/${startDate}/${endDate}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get Subscriptions data**************************************** */
  getSubscriptionsDatasets(brand: number, startDate, endDate, datasetValue, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/dataset/${brand}/range/${startDate}/${endDate}/${datasetValue}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get Datawall data**************************************** */
  getDatawallData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/datawall/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get New Subscriptions data**************************************** */
  getNewSubsData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/newsubs/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get New Subscriptions data**************************************** */
  getLostSaveData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/lostsubs/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get Revenue data**************************************** */
  getRevenueData(startDate, endDate, brand, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/revenue/${brand}/range/${startDate}/${endDate}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get Periodic Revenue data**************************************** */
  getPeriodicRevenueData(isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/revenue/graph?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get transaction data**************************************** */
  getTransactionData(brand: number) {
    return this.http
      .get(`${this.url}/v1/reports/transaction/${brand}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to download report based on type*************************************** */
  downloadReport(type: number) {
    return this.http
      .get(`${this.url}/v1/reports/download/${type}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to download report based on type*************************************** */
  getContentDataFromBq(type: string, brand: string) {
    let bqBrandUrl = brand != "All" ? '&brand=' + brand : '';
    return this.http
      .get(`${this.bq_url}/?type=${type}${bqBrandUrl}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  /*********************************API to Get subscriptions key metrics monthly data**************************************** */
  getSubsKeyMetricsMonthlyData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/metrics/month/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get subscriptions key metrics annual data**************************************** */
  getSubsKeyMetricsAnnualData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/metrics/annual/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get subscriptions key metrics average Rev data**************************************** */
  getSubsKeyMetricsAvgRevData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/metrics/avgrev/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*********************************API to Get subscriptions key metrics churn rate data**************************************** */
  getSubsKeyMetricChurnRateData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/metrics/custchurnrate/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*********************************API to Get subscriptions key metrics revenue churn rate data**************************************** */
  getSubsKeyMetricRevChurnRateData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/metrics/revchurnrate/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*********************************API to Get subscriptions key metrics life time value data**************************************** */
  getSubsKeyMetricLifeTimeValData(brand: number, isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/metrics/lifetimeval/${brand}?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*********************************API to Get Screen Dashboard data**************************************** */
  getScreenDashboardData(isRefreshed: boolean) {
    return this.http
      .get(`${this.url}/v1/reports/screen/dashboard?isRefreshed=${isRefreshed}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*********************************Method to get brand display name from brand name**************************************** */
  getBrandDisplayName(allBrandArr, brandName) {
    const brand = allBrandArr.find(item => item.brand_name === brandName);
    if (brand) {
      return brand.brand_display_name;
    }
    return brandName;
  }
  /*************************************API to Get Customer Roles*************************************** */
  getAllCustomerRoles(status: number): Promise<any> {
    return this.http
      .get(`${this.url}/v1/constants/roles/${status}`, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*************************************API to Get Customer Roles*************************************** */
  getAllData(dataObj): Promise<any> {
    return this.http
      .post(`${this.url}/v1/reports/minedata`, dataObj, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
  /*************************************API to Auto-suggested Company Name*************************************** */
  getCompNamesSuggestionLst(dataObj): Promise<any> {
    return this.http
      .post(`${this.url}/v1/reports/company/autocomplete`, dataObj, { headers: contentHeaders })
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
}
