import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomersService } from '../../../../services/customers.service';
import { Constants } from '../../../../common/constants';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModalComponent implements OnInit {
  crValue: number;
  CancelRequestForm: FormGroup;
  cancelReason: any[];
  tmp: any[];
  alerts: any[];
  customerId : string;
  brandId : string;
  orderId : string;
  public requestType: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private customersService: CustomersService,
    private configService: ConfigService) {
      this.getAllCancelReasons();
  }

  ngOnInit(): void {
    this.CancelRequestForm = this.formBuilder.group({
      cancelRequestReason: ['', [Validators.required]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.CancelRequestForm.controls; }

  getFormControl(name) {
    return this.CancelRequestForm.controls[name];
  }
  isFieldValid(field: string) {
    return !this.CancelRequestForm.get(field).valid && this.CancelRequestForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  } 
// Method send to its parent
  triggerEvent(value: number) {
    this.requestType.emit({ data: value , res:200 });
  }

  CancelRequestFormSubmit() {
    // stop here if form is invalid
    for (const i in this.CancelRequestForm.controls) {
      this.CancelRequestForm.controls[i].markAsDirty();
    }
    if (this.CancelRequestForm.invalid) {
      return;
    }
    let dataObj = {
      'order_id': this.orderId,
      'rq_type': this.crValue,
      'reason': this.f.cancelRequestReason.value,
      'user_id': this.customerId
    };
    this.customersService.cancelRequest(dataObj).then(
      res => {
          /*let resStatus = res['status']
          if(res['code']==1 && resStatus==1) {//success
            this.alerts = [{
              type: 'success',
              msg: Constants.CANCEL_REQUEST_SUCCESS_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
           
          } else {
            let errorMsg = Constants.CANCEL_REQUEST_FAILURE_MSG;
            this.alerts = [{
              type: 'danger',
              msg: errorMsg,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }*/
          let reason = dataObj.reason
          console.log(reason)
          if(reason){
            console.log("---cancel 1-----"+JSON.stringify(res));
            this.bsModalRef.hide();
            this.triggerEvent(res);
          }

      });
  }

     /**********************************API Method to get all cancel reason*********************/
  getAllCancelReasons() {
    this.configService.getAllCancelReasons().then(
      res=>{
        if(res['code']==1 && res['status']==1) {
          this.cancelReason = res['result'];
          this.tmp = res['result'];
        } else {
          this.alerts = [{
            type: 'danger',
            msg: res['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      },error=>{
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
      }
    );
  }

}
