import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';
import { DropdownConstants } from '../../../common/dropdown_constants';
import { PlansService } from '../../../services/plans.service';
import { PromoCodesService } from '../../../services/promo-codes.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-mine-data',
  templateUrl: './mine-data.component.html',
  styleUrls: ['./mine-data.component.scss']
})
export class MineDataComponent implements OnInit {
  form: FormGroup;
  userRolesArr;
  allActiveBrands: any[] = [];
  allPromoCodes: any[] = [];
  alerts: any[] = [];
  showLoadingSpinner = true;
  viewSubsReportsPrmsn = true;

  companyNameArr : any[] = [];
  companySizeArr : any[] = DropdownConstants.COMPANY_SIZE_DATA;
  industriesArr : any[] = DropdownConstants.INDUSTRIES_DATA;
  jobTtlArr : any[] = DropdownConstants.JOB_TITLE_DATA;
  countriesArr : any[] = DropdownConstants.COUNTRIES_DATA;
  planArr : any[] = [];
  selPlanArr : any[] = [];

  userList = [];
  totalUsers = 0;
  pageSize = 10; // or whatever default you prefer
  pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  sortColumn = 'id';
  sortOrder = Constants.SORT_ORDER_ASC;

  columns = [
    { prop: 'id', name: 'User Id' },
    { prop: 'email', name: 'Email' },
    { prop: 'brand_name', name: 'Brand' },
    { prop: 'first_name', name: 'First Name' },
    { prop: 'last_name', name: 'Last Name' },
    { prop: 'industry', name: 'Industry' },
    { prop: 'jobTitle', name: 'Job Title' },
    { prop: 'country', name: 'Country' },
    { prop: 'compSize', name: 'Company Size' },
    { prop: 'compName', name: 'Company Name' },
    { prop: 'subscriber', name: 'Subsciber' },
    { prop: 'subType', name: 'Subscription Type' },
    { prop: 'plan_name', name: 'Plan' },
    { prop: 'promoCode', name: 'Promo Code' },
    { prop: 'brand_id', name: 'Brand id' },
  ];


  constructor(private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private fb: FormBuilder,
    private plansService: PlansService,
    private promoCodeService: PromoCodesService,
    private reportsService: ReportsService) { 
      this.viewSubsReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_MINE_DATA]);
      this.commonService.subNavSelect(Constants.NAV_DASHBOARD);
      this.getAllActiveBrands();
      this.getAllCustomerRoles();
      this.getAllPromoCodes();
      this.getSelectedPlan(0);
    }

  ngOnInit(): void {
    this.titleService.setTitle('Mine Data');
    this.commonService.setTitle('Mine Data');
    this.form = this.fb.group({
      selectedBrand: [null],
      selectedPlan: [null],
      selectedSubscriber: [null],
      selectedIndustry: [null],
      selectedJobTtl: [null],
      selectedCountry: [null],
      selectedCompSize: [null],
      selectedCompanyName: [null],
      selectedSubType: [null],
      selectedPromo: [null]
    });
  }

  getAllActiveBrands(): void {
    if (this.viewSubsReportsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res.code === 1 && res.status === 1) {
            this.allActiveBrands = res.result;
            //this.getAllData(this.currentPage, this.defPageCount);
            this.setPage({ offset: 0 });
          } else {
            this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
        },
        error => {
          this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      );
    }
  }

  /**********************************API Method to Get All the Promo Codes*********************/
  getAllPromoCodes() {
    if (this.viewSubsReportsPrmsn) {
      this.promoCodeService.getAllPromocodes().then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allPromoCodes = res['result'];
          } else {
            this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }

  /**********************************API Method to Get All Customer roles*********************/
  getAllCustomerRoles() {
    if(this.viewSubsReportsPrmsn) {
      this.reportsService.getAllCustomerRoles(Constants.STATUS_ACTIVE).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
            this.userRolesArr = res['result'];
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

  /******************************Method to filter email/firstName/lastName column based on search string***************************/
  filterCompanyStr(event) {
    const val = event.target.value.toLowerCase();
    console.log(val);
    if(val.length>=2) {
      let obj = {
        'companyName' : val,
        'brandId' : this.f.selectedBrand.value
      };
      this.reportsService.getCompNamesSuggestionLst(obj).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
            this.companyNameArr = res['result'];
        } else {
            
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
      
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onFilterChange(val, type : string): void {
    console.log(`--------${val}---------------${type}------------`);
  }

  onBrandFilterChange(val) {
    this.filterPlanByBrand(val);
  }

  getSelectedPlan(brandId) {
    this.plansService.getAllPlans(brandId).then(
      res => {
        if (res['code'] == 1 && res['status'] == 1) {
          this.planArr = res['result'];
          this.selPlanArr = this.planArr;
        } else {
          this.alerts = [{
            type: 'danger',
            msg: res['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      }, error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
      }
    );
  }

  filterPlanByBrand(val) {
    const allPlans = this.planArr;
    if(val.length > 0) {
      this.selPlanArr = allPlans.filter(plan => val.includes(plan.brand_id));
    } else {
      this.selPlanArr = allPlans;
    }
  }

  onSubmit(): void {
    this.userList = [];
    this.setPage({ offset: 0 });
   // this.getAllData(this.currentPage, this.defPageCount);
  }

  onReset(): void {
    this.form.reset();
  }

  onLimitChange() {
    this.userList = [];
    this.setPage({ offset: 0 });
  }

  /*************************Pagination button click event**************************************************/
  /*setPage(currentPage: number) {
    this.userList = [];
    this.getAllData(currentPage, this.defPageCount)
  }*/

  onSort(event) {
    this.sortColumn = event.sorts[0].prop;
    this.sortOrder = event.sorts[0].dir.toUpperCase();
    this.setPage({ offset: 0 });
  }

  onPage(event) {
    this.setPage({ offset: event.offset });
  }

  setPage(pageInfo) {
    const currentPage = pageInfo.offset + 1;
    this.getAllData(currentPage, this.pageSize);
  }

  /***********************************Method to get All Users List***********************************************/
  getAllData(currentPage: number, pageSize : number) {
    if(this.viewSubsReportsPrmsn) {
      this.showLoadingSpinner = true;
      let dataObj = {
        'pageNumber' : currentPage-1,
        'pageSize' : pageSize,
        'sortingColumn' : this.sortColumn,
        'sortingOrder' : this.sortOrder,
        'brand' : this.f.selectedBrand.value || [],
        'compSize' : this.f.selectedCompSize.value || [],
        'country' : this.f.selectedCountry.value || [],
        'industry' : this.f.selectedIndustry.value || [],
        'jobTtl' : this.f.selectedJobTtl.value || [],
        'plan' : this.f.selectedPlan.value || [],
        'subType' : this.f.selectedSubType.value || [],
        'subscriber' : this.f.selectedSubscriber.value || [],
        'companyName' : this.f.selectedCompanyName.value,
        'promoCode' : this.f.selectedPromo.value || [],
      };
      console.log("------obj-------"+JSON.stringify(dataObj));
     /* this.reportsService.getAllData(dataObj).then(
        res => {
          this.showLoadingSpinner = false;
          if(res.userList.length>0) {
            this.userList = res.userList;
            this.currentPage = currentPage;
            this.index1 = currentPage*Constants.DEF_TABLE_DATA_COUNT - 9; //calculation based on selected pager for S.No
            this.totalUsers = res.totalUsers;
            this.pager = this.commonService.getPaginationDetails(currentPage, this.totalUsers, this.defPageCount);
          } else {
            this.userList = [];
            this.totalUsers = 0;
          }
        },
        error => {
          this.showLoadingSpinner = false;
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        });*/
        this.reportsService.getAllData(dataObj).then(res => {
          this.showLoadingSpinner = false;
          if (res.userList.length > 0) {
            this.userList = res.userList;
            this.totalUsers = res.totalUsers;
          } else {
            this.userList = [];
            this.totalUsers = 0;
          }
        }).catch(error => {
          this.showLoadingSpinner = false;
          // handle error
        });
      }
  }
  
  /**************************Method to get sorted user list*******************************/
  sortUserTable(event: any, name : string) {
    this.sortColumn = name;
    const classList = event.target.classList;
    const classes = event.target.className;
    
    if(classes.includes("sorting_asc")) {
      event.target.classList.remove('sorting_asc');
      event.target.classList.add('sorting_desc');
      this.sortOrder = Constants.SORT_ORDER_DESC;
    }
    else {
      event.target.classList.remove('sorting_desc');
      event.target.classList.add('sorting_asc');
      this.sortOrder = Constants.SORT_ORDER_ASC;
    }

    const allHeaders = Array.from(document.querySelectorAll('th'));

    allHeaders.forEach(elem => {
      if(elem.classList.contains("sortable") && elem != event.target) {
        elem.classList.remove('sorting_desc');
        elem.classList.remove('sorting_asc');
        elem.classList.add('sorting');
      }
    })

    event.target.classList.remove('sorting');
    this.setPage(Constants.DEF_TABLE_START_PAGE);
  }

  exportToExcel(): void {
    // Prepare data for export
    const dataToExport = this.userList.map(row => {
      return {
        'User Id': row['id'],
        'Email': row['email'],
        'Brand': row['brand_name'],
        'First Name': row['first_name'],
        'Last Name': row['last_name'],
        'Industry': row['industry'],
        'Job Title': row['jobTitle'],
        'Country': row['country'],
        'Company Size': row['compSize'],
        'Company Name': row['compName'],
        'Subscriber': row['subsciber'],
        'Subscription Type': row['subType'],
        'Plan': row['plan_name'],
        'Promo Code': row['promoCode']
      };
    });

    // Create a worksheet
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);

    // Create a workbook and add the worksheet
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Data': worksheet },
      SheetNames: ['Data']
    };

    // Generate the Excel file
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the file
    this.saveAsExcelFile(excelBuffer, 'UserList');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${fileName}_export_${new Date().getTime()}.xlsx`);
  }

}
