<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="editProductsPrmns || viewProductsPrmsn">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/products/all" href="javascript:void(0)">PRODUCTS</a></li>
      <li class="breadcrumb-item active" *ngIf="editProductsPrmns">EDIT PRODUCT</li>
      <li class="breadcrumb-item active" *ngIf="!editProductsPrmns && viewProductsPrmsn">VIEW PRODUCT</li>
    </ol>
  </div>
  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <h5 class="mb-2" *ngIf="editProductsPrmns">Edit Product</h5>
            <h5 class="mb-2" *ngIf="!editProductsPrmns && viewProductsPrmsn">View Product</h5>
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <form *ngIf="!showLoadingSpinner" class="addNewBrandForm mx-auto" id="form-project"  [formGroup]="editProductForm" (ngSubmit)="editProductFormSubmit()"  novalidate>
                <div class="">
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('productId').invalid && (getFormControl('productId').dirty || getFormControl('productId').touched)">
                        <label>Product ID</label>
                        <input type="text" class="form-control" formControlName="productId" placeholder="Enter Product ID">
                        <div class="invalid-tooltip" *ngIf="getFormControl('productId').invalid && (getFormControl('productId').dirty || getFormControl('productId').touched)">
                          Product ID is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('productName').invalid && (getFormControl('productName').dirty || getFormControl('productName').touched)">
                        <label>Product Name <br/><small>(Only letters, digits, underscore or dash. No whitespaces or symbols)</small></label>
                        <input type="text" class="form-control" formControlName="productName"   placeholder="Enter Product Name" appNameValidation>
                        <div class="invalid-tooltip" *ngIf="getFormControl('productName').invalid && (getFormControl('productName').dirty || getFormControl('productName').touched)">
                          Product Name is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div  pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('displayName').invalid && (getFormControl('displayName').dirty || getFormControl('displayName').touched)">
                        <label>Display Name <br/><small>(Name to be displayed on the front-end)</small></label>
                        <input type="text" class="form-control" formControlName="displayName"  placeholder="Enter Display Name">
                        <div class="invalid-tooltip" *ngIf="getFormControl('displayName').invalid && (getFormControl('displayName').dirty || getFormControl('displayName').touched)">
                          Display Name is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div  pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                        <label>Brand</label>
                        <!-- <select class="form-control" formControlName="brandName">
                          <option value="">Select Brand</option>
                          <option *ngFor='let brand of allActiveBrands;let i = index' value="{{brand.id}}">{{brand.brand_name}}</option>
                        </select> -->
                        <pg-select style="width: 100%;" formControlName="brandName" [PlaceHolder]="'Select Brand'" AllowClear
                        ShowSearch >
                            <pg-option *ngFor='let brand of allActiveBrands;let i = index' Label="{{brand.brand_name}}"   Value="{{brand.id}}">
                            </pg-option>
                        </pg-select>
                        <div class="invalid-tooltip" *ngIf="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                          Brand is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix" >
                    <div class="col-md-12">
                      <div  pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('proDesc').invalid && (getFormControl('proDesc').dirty || getFormControl('proDesc').touched)">
                        <label>Description</label>
                        <input type="text" class="form-control" formControlName="proDesc" placeholder="Enter Description">
                       
                        <div class="invalid-tooltip" *ngIf="getFormControl('proDesc').invalid && (getFormControl('proDesc').dirty || getFormControl('proDesc').touched)">
                          Description is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div  pgFormGroupDefault  class="form-group form-group-default date input-group required" [class.has-error]="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                        <label>Start Date</label>
                        <!-- <input type="date" class="form-control" formControlName="startDate" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" placeholder="Select Start Date"> -->
                        <div class="input-group ">
                          <pg-datepicker formControlName="startDate"  Placeholder="Select Start Date" [Format]="'DD/MM/YYYY'"></pg-datepicker>
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="pg-icon">calendar</i>
                            </span>
                          </div>
                        </div>
                        <div class="invalid-tooltip" *ngIf="getFormControl('startDate').invalid && (getFormControl('startDate').dirty || getFormControl('startDate').touched)">
                          Start Date is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div  pgFormGroupDefault  class="form-group form-group-default date input-group" >
                        <label>End Date</label>
                        <!-- <input type="date" class="form-control" formControlName="endDate" placeholder="Select End Date"> -->
                        <div class="input-group ">
                          <pg-datepicker formControlName="endDate"  Placeholder="Select End Date" [Format]="'DD/MM/YYYY'"></pg-datepicker>
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="pg-icon">calendar</i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group form-group-default d-flex align-items-center" >
                        <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                          <input type="checkbox" id="productStatus" checked="checked" formControlName="productStatus">
                          <label for="productStatus">Status</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div  pgFormGroupDefault  class="form-group form-group-default form-group-default-select2 required" [class.has-error]="getFormControl('paymentPlans').invalid && (getFormControl('paymentPlans').dirty || getFormControl('paymentPlans').touched)">
                        <label>Payment Plan</label>
                        <!-- <select class="form-control" formControlName="paymentPlans">
                          <option value="0"></option>
                          <option *ngFor='let plan of allPlansData;let i = index' value="{{plan.id}}">{{plan.plan_name}} - {{plan.plan_display_name}}</option>
                        </select> -->
                        <div class="inpt" class="inpt d-flex align-items-center">
                          <pg-select style="width: 88%;" formControlName="paymentPlans" [PlaceHolder]="'Please Select'" AllowClear
                          ShowSearch >
                              <pg-option *ngFor='let plan of allPlansData;let i = index' Label="{{plan.plan_name}} - {{plan.plan_display_name}}" Value="{{plan.id}}">
                              </pg-option>
                          </pg-select>
                          <button class="btn m-l-10 p-r-20 p-l-20 btn-primary active" type="button" (click)="addPaymentPlan()" *ngIf="editProductsPrmns">Add</button>
                        </div> 
                        <div class="invalid-tooltip" *ngIf="getFormControl('paymentPlans').invalid && (getFormControl('paymentPlans').dirty || getFormControl('paymentPlans').touched)">
                          Payment Plan required
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                
                <div class="form-group row clearfix">
                  <div class="table-responsive">
                    <table class="table dataTable selTbl">
                        <thead>
                            <tr>
                                <th scope="col">Payment Plan</th>
                                <th scope="col">Description</th>
                                <th scope="col">Frequency</th>
                                <th scope="col">Trial Price</th>
                                <th scope="col">Final Price</th>
                                <th scope="col">Order</th>
                                <th scope="col" class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let plan of selPlanArr; let i = index;">
                              <td>
                                <a routerLink="/plans/edit/{{plan.id}}" *ngIf="viewPlanPrmsn">{{plan.plan_name}}</a>
                                <span *ngIf="!viewPlanPrmsn">{{plan.plan_name}}</span>
                              </td>
                              <td>
                                {{plan.plan_desc}}
                              </td>
                              <td>
                                {{plan.frequency_text}}
                              </td>
                              <td>
                                <span *ngIf="plan.trial_price != '$0.00'">{{plan.trial_price}}</span>
                              </td>
                              <td>
                                {{plan.symbol}}{{plan.final_price}}
                              </td>
                              <td></td>
                              <td class="text-center">
                                <a (click)="deletePlanRow(i,plan.id)" class="hover" *ngIf="editProductsPrmns">
                                  <img src="assets/close.svg"/>
                                </a>
                              </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                </div>

                <div class="row p-t-20">
                  <div class=" col-md-12 text-right ">
                    <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10" *ngIf="editProductsPrmns">Update</button>
                    <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                    <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/products/all" *ngIf="editProductsPrmns">Cancel</button>
                    <button type="button" class="btn btn-light fs-14 padding-10  p-r-30 p-l-30" routerLink="/products/all" *ngIf="!editProductsPrmns && viewProductsPrmsn">Back</button>
                  </div>
                </div>
              </form>
              <div *ngFor="let alert of alerts" class="p-t-20">
                <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
              </div>
        </div>
      </div>
    </div>
  </div>  

</div>  

<!-------No Access Block--------->
<app-no-access *ngIf="!editProductsPrmns && !viewProductsPrmsn"></app-no-access>