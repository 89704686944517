import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from './../../../services/common.service';
import { Router} from '@angular/router';
import { PromoCodesService } from '../../../services/promo-codes.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-add-promo-code',
  templateUrl: './add-promo-code.component.html',
  styleUrls: ['./add-promo-code.component.scss']
})
export class AddPromoCodeComponent implements OnInit {
  addNewPromocodeForm: FormGroup;
  submitted = false;
  loading = false;
  alerts: any[];
  promoUserType;
  selectedOptions = 'NEW_USERS';
  //Role based permission check
  addPromoPrmns : boolean = true;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private promoCodeService: PromoCodesService,
    private cdr: ChangeDetectorRef, 
    private router: Router) {
      /****************Permission check based on role******************/
      this.promoUserType = Constants.Promo_User_Type;
      console.log(this.promoUserType)
      this.addPromoPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_PROMOCODES]);
    }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
    /****************Add New Promo-code Form Validation****************** */
    this.addNewPromocodeForm = this.formBuilder.group({
      promoCode: ['', [Validators.required]],
      promoName: ['', [Validators.required]],
      promoDesc: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: [''],
      promoUserType: ['', [Validators.required]],
      promocodeStatus: [true]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addNewPromocodeForm.controls; }

  
  getFormControl(name) {
    return this.addNewPromocodeForm.controls[name];
  }

  isFieldValid(field: string) {
    return !this.addNewPromocodeForm.get(field).valid && this.addNewPromocodeForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  } 
  /*******************************Method to submit add new promo code form***************************************** */
  addNewPromocodeFormSubmit() {
    if(this.addPromoPrmns) {
      this.submitted = true;
      // stop here if form is invalid
      for (const i in this.addNewPromocodeForm.controls) {
        this.addNewPromocodeForm.controls[i].markAsDirty();
      }

      if (this.addNewPromocodeForm.invalid) {
          return;
      }
      this.loading = true;
      let dataObj = {};
      dataObj['promo_code'] = this.f.promoCode.value;
      dataObj['promo_name'] = this.f.promoName.value;
      dataObj['promo_desc'] = this.f.promoDesc.value;
      dataObj['start_date'] = this.commonService.formatDate(this.f.startDate.value);
      dataObj['end_date'] = this.commonService.formatDate(this.f.endDate.value);
      dataObj['status'] = this.commonService.getStatusValue(this.f.promocodeStatus.value);
      dataObj['promo_user_type'] = this.f.promoUserType.value;
      this.promoCodeService.addNewPromocode(dataObj).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.alerts = [{
                type: 'success',
                msg: Constants.ADD_PROMOS_SUCCESS_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
              setTimeout(()=>{
              this.router.navigate(['/promocodes/all']);
              }, 2000);
            } else {
              let errorMsg = Constants.ADD_PROMOS_FAILURE_MSG;
              if(resStatus==3) {
                errorMsg = Constants.UPDATE_PROMOS_EXISTS_MSG;
              }
              this.alerts = [{
                type: 'danger',
                msg: errorMsg,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.ADD_PROMOS_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
            this.loading = false;
        });   
      } 
  }
}
