export class Constants {
  //commom
  public static ADMIN_API_ACCESS_TYPE: string = '2';
  public static DEF_ALERT_MSG_TIMEOUT: number = 7000;
  public static STATUS_ACTIVE: number = 1;
  public static STATUS_INACTIVE: number = 0;
  public static VERSION_ADD: number = 1;
  public static VERSION_EDIT: number = 2;
  public static STATUS_ACTIVE_LABEL: string = 'Active';
  public static STATUS_INACTIVE_LABEL: string = 'Inactive';
  //default status constants
  public static DEF_STATUS: any[] = [
    { id: '0', name: 'INACTIVE' },
    { id: '1', name: 'ACTIVE' }
  ];

  //nav
  public static NAV_BRANDS: string = "brands";
  public static NAV_CUSTOMERS: string = "customers";
  public static NAV_DASHBOARD: string = "dashboard";
  public static NAV_WIDGETS: string = "widgets";
  public static NAV_REPORTS: string = "reports";
  public static NAV_CONFIG: string = "config";
  public static NAV_PRODUCTS: string = "products";
  public static NAV_PLANS: string = "plans";
  public static NAV_DISCOUNTS: string = "discounts";
  public static NAV_PROMO: string = "promo";
  public static NAV_EMAILS: string = "emails";
  public static NAV_MANAGE_ADMIN: string = "manageadmin";
  public static NAV_CURRENCY: string = "currency";
  public static NAV_PERIODS: string = "periods";
  public static NAV_CANCEL_REASON: string = "cancelreason";
  public static NAV_CONT_CAT: string = "contcat";
  public static NAV_CONT_TYPE: string = "conttype";
  public static NAV_MET_ACT_TYPE: string = "acttype";
  public static NAV_USER_ROLE: string = "userroles";
  public static NAV_PAYMENT_TYPE: string = "paymenttype";
  public static NAV_SITE_LICENSES: string = "licenses";

  //Brands constants
  public static VIEW_BRAND_FAILURE_MSG: string = 'Failed to load the brand details. Please try again later.';
  public static UPDATE_BRAND_BRAND_EXISTS_MSG: string = 'Brand name already exists.';
  public static UPDATE_BRAND_DOMAIN_EXISTS_MSG: string = 'Domain name already exists.';
  public static UPDATE_BRAND_FAILURE_MSG: string = 'Failed to update brand. Please try again later.';
  public static ADD_BRAND_FAILURE_MSG: string = 'Failed to create new brand. Please try again later.';
  public static UPDATE_BRAND_SUCCESS_MSG: string = 'Brand details updated successfully.';
  public static ADD_BRAND_SUCCESS_MSG: string = 'New brand has been created successfully.';
  public static DEL_BRAND_FAILURE_MSG: string = 'Failed to Update Brand Status. Please try again later.';
  public static DEL_BRAND_SUCCESS_MSG: string = 'Brand status has been updated successfully.';

  //Customer constants
  public static CUSTOMER_TYPE_REGULAR_USER: number = 1;
  public static CUSTOMER_TYPE_FREE_USER: number = 2;
  public static CUSTOMER_TYPE_CORP_USER: number = 3;
  public static CUSTOMER_TYPE_STUDENT_USER: number = 4;
  public static VIEW_CUSTOMER_FAILURE_MSG: string = 'Failed to load the customer details. Please try again later.';
  public static UPDATE_CUSTOMER_EXISTS_MSG: string = 'Customer Email already exists.';
  public static UPDATE_CUSTOMER_NO_CHANGE_MSG: string = 'No changes has been made.';
  public static UPDATE_CUSTOMER_FAILURE_MSG: string = 'Failed to update customer details. Please try again later.';
  public static ADD_CUSTOMER_FAILURE_MSG: string = 'Failed to register new customer profile. Please try again later.';
  public static UPDATE_CUSTOMER_SUCCESS_MSG: string = 'Customer details updated successfully.';
  public static ADD_CUSTOMER_SUCCESS_MSG: string = 'New customer profile has been created successfully.';
  public static DEL_CUSTOMER_FAILURE_MSG: string = 'Failed to Update Customer Status. Please try again later.';
  public static DEL_CUSTOMER_SUCCESS_MSG: string = 'Customer status has been updated successfully.';
  public static CANCEL_REQUEST_SUCCESS_MSG: string = 'Subscription cancelled successfully.';
  public static CANCEL_REQUEST_FAILURE_MSG: string = 'Unable to cancel the subscription.';

  //order status constants
  public static ORDER_STATUS: any[] = [
    { id: '0', name: 'CANCELLED' },
    { id: '1', name: 'ACTIVE' },
    { id: '2', name: 'CANCEL_PENDING' },
    { id: '3', name: 'ACTIVE_PENDING' },
    { id: '4', name: 'COMPLETED' },
    { id: '5', name: 'FAILED_PENDING' },
    { id: '6', name: 'FAILED' }
  ];
  //transaction status constants
  public static TRANSACTION_STATUS: any[] = [
    { id: '1', name: 'Succeeded' },
    { id: '2', name: 'Failed' },
    { id: '3', name: 'Pending' },
    { id: '4', name: 'Active_Pending' }
  ];
  //purchase status constants
  public static PURCHASE_STATUS: any[] = [
    { id: '1', name: 'AUTO_RENEWED' }
  ];
  //cancel reason type constants
  public static CANCEL_REASON_TYPE: any[] = [
    { id: '1', name: 'Public' },
    { id: '2', name: 'Private' }
  ];


  //order status constants
  public static Promo_User_Type: any[] = [
    { id: 'NEW_USERS', name: 'New Users' },
    { id: 'CANCELLED_USERS', name: 'Cancelled Users' },
    { id: 'FAILED_USERS', name: 'Failed users' },

  ];

  // Promo code constants
  public static VIEW_PROMOS_FAILURE_MSG: string = 'Failed to load the promo code details. Please try again later.';
  public static UPDATE_PROMOS_EXISTS_MSG: string = 'Promo code already exists.';
  public static UPDATE_PROMOS_FAILURE_MSG: string = 'Failed to update brand. Please try again later.';
  public static UPDATE_PROMOS_SUCCESS_MSG: string = 'Promo code details updated successfully.';
  public static ADD_PROMOS_FAILURE_MSG: string = 'Failed to create new brand. Please try again later.';
  public static ADD_PROMOS_SUCCESS_MSG: string = 'New promo code has been created successfully.';
  public static DEL_PROMOS_SUCCESS_MSG: string = 'Promo code status has been updated successfully.';
  public static DEL_PROMOS_FAILURE_MSG: string = 'Failed to Update Promo code Status. Please try again later.';
  public static DEL_PROMOS_FAILURE_ALREADY_MAPPED_MSG: string = 'Failed to Update Promo code Status. Promo code is mapped with Discount Id/Ids';

  // Products constants
  public static VIEW_PRODUCT_FAILURE_MSG: string = 'Failed to load the product details. Please try again later.';
  public static UPDATE_PRODUCT_EXISTS_MSG: string = 'Product already exists.';
  public static UPDATE_PRODUCT_FAILURE_MSG: string = 'Failed to update product. Please try again later.';
  public static UPDATE_PRODUCT_SUCCESS_MSG: string = 'Product details updated successfully.';
  public static ADD_PRODUCT_FAILURE_MSG: string = 'Failed to create new product. Please try again later.';
  public static ADD_PRODUCT_SUCCESS_MSG: string = 'New product has been created successfully.';
  public static DEL_PRODUCT_SUCCESS_MSG: string = 'Product status has been updated successfully.';
  public static DEL_PRODUCT_FAILURE_MSG: string = 'Failed to Update Product Status. Please try again later.';

  // Discount constants
  public static VIEW_DISCOUNT_FAILURE_MSG: string = 'Failed to load the discount details. Please try again later.';
  public static UPDATE_DISCOUNT_EXISTS_MSG: string = 'Discount name already exists.';
  public static UPDATE_DISCOUNT_FAILURE_MSG: string = 'Failed to update discount. Please try again later.';
  public static UPDATE_DISCOUNT_SUCCESS_MSG: string = 'Discount details updated successfully.';
  public static ADD_DISCOUNT_FAILURE_MSG: string = 'Failed to create new discount. Please try again later.';
  public static ADD_DISCOUNT_SUCCESS_MSG: string = 'New discount has been created successfully.';
  public static DEL_DISCOUNT_SUCCESS_MSG: string = 'Discount status has been updated successfully.';
  public static DEL_DISCOUNT_FAILURE_MSG: string = 'Failed to Update Discount Status. Please try again later.';
  public static DEL_DISCOUNT_FAILURE_ALREADY_MAPPED_MSG: string = 'Failed to Update Discount Status. Discount is mapped with Plan Id/Ids';

  // Plan constants
  public static VIEW_PLAN_FAILURE_MSG: string = 'Failed to load the plan details. Please try again later.';
  public static UPDATE_PLAN_EXISTS_MSG: string = 'Plan name already exists.';
  public static UPDATE_PLAN_FAILURE_MSG: string = 'Failed to update plan. Please try again later.';
  public static UPDATE_PLAN_SUCCESS_MSG: string = 'Plan details updated successfully.';
  public static ADD_PLAN_FAILURE_MSG: string = 'Failed to create new plan. Please try again later.';
  public static ADD_PLAN_SUCCESS_MSG: string = 'New plan has been created successfully.';
  public static DEL_PLAN_SUCCESS_MSG: string = 'Plan status has been updated successfully.';
  public static DEL_PLAN_FAILURE_MSG: string = 'Failed to Update Plan Status. Please try again later.';
  public static DEL_PLAN_FAILURE_ALREADY_MAPPED_MSG: string = 'Failed to Update Plan Status. Plan is mapped with Product Id/Ids';

  //Settings Manage Admin constants
  public static VIEW_ADMIN_FAILURE_MSG: string = 'Failed to load the admin details. Please try again later.';
  public static UPDATE_ADMIN_EXISTS_MSG: string = 'Admin email already exists.';
  public static UPDATE_ADMIN_FAILURE_MSG: string = 'Failed to update admin details. Please try again later.';
  public static ADD_ADMIN_FAILURE_MSG: string = 'Failed to add new admin user. Please try again later.';
  public static UPDATE_ADMIN_SUCCESS_MSG: string = 'Admin details updated successfully.';
  public static ADD_ADMIN_SUCCESS_MSG: string = 'New admin user has been added successfully.';
  public static DEL_ADMIN_FAILURE_MSG: string = 'Failed to Update Admin User Status. Please try again later.';
  public static DEL_ADMIN_SUCCESS_MSG: string = 'Admin user status has been updated successfully.';
  public static UPDATE_ADMIN_PWD_SUCCESS_MSG: string = 'Admin user password has been updated successfully.';
  public static UPDATE_ADMIN_PWD_FAILURE_MSG: string = 'Failed to update admin user password. Please try again later.';
  public static UPDATE_ADMIN_PWD_INCORRECT: string = 'Old password is incorrect.';
  public static UPDATE_ADMIN_PWD_USER_NOT_FOUND: string = 'User profile not found.';
  public static UPDATE_ADMIN_PWD_NO_CHANGES: string = 'No changes have been made to the password.';
  public static UPDATE_ADMIN_PWD_SAME: string = 'Both old and new password are same.';

  //Site Licenses
  public static VIEW_LICENSEE_FAILURE_MSG: string = 'Failed to load the licensee details. Please try again later.';
  public static ADD_LICENSEE_FAILURE_MSG: string = 'Failed to create new licensee. Please try again later.';
  public static ADD_LICENSEE_SUCCESS_MSG: string = 'New licensee has been created successfully.';
  public static EDIT_LICENSEE_FAILURE_MSG: string = 'Failed to update the licensee. Please try again later.';
  public static EDIT_LICENSEE_SUCCESS_MSG: string = 'Licensee details has been updated successfully.';
  public static DEL_SITE_LIC_FAILURE_MSG: string = 'Failed to Update Corporate Subscription Status. Please try again later.';
  public static DEL_SITE_LIC_SUCCESS_MSG: string = 'Corporate Subscription status has been updated successfully.';
  public static INV_USER_FAILURE_MSG: string = 'Failed to invite the user. Please try again later.';
  public static INV_USER_SUCCESS_MSG: string = 'Used has been invited successfully.';
  public static PAYLINK_FAILURE_MSG: string = 'Failed to generate payment link. Please try again later.';
  public static PAYLINK_SUCCESS_MSG: string = 'Payment link has been generated successfully.';
  public static VIEW_DOMAIN_USERS_FAILURE_MSG: string = 'Failed to load the users for this domain. Please try again later.';

  public static ADMIN_ROLES: any[] = [
    { id: '1', name: 'Super Admin' },
    { id: '2', name: 'Company Admin' }
  ];
  public static ADMIN_ROLE_SUPER_ADMIN: number = 1;
  public static ADMIN_ROLE_COMPANY_ADMIN: number = 2;

  //Widgets constants
  public static WIDGET_TYPE_DATA: any[] = [
    { id: '1', name: 'Paywall' },
    { id: '2', name: 'Notifier' }
  ];
  public static WIDGET_TYPE_PAYWALL: number = 1;
  public static WIDGET_ACTION_TYPE_CUSTOM_COUNT: number = 5;
  public static ADD_WIDGET_SUCCESS_MSG: string = 'New widget has been created successfully.';
  public static ADD_WIDGET_FAILURE_MSG: string = 'Failed to create new widget. Please try again later.';
  public static DEL_WIDGET_FAILURE_MSG: string = 'Failed to Update Widget Status. Please try again later.';
  public static DEL_WIDGET_SUCCESS_MSG: string = 'Widget status has been updated successfully.';
  public static UPDATE_WIDGET_FAILURE_MSG: string = 'Failed to update widget. Please try again later.';
  public static UPDATE_WIDGET_SUCCESS_MSG: string = 'Widget details updated successfully.';
  public static UPDATE_WIDGET_RULE_EXISTS_MSG: string = 'Rule already exists. Please try new rule or update the existing rule.';
  public static VIEW_WIDGET_FAILURE_MSG: string = 'Failed to load the widget details. Please try again later.';

  public static ADD_EMAIL_TMPLT_SUCCESS_MSG: string = 'New email template has been created successfully.';
  public static ADD_EMAIL_TMPLT_FAILURE_MSG: string = 'Failed to create new email template. Please try again later.';
  public static DEL_EMAIL_TMPLT_FAILURE_MSG: string = 'Failed to Update Email Template Status. Please try again later.';
  public static DEL_EMAIL_TMPLT_SUCCESS_MSG: string = 'Email Template status has been updated successfully.';
  public static UPDATE_EMAIL_TMPLT_FAILURE_MSG: string = 'Failed to update email template. Please try again later.';
  public static UPDATE_EMAIL_TMPLT_SUCCESS_MSG: string = 'Email template details updated successfully.';
  public static UPDATE_EMAIL_TMPLT_RULE_EXISTS_MSG: string = 'Rule already exists. Please try new rule or update the existing rule.';
  public static VIEW_EMAIL_TMPLT_FAILURE_MSG: string = 'Failed to load the email template details. Please try again later.';

  public static REPORT_DOWNLOAD_SUCCESS: string = 'Report downloaded successfully.';
  public static REPORT_DOWNLOAD_FAILED: string = 'Failed to download the report.';

  public static INVOICE_DOWNLOAD_SUCCESS_MSG: string = 'Invoice downloaded successfully.';
  public static INVOICE_DOWNLOAD_FAILURE_MSG: string = 'Failed to download the invoice.';
  public static INVOICE_NOT_FOUND_MSG: string = 'Invoice not found for this transaction.';

  public static NO_CARD_SELECTED: string = 'Please select any of the card to update.';
  public static VIEW_CARD_FAILURE_MSG: string = 'Failed to load the card details.';
  public static CARD_UPDATE_SUCCESS_MSG: string = 'Card updated successfully.';
  public static CARD_UPDATE_FAILURE_MSG: string = 'Failed to update the card. Please try again later.';
  public static CARD_ADDED_SUCCESS_MSG: string = 'New card added successfully.';
  public static CARD_ADDED_FAILURE_MSG: string = 'Failed to add new card. Please try again later.';
  public static REFUND_PAYMENT_SUCCESS_MSG: string = 'Payment refunded successfully.';
  public static REFUND_PAYMENT_FAILURE_MSG: string = 'Failed to refund the payment. Please try again later.';

  public static DEF_USERS_SORT_COLUMN: string = "email";
  public static SORT_ORDER_ASC: string = "asc";
  public static SORT_ORDER_DESC: string = "desc";
  public static DEF_TABLE_PAGER: number = 1;//offset
  public static DEF_TABLE_DATA_COUNT: number = 10;//limit
  public static DEF_TABLE_START_PAGE: number = 1;
  public static DEF_TABLE_START_INDEX: number = 1;

  public static DASH_DATA_FAILURE_MSG: string = 'Failed to load the data.';
  public static DASH_TRANSDATA_FAILURE_MSG: string = 'Failed to load the transaction details.';

  //Permission constants
  //No Access
  public static PERMISSION_NO_ACCESS: string = "You have no access to this screen. Please contact your administrator.";

  //All Access
  public static PERMISSION_ALL_ACCESS: string = "ALL ACCESS";

  //Dashboard
  public static PERMISSION_VIEW_DASHBOARD: string = "VIEW DASHBOARD";

  //Brands
  public static PERMISSION_VIEW_BRANDS: string = "VIEW BRANDS";
  public static PERMISSION_ADD_BRANDS: string = "ADD BRANDS";
  public static PERMISSION_EDIT_BRANDS: string = "EDIT BRANDS";

  //Widgets
  public static PERMISSION_VIEW_WIDGETS: string = "VIEW WIDGETS";
  public static PERMISSION_ADD_WIDGETS: string = "ADD WIDGETS";
  public static PERMISSION_EDIT_WIDGETS: string = "EDIT WIDGETS";

  //Customers
  public static PERMISSION_VIEW_CUSTOMERS: string = "VIEW CUSTOMERS";
  public static PERMISSION_ADD_CUSTOMERS: string = "ADD CUSTOMERS";
  public static PERMISSION_EDIT_CUSTOMERS: string = "EDIT CUSTOMERS";
  public static PERMISSION_VIEW_SUBSCRIBERS: string = "VIEW SUBSCRIBERS";
  public static PERMISSION_CANCEL_SUBSCRIBERS: string = "CANCEL SUBSCRIBERS";
  public static PERMISSION_REFUND_SUBSCRIBERS: string = "REFUND SUBSCRIBERS";

  //PRODUCTS
  public static PERMISSION_VIEW_PRODUCTS: string = "VIEW PRODUCTS";
  public static PERMISSION_ADD_PRODUCTS: string = "ADD PRODUCTS";
  public static PERMISSION_EDIT_PRODUCTS: string = "EDIT PRODUCTS";

  //Plans
  public static PERMISSION_VIEW_PLANS: string = "VIEW PLANS";
  public static PERMISSION_ADD_PLANS: string = "ADD PLANS";
  public static PERMISSION_EDIT_PLANS: string = "EDIT PLANS";

  //Discounts
  public static PERMISSION_VIEW_DISCOUNTS: string = "VIEW DISCOUNTS";
  public static PERMISSION_ADD_DISCOUNTS: string = "ADD DISCOUNTS";
  public static PERMISSION_EDIT_DISCOUNTS: string = "EDIT DISCOUNTS";

  //Promocodes
  public static PERMISSION_VIEW_PROMOCODES: string = "VIEW PROMOCODES";
  public static PERMISSION_ADD_PROMOCODES: string = "ADD PROMOCODES";
  public static PERMISSION_EDIT_PROMOCODES: string = "EDIT PROMOCODES";

  //Site Licenses
  public static PERMISSION_VIEW_SITE_LICENSES: string = "VIEW SITE LICENSES";
  public static PERMISSION_ADD_SITE_LICENSES: string = "ADD SITE LICENSES";
  public static PERMISSION_EDIT_SITE_LICENSES: string = "EDIT SITE LICENSES";

  //Email Templates
  public static PERMISSION_VIEW_EMAIL_TEMPLATES: string = "VIEW EMAIL TEMPLATES";
  public static PERMISSION_ADD_EMAIL_TEMPLATES: string = "ADD EMAIL TEMPLATES";
  public static PERMISSION_EDIT_EMAIL_TEMPLATES: string = "EDIT EMAIL TEMPLATES";

  //Reports
  public static PERMISSION_VIEW_REPORTS: string = "VIEW REPORTS";
  public static PERMISSION_DOWNLOAD_REPORTS: string = "DOWNLOAD REPORTS";
  public static PERMISSION_VIEW_SUBSCRIPTIONS_REPORTS: string = "VIEW SUBSCRIPTION REPORTS";
  public static PERMISSION_VIEW_DATAWALL_REPORTS: string = "VIEW DATAWALL REPORTS";
  public static PERMISSION_VIEW_NEW_SUBS_REPORTS: string = "VIEW NEW SUBSCRIBERS REPORTS";
  public static PERMISSION_VIEW_REVENUE_REPORTS: string = "VIEW REVENUE REPORTS";
  public static PERMISSION_VIEW_SUBS_RETENTION_REPORTS: string = "VIEW SUBS RETENTION REPORTS";
  public static PERMISSION_VIEW_CONT_METRICS_REPORTS: string = "VIEW CONTENT METRICS REPORTS";
  public static PERMISSION_VIEW_SUBS_KEY_METRICS_REPORTS: string = "VIEW SUBS KEY METRICS REPORTS";
  public static PERMISSION_VIEW_SCREEN_DASHBOARD: string = "VIEW SCREEN DASHBOARD";
  public static PERMISSION_VIEW_MINE_DATA: string = "VIEW MINE DATA";

  //Configurations
  public static PERMISSION_VIEW_CURRENCIES: string = "VIEW CURRENCIES";
  public static PERMISSION_VIEW_CONTENT_CATEGORIES: string = "VIEW CONTENT CATEGORIES";
  public static PERMISSION_VIEW_CONTENT_TYPES: string = "VIEW CONTENT TYPES";
  public static PERMISSION_VIEW_ACTION_TYPES: string = "VIEW ACTION TYPES";
  public static PERMISSION_VIEW_PERIODS: string = "VIEW PERIODS";
  public static PERMISSION_VIEW_USER_ROLES: string = "VIEW USER ROLES";
  public static PERMISSION_VIEW_CANCEL_REASONS: string = "VIEW CANCEL REASONS";
  public static PERMISSION_VIEW_PAYMENT_TYPES: string = "VIEW PAYMENT TYPES";

  //Settings
  public static PERMISSION_VIEW_MANAGE_ADMIN: string = "VIEW MANAGE ADMIN";
  public static PERMISSION_ADD_ADMIN: string = "ADD ADMIN";
  public static PERMISSION_EDIT_ADMIN: string = "EDIT ADMIN";
  public static PERMISSION_CHANGE_ADMIN_PASSWORD: string = "CHANGE ADMIN PASSWORD";

  //Color
  public static REPORTS_CONSTANT_COLORS: string[] = ['#BC80BD80', '#FB807280', '#FDB46280', '#B3DE6980', '#FFED6F80', '#80B1D380', '#8DD3C780', '#FFFFB380', '#FCCDE580', '#BEBADA80', '#CCEBC580', '#D9D9D980'];

}
