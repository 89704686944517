import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TemplatesComponent } from './templates/templates.component';
import { AllTemplatesComponent } from './all-templates/all-templates.component';
import { AddTemplateComponent } from './add-template/add-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';

import { TemplatesRoutingModule } from './templates-routing.module';
//Services
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { FormGroupDefaultModule } from '../../directives/form-group-default.module';
import { pgSelectModule } from '../../components/select/select.module';

import { CommonTempModule } from '../common-temp/common.module';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';

@NgModule({
  declarations: [TemplatesComponent, AllTemplatesComponent, AddTemplateComponent, EditTemplateComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    TemplatesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FormGroupDefaultModule,
    pgSelectModule,
    BrowserAnimationsModule,
    CommonTempModule,
    CodemirrorModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    TabsModule.forRoot()
  ]
})
export class TemplatesModule { }
