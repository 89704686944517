<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5 m-l-15">
      <li class="breadcrumb-item"><a routerLink="/experience/all" href="javascript:void(0)">Experiences</a></li>
      <li class="breadcrumb-item active">CREATE NEW</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">

      <form class="form-where" [formGroup]="experienceForm">
        <div class="form-row row align-items-end">
          <div class="form-group col-md-4">
            <label for="expBrand">Brand</label>
            <pg-select style="width: 100%;" [(ngModel)]="selectedOption" (ngModelChange)="brandChange(selectedOption)"
              formControlName="expBrand" [PlaceHolder]="'Select Brand'" AllowClear ShowSearch>
              <pg-option *ngFor='let brand of allActiveBrands;let i = index' Label="{{brand.brand_name}}"
                Value="{{brand.id}}">
              </pg-option>
            </pg-select>
          </div>
          <div class="form-group col-md-4">
            <label for="expName">Name</label>
            <input type="text" class="form-control" formControlName="expName" placeholder="Enter Experience Name">
          </div>
          <div class="form-group col-md-4">
            <label for="expDesc">Description</label>
            <input type="text" class="form-control" formControlName="expDesc" placeholder="Enter Description">
          </div>
        </div>
        <!-- WHERE starts here -->
        <div class="col-md-12 mb-4">
          <pgcard Maximize="false" collapseState="active" ProgressType="bar" TimeOut="2500">
            <ng-template #CardTitle>Where?</ng-template>
            <div formGroupName="where" class="card-collapsed">
              <div class="form-group row align-items-center">
                <h4 class="col-md-6"></h4>
                <div class="form-check col-md-6 text-right">
                  <input class="form-check-input" type="checkbox" formControlName="allpages" id="allpages">
                  <label class="form-check-label" for="allpages">All Pages</label>
                </div>
              </div>
              <div formArrayName="data" *ngIf="!allPagesControl.value">
                <div *ngFor="let item of whereData.controls; let i=index" [formGroupName]="i">
                  <div class="form-row col-md-8" *ngIf="i !== 0">
                    <div class="form-group col-md-2 mx-auto m-t-10 m-b-15">
                      <pg-select style="width: 100%;" formControlName="expCond" [PlaceHolder]="'Select Condition'"
                        AllowClear ShowSearch>
                        <pg-option *ngFor='let expCond of expCondOption' Label="{{expCond.label}}"
                          Value="{{expCond.value}}">
                        </pg-option>
                      </pg-select>
                      <!-- <select formControlName="expCond" class="form-control">
                        <option selected value="and">AND</option>
                        <option value="or">OR</option>
                      </select> -->
                    </div>
                  </div>
                  <div class="form-row row align-items-end expCusForm" id='expCusForm_{{i}}'>
                    <div class="form-group col-md-3">
                      <label for="expField">Condition</label>
                      <!--<pg-select style="width: 100%;" formControlName="expField" [PlaceHolder]="'Select Condition'"
                        AllowClear ShowSearch>
                        <pg-option *ngFor='let expField of expFieldOption' Label="{{expField.label}}"
                          Value="{{expField.value}}">
                        </pg-option>
                      </pg-select>-->
                      <select formControlName="expField" class="form-control" #t (change)="expFieldChange(t.value,i)">
                        <option selected value="url">URL</option>
                        <option value="metaTag">Meta Tag</option>
                        <option value="cat">Category</option>
                        <option value="contType">Content Type</option>
                        <option value="tags">Tags</option>
                      </select>
                    </div>
                    <div class="form-group col-md-2 d-none" id='expCusFldCtr_{{i}}'>
                      <label for="expCusFld">Meta Tag Name</label>
                      <input type="text" class="form-control" formControlName="expCusFld">
                    </div>
                    <div class="form-group col-md-2">
                      <pg-select style="width: 100%;" formControlName="expDataCond"
                        [PlaceHolder]="'Select Meta Tag Name'" AllowClear ShowSearch>
                        <pg-option *ngFor='let expDataCond of expDataCondOption' Label="{{expDataCond.label}}"
                          Value="{{expDataCond.value}}">
                        </pg-option>
                      </pg-select>
                      <!-- <select formControlName="expDataCond" class="form-control">
                        <option selected value="includes">Includes</option>
                        <option value="excludes">Excludes</option>
                        <option value="equals">Equals</option>
                        <option value="notEquals">Not Equals</option>
                      </select> -->
                    </div>
                    <div class="form-group col-md-3">
                      <label for="expValue">Value</label>
                      <input type="text" class="form-control" formControlName="expValue">
                    </div>
                    <div class="form-group col-md-2">
                      <button type="button" class="btn btn-danger m-r-10 btn-rounded"
                        (click)="removeWhereDataItem(i)">-</button>
                      <button type="button" class="btn btn-primary btn-rounded" (click)="addWhereDataItem()"
                        *ngIf="i === (whereData.controls.length - 1)">+</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pgcard>

        </div>
        <!-- WHERE ends here -->
      </form>
      <div class="row">
        <div class="col-md-12">
          <pgcard Maximize="true" ProgressType="bar" TimeOut="2500">
            <ng-template #CardTitle>Who?</ng-template>
            <form class="form-condobj" [formGroup]="experienceForm">
              <!-- CONDOBJ -->
              <div formGroupName="condObj">
                <!-- WHO starts here-->
                <div formGroupName="who">
                  <div class="form-group row align-items-center">
                    <h4 class="col-md-6"></h4>
                    <div class="form-check col-md-6 text-right">
                      <input class="form-check-input" type="checkbox" formControlName="allUsers" id="allUsers">
                      <label class="form-check-label" for="allUsers">All Users</label>
                    </div>
                  </div>
                  <div formArrayName="data" *ngIf="!allUsersControl.value">
                    <div *ngFor="let item of whoData.controls; let i=index" [formGroupName]="i">
                      <div class="form-row col-md-8" *ngIf="i !== 0">
                        <div class="form-group col-md-2 mx-auto m-t-10 m-b-15">
                          <pg-select style="width: 100%;" formControlName="whoCond" [PlaceHolder]="'Select Condition'"
                            AllowClear ShowSearch>
                            <pg-option *ngFor='let expCond of expCondOption' Label="{{expCond.label}}"
                              Value="{{expCond.value}}">
                            </pg-option>
                          </pg-select>
                        </div>
                      </div>
                      <div class="form-row row align-items-end expCusForm" id='expCusForm_{{i}}'>
                        <div class="form-group col-md-3">
                          <label for="whoField">Condition</label>

                          <pg-select style="width: 100%;" formControlName="whoField" [PlaceHolder]="'Select Condition'"
                            AllowClear ShowSearch>
                            <pg-option *ngFor='let whoField of whoFieldOption' Label="{{whoField.label}}"
                              Value="{{whoField.value}}">
                            </pg-option>
                          </pg-select>
                        </div>
                        <div class="form-group col-md-2">
                          <pg-select style="width: 100%;" formControlName="whoDataCond"
                            [PlaceHolder]="'Select Condition'" AllowClear ShowSearch>
                            <pg-option *ngFor='let whoDataCond of whoDataCondOption' Label="{{whoDataCond.label}}"
                              Value="{{whoDataCond.value}}">
                            </pg-option>
                          </pg-select>

                        </div>
                        <div class="form-group col-md-3">
                          <label for="whoValue">Value</label>
                          <ng-container *ngIf="item.get('whoField').value === 'plan'">
                            <pg-select style="width: 100%;" formControlName="whoValue" [PlaceHolder]="'Select Plan'"
                              AllowClear ShowSearch>
                              <pg-option *ngFor='let plan of slectedPlan' Label="{{plan.plan_name}}"
                                Value="{{plan.id}}">
                              </pg-option>
                            </pg-select>
                          </ng-container>

                          <ng-container *ngIf="item.get('whoField').value === 'subType'">
                            <pg-select style="width: 100%;" formControlName="whoValue" [PlaceHolder]="'Select Value'"
                              AllowClear ShowSearch>
                              <pg-option *ngFor='let whoValue of whoValueOption' Label="{{whoValue.label}}"
                                Value="{{whoValue.value}}">
                              </pg-option>
                            </pg-select>
                          </ng-container>

                          <ng-container *ngIf="item.get('whoField').value === 'licStatus'">
                            <pg-select style="width: 100%;" formControlName="whoValue" [PlaceHolder]="'Select Value'"
                              AllowClear ShowSearch>
                              <pg-option *ngFor='let licStatus of licStatusOption' Label="{{licStatus.label}}"
                                Value="{{licStatus.value}}">
                              </pg-option>
                            </pg-select>
                          </ng-container>

                          <ng-container *ngIf="item.get('whoField').value === 'userRole'">
                            <pg-select style="width: 100%;" formControlName="subType" [PlaceHolder]="'Select Value'"
                              AllowClear ShowSearch>
                              <pg-option *ngFor='let role of userRolesArr' Label="{{role.access_role_name}}"
                                Value="{{role.id}}">
                              </pg-option>
                            </pg-select>
                          </ng-container>

                          <ng-container *ngIf="item.get('whoField').value === 'logStatus'">
                            <pg-select style="width: 100%;" formControlName="whoValue" [PlaceHolder]="'Select Value'"
                              AllowClear ShowSearch>
                              <pg-option *ngFor='let whoValue of whoValueOption' Label="{{whoValue.label}}"
                                Value="{{whoValue.value}}">
                              </pg-option>
                            </pg-select>
                          </ng-container>

                          <ng-container *ngIf="item.get('whoField').value === 'geoLoc'">
                            <pg-select style="width: 100%;" formControlName="geoLoc" [PlaceHolder]="'Select Country'"
                              AllowClear ShowSearch>
                              <pg-option *ngFor='let country of countries' Label="{{ country.name }}"
                                Value="country | json">
                              </pg-option>
                            </pg-select>
                          </ng-container>

                        </div>
                        <div class="form-group col-md-3">
                          <button type="button" class="btn btn-danger m-r-10 btn-rounded"
                            (click)="removeWhoDataItem(i)">-</button>
                          <button type="button" class="btn btn-primary btn-rounded" (click)="addWhoDataItem()"
                            *ngIf="i === (whoData.controls.length - 1)">+</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row row m-t-25 m-b-20">
                    <div class="form-group d-flex justify-content-end">
                      <button type="button" class="btn btn-info m-r-10" (click)="addMeteringEvent()"
                        *ngIf="!showWhereEvent && !showWhereAction">Add Metering</button>
                      <button type="button" class="btn btn-info m-r-10" (click)="removeWhereEvent()"
                        *ngIf="showWhereEvent">Remove Metering</button>
                      <button type="button" class="btn btn-info" (click)="addWhereAction()"
                        *ngIf="!showWhereAction && !showWhereEvent">Add Action</button>
                      <button type="button" class="btn btn-info" (click)="removeWhereAction()"
                        *ngIf="showWhereAction">Remove Action</button>
                    </div>
                  </div>
                  <!-- WHO ends here-->
                  <div class="card" *ngIf="showWhereEvent">
                    <div class="card-body border-info">
                      <!-- Event Object -->
                      <div formGroupName="eventObj">
                        <h4>When?</h4>
                        <div class="form-group row">
                          <label for="metFor" class="col-sm-1 col-form-label col-form-label-sm">For</label>
                          <div class="col-sm-2 d-flex align-items-center">

                            <pg-select style="width: 100%;" formControlName="metFor" [PlaceHolder]="'Select For'"
                              AllowClear ShowSearch>
                              <pg-option *ngFor='let metFor of metForOption' Label="{{metFor.label}}"
                                Value="{{metFor.value}}">
                              </pg-option>
                            </pg-select>
                          </div>
                        </div>
                        <div formArrayName="meterAct">
                          <div *ngFor="let item of meterAct.controls; let i=index" [formGroupName]="i" class="p-l-5">
                            <h5 class="m-t-25">Meter Action {{i+1}}</h5>
                            <div class="form-row row align-items-end metActForm">
                              <div class="form-group col-md-3">Meter Value</div>
                              <div class="form-group col-md-3">
                                <pg-select style="width: 100%;" formControlName="metDataCond"
                                  [PlaceHolder]="'Select Meter Value'" AllowClear ShowSearch>
                                  <pg-option *ngFor='let metDataCond of metDataCondOption' Label="{{metDataCond.label}}"
                                    Value="{{metDataCond.value}}">
                                  </pg-option>
                                </pg-select>
                              </div>
                              <div class="form-group col-md-3">
                                <pg-select style="width: 100%;" formControlName="metActVal"
                                  [PlaceHolder]="'Select Meter Value'" AllowClear ShowSearch>
                                  <pg-option *ngFor='let metActVal of metActValOption' Label="{{metActVal.label}}"
                                    Value="{{metActVal.value}}">
                                  </pg-option>
                                </pg-select>
                              </div>
                              <div class="form-group col-md-3" *ngIf="item.get('metActVal').value === 'custom'">
                                <label for="metCustcount">Page Count</label>
                                <input type="number" class="form-control" formControlName="metCustcount">
                              </div>
                            </div>
                            <div class="form-row row align-items-end metCondForm m-t-20">
                              <h5 class="p-l-0">What?</h5>
                              <div class="form-group col-md-3 p-l-0">
                                <label for="metActCondVal">Show Template</label>
                                <pg-select style="width: 100%;" formControlName="metActCondVal"
                                  [PlaceHolder]="'Select Template'" AllowClear ShowSearch>
                                  <pg-option *ngFor='let metActCondVal of metActCondValOption'
                                    Label="{{metActCondVal.label}}" Value="{{metActCondVal.value}}">
                                  </pg-option>
                                </pg-select>
                              </div>
                              <div class="form-group col-md-3">
                                <label for="metActCondType">Type</label>
                                <pg-select style="width: 100%;" formControlName="metActCondType"
                                  [PlaceHolder]="'Select Type'" AllowClear ShowSearch>
                                  <pg-option *ngFor='let metActCondType of metActCondTypeOption'
                                    Label="{{metActCondType.label}}" Value="{{metActCondType.value}}">
                                  </pg-option>
                                </pg-select>
                              </div>
                              <div class="form-group col-md-3" *ngIf="item.get('metActCondType').value === 'inline'">
                                <label for="metActCondId">Selector</label>
                                <input type="text" class="form-control" formControlName="metActCondId"
                                  placeholder="(.class, #id, element)">
                              </div>
                              <div class="form-group col-md-3">
                                <button type="button" class="btn btn-danger m-r-10 btn-rounded"
                                  (click)="removeMeterActItem(i)">-</button>
                                <button type="button" class="btn btn-primary btn-rounded" (click)="addMeterActItem()"
                                  *ngIf="i === (meterAct.controls.length - 1)">+</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Event Object Ends -->
                    </div>
                  </div>

                  <div class="card" *ngIf="showWhereAction">
                    <div class="card-body border-success">
                      <h4>What?</h4>
                      <!-- Action Object -->
                      <div formArrayName="actionObj">
                        <div *ngFor="let item of actionObj.controls; let i=index" [formGroupName]="i">
                          <div class="form-row row align-items-end">
                            <div class="form-group col-md-3">
                              <label for="finalActCondVal">Show Template</label>
                              <!-- <select formControlName="finalActCondVal" class="form-control">
                                <option selected value="hme-stickybar1">hme-stickybar1</option>
                                <option value="hme-stickybar2">hme-stickybar2</option>
                                <option value="hme-stickybar1-loggedin">hme-stickybar1-loggedin</option>
                                <option value="hme-stickybar2-loggedin">hme-stickybar2-loggedin</option>
                                <option value="hme-requirelogin">hme-requirelogin</option>
                                <option value="hme-requireentitlement">hme-requireentitlement</option>
                                <option value="hme-requireloginwithentitlement">hme-requireloginwithentitlement</option>
                                <option value="hme-requireentitlementpremium">hme-requireentitlementpremium</option>
                                <option value="hme-requireloginwithentitlementpremium">
                                  hme-requireloginwithentitlementpremium</option>
                                <option value="hme-requireentitlementemagazine">hme-requireentitlementemagazine</option>
                                <option value="hme-requireloginwithentitlement-emagazine">
                                  hme-requireloginwithentitlement-emagazine</option>
                              </select> -->
                              <pg-select style="width: 100%;" formControlName="finalActCondVal"
                                [PlaceHolder]="'Select Template'" AllowClear ShowSearch>
                                <pg-option *ngFor='let metActCondVal of metActCondValOption'
                                  Label="{{metActCondVal.label}}" Value="{{metActCondVal.value}}">
                                </pg-option>
                              </pg-select>
                            </div>
                            <div class="form-group col-md-3">
                              <label for="finalActCondType">Type</label>
                              <!-- <select formControlName="finalActCondType" class="form-control">
                                <option selected value="inline">Inline</option>
                                <option value="modal">Modal</option>
                              </select> -->
                              <pg-select style="width: 100%;" formControlName="finalActCondType"
                                [PlaceHolder]="'Select Type'" AllowClear ShowSearch>
                                <pg-option *ngFor='let metActCondType of metActCondTypeOption'
                                  Label="{{metActCondType.label}}" Value="{{metActCondType.value}}">
                                </pg-option>
                              </pg-select>
                            </div>
                            <div class="form-group col-md-3" *ngIf="item.get('finalActCondType').value === 'inline'">
                              <label for="finalActCondId">Selector</label>
                              <input type="text" class="form-control" formControlName="finalActCondId"
                                placeholder="(.class, #id, element)">
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Action Object Ends -->
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="form-row d-flex justify-content-end">
              <button type="submit" (click)="onExperienceFormSubmit()" class="btn btn-primary saveBtn m-r-10">Save
                Changes</button>
              <button class="btn btn-light cancelBtn" (click)="onExperienceFormReset()">Cancel</button>
            </div>
            <pre>{{experienceForm.value | json}}</pre>
          </pgcard>
        </div>
      </div>


    </div>
  </div>
</div>