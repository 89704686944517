<div class="padding-20">
    <div class="card " *ngIf="viewUsersPrmsn">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12"> 
                    <div class="d-flex flex-row align-items-center"> 
                    <span class="m-r-5">Brands: </span>

                    <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="selectedOption" (ngModelChange)="brandChange(selectedOption)"  ShowSearch >
                        <pg-option  [Label]="'All'" [Value]="'0'" ></pg-option>
                        <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select" [Label]="brand.brand_name" [Value]="brand.id" >
                        </pg-option>
                    </pg-select>
                    <!-- <select class="form-control m-l-10 m-r-auto" [style.width.px]="300" (change)="brandChange($event.target.value)" [(ngModel)]="selectedBrand">
                        <option value="0">All</option>
                        <option *ngFor='let brand of allActiveBrands;let i = index' value="{{brand.id}}">{{brand.brand_display_name}}</option>
                    </select> -->
                    </div>    
                </div>
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search User Id/Email" (input)="searchStr=$event.target.value" #searchFld aria-label="Search" (keyup.enter)="searchUserList(searchFld.value)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv text-right" *ngIf="addUsersPrmns">
                    <a class="btn btn-complete fs-15 padding-10" routerLink="/users/add" role="button">+ Add User</a>
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
        <div class="card-body" *ngIf="!showLoadingSpinner">
            <div class="table-responsive">
                <table class="table dataTable listUserTbl table-hover">
                    <thead>
                        <tr>
                            <th scope="sorting sortable" scope="col" (click)="sortUserTable($event, 'id')">User Id</th>
                            <th class="sorting_asc sortable" scope="col" (click)="sortUserTable($event, 'email')">Email</th>
                            <th *ngIf="selectedBrand == 0">Brand</th>
                            <th class="sorting sortable" scope="col" (click)="sortUserTable($event, 'first_name')">First Name</th>
                            <th class="sorting sortable" scope="col" (click)="sortUserTable($event, 'last_name')">Last Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!---------------------------No data available section----------------------->
                        <tr *ngIf="totalUsers == 0">
                            <td colspan="8" class="text-center">No data available.</td>
                        </tr>
                        <tr *ngFor="let user of userList; index as i">
                            <td>{{ user.id }}</td>
                            <td><a routerLink="/users/edit/{{user.id}}/{{user.brand_id}}" class="" title="View/Edit">{{ user.email }}</a></td>
                            <td *ngIf="selectedBrand == 0">{{ user.brand_name}}</td>
                            <td>{{ user.first_name }}</td>
                            <td>{{ user.last_name }}</td>
                            <td>
                                <span *ngIf="user.status == 1">Active</span>
                                <span *ngIf="user.status == 0">Inactive</span>
                            </td>
                            <td>
                                <a routerLink="/users/edit/{{user.id}}/{{user.brand_id}}" class="" title="View/Edit" *ngIf="editUsersPrmns">
                                    <i class="pg-icon text-info">edit</i>
                                </a>
                                <!--<a routerLink="/users/change-pwd/{{user.id}}/{{user.brand_id}}" class="" title="Change Password">
                                    <i class="bi bi-lock-fill editDataImg"></i>
                                </a>-->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>       
        </div>
        <div class="card-footer" *ngIf="!showLoadingSpinner && totalUsers > 0">
            <div class="totCount">{{totalUsers}} total</div>
            <nav>
                <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-end">
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link" (click)="setPage(1)">First</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                        <a class="page-link" (click)="setPage(pager.currentPage - 1)">❮</a>
                    </li>
                    <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{activeLnk:pager.currentPage === page}">
                        <a class="page-link" (click)="setPage(page)">{{page}}</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage(pager.currentPage + 1)">❯</a>
                    </li>
                    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                        <a class="page-link" (click)="setPage(pager.totalPages)">Last</a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
    <!-------No Access Block--------->
  <app-no-access *ngIf="!viewUsersPrmsn"></app-no-access>
</div>
    