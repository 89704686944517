import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportsComponent } from './reports/reports.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AllReportsComponent } from './all-reports/all-reports.component';
import { RevenueComponent } from './revenue/revenue.component';
import { ContentComponent } from './content/content.component';
import { DatawallComponent } from './datawall/datawall.component';
import { NewSubsComponent } from './new-subs/new-subs.component';
import { TotalLostSavesComponent } from './total-lost-saves/total-lost-saves.component';
import { FunnelReportComponent } from './funnel-report/funnel-report.component';
import { SubsMetricsComponent } from './subs-metrics/subs-metrics.component';
import { ScreenDashboardComponent } from './screen-dashboard/screen-dashboard.component';
import { MineDataComponent } from './mine-data/mine-data.component';

import { AuthGuard } from '../../common/auth.guard';

const routes: Routes = [
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        children: [
            {
              path: 'all',
              component: AllReportsComponent,
            },
            {
                path: 'subscription',
                component: SubscriptionComponent,
            },
            {
              path: 'datawall',
              component: DatawallComponent,
            },
            {
              path: 'newsubs',
              component: NewSubsComponent,
            },
            {
              path: 'revenue',
              component: RevenueComponent,
            },
            {
              path: 'totlostsave',
              component: TotalLostSavesComponent,
            },
            {
              path: 'content',
              component: ContentComponent,
            },
            {
              path: 'funnelreport',
              component: FunnelReportComponent,
            },
            {
              path: 'subsmetrics',
              component: SubsMetricsComponent,
            },
            {
              path: 'mineData',
              component: MineDataComponent,
            },
            {
              path: 'screenDashboard',
              component: ScreenDashboardComponent,
            },
            { path: '', redirectTo: 'all', pathMatch: 'full' }
        ]
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }