<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/users/all" href="javascript:void(0)">Datawall Users</a></li>
      <li class="breadcrumb-item"><a routerLink="/users/edit/{{customerId}}/{{brandId}}" href="javascript:void(0)" >View Profile</a></li>
      <li class="breadcrumb-item active">Order Details</li>
    </ol>
  </div>
</div>  
<div class="padding-20">
  <div class="card ">
    <div class="card-body">
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
      </div>
      <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
      <div class="form-group row" *ngIf="!showLoadingSpinner">
        <div class="col-md-12">
          <h6 class="form-sub-ttl">Order Details</h6>
        </div>
        <div class="table-responsive">
          <table class="table dataTable selTbl table-borderless">
            <thead  [ngStyle]="{background:'#f3f3f4'}">
              <tr>
                <th scope="col" class="bold fs-12">Order ID</th>
                <th scope="col" class="bold fs-12">Product</th>
                <th scope="col" class="bold fs-12">Plan ID</th>
                <th scope="col" class="bold fs-12">Contract Length</th>
                <th scope="col" class="bold fs-12">Trial Period</th>
                <th scope="col" class="bold fs-12">Discount</th>
                <th scope="col" class="bold fs-12">Promo Discount</th>
                <th></th>
                <!--<th scope="col">Promo Code</th>-->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{userOrderObj.id}}
                </td>
                <td>
                  {{userOrderObj.product_name}}
                </td>
                <td>
                  {{userOrderObj.plan_name}}
                </td>
                <td>
                  {{userOrderObj.contract_length_name}}
                </td>
                <td>
                  {{userOrderObj.frequency_name}}
                </td>
                <td>
                  {{userOrderObj.offset}}
                </td>
                <td>
                  <!--<a routerLink="/discounts/edit/{{userOrderObj.discount_id}}" *ngIf="userOrderObj.discount_id!=0">{{userOrderObj.discount_id}}</a>
                  <template *ngIf="userOrderObj.discount_id==0">-</template>-->
                  {{userOrderObj.display_name}}
                </td>
                <td>
                  <!--<a routerLink="/discounts/edit/{{userOrderObj.promo_discount_id}}" *ngIf="userOrderObj.promo_discount_id!=0">{{userOrderObj.promo_discount_id}}</a>
                  <template *ngIf="userOrderObj.promo_discount_id==0">-</template>-->
                  {{userOrderObj.promo_name}}
                </td>
                <!--<td>
                  ColMissing
                </td>-->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table dataTable selTbl table-borderless">
            <thead [ngStyle]="{background:'#f3f3f4'}">
              <tr>
                <th scope="col" class="bold fs-12">Base Price</th>
                <th scope="col" class="bold fs-12">Final Price</th>
                <th scope="col" class="bold fs-12">Start Date</th>
                <th scope="col" class="bold fs-12">End Date</th>
                <th scope="col" class="bold fs-12">Trial End Date</th>
                <th scope="col" class="bold fs-12">Discount End Date</th>
                <th scope="col" class="bold fs-12">Last Payment Date</th>
                <th scope="col" class="bold fs-12">Next Payment Date</th>
                <th scope="col" class="bold fs-12">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  ${{userOrderObj.base_price}}
                </td>
                <td>
                  ${{userOrderObj.final_price}}
                </td>
                <td>
                  {{userOrderObj.start_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{userOrderObj.end_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{userOrderObj.trial_end_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{userOrderObj.discount_end_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{userOrderObj.last_payment_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{userOrderObj.next_payment_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{commonService.getValueFromArr(orderStatusArr,userOrderObj.status,'name')}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table dataTable selTbl table-borderless">
            <thead [ngStyle]="{background:'#f3f3f4'}" >
              <tr>
                <th scope="col" class="bold fs-12">Cancel Request Date</th>
                <th scope="col" class="bold fs-12">Cancel Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{recentTransArr.cancel_req_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{recentTransArr.cancel_reason}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-start m-b-15" >
          <!-- <button type="button" class="btn btn-danger" (click)="cancelTrans()">Cancel</button> showCancelButton-->
            <div class="btn-group" dropdown *ngIf="!(!showCancelButton || (CancelRequestType == 1)) && cancelSubsPrmsn">
              <button id="button-animated" dropdownToggle type="button" class="btn btn-danger dropdown-toggle"
                aria-controls="dropdown-animated">
                Cancel
              </button>
              <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu cancel-menu" role="menu"
                aria-labelledby="button-animated">
                <li role="menuitem" *ngIf="allAccessPrmns"><a class="dropdown-item hover" value="1" (click)="openModalWithComponent($event)">Cancel
                    now</a></li>
                <li role="menuitem"><a class="dropdown-item hover" value="2" (click)="openModalWithComponent($event)"
                    *ngIf="!(!showCancelEndButton || (CancelRequestType == 2))">Cancel at the end of billing period</a>
                </li>
              </ul>
            </div>
          <ng-template #cancelRequest>Show all cancel request</ng-template>
          <button type="button" class="btn btn-warning hide" (click)="changeCard($event)" *ngIf="userOrderObj.status==1">Change card</button>
          <button type="button" class="btn btn-success me-md-2 hide" (click)="addNewCard($event)" *ngIf="userOrderObj.status==1">Add new card</button>
        </div>
        <h6 class="form-sub-ttl">Transactions</h6>
        <div class="table-responsive transition-tab">
          <table class="table dataTable selTbl table-borderless">
            <thead [ngStyle]="{background:'#f3f3f4'}">
              <tr>
                <th scope="col" class="bold fs-12">Transaction ID</th>
                <th scope="col" class="bold fs-12">Created</th>
                <th scope="col" class="bold fs-12">Payment ID</th>
                <th scope="col" class="bold fs-12">Payment Type</th>
                <th scope="col" class="bold fs-12">Amount</th>
                <th scope="col" class="bold fs-12">From</th>
                <th scope="col" class="bold fs-12">To</th>
                <th scope="col" class="bold fs-12">Type</th>
                <th scope="col" class="bold fs-12">Status</th>
                <th scope="col" class="bold fs-12">Payment Status</th>
                <th scope="col" class="bold fs-12"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let trans of userTransHistArr; let i = index;">
                <td>
                  {{trans.id}}
                </td>
                <td>
                  {{trans.created | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{trans.external_payment_id}}
                </td>
                <td>
                  {{trans.payment_type}}
                </td>
                <td>
                  ${{trans.amount}}
                </td>
                <td>
                  {{trans.last_payment_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{trans.next_payment_date | date: 'dd-MMM-yy'}}
                </td>
                <td>
                  {{trans.transaction_type}}
                </td>
                <td>
                  {{commonService.getValueFromArr(transStatusArr,trans.status,'name')}}
                </td>
                <td>
                  {{trans.external_trans_status}}
                </td>
                <td class="text-center">
                  <div class="btn-group dropup " dropdown *ngIf="trans.status==1" >
                    <button id="button-animated" dropdownToggle type="button" class="btn dropdown-toggle dropup invoice"
                      aria-controls="dropdown-animated1" aria-haspopup="true" aria-expanded="false" >
                    </button>
                    <ul id="dropdown-animated1" *dropdownMenu class="dropdown-menu dropup dropdown-menu-right trans-action" role="menu"
                      aria-labelledby="button-animated">
                      <li role="menuitem"><a class="dropdown-item hover" value="1" (click)="viewInvoice(trans.id)" *ngIf="trans.invoice_path">Download Invoice</a></li>
                      <li role="menuitem" *ngIf="trans.transaction_type!='Refund' && refundSubsPrmns"><a class="dropdown-item hover" value="2" 
                        (click)="refundPayment(trans.id)">Refund Payment</a>
                      </li>
                    </ul>
                  </div>
                  <!--<a href="javascript:;" (click)="viewInvoice(trans.user_license_id)">View Invoice</a>-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
