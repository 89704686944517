
  <div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="changePasswordPrmns" >  
    <div class="row">
      <div class="col-lg-9 col-xl-7 mx-auto">
        <div class="card ">
          <div class="card-body">
              <h5 class="mb-2" >Change Password</h5>
              <!-- <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div> -->
              <form *ngIf="!showLoadingSpinner" class="changePwdForm mx-auto" id="form-project" [formGroup]="changePwdForm" (ngSubmit)="changePwdFormSubmit()" novalidate>
                  <div class="">

                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('oldPwd').invalid && (getFormControl('oldPwd').dirty || getFormControl('oldPwd').touched)">
                          <label>Old Password</label>
                          <input type="password" class="form-control" formControlName="oldPwd"  placeholder="Enter old password" >
                          <div class="invalid-tooltip" class="invalid-tooltip" *ngIf="getFormControl('oldPwd').invalid && (getFormControl('oldPwd').dirty || getFormControl('oldPwd').touched)">
                            Old Password
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('newPwd').invalid && (getFormControl('newPwd').dirty || getFormControl('newPwd').touched)">

                          <label>New Password</label>
                          <input type="password" class="form-control" formControlName="newPwd"  placeholder="Enter new password" >
                          <div class="invalid-tooltip" class="invalid-tooltip" *ngIf="getFormControl('newPwd').invalid && (getFormControl('newPwd').dirty || getFormControl('newPwd').touched)">
                            New Password is required
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('confirmPwd').invalid && (getFormControl('confirmPwd').dirty || getFormControl('confirmPwd').touched)">
                          <label>Confirm Password</label>
                          <input type="password" class="form-control" formControlName="confirmPwd"  placeholder="Confirm password" >
                          <div class="invalid-tooltip" class="invalid-tooltip" *ngIf="getFormControl('confirmPwd').invalid && (getFormControl('confirmPwd').dirty || getFormControl('confirmPwd').touched)">
                            Confirm Password is required
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div class="row p-t-20">
                    <div class=" col-md-12 text-right ">
                      <button type="submit" [disabled]="loading || disableBtn" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10" >Done</button>
                      <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
  
                    </div>
                  </div>
  
              </form>

              <div *ngFor="let alert of alerts" class="p-t-20">
                <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
              </div>
          </div>
        </div>
      </div>
    </div>  
  </div>    
  