import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DashboardService } from '../../services/dashboard.service';
import { Constants } from '../../common/constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  selectedOption = '0';
  allActiveBrands: any[];
  alerts: any[];
  selBrand: number = 0;
  showLoadingSpinner = true;
  editWidgetForm: FormGroup;
  lastSyncTime;
  noDataAvailable: boolean = false;
  //Role based permission check
  viewDashboardPrmsn: boolean = true;
  bsValue = [];
  startDate;
  endDate;
  newPurchases: string = "0";
  newRegUsers: number = 0;
  newSubs: number = 0;
  paymentFails: string = "0";
  totalUsers: number = 0;
  unSubs: number = 0;
  transData: [] = [];
  datePlaceholder: string = '';

  ranges: any = [
    {
      value: [new Date(), new Date()],
      label: 'Today'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))],
      label: 'Yesterday'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()],
      label: 'Last 7 Days'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()],
      label: 'Last 30 Days'
    }, {
      value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
      label: 'This Month'
    }, {
      value: [new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
      label: 'Last Month'
    }];

  constructor(private commonService: CommonService, private cdr: ChangeDetectorRef, private titleService: Title, private dashboardService: DashboardService) {
    /****************Permission check based on role******************/
    this.viewDashboardPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_DASHBOARD]);
    this.commonService.subNavSelect(Constants.NAV_DASHBOARD);
    this.titleService.setTitle("Dashboard");
    this.commonService.setTitle("Dashboard");
    this.getAllActiveBrands();

  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if (this.viewDashboardPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allActiveBrands = res['result'];
            this.bsValue = [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()];
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }

  /**********************************Date range filter change event*********************/
  dateRangeChange($event) {
    if ($event[0] & $event[1]) {
      const startDate = new Date($event[0]);
      const endDate = new Date($event[1]);
      startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
      this.startDate = startDate.toISOString().split('T')[0];
      this.endDate = endDate.toISOString().split('T')[0];
      this.getDashboardData(false);
    }
  }




  /**********************************Brand filter change event*********************/
  brandFilterChange(val) {
    this.selBrand = val;
    this.getDashboardData(false);
  }

  formatDate(input: Date | string): string {
    let date: Date;

    // Convert input to Date object if it's a string
    if (typeof input === 'string') {
      date = new Date(input);
    } else {
      date = input;
    }

    // Check if date is a valid Date object
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.error("Invalid input: Expected a valid Date object.");
      return "Invalid Date";
    }

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };

    return date.toLocaleDateString('en-US', options);
  }
  /**********************************API Method to Get dashboard data*********************/
  getDashboardData(isRefreshed) {
    if (this.viewDashboardPrmsn) {
      this.showLoadingSpinner = true;
      this.dashboardService.getDashboardData(this.selBrand, this.startDate, this.endDate, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            let dashboardData = res['result'];
            this.newPurchases = dashboardData['newPurchases'];
            this.newRegUsers = dashboardData['newRegUsers'];
            this.newSubs = dashboardData['newSubs'];
            this.paymentFails = dashboardData['paymentFails'];
            this.lastSyncTime = res['result'].memcached_time
            this.totalUsers = dashboardData['totalUsers'];
            this.unSubs = dashboardData['unSubs'];
            this.transData = dashboardData['recent_transaction'];
            this.datePlaceholder = this.formatDate(this.startDate) + ' - ' + this.formatDate(this.endDate)

          } else {
            this.alerts = [{
              type: 'danger',
              msg: Constants.DASH_DATA_FAILURE_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
          this.showLoadingSpinner = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showLoadingSpinner = false;
        }
      );
    }
  }



}
