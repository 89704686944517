import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { PlansService } from '../../../services/plans.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-all-plans',
  templateUrl: './all-plans.component.html',
  styleUrls: ['./all-plans.component.scss']
})
export class AllPlansComponent implements OnInit {
  selectedOption = '0';
  rows: any[];
  tmp: any[];
  activeData: [];
  inactiveData: [];
  selectedBrand: number;//PMV temporary
  allActiveBrands: any[];
  statusDropDownLbl: string = "Active Plans";
  selStatus: number = Constants.STATUS_ACTIVE;
  alerts: any[];
  showLoadingSpinner = true;
  //Role based permission check
  viewPlansPrmsn: boolean = true;
  addPlansPrmns: boolean = true;
  editPlansPrmns: boolean = true;

  constructor(private commonService: CommonService,
    private plansService: PlansService,
    private cdr: ChangeDetectorRef) {
    /****************Permission check based on role******************/
    this.viewPlansPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_PLANS, Constants.PERMISSION_ADD_PLANS, Constants.PERMISSION_EDIT_PLANS]);
    this.addPlansPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_PLANS]);
    this.editPlansPrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_PLANS]);
    this.getAllActiveBrands();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if (this.viewPlansPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allActiveBrands = res['result'];
            this.selectedBrand = 0;//All
            this.getAllPlans(this.selectedBrand);
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }
  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    //this.userList = [];
    this.getAllPlans(brandId);
  }
  /**********************************API Method to Get All the Plans*********************/
  getAllPlans(brandId) {
    if (this.viewPlansPrmsn) {
      this.plansService.getAllPlans(brandId).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.rows = res['result'];
            this.tmp = res['result'];
            this.fetchPlansData(this.selStatus);
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
          this.showLoadingSpinner = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showLoadingSpinner = false;
        }
      );
    }
  }

  /******************************Method to filter the status based plans data***************************/
  fetchPlansData(status: number) {
    this.rows = this.tmp;
    const temp = this.rows.filter(function (d) {
      return d.is_active == status;
    });
    this.rows = temp;
  }

  /******************************Method to set selected plan status dropdown***************************/
  filterStaus(status: number) {
    if (status == 1) {
      this.selStatus = status;
      this.statusDropDownLbl = "Active Plans"
      this.fetchPlansData(status);
    } else {
      this.selStatus = 0;
      this.statusDropDownLbl = "Inactive Plans"
      this.fetchPlansData(status);
    }
  }

  /******************************Method to update Plan Status (Active/Inactive)***************************/
  updatePlanStatus(planId: number, event: any) {
    if (this.editPlansPrmns) {
      const isChecked = this.commonService.getStatusValue(event.target.checked);
      this.selectedBrand = 0;//All
      this.plansService.updatePlanStatus(planId, isChecked).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {//Success
            this.alerts = [{
              type: 'success',
              msg: Constants.DEL_PLAN_SUCCESS_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          } else {
            let errorMsg = Constants.DEL_PLAN_FAILURE_MSG;
            let proArr = [];
            if (res['status'] == 2) {
              proArr = this.commonService.getIdsFromArr(proArr, res.result);
              errorMsg = Constants.DEL_PLAN_FAILURE_ALREADY_MAPPED_MSG + " '" + proArr + "'";
            }
            this.alerts = [{
              type: 'danger',
              msg: errorMsg,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
          this.getAllPlans(this.selectedBrand);
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DEL_PLAN_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.getAllPlans(this.selectedBrand);
        });
    }
  }

  /******************************Method to filter plan name column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    if (val) {
      const temp = this.rows.filter(function (d) {
        return d.plan_name.toLowerCase().indexOf(val) !== -1 || d.plan_display_name.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
    } else {
      this.fetchPlansData(this.selStatus);
    }
  }
}
