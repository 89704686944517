import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { ActivatedRoute,Router} from '@angular/router';
import { SiteLicensesService } from '../../../services/site-licenses.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-edit-site-licensee',
  templateUrl: './edit-site-licensee.component.html',
  styleUrls: ['./edit-site-licensee.component.scss']
})
export class EditSiteLicenseeComponent implements OnInit {
  allActiveBrands: any[];
  editNewLicenseeForm: FormGroup;
  payDetailsForm: FormGroup;
  disableBtn = false;
  submitted = false;
  loading = false;
  payLoading = false;
  alerts: any[];
  showLoadingSpinner = true;
  licenseeId : string;
  invalidEmails : any = [];
  invalidDomains : any = [];
  validEmails : any = [];
  validDomains : any = [];
  tmpValidEmails : any = [];
  tmpValidDomains : any = [];
  selContractType : string = "Email";//Email
  seatLimExceedError : string = "";
  domainExistsError : string = "";
  currenciesFltrArr: any[];
  updateEmailArr:any = [];
  updateDomainArr:any = [];
  domainUsersArr:any = [];
  isPaid : boolean = false;
  selDomainId;
  //Role based permission check
  viewStLicensePrmsn : boolean = true;
  editStLicensePrmns : boolean = true;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private siteLicensesService: SiteLicensesService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,) {
      /****************Permission check based on role******************/
      this.viewStLicensePrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_SITE_LICENSES]);
      this.editStLicensePrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_SITE_LICENSES]);
    }


  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
    this.licenseeId = this.route.snapshot.paramMap.get('id');
    this.getAllActiveBrands();
    this.getAllActiveCurrencies();
    /****************Add New Plan Form Validation****************** */
    this.editNewLicenseeForm = this.formBuilder.group({
      licenseeName: ['', [Validators.required]],
      licenseeDesc: [''],
      repEmail: ['', [Validators.required]],
      contractId: [''],
      invUrl: [''],
      brand: ['', [Validators.required]],
      contractType: ['', [Validators.required]],
      noOfSeats: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      userEmail: [''],
      userDomain: [''],
      isPaid: [false]
    })
    this.payDetailsForm = this.formBuilder.group({
      currency: ['AED'],
      finalPrice: [''],
      payLink: [''],
    })
    this.getLicenseeDetail(this.licenseeId);
  }


  // convenience getter for easy access to form fields
  get f() { return this.editNewLicenseeForm.controls; }
  

  // convenience getter for easy access to form fields
  get p() { return this.payDetailsForm.controls; }
  getFormControl(name) {
    return this.editNewLicenseeForm.controls[name];
  }

  isFieldValid(field: string) {
    return !this.editNewLicenseeForm.get(field).valid && this.editNewLicenseeForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  } 
  /**************************Method to get licensee details to prefill the form*******************************/
  getLicenseeDetail(licenseeId) {
    if(this.editStLicensePrmns || this.viewStLicensePrmsn) {
      this.siteLicensesService.getLicenseeDetail(licenseeId).then(
        res => {
          if(res['code']==1 && res['status']==1) {//success
              this.showLoadingSpinner = false;
              let data = res.result;
              this.selContractType = data['contract_type'];
              this.editNewLicenseeForm.controls['licenseeName'].setValue(data['licensee_name']);
              this.editNewLicenseeForm.controls['licenseeDesc'].setValue(data['licensee_desc']);
              this.editNewLicenseeForm.controls['repEmail'].setValue(data['rep_email']);
              this.editNewLicenseeForm.controls['brand'].setValue(data['brand_id']);
              this.editNewLicenseeForm.controls['contractType'].setValue(data['contract_type']);
              this.editNewLicenseeForm.controls['noOfSeats'].setValue(parseInt(data['seats']));
              this.editNewLicenseeForm.controls['startDate'].setValue(this.commonService.formatDate(data['start_date']));
              this.editNewLicenseeForm.controls['endDate'].setValue(this.commonService.formatDate(data['end_date']));
              this.editNewLicenseeForm.controls['contractId'].setValue(data['contract_id']);
              this.editNewLicenseeForm.controls['invUrl'].setValue('https://'+data['domain_name']+'/?contractId='+data['contract_id']);
              this.isPaid = this.commonService.setStatusValue(data['is_paid']);
              this.editNewLicenseeForm.controls['isPaid'].setValue(this.isPaid);
              this.validEmails = data['user_emails'];
              this.tmpValidEmails = data['user_emails'];
              this.validDomains = data['comp_domains'];
              this.tmpValidDomains = data['comp_domains'];
              this.payDetailsForm.controls['currency'].setValue(data['currency']);
              this.payDetailsForm.controls['finalPrice'].setValue(data['amount']);
              this.payDetailsForm.controls['payLink'].setValue(data['link']);
          } else {
            this.alerts = [{
              type: 'danger',
              msg: Constants.VIEW_LICENSEE_FAILURE_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        },
        error => {
            this.alerts = [{
              type: 'danger',
              msg: Constants.VIEW_LICENSEE_FAILURE_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        });
      }
  }

 
  /*******************************Method to submit add new plan form***************************************** */
  editNewLicenseeFormSubmit() {
    if(this.editStLicensePrmns) {
      this.submitted = true;
      // stop here if form is invalid
      for (const i in this.editNewLicenseeForm.controls) {
        this.editNewLicenseeForm.controls[i].markAsDirty();
      }
      if (this.editNewLicenseeForm.invalid) {
          return;
      }
      let dataObj = { 
        'licensee_name' : this.f.licenseeName.value, 
        'licensee_desc' : this.f.licenseeDesc.value, 
        'rep_email': this.f.repEmail.value, 
        'brand_id': this.f.brand.value, 
        'contract_type': this.f.contractType.value, 
        'seats': this.f.noOfSeats.value, 
        'start_date': this.f.startDate.value, 
        'end_date': this.f.endDate.value, 
        'is_paid': this.commonService.getStatusValue(this.f.isPaid.value),
        'user_emails' : this.selContractType == "Email" ? this.updateEmailArr : [],
        'comp_domains' : this.selContractType == "Domain" ? this.updateDomainArr : []
      };
      this.siteLicensesService.updateLicensee(dataObj,this.licenseeId).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.alerts = [{
                type: 'success',
                msg: Constants.EDIT_LICENSEE_SUCCESS_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
              setTimeout(()=>{
                this.getLicenseeDetail(this.licenseeId);
              }, 1000);
              this.editNewLicenseeForm.controls['userEmail'].setValue("");
              this.editNewLicenseeForm.controls['userDomain'].setValue("");
              this.updateEmailArr = [];
            this.validEmails = [];
            this.invalidEmails = [];
            this.seatLimExceedError = "";
            this.updateDomainArr = [];
            this.invalidDomains = [];
            this.validDomains = [];
            this.domainUsersArr = [];
            } else {
              this.alerts = [{
                type: 'danger',
                msg: Constants.EDIT_LICENSEE_FAILURE_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.EDIT_LICENSEE_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
            this.loading = false;
        });
      }
  }

  /*******************************Method to submit add new plan form***************************************** */
  payDetailsFormSubmit() {
    if(this.editStLicensePrmns) {
      this.submitted = true;
      // stop here if form is invalid
      if (this.payDetailsForm.invalid) {
          return;
      }
      let dataObj = { 
        'currency' : this.p.currency.value, 
        'amount' : this.p.finalPrice.value, 
        'brand_id' : this.f.brand.value, 
      };
      this.siteLicensesService.generatePayLink(dataObj,this.licenseeId).then(
        res => {
            this.payLoading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.alerts = [{
                type: 'success',
                msg: Constants.PAYLINK_SUCCESS_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
              setTimeout(()=>{
                this.getLicenseeDetail(this.licenseeId);
              }, 1000);
            } else {
              this.alerts = [{
                type: 'danger',
                msg: Constants.PAYLINK_FAILURE_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.PAYLINK_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
            this.payLoading = false;
        });
      }
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if(this.editStLicensePrmns || this.viewStLicensePrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
          this.allActiveBrands = res['result'];
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

  /**********************************API Method to Get All active Currencies*********************/
  getAllActiveCurrencies() {
    if(this.editStLicensePrmns || this.viewStLicensePrmsn) {
      this.commonService.getAllActiveCurrencies(Constants.STATUS_ACTIVE).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
          this.currenciesFltrArr = res['result'];
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

  /**********************************API Method to Send Invitation to the User*********************/
  sendUserInv(email,id=0) {
    if(this.editStLicensePrmns) {
      let dataObj = { 
        'email' : email, 
        'id' : id,
      };
      this.siteLicensesService.sendUserInvitation(dataObj,this.licenseeId).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.alerts = [{
                type: 'success',
                msg: Constants.INV_USER_SUCCESS_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
              this.getLicenseeDetail(this.licenseeId);
              this.editNewLicenseeForm.controls['userEmail'].setValue("");
            } else {
              this.alerts = [{
                type: 'danger',
                msg: Constants.INV_USER_FAILURE_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.INV_USER_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
            this.loading = false;
        });
      }
  }

  /**********************************API Method to View the User List based on Domain*********************/
  viewInvUsers(id) {
    if(this.editStLicensePrmns || this.viewStLicensePrmsn) {
      this.selDomainId = id;
      this.domainUsersArr = [];
      this.siteLicensesService.viewInvUsers(this.licenseeId,id).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.domainUsersArr = res['result'];
            } else {
              this.alerts = [{
                type: 'danger',
                msg: Constants.VIEW_DOMAIN_USERS_FAILURE_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.VIEW_DOMAIN_USERS_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        });
      }
  }

  /**********************************Method to Remove Domain Users*********************/
  deleteDomainUser(id, email) {
    if(this.editStLicensePrmns) {
      let dataObj = { 
        'id' : id,
        'brand_id' : this.f.brand.value,
        'email' : email
      };
      this.siteLicensesService.deleteDomainUser(this.licenseeId,id,dataObj).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.viewInvUsers(this.selDomainId);
            } else {
              this.alerts = [{
                type: 'danger',
                msg: Constants.VIEW_DOMAIN_USERS_FAILURE_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.VIEW_DOMAIN_USERS_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        });
      }
  }

/**********************************Method to Validate Email Adrress*********************/
validateMultipleEmails(emailInput) {
    // Get value on emails input as a string
    var emails = emailInput;
    this.invalidEmails = [];
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let obj = {};
    let email;
    for (var i = 0; i < emails.length; i++) {
        // Trim whitespaces from email address
        obj = {};
        email = emails[i].trim();
        if(this.validEmails.find(o => o.email === email)) {
          this.disableBtn = true;
          this.seatLimExceedError = "Email already exists - "+email;
        } else {
          // Check email against our regex to determine if email is valid
          if( email == "" || ! regex.test(email)){
              this.invalidEmails.push(email);
          } else {
            obj['email'] = email;
            obj['invitation_status'] = 0;
            this.setUpdateInvArr(0, email, Constants.VERSION_ADD, 0);
            this.validEmails.push(obj);
          }
        }
    }
    if(this.invalidEmails != 0) {
      this.disableBtn = true;
       return false ;
    }
    else { 
      this.disableBtn = false;
      return true;
    }
  }

  /**********************************Method to add user emails*********************/
  addUserEmail() {
    let userEmails = Array.from(new Set(this.f.userEmail.value.split(",")));
    if(this.f.noOfSeats.value) {
      if(userEmails.length <= this.f.noOfSeats.value && this.validEmails.length < this.f.noOfSeats.value) {
        this.seatLimExceedError = "";
        this.validateMultipleEmails(userEmails);
      } else {
        this.disableBtn = true;
        this.seatLimExceedError = "User count exceeds the number of seats booked.";
      }
    } else {
      this.disableBtn = true;
      this.seatLimExceedError = "Please fill the number of seats and click on add again.";
    }
  }

  /**********************************Method to Remove Email Adrress*********************/
  deleteUserEmailRow(index:any, email, id=0, invStatus) {
    this.validEmails.splice(index, 1);
    if(id!=0) {
      this.setUpdateInvArr(id, email, Constants.VERSION_EDIT, invStatus);
    }

  }

  setUpdateInvArr(id:number, str:string, version:number, invStatus =0) {
      let editInvObj = {};
      editInvObj['str'] = str;
      editInvObj['version'] = version;
      editInvObj['id'] = id;
      editInvObj['invStatus'] = invStatus;
      if(this.selContractType == "Email") {
       this.updateEmailArr.push(editInvObj);
      } else {
        this.updateDomainArr.push(editInvObj);
      }
  }

  /**********************************Method to Validate Email Domains*********************/
  validateMultipleDomains(domainInput) {
    // Get value on domain input as a string
    var domains = domainInput;
    this.invalidDomains = [];
    // Split string by comma into an array
    domains = domains.split(",");
    var regex = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;
    let obj = {};
    let domain;
    this.domainExistsError = "";
    for (var i = 0; i < domains.length; i++) {
        // Trim whitespaces from email address
        obj = {};
        domain = domains[i].trim();
        if(this.validDomains.find(o => o.domain === domain)) {
          this.disableBtn = true;
          this.domainExistsError = "Domain already exists - "+domain;
        } else {
          // Check domain against our regex to determine if domain is valid
          if( domain == "" || ! regex.test(domain)){
            this.invalidDomains.push(domain);
          } else {
            obj['domain'] = domain;
            this.setUpdateInvArr(0, domain, Constants.VERSION_ADD, 0);
            this.validDomains.push(obj);
          }
        }
    }
    if(this.invalidDomains != 0) {
      this.disableBtn = true;
      return false;
    }else {
      this.disableBtn = false;
      return true;
    }
  }

  /**********************************Method to Add Email Domains*********************/
  addUserDomain() {
    let userDomains = this.f.userDomain.value;
    if(userDomains) 
      this.validateMultipleDomains(userDomains);
  }

  /**********************************Method to Remove Email Domain*********************/
  deleteUserDomainRow(index:any, domain, id=0) {
    this.validDomains.splice(index, 1);
    if(id!=0) {
      this.setUpdateInvArr(id, domain, Constants.VERSION_EDIT, 0);
    }
  }

  /**********************************Method to Show/Hide Email/Type add fields based on change*********************/
  onContractTypeChange() {
    this.selContractType = this.f.contractType.value;
  }

  /**********************************Event to copy payment link*********************/
  copyToClipboard (url) {
    console.log(url)
    if(url) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = url;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      alert('Copied');
    }
  };

  generatePaymentLink() {

  }

  getInvStatusVal(status) {
    return status==2 ? 'Active' : 'Pending';
  }

  getDomainStatusVal(status) {
    return status==1 ? 'Active' : 'Revoked';
  }

   /******************************Method to show/hide Add Email/Domain fields based on payment status change***************************/
   payStatusChange(event: any) {
    const isChecked = event.target.checked;
    this.isPaid = isChecked;
   }
}
