<div class="container-fluid p-l-25 p-r-25 p-t-0 p-b-25 sm-padding-10 sm-gutter">
    <div class="card card-transparent">
      <div class="card-header mb-3 p-0">
        <p class="mb-4">This report provides a comprehensive overview of essential paywall performance indicators.  It is designed to help stakeholders understand revenue trends, assess customer retention, and evaluate overall subscription performance. By analyzing these critical metrics, businesses can make informed decisions to optimize their paywall strategy, enhance user engagement, and drive sustainable growth.</p>
      </div>
      <div class="card-body p-0">

        <pgcard [Maximize]="false" (onRefresh)="getSubsKeyMetricsMonthlyMRRData(true)" [Loading]="showMonthlyMrrldngSpnr">
          <ng-template #CardTitle><h4>Monthly Recurring Revenue (MRR)</h4></ng-template>
          <div class="card-collapsed">
            <p>Monthly recurring revenue (MRR) is the amount of revenue you get from your customers on a monthly basis.</p>
              <br>
              <div class="row align-items-center">
                <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
                  <div class="d-flex align-items-center">
                    <span class="m-r-5">Brands: </span>
                    <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="monthlyMrrBrand" (ngModelChange)="monthlyMrrBrandFltrChage(monthlyMrrBrand)" ShowSearch>
                      <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                      <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
                    </pg-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-4 mb-4 chart-container">
                <div *ngIf="showMonthlyMrrldngSpnr" class="loadingSpinner"></div>
                <canvas #monthlyMrrCanvas width="auto" height="75">{{ monthlyMrrChart }}</canvas>
                <div class="widget-11-table auto-overflow m-t-10" *ngIf="!noMonthlyMrrDataAvailable && !showMonthlyMrrldngSpnr">
                  <div class="table-container">
                     <table class="table table-condensed1 table-hover">
                         <thead>
                           <tr>
                              <th>Brands</th>
                              <th *ngFor="let month of mrrMonthTblMonths">{{ month }}</th>
                           </tr>
                         </thead>
                         <tbody>
                            <tr *ngFor="let brand of mrrMonthTblBrands">
                              <td>{{ getBrandDisplayName(brand) }}</td>
                              <td *ngFor="let month of mrrMonthTblMonths">${{ mrrMonthTblData[brand][month] | number:'1.2-2' || '-' }}</td>
                            </tr>
                         </tbody>
                       </table>
                  </div>
                 </div>
                <h4 *ngIf="noMonthlyMrrDataAvailable && !showMonthlyMrrldngSpnr" class="text-center">No data available for the selected brand.</h4>
              </div>
            </div>
        </pgcard>

        <pgcard [Maximize]="false" (onRefresh)="getSubsKeyMetricsAnnualMRRData(true)" [Loading]="showAnnualMrrldngSpnr">
          <ng-template #CardTitle><h4>Annual Recurring Revenue (ARR)</h4></ng-template>
          <div class="card-collapsed">
            <p>Annual Recurring Revenue, is your monthly recurring revenue (MRR) annualised. It’s a prediction of how much revenue your company will generate annually based on your current MRR.
              <br/>Annual Recurring Revenue = MRR of 12 months</p>
              <br>
              <div class="row align-items-center">
                <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
                  <div class="d-flex align-items-center">
                    <span class="m-r-5">Brands: </span>
                    <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="annualMrrBrand" (ngModelChange)="annualMrrBrandFltrChage(annualMrrBrand)" ShowSearch>
                      <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                      <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
                    </pg-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-4 mb-4 chart-container">
                <div *ngIf="showAnnualMrrldngSpnr" class="loadingSpinner"></div>
                <canvas #annualMrrCanvas width="auto" height="75">{{ annualMrrChart }}</canvas>
                <div class="widget-11-table auto-overflow m-t-10" *ngIf="!noAnnualMrrDataAvailable && !showAnnualMrrldngSpnr">
                  <div class="table-container">
                     <table class="table table-condensed1 table-hover">
                         <thead>
                           <tr>
                              <th>Brands</th>
                              <th *ngFor="let year of mrrAnnualTblYears">{{ year }}</th>
                           </tr>
                         </thead>
                         <tbody>
                            <tr *ngFor="let brand of mrrAnnualTblBrands">
                              <td>{{ getBrandDisplayName(brand) }}</td>
                              <td *ngFor="let year of mrrAnnualTblYears">${{ mrrAnnualTblData[brand][year] | number:'1.2-2' || '-' }}</td>
                            </tr>
                         </tbody>
                       </table>
                  </div>
                 </div>
                <h4 *ngIf="noAnnualMrrDataAvailable && !showAnnualMrrldngSpnr" class="text-center">No data available for the selected brand.</h4>
              </div>
            </div>
        </pgcard>

        <pgcard [Maximize]="false" collapseState="active" (onRefresh)="getSubsKeyMetricsAvgRevData(true)" [Loading]="showAvgRevldngSpnr">
          <ng-template #CardTitle><h4>Average Revenue Per User (ARPU)</h4></ng-template>
          <div class="card-collapsed">
            <p>Average revenue per user (ARPU) is the average amount of revenue you earn from each of your active customers monthly. Note that ARPU is calculated based on active customers, not total users.
              <br/>ARPU = Monthly Recurring Revenue / Active Customers
          </p>
              <br>
              <div class="row align-items-center">
                <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
                  <div class="d-flex align-items-center">
                    <span class="m-r-5">Brands: </span>
                    <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="arpuBrand" (ngModelChange)="arpuBrandFltrChage(arpuBrand)" ShowSearch>
                      <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                      <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
                    </pg-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-4 mb-4 chart-container">
                <div *ngIf="showAvgRevldngSpnr" class="loadingSpinner"></div>
                <div class="widget-11-table auto-overflow" *ngIf="!noAvgRevDataAvailable && !showAvgRevldngSpnr">
                 <div class="table-container">
                    <table class="table table-condensed1 table-hover">
                        <thead>
                          <tr>
                            <th>Brand</th>
                            <th>Month</th>
                            <th>User Count</th>
                            <th>Total Revenue</th>
                            <th>ARPU</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of avgRevTblData; let i = index">
                            <td>{{getBrandDisplayName(item.brand_name)}}</td>
                            <td>{{item.month}}</td>
                            <td>{{item.user_count | number}}</td>
                            <td>${{item.total_revenue | number:'1.2-2' }}</td>
                            <td>{{item.arpu | number:'1.2-2'}}</td>
                          </tr>
                        </tbody>
                      </table>
                 </div>
                </div>
                <h4 *ngIf="noAvgRevDataAvailable && !showAvgRevldngSpnr" class="text-center">No data available for the selected brand.</h4>
              </div>
            </div>
        </pgcard>


        <pgcard [Maximize]="false" collapseState="active" (onRefresh)="getSubsKeyMetricsChurnRateData(true)" [Loading]="showCustChurnRateldngSpnr">
          <ng-template #CardTitle><h4>Customer Churn Rate</h4></ng-template>
          <div class="card-collapsed">
            <div>Churn is the percentage of customers lost during a period (usually monthly).
              <ul>
                <li><strong>Total customer churn:</strong> Percentage of total customers lost</li>
                <li><strong>Voluntary customer churn:</strong> Percentage of customers who Voluntarily Cancelled their subscriptions</li>
                <li><strong>Involuntary customer churn:</strong> Percentage of customers who Involuntarily Cancelled their subscriptions</li>
              </ul>
              Revenue churn rate, also known as MRR churn rate, measures the rate at which a company loses revenue as a result of churned customers or downgraded subscriptions.</div>
              <br>
              <div class="row align-items-center">
                <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
                  <div class="d-flex align-items-center">
                    <span class="m-r-5">Brands: </span>
                    <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="custChurnRateBrand" (ngModelChange)="custChurnRtBrandFltrChage(custChurnRateBrand)" ShowSearch>
                      <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                      <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
                    </pg-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-4 mb-4 chart-container">
                <div *ngIf="showCustChurnRateldngSpnr" class="loadingSpinner"></div>
                <canvas #custChurnRateCanvas width="auto" height="75">{{ custChurnRateChart }}</canvas>
                <div class="widget-11-table auto-overflow m-t-10" *ngIf="!noCustChurnRateDataAvailable && !showCustChurnRateldngSpnr">
                  <div class="table-container">
                     <table class="table table-condensed1 table-hover">
                         <thead>
                           <tr>
                             <th>Brand</th>
                             <th>Month</th>
                             <th>Total Customers</th>
                             <th>Churned Customers</th>
                             <th>Voluntary Churned Customers</th>
                             <th>Involuntary Churned Customers</th>
                             <th>Total Churn Rate (%)</th>
                             <th>Voluntary Churn Rate (%)</th>
                             <th>Involuntary Churn Rate (%)</th>
                           </tr>
                         </thead>
                         <tbody>
                           <tr *ngFor="let item of custChurnRateTblData; let i = index">
                             <td>{{getBrandDisplayName(item.brand_name)}}</td>
                             <td>{{item.month}}</td>
                             <td>{{item.total_customers | number}}</td>
                             <td>{{item.churned_customers | number}}</td>
                             <td>{{item.voluntary_churned_customers | number}}</td>
                             <td>{{item.involuntary_churned_customers | number}}</td>
                             <td>{{item.total_churn_rate | number:'1.2-2'}}</td>
                             <td>{{item.voluntary_churn_rate | number:'1.2-2'}}</td>
                             <td>{{item.involuntary_churn_rate | number:'1.2-2'}}</td>
                           </tr>
                         </tbody>
                       </table>
                  </div>
                 </div>
                <h4 *ngIf="noCustChurnRateDataAvailable && !showCustChurnRateldngSpnr" class="text-center">No data available for the selected brand.</h4>
              </div>
            </div>
        </pgcard>

        <pgcard [Maximize]="false" collapseState="active" (onRefresh)="getSubsKeyMetricRevChurnRateData(true)" [Loading]="showRevChurnRateldngSpnr">
          <ng-template #CardTitle><h4>Revenue churn rate</h4></ng-template>
          <div class="card-collapsed">
            <div>Churn is the percentage of Revenue lost during a period (usually monthly).
              <ul>
                <li><strong>Total Revenue churn:</strong> Percentage of revenue total lost </li>
                <li><strong>Voluntary Revenue churn:</strong> Percentage of revenue lost by voluntary customer churns</li>
                <li><strong>Involuntary Revenue churn:</strong> Percentage of revenue lost by involuntary customer churns</li>
              </ul>
              Revenue churn rate, also known as MRR churn rate, measures the rate at which a company loses revenue as a result of churned customers or downgraded subscriptions.</div>
              <br>
              <div class="row align-items-center">
                <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
                  <div class="d-flex align-items-center">
                    <span class="m-r-5">Brands: </span>
                    <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="revChurnRateBrand" (ngModelChange)="revChurnRtBrandFltrChage(revChurnRateBrand)" ShowSearch>
                      <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                      <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
                    </pg-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-4 mb-4 chart-container">
                <div *ngIf="showRevChurnRateldngSpnr" class="loadingSpinner"></div>
                <canvas #revChurnRateCanvas width="auto" height="75">{{ revChurnRateChart }}</canvas>
                <div class="widget-11-table auto-overflow m-t-10" *ngIf="!noRevChurnRateDataAvailable && !showRevChurnRateldngSpnr">
                  <div class="table-container">
                     <table class="table table-condensed1 table-hover ">
                         <thead>
                           <tr>
                             <th>Brand</th>
                             <th style="width:110px;">Month</th>
                             <th>Total MRR</th>
                             <th>Churned Revenue</th>
                             <th>Voluntary Churned Revenue</th>
                             <th>Involuntary Churned Revenue</th>
                             <th>Total Churned Revenue Rate (%)</th>
                             <th>Voluntary Churned Revenue Rate (%)</th>
                             <th>Involuntary Churned Revenue Rate (%)</th>
                           </tr>
                         </thead>
                         <tbody>
                           <tr *ngFor="let item of revChurnRateTblData; let i = index">
                             <td>{{getBrandDisplayName(item.brand_name)}}</td>
                             <td>{{item.month}}</td>
                             <td>{{item.total_mrr | number:'1.2-2'}}</td>
                             <td>{{item.churned_revenue | number:'1.2-2'}}</td>
                             <td>{{item.voluntary_churned_revenue | number:'1.2-2'}}</td>
                             <td>{{item.involuntary_churned_revenue | number:'1.2-2'}}</td>
                             <td>{{item.total_churned_revenue_rate  | number:'1.2-2'}}</td>
                             <td>{{item.voluntary_churned_revenue_rate | number:'1.2-2'}}</td>
                             <td>{{item.involuntary_churned_revenue_rate | number:'1.2-2'}}</td>
                           </tr>
                         </tbody>
                       </table>
                  </div>
                 </div>
                <h4 *ngIf="noRevChurnRateDataAvailable && !showRevChurnRateldngSpnr" class="text-center">No data available for the selected brand.</h4>
              </div>
            </div>
        </pgcard>

        <pgcard [Maximize]="false" collapseState="active" (onRefresh)="getSubsKeyMetricLifeTimeValData(true)" [Loading]="showLifeTimeValldngSpnr">
          <ng-template #CardTitle><h4>Lifetime Value (LTV)</h4></ng-template>
          <div class="card-collapsed">
            <p>Customer lifetime value (LTV) estimates how much revenue you’ll make from the average customer before they churn.
              <br/>
              Lifetime value (LTV) = Average monthly MRR per customer / User Churn Rate
              </p>
              <br>
              <div class="row align-items-center">
                <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
                  <div class="d-flex align-items-center">
                    <span class="m-r-5">Brands: </span>
                    <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'" [(ngModel)]="ltvBrand" (ngModelChange)="ltvBrandFltrChage(ltvBrand)" ShowSearch>
                      <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                      <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
                    </pg-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-4 mb-4 chart-container">
                <div *ngIf="showLifeTimeValldngSpnr" class="loadingSpinner"></div>
                <div class="widget-11-table auto-overflow" *ngIf="!noLifeTimeValAvailable && !showLifeTimeValldngSpnr">
                 <div class="table-container">
                    <table class="table table-condensed1 table-hover">
                        <thead>
                          <tr>
                            <th>Brand</th>
                            <th>Month</th>
                            <th>Avg Monthly MRR</th>
                            <th>User Churn Rate</th>
                            <th>LTV</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of lifeTimeValTblData; let i = index">
                            <td>{{getBrandDisplayName(item.brand_name)}}</td>
                            <td>{{item.month}}</td>
                            <td>{{item.avg_monthly_mrr | number:'1.2-2'}}</td>
                            <td>{{item.user_churn_rate | number:'1.2-2'}}</td>
                            <td>{{item.ltv | number:'1.2-2'}}</td>
                          </tr>
                        </tbody>
                      </table>
                 </div>
                </div>
                <h4 *ngIf="noLifeTimeValAvailable && !showLifeTimeValldngSpnr" class="text-center">No data available for the selected brand.</h4>
              </div>
            </div>
        </pgcard>
        
      </div>
    </div>
  </div>
  <app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>
  