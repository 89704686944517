
<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="addManageAdminPrmns">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a routerLink="/settings/admin/all" href="javascript:void(0)">Admin</a></li>
      <li class="breadcrumb-item active"> Add New</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
            <h5 class="mb-2">Add Member</h5>
            <form id="form-project" class="addNewWidgetForm mx-auto" [formGroup]="addNewCompAdminForm" (ngSubmit)="addNewCompAdminFormSubmit()" novalidate>
              <div class="">
                <div class="row clearfix">
                  <div class="col-md-12">
                    <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('firstName').invalid && (getFormControl('firstName').dirty || getFormControl('firstName').touched)">
                      <label>First Name</label>
                      <input type="text" class="form-control" formControlName="firstName" placeholder="Enter First Name">
                      <div class="invalid-tooltip" *ngIf="getFormControl('firstName').invalid && (getFormControl('firstName').dirty || getFormControl('firstName').touched)">
                        First Name is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="col-md-12">
                    <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('lastName').invalid && (getFormControl('lastName').dirty || getFormControl('lastName').touched)">
                      <label>Last Name</label>
                      <input type="text" class="form-control" formControlName="lastName" placeholder="Enter Last Name">
                      <div class="invalid-tooltip" *ngIf="getFormControl('lastName').invalid && (getFormControl('lastName').dirty || getFormControl('lastName').touched)">
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="col-md-12">
                    <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('email').invalid && (getFormControl('email').dirty || getFormControl('email').touched)">
                      <label>Email</label>
                      <input type="email" class="form-control" formControlName="email" placeholder="Enter Email">
                      <div class="invalid-tooltip" *ngIf="getFormControl('email').invalid && (getFormControl('email').dirty || getFormControl('email').touched)">
                        Email is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="col-md-12">
                    <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('password').invalid && (getFormControl('password').dirty || getFormControl('password').touched)">
                      <label>Password</label>
                      <input type="password" class="form-control" formControlName="password" placeholder="Enter Password">
                      <div class="invalid-tooltip" *ngIf="getFormControl('password').invalid && (getFormControl('password').dirty || getFormControl('password').touched)">
                        Password is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="col-md-12">
                    <div pgFormGroupDefault  class="form-group form-group-default required" [class.has-error]="getFormControl('confirmPassword').invalid && (getFormControl('confirmPassword').dirty || getFormControl('confirmPassword').touched)">
                      <label>Confirm Password</label>
                      <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Enter Confirm Password">
                      <div class="invalid-tooltip" *ngIf="getFormControl('confirmPassword').invalid && (getFormControl('confirmPassword').dirty || getFormControl('confirmPassword').touched)">
                        Confirm Password is required
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row clearfix">
                  <div class="col-md-12">
                    <div pgFormGroupDefault  class="form-group form-group-default form-group-default-select2  required" [class.has-error]="getFormControl('role').invalid && (getFormControl('role').dirty || getFormControl('role').touched)">
                      <label>Role</label>
                      <pg-select style="width: 100%;" formControlName="role" [PlaceHolder]="'Select a role'" AllowClear ShowSearch >
                        <pg-option *ngFor='let adminRole of adminRoleArr;let i = index' Label="{{adminRole.role_name}}"   Value="{{adminRole.id}}">
                        </pg-option>
                      </pg-select>
                      <div class="invalid-tooltip" *ngIf="getFormControl('role').invalid && (getFormControl('role').dirty || getFormControl('role').touched)">
                        Role is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row clearfix p-t-5 p-b-5">
                  <div class="col-md-12" >
                    <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                      <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                        <input type="checkbox" id="switchSample" checked="checked" formControlName="adminStatus">
                        <label for="switchSample">Status</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>  
              <div class="row p-t-20">
                <div class=" col-md-12 text-right ">
                  <button type="submit" [disabled]="loading" class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10">Add</button>
                  <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30" routerLink="/settings/admin/all">Cancel</button>
                </div>
              </div>
            </form> 
            <div *ngFor="let alert of alerts" class="p-t-20">
              <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
            </div> 
        </div>
      </div>
    </div>
  </div>  
</div>   
<!-------No Access Block--------->
<app-no-access *ngIf="!addManageAdminPrmns"></app-no-access>

