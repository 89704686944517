<div class="container-fluid p-l-25 p-r-25 p-t-0 p-b-25 sm-padding-10 sm-gutter">
  <!-- START ROW -->
  <div class="card card-transparent" *ngIf="viewDashboardPrmsn">
    <div class="card-header p-l-0 p-r-0">
      <div class="row align-items-center">
        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 p-b-15">
          <div class="d-flex flex-row align-items-center hide">
            <span class="m-r-5">Brands: </span>

            <pg-select class="brand" style="width: 100%;" [PlaceHolder]="'All'" [(ngModel)]="selectedOption"
              (ngModelChange)="brandFilterChange(selectedOption)" Filter="false" ShowSearch>
              <pg-option [Label]="'All'" [Value]="'0'"></pg-option>
              <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select"
                [Label]="brand.brand_name" [Value]="brand.id">
              </pg-option>
            </pg-select>
            <select class="form-control m-l-10 m-r-auto" (change)="brandFilterChange($event.target.value)">
              <option value="0">All</option>
              <option *ngFor='let brand of allActiveBrands;let i = index' value="{{brand.id}}">{{brand.brand_display_name}}</option>
          </select> 
          </div>
        </div>
        <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 p-b-15 " >
          <div class="d-flex flex-row align-items-center hide">
            <span class="p-r-10">Period: </span>
            <input type="text" placeholder="Last 30 Days" class="form-control" (bsValueChange)="dateRangeChange($event)"
              bsDaterangepicker
              [bsConfig]="{ ranges: ranges, showCustomRangeLabel: false, containerClass:'theme-dark-blue' }"
              [bsValue]="bsValue">
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end align-items-center">
          <a (click)="getDashboardData(true)" class="hover m-r-5" title="Refresh">
            <i class="pg-icon m-20">refresh</i>
          </a>
          <span class="text-muted font-size-13">Last synced on: {{ lastSyncTime }}</span>
        </div>
      </div>
    </div>
    <div class="card-body p-l-0 p-r-0">
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
      </div>
      <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
      <div class="row clearfix" *ngIf="!showLoadingSpinner">
        <div class="col-sm-12 col-md-4 col-xl-2">
          <div class="card m-b-10 ">

            <div class="card-header top-left top-right">
              <div class="card-title">
                <span class="font-montserrat fs-11 all-caps">
                  Total Users (As of Today)<i class="fa fa-chevron-right"></i>
                </span>
              </div>
            </div>

            <div class="card-body no-padding">
              <div class="container-xs-height full-height">
                <div class="row-xs-height">
                  <div class="col-xs-height col-top">
                    <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h4 class="no-margin p-b-5"> {{totalUsers | number}} </h4>
                      <!-- <p class="p-t-20"><small >(As of Today)</small></p> -->
                    </div>
                  </div>
                </div>

                <div class="row-xs-height">
                  <div class="col-xs-height col-bottom">
                    <div class="progress progress-small m-b-0">
                      <div class="progress-bar progress-bar-primary" style="width: 100%;">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-2">
          <div class="card m-b-10 ">
            <div class="card-header top-left top-right">
              <div class="card-title">
                <span class="font-montserrat fs-11 all-caps">
                  New registrations <i class="fa fa-chevron-right"></i>
                </span>
              </div>
            </div>

            <div class="card-body no-padding">
              <div class="container-xs-height full-height">
                <div class="row-xs-height">
                  <div class="col-xs-height col-top">
                    <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h4 class="no-margin p-b-5">{{newRegUsers | number}}</h4>
                      <!-- <p class="p-t-20"><small >({{datePlaceholder}}) </small></p> -->
                      <!--<span class="small hint-text pull-left">20% of total goal</span>
                      <span class="pull-right small text-success">{{newRegUser}}</span>-->
                    </div>
                  </div>
                </div>

                <div class="row-xs-height">
                  <div class="col-xs-height col-bottom">
                    <div class="progress progress-small m-b-0">
                      <div class="progress-bar progress-bar-green" style="width: 100%;background-color: green;">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-2">
          <div class="card m-b-10 ">
            <div class="card-header top-left top-right">
              <div class="card-title">
                <span class="font-montserrat fs-11 all-caps">
                  New Subscribers <i class="fa fa-chevron-right"></i>
                </span>
              </div>
            </div>

            <div class="card-body no-padding">
              <div class="container-xs-height full-height">
                <div class="row-xs-height">
                  <div class="col-xs-height col-top">
                    <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h4 class="no-margin p-b-5"> {{newSubs | number}}</h4>
                      <!-- <p class="p-t-20"><small >({{datePlaceholder}}) </small></p> -->
                      <!--<span class="small hint-text pull-left">20% of total goal</span>
                      <span class="pull-right small text-warning">$23,000</span>-->
                    </div>
                  </div>
                </div>

                <div class="row-xs-height">
                  <div class="col-xs-height col-bottom">
                    <div class="progress progress-small m-b-0">
                      <div class="progress-bar progress-bar-warning" style="width: 100%;">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-2">
          <div class="card m-b-10 ">
            <div class="card-header top-left top-right">
              <div class="card-title">
                <span class="font-montserrat fs-11 all-caps">
                  New purchases <i class="fa fa-chevron-right"></i>
                </span>
              </div>
            </div>

            <div class="card-body no-padding">
              <div class="container-xs-height full-height">
                <div class="row-xs-height">
                  <div class="col-xs-height col-top">
                    <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h4 class="no-margin p-b-5">${{newPurchases}}</h4>
                      <!-- <p class="p-t-20"><small >({{datePlaceholder}}) </small></p> -->
                      <!--<span class="small hint-text pull-left">30% of total goal</span>
                      <span class="pull-right small text-warning">${{totSale}}</span>-->
                    </div>
                  </div>
                </div>

                <div class="row-xs-height">
                  <div class="col-xs-height col-bottom">
                    <div class="progress progress-small m-b-0">
                      <div class="progress-bar progress-bar-default" style="width: 100%;">
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-2">
          <div class="card m-b-10 ">
            <div class="card-header top-left top-right">
              <div class="card-title">
                <span class="font-montserrat fs-11 all-caps">
                  Unsubscribers <i class="fa fa-chevron-right"></i>
                </span>
              </div>
            </div>
            <div class="card-body no-padding">
              <div class="container-xs-height full-height">
                <div class="row-xs-height">
                  <div class="col-xs-height col-top">
                    <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h4 class="no-margin p-b-5">{{unSubs | number}}</h4>
                      <!-- <p class="p-t-20"><small >({{datePlaceholder}}) </small></p> -->
                      <!--<span class="small hint-text pull-left">30% of total goal</span>
                      <span class="pull-right small text-danger">${{totSale}}</span>-->
                    </div>
                  </div>
                </div>

                <div class="row-xs-height">
                  <div class="col-xs-height col-bottom">
                    <div class="progress progress-small m-b-0">
                      <div class="progress-bar progress-bar-danger" style="width: 100%;">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-xl-2">
          <div class="card m-b-10 ">
            <div class="card-header top-left top-right">
              <div class="card-title">
                <span class="font-montserrat fs-11 all-caps">
                  Payment failures <i class="fa fa-chevron-right"></i>
                </span>
              </div>
            </div>
            <div class="card-body no-padding">
              <div class="container-xs-height full-height">
                <div class="row-xs-height">
                  <div class="col-xs-height col-top">
                    <div class="p-l-20 p-t-50 p-b-40 p-r-20">
                      <h4 class="no-margin p-b-5">{{paymentFails}}</h4>
                      <!-- <p class="p-t-20"><small >({{datePlaceholder}}) </small></p> -->
                    </div>
                  </div>
                </div>

                <div class="row-xs-height">
                  <div class="col-xs-height col-bottom">
                    <div class="progress progress-small m-b-0">
                      <div class="progress-bar progress-bar-brown" style="width: 100%;background-color: #810402;">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!------Recent Transactions-->
      <div class="col-sm-12 m-t-20 m-b-10 d-flex" *ngIf="!showLoadingSpinner">
        <div class="card  full-height no-border no-margin widget-11 widget-loader-bar">
          <div class="card-body no-padding" style="opacity: 1;">
            <div class="p-l-20 p-r-20 p-b-10 p-t-15 ">
              <div class="pull-left">
                <h3 class="text-primary no-margin">Recent Transactions</h3>
              </div>
              <h4 class="pull-right semi-bold no-margin hide"><sup><small class="semi-bold">$</small></sup> 14.99</h4>
              <div class="clearfix"></div>
            </div>
            <div class="widget-11-table auto-overflow ">
              <div class="table-container">
                <table class="table table-condensed2 table-hover recent-transiction">
                  <thead>
                    <tr>
                      <th>Brand</th>
                      <th>Plan</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Promo Code</th>
                      <th class="text-center">Next Payment Date</th>
                      <th>Job Title</th>
                      <th>Company</th>
                      <th>Industry</th>
                      <th class="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="transData.length <= 0">
                      <td colspan="12" class="text-center">No transactions available.</td>
                    </tr>
                    <tr *ngFor="let trans of transData; index as i">
                      <td><span class="fs-14">{{trans.brand_name}}</span></td>
                      <td><span class="fs-12">{{trans.plan_name}}</span></td>
                      <td><span class="fs-12">{{trans.first_name}} {{trans.last_name}}</span></td>
                      <td><span class="fs-12">{{trans.email}}</span></td>
                      <td><span class="fs-12">{{trans.promo_code}}</span></td>
                      <td class="text-center"><span class="hint-text small">{{trans.end_date | date: 'dd-MMM-yy'}}</span>
                      </td>
                      <td><span class="fs-12">{{trans.job_ttl}}</span></td>
                      <td><span class="fs-12">{{trans.comp}}</span></td>
                      <td class="b-r b-dashed b-grey "><span class="fs-12">{{trans.industry}}</span></td>
                      <td class="text-right"><span class="font-montserrat fs-18 text-primary">${{trans.amount}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
  <!-- END ROW -->
  <!-------No Access Block--------->
  <app-no-access *ngIf="!viewDashboardPrmsn"></app-no-access>
</div>