import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SubscribersComponent } from './subscribers/subscribers.component';
import { AllSubscribersComponent } from './all-subscribers/all-subscribers.component';
import { EditSubscriberComponent } from './edit-subscriber/edit-subscriber.component';

import { AuthGuard } from '../../common/auth.guard';

const routes: Routes = [
    {
        path: 'subs',
        component: SubscribersComponent,
        canActivate: [AuthGuard],
        children: [
            {
              path: 'all/:type',
              component: AllSubscribersComponent
            },
            {
              path: 'view/:type/:userId/:brandId',
              component: EditSubscriberComponent
            },
            { path: '', redirectTo: 'subs/all/datawall', pathMatch: 'full' }
        ]
    }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscribersRoutingModule { }