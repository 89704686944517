<div class="container-fluid p-l-25 p-r-25 p-t-0 p-b-25 sm-padding-10 sm-gutter">
    <div class="card card-transparent">
      <div class="card-header mb-3 p-0">
        <p class="mb-4">This report provides a detailed analysis of user journeys through the paywall funnel, highlighting top content, authors, sections, geographic distribution, and source attribution. It offers insights into user interactions at various stages, facilitating data-driven decisions to enhance user engagement and conversion.</p>
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
            <div class="d-flex align-items-center">
              <span class="m-r-5">Brands: </span>
              <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'"
                         [(ngModel)]="selectedOption" (ngModelChange)="brandFilterChange(selectedOption)" ShowSearch>
                <pg-option [Label]="'All'" [Value]="'All'"></pg-option>
                <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
              </pg-select>
            </div>
          </div>
         <!--<div class="col-xl-9 col-lg-7 col-md-6 col-sm-12 p-b-15 d-flex justify-content-end align-items-center">
            <a (click)="getContentData(true)" class="hover m-r-5" title="Refresh">
              <i class="pg-icon">refresh</i>
            </a>
            <span class="text-muted font-size-13">Last synced on: {{ lastSyncTime }}</span>
          </div>-->
        </div>
      </div>
      <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
      <h4 *ngIf="noDataAvailable && !showLoadingSpinner" class="text-center">No data available for the selected brand.</h4>
      <div class="card-body p-0">
        <div class="dashboard1">
            <div class="chart-funnel">
                <div class="funnel"></div>
            </div>
        </div>

          <div class="row m-t-20">
            <div class="col-lg-8 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h4>Top Contributors</h4>
                                <div class="col-sm-12 chart-container">
                                    <!--<div *ngIf="showAuthorLoadSpnr" class="loadingSpinner"></div>-->
                                    <canvas #authorCanvas width="auto" height="300">{{ authorChart }}</canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <h4>Section-wise User Engagement</h4>
                                <div class="col-sm-12 chart-container">
                                    <!--<div *ngIf="showSecLoadSpnr" class="loadingSpinner"></div>-->
                                    <canvas #sectionCanvas width="auto" height="300">{{ sectionChart }}</canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <h4>Top Content Performance</h4>
                        <div class="col-sm-12 mt-3 chart-container">
                            <div *ngIf="showContLoadSpnr" class="loadingSpinner"></div>
                            <div class="widget-11-table auto-overflow" *ngIf="!showContLoadSpnr">
                                <table class="table table-condensed1 table-hover" style="width:auto;height:250px;">
                                    <thead>
                                        <tr>
                                            <th>Content</th>
                                            <th>Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of contentArr; let i = index">
                                            <td>{{ item }}</td>
                                            <td>{{ contentCountArr[i] | number }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--<canvas #contCanvas width="200" height="400">{{ contChart }}</canvas>-->
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h4>Topics Driving Engagement</h4>
                        <div class="col-sm-12 mt-4 mb-4 chart-container">
                            <div *ngIf="showTagLoadSpnr" class="loadingSpinner"></div>
                            <canvas #tagCanvas width="auto" height="200">{{ tagChart }}</canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h4>Source Attribution and Analysis</h4>
                        <div class="col-sm-12 mt-4 mb-4 chart-container">
                            <div *ngIf="showSourceLoadSpnr" class="loadingSpinner"></div>
                            <canvas #sourceCanvas width="auto" height="200">{{ sourceChart }}</canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
                <div class="card">
                    <div class="card-body">
                        <h4>Geographic Distribution of Users</h4>
                        <div class="col-sm-12 mt-4 mb-4 chart-container">
                            <div *ngIf="showGeoLoadSpnr" class="loadingSpinner"></div>
                            <canvas #geoCanvas width="auto" height="200">{{ geoChart }}</canvas>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>
  