<div class="padding-20">
    <div class="card ">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-7 col-sm-12">  
                    <h5 class="card-title d-none d-sm-block">All Reports</h5>
                </div>
                <div class="col-lg-5 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Title/Description" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
            </div>
        </div>
        <div *ngFor="let alert of alerts">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="card-body">
            <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
            <ngx-datatable class="material container1 striped" *ngIf="!showLoadingSpinner" [rows]="rows" [columnMode]="'flex'" [headerHeight]="50" [limit]="10" [footerHeight]="50" rowHeight="auto">
                <ngx-datatable-column name="S.No" [flexGrow]="0.5">
                    <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template prop="rowIndex"> 
                        {{(rowIndex + 1)}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Title" [flexGrow]="1.5" prop="title">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.title}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Description" [flexGrow]="2" prop="description">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.description}}
                    </ng-template>
                </ngx-datatable-column>
              <ngx-datatable-column name="Action" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <button class="btn btn-secondary downloadBtn" title="Download CSV" (click)="downloadReport(row.id)">
                      <i  class="pg-icon text-info">download</i>
                    </button>
                </ng-template>
            </ngx-datatable-column>
            </ngx-datatable>
        </div>

    </div>
</div>
    
    
  