import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { SiteLicensesService } from '../../../services/site-licenses.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-all-site-licenses',
  templateUrl: './all-site-licenses.component.html',
  styleUrls: ['./all-site-licenses.component.scss']
})
export class AllSiteLicensesComponent implements OnInit {
  selectedOption = '0';
  rows: any[];
  tmp: any[];
  activeData: [];
  inactiveData: [];
  selectedBrand: number;//PMV temporary
  allActiveBrands: any[];
  statusDropDownLbl: string = "Active Subscriptions";
  selStatus: number = Constants.STATUS_ACTIVE;
  alerts: any[];
  showLoadingSpinner = true;
  //Role based permission check
  viewStLicensePrmsn: boolean = true;
  addStLicensePrmns: boolean = true;
  editStLicensePrmns: boolean = true;

  constructor(private commonService: CommonService,
    private siteLicensesService: SiteLicensesService,
    private cdr: ChangeDetectorRef) {
    /****************Permission check based on role******************/
    this.viewStLicensePrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_SITE_LICENSES, Constants.PERMISSION_ADD_SITE_LICENSES, Constants.PERMISSION_EDIT_SITE_LICENSES]);
    this.addStLicensePrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_SITE_LICENSES]);
    this.editStLicensePrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_SITE_LICENSES]);
    this.getAllActiveBrands();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if(this.viewStLicensePrmsn){
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allActiveBrands = res['result'];
            this.selectedBrand = 0;//All
            this.getAllSiteLicenses(this.selectedBrand);
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }
  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    //this.userList = [];
    this.getAllSiteLicenses(brandId);
  }
  /**********************************API Method to Get All the SiteLicenses*********************/
  getAllSiteLicenses(brandId) {
    if (this.viewStLicensePrmsn) {
      this.siteLicensesService.getAllSiteLicenses(brandId).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.rows = res['result'];
            this.tmp = res['result'];
            this.fetchSiteLicensesData(this.selStatus);
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
          this.showLoadingSpinner = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showLoadingSpinner = false;
        }
      );
    }
  }

  /******************************Method to update Brand Status (Active/Inactive)***************************/
  updateSiteLicStatus(licenseeId: number, event: any) {
    if (this.editStLicensePrmns) {
      const isChecked = this.commonService.getStatusValue(event.target.checked);
      this.selectedBrand = 0;//All
      this.siteLicensesService.updateSiteLicStatus(licenseeId, isChecked).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {//Success
            this.alerts = [{
              type: 'success',
              msg: Constants.DEL_SITE_LIC_SUCCESS_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          } else {
            this.alerts = [{
              type: 'danger',
              msg: Constants.DEL_SITE_LIC_FAILURE_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
          this.getAllSiteLicenses(this.selectedBrand );
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.getAllSiteLicenses(this.selectedBrand );
        });
    }
  }

  /******************************Method to filter the status based site licenses data***************************/
  fetchSiteLicensesData(status: number) {
    this.rows = this.tmp;
    const temp = this.rows.filter(function (d) {
      return d.is_active == status;
    });
    this.rows = temp;
  }

  /******************************Method to set selected site licenses status dropdown***************************/
  filterStaus(status: number) {
    if (status == 1) {
      this.selStatus = status;
      this.statusDropDownLbl = "Active Subscriptions"
      this.fetchSiteLicensesData(status);
    } else {
      this.selStatus = 0;
      this.statusDropDownLbl = "Inactive Subscriptions"
      this.fetchSiteLicensesData(status);
    }
  }

  /******************************Method to filter site licenses name column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    if (val) {
      const temp = this.rows.filter(function (d) {
        return d.licensee_name.toLowerCase().indexOf(val) !== -1 || d.brand_name.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
    } else {
      this.fetchSiteLicensesData(this.selStatus);
    }
  }

}
