import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';
import * as FunnelGraph from 'funnel-graph-js';
import { FunnelGraphLoaderServiceService } from '../../../services/funnel-service-loader.service.service';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  viewSubsReportsPrmsn = true;
  noDataAvailable: boolean = false;
  selectedOption : string  = 'All';
  allActiveBrands: any[] = [];
  alerts: any[] = [];
  lastSyncTime: string;
  selBrand: string = 'All';
  showLoadingSpinner = true;
  showAuthorLoadSpnr = true;
  showSecLoadSpnr = true;
  showContLoadSpnr = true;
  showTagLoadSpnr = true;
  showSourceLoadSpnr = true;
  showGeoLoadSpnr = true;
  authorData;
  sectionData;
  contData;
  tagData;
  sourceData;
  geoData;
  contentArr;
  contentCountArr;
  eventClicked : string = 'All';

  authorChart: any;
  @ViewChild('authorCanvas') authorCanvasRef: ElementRef;
  sectionChart: any;
  @ViewChild('sectionCanvas') sectionCanvasRef: ElementRef;
  contChart: any;
  @ViewChild('contCanvas') contCanvasRef: ElementRef;
  tagChart: any;
  @ViewChild('tagCanvas') tagCanvasRef: ElementRef;
  sourceChart: any;
  @ViewChild('sourceCanvas') sourceCanvasRef: ElementRef;
  geoChart: any;
  @ViewChild('geoCanvas') geoCanvasRef: ElementRef;

  datwallEvents: any[] = [];
  paywallEvents: any[] = [];

  chartOptions;
  createChart;

  constructor(
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private reportsService: ReportsService,
    private dynamicScriptLoader: FunnelGraphLoaderServiceService
  ) {
    this.viewSubsReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_CONT_METRICS_REPORTS]);
    this.commonService.subNavSelect(Constants.NAV_DASHBOARD);
    this.getAllActiveBrands();
  }

  ngOnInit(): void {
    this.titleService.setTitle('User Behavior Insights: Paywall and Content Metrics');
    this.commonService.setTitle('User Behavior Insights: Paywall and Content Metrics');


    this.chartOptions = {
      type: 'horizontalBar',
      options: {
          animation: {
              duration: 1000,
              easing: 'easeInOutQuad'
          },
          legend: { display: false },
          scales: {
              xAxes: [{
                  gridLines: { display: false },
                  ticks: {
                      display: false,
                      beginAtZero: true,
                      stepSize: 5
                  }
              }],
              yAxes: [{ gridLines: { display: false } }]
          },
          tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }
          },
          responsive: true,
          maintainAspectRatio: false
      },
      plugins: [{
        afterDatasetsDraw: function(chart) {
            const ctx = chart.ctx;
            chart.data.datasets.forEach((dataset, i) => {
                const meta = chart.getDatasetMeta(i);
                meta.data.forEach((bar, index) => {
                    const data = dataset.data[index];
                    ctx.fillStyle = '#000';
                    ctx.font = 'bold 12px Arial';
                    const position = bar.tooltipPosition();
                    const formattedData = data >= 1000 ? (data / 1000).toFixed(1) + 'k' : data;

                    // Convert formattedData to string and get its length
                    const formattedDataStr = formattedData.toString();
                    let paddingX;
                    if (formattedDataStr.length >= 5) {
                        paddingX = -40;
                    } else if (formattedDataStr.length >= 3) {
                        paddingX = -25;
                    } else if (formattedDataStr.length >= 2) {
                      paddingX = -15;
                  }  else if (formattedDataStr.length === 1) {
                        paddingX = -8;
                    } else {
                        paddingX = -5; // Default padding for lengths not explicitly handled
                    }

                    const paddingY = 5; // Adjust paddingY to move text vertically
                    ctx.fillText(formattedDataStr, position.x + paddingX, position.y + paddingY);
                });
            });
        }
    }]
  };

    this.createChart = (canvasRef: any, labels: string[], data: number[], backgroundColor: string) => {
      const ctx = canvasRef.nativeElement.getContext('2d');
      return new Chart(ctx, {
          ...this.chartOptions,
          data: {
              labels,
              datasets: [{
                  axis: 'y',
                  data,
                  backgroundColor
              }]
          }
      });
    };
  }

  getAllActiveBrands(): void {
    if (this.viewSubsReportsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res.code === 1 && res.status === 1) {
            this.allActiveBrands = res.result;
            this.getContentData(false);
          } else {
            this.alerts.push({ type: 'danger', msg: res.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
        },
        error => {
          this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
        }
      );
    }
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('chartjs', 'random-num').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));
  }

  brandFilterChange(val: string): void {
    this.selBrand = val;
    this.getContentData(false);
  }

  getContentData(isRefreshed: boolean): void {
    if (this.viewSubsReportsPrmsn) {
      let existingChart = document.querySelector(".funnel");
      if (existingChart) {
          existingChart.innerHTML = "";
      }
      this.noDataAvailable = false;
      this.showLoadingSpinner = true;
      this.reportsService.getContentDataFromBq('count_event_name',this.selBrand).then(
        res => {
          if (res) {
              this.processContentData(res);
              this.getTopAuthorData('All');
              this.getTopSecData('All');
              this.getTopContData('All');
              this.getTopTagData('All');
              this.getTopSourceData('All');
              this.getTopGeoData('All');
          } else {
            this.alerts.push({ type: 'danger', msg: Constants.DASH_DATA_FAILURE_MSG, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          }
          this.showLoadingSpinner = false;
        },
        error => {
          this.alerts.push({ type: 'danger', msg: error.message, timeout: Constants.DEF_ALERT_MSG_TIMEOUT });
          this.showLoadingSpinner = false;
        }
      );
    }
  }

  processContentData(resArr) {
    const eventLabelMapping = {
      "datawall_hit": "Show Datawall",
      "plans_viewed": "Show Plans",
      "paywall_hit": "Show Paywall",
      "login_completed": "Signin",
      "registration_completed": "Signup",
      "paywall_click": "Click Subscribe",
      "checkout_start": "Checkout Start",
      "checkout_pay_success": "Checkout Success",
      "checkout_pay_failed": "Checkout Failed"
    };
    let filteredResArr = resArr.filter(event => event.event_name !== "plan_selected");
    let generateFunnelData = {
        labels: filteredResArr.map(event => eventLabelMapping[event.event_name]),
        colors: ['#F38181', '#FCE38A', '#EAFFD0', '#F08A5D', '#B83B5E', '#6A2C70'],
        values: filteredResArr.map(event => this.formatNumberWithCommas(event.event_count))
    };

    const graph = new FunnelGraph({
        container: '.funnel',
        gradientDirection: 'horizontal',
        data: generateFunnelData,
        displayPercent: true,
        direction: 'horizontal',
    });
    graph.draw();
    this.initEventNameClick();
  }
  

  formatNumberWithCommas(number: number) {
    return number;
    //return parseInt(number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  }

  initEventNameClick() {
    // Get all elements with the class 'svg-funnel-js__label'
    var labels = document.querySelectorAll('.svg-funnel-js__label');
    var selectedLabel: HTMLElement | null = null;

    // Add click event listener to each label
    labels.forEach(label => {
        label.addEventListener('click', (event) => {
            var clickedLabel = event.currentTarget as HTMLElement;

            if (clickedLabel) {
                if (clickedLabel.classList.contains("selected")) {
                    // If clicked label is already selected, remove the 'selected' class and set title to 'All'
                    clickedLabel.classList.remove("selected");
                    this.eventClicked = 'All';
                    this.generateAllChartByEvent('All');
                    selectedLabel = null;
                } else {
                    // Remove the 'selected' class from the previously selected label
                    if (selectedLabel) {
                        selectedLabel.classList.remove("selected");
                    }

                    // Add the 'selected' class to the clicked label
                    clickedLabel.classList.add("selected");
                    selectedLabel = clickedLabel;

                    // Get the title of the clicked label
                    const labelEventMapping = {
                      "Show Datawall" : "datawall_hit",
                      "Show Plans" : "plans_viewed",
                      "Show Paywall" : "paywall_hit",
                      "Signin" : "login_completed",
                      "Signup" : "registration_completed",
                      "Click Subscribe" : "paywall_click",
                      "Checkout Start" : "checkout_start",
                      "Checkout Success" : "checkout_pay_success",
                      "Checkout Failed" : "checkout_pay_failed"
                  };
                    var titleElement = clickedLabel.querySelector('.label__title') as HTMLElement;
                    var title = titleElement ? (titleElement.textContent || '') : '';
                    this.eventClicked = labelEventMapping[title];

                    // Generate chart by event
                    this.generateAllChartByEvent(this.eventClicked);
                }
            }
        });
    });
  }

  generateAllChartByEvent(event_name : string) {
    this.generateTopAuthorChart(this.authorData, event_name);
    this.generateTopSectionChart(this.sectionData, event_name);
    this.generateTopContChart(this.contData, event_name);
    this.generateTopTagChart(this.tagData, event_name);
    this.generateTopSourceChart(this.sourceData, event_name);
    this.generateTopGeoChart(this.geoData, event_name);
  }

  getTopAuthorData(event_name : string) {
    this.showAuthorLoadSpnr = true;
    this.reportsService.getContentDataFromBq('10_author_event_name',this.selBrand).then(
      res => {
        if (res) {
          this.authorData = res;
          this.generateTopAuthorChart(res, event_name);
        }
        this.showAuthorLoadSpnr = false;
      }
    );
  }

  generateTopAuthorChart(res, event_name) {
    const filteredData = this.filterDataByEvent(res, event_name);
    const authorCounts = this.aggregateCounts(filteredData, 'author');
    const topItems = this.getTopItems(authorCounts, 10);

    let labels:any = [];
    const counts = [];
    this.populateTopItems(topItems, labels, counts);
    let cleanedLabels = labels.filter(label => label !== '').map(label => label.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase()));

    if (this.authorChart) this.authorChart.destroy();
    this.authorChart = this.createChart(this.authorCanvasRef, cleanedLabels, counts, '#8dd3c7');
  }

  getTopSecData(event_name : string) {
    this.showSecLoadSpnr = true;
    this.reportsService.getContentDataFromBq('10_sections_event_name',this.selBrand).then(
      res => {
        if (res) {
          this.sectionData = res;
          this.generateTopSectionChart(res, event_name);
        }
        this.showSecLoadSpnr = false;
      }
    );
  }

  generateTopSectionChart(res, event_name) {
    const filteredData = this.filterDataByEvent(res, event_name);
    const sectionCounts = this.aggregateCounts(filteredData, 'section');
    const topItems = this.getTopItems(sectionCounts, 10);

    const labels = [];
    const counts = [];
    this.populateTopItems(topItems, labels, counts);

    if (this.sectionChart) this.sectionChart.destroy();
    this.sectionChart = this.createChart(this.sectionCanvasRef, labels, counts, '#ffffb3');
  }

  getTopContData(event_name : string) {
    this.showContLoadSpnr = true;
    this.reportsService.getContentDataFromBq('10_content_event_name',this.selBrand).then(
      res => {
        if (res) {
          this.contData = res;
          this.generateTopContChart(res, event_name);
        }
        this.showContLoadSpnr = false;
      }
    );
  }

  generateTopContChart(res, event_name) {
    const filteredData = this.filterDataByEvent(res, event_name);
    const contentCounts = this.aggregateCounts(filteredData, 'content');
    const topItems = this.getTopItems(contentCounts, 10);

    const labels = [];
    const counts = [];
    this.populateTopItems(topItems, labels, counts);
    this.contentArr = labels;
    this.contentCountArr = counts;
   /* if (this.contChart) this.contChart.destroy();
    this.contChart = this.createChart(this.contCanvasRef, labels, counts, '#bebada');*/
  }

  getTopTagData(event_name : string) {
    this.showTagLoadSpnr = true;
    this.reportsService.getContentDataFromBq('10_tags_event_name',this.selBrand).then(
      res => {
        if (res) {
          this.tagData = res;
          this.generateTopTagChart(res, event_name);
        }
        this.showTagLoadSpnr = false;
      }
    );
  }

  getTopSourceData(event_name : string) {
    this.showSourceLoadSpnr = true;
    this.reportsService.getContentDataFromBq('10_source_event_name',this.selBrand).then(
      res => {
        if (res) {
          this.sourceData = res;
          this.generateTopSourceChart(res, event_name);
        }
        this.showSourceLoadSpnr = false;
      }
    );
  }

  getTopGeoData(event_name : string) {
    this.showGeoLoadSpnr = true;
    this.reportsService.getContentDataFromBq('10_geo_event_name',this.selBrand).then(
      res => {
        if (res) {
          this.geoData = res;
          this.generateTopGeoChart(res, event_name);
        }
        this.showGeoLoadSpnr = false;
      }
    );
  }

  generateTopTagChart(res, event_name) {
    this.generateDoughnutChart(this.tagCanvasRef, res, event_name, 'tags');
  }
  
  generateTopSourceChart(res, event_name) {
    this.generateDoughnutChart(this.sourceCanvasRef, res, event_name, 'tsource');
  }
  
  generateTopGeoChart(res, event_name) {
    this.generateDoughnutChart(this.geoCanvasRef, res, event_name, 'country');
  }

  generateDoughnutChart(canvasRef, res, event_name, countType) {
    const filteredData = this.filterDataByEvent(res, event_name);
    const counts = countType === 'tags' ? this.aggregateTagCounts(filteredData, countType) : this.aggregateCounts(filteredData, countType);
    const topItems = this.getTopItems(counts, 10);
  
    const labels = [];
    const data = [];
    this.populateTopItems(topItems, labels, data);
  
    const chartProperty = `${countType}Chart`;
    if (this[chartProperty]) this[chartProperty].destroy();
    const ctx = canvasRef.nativeElement.getContext('2d');
    this[chartProperty] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: Constants.REPORTS_CONSTANT_COLORS,
          }
        ]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = data.labels[tooltipItem.index];
              const value = dataset.data[tooltipItem.index];
              return `${label}: ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
          }
        }
      }
    });
  }

  filterDataByEvent(data: any[], event_name: string) {
    console.log('Filtering data with event_name:', event_name); // Debugging line
    if (event_name === 'All') {
        return data;
    }
    // Normalize event_name and data for consistent comparison
    const normalizedEventName = event_name.trim().toLowerCase();
    const filteredData = data.filter(item => item.event_name.trim().toLowerCase() === normalizedEventName);
   // console.log('Filtered data:', data); // Debugging line
    return filteredData;
  };

  aggregateTagCounts(data: any[], key: string) {
    const counts: { [key: string]: number } = {};
    data.forEach((item: any) => {
        const tags = item[key].split(',').map(tag => tag.trim()).filter(tag => tag);
        tags.forEach(tag => {
            counts[tag] = (counts[tag] || 0) + item.event_count;
        });
    });
    //console.log("------counts--------", counts)
    return counts;
};

  aggregateCounts(data: any[], key: string) {
    const counts: { [key: string]: number } = {};
    data.forEach((item: any) => {
        counts[item[key]] = (counts[item[key]] || 0) + item.event_count;
    });
    //console.log("------counts--------"+counts)
    return counts;
  };

  getTopItems(counts: { [key: string]: number }, topN: number) {
    const obj = Object.entries(counts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, topN);
        //console.log("------getTopItems--------"+obj)
        return obj;
  }

  populateTopItems(topItems: [string, number][], labelArray: string[], countArray: number[]) {
    topItems.forEach(([label, count]) => {
      labelArray.push(label);
      countArray.push(count);
    });
    //console.log("------populateTopItems--------", labelArray, countArray);
    return topItems;
  }

  truncateLabel(label: string, maxLength: number) {
      return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }

}
