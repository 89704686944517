import { Injectable } from '@angular/core';
import { contentHeaders } from '../common/headers';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private url = environment.api_url;
  constructor(private http: HttpClient, private commonService : CommonService) { }

   /*********************************API to Get dashboard data**************************************** */
   getDashboardData(brand:number, startDate, endDate, isRefreshed:boolean) {
    return this.http
      .get(`${this.url}/v1/reports/${brand}/range/${startDate}/${endDate}?isRefreshed=${isRefreshed}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to Get transaction data**************************************** */
  getTransactionData(brand:number) {
    return this.http
      .get(`${this.url}/v1/reports/transaction/${brand}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }

  /*********************************API to download report based on type*************************************** */
  downloadReport(type:number) {
    return this.http
      .get(`${this.url}/v1/reports/download/${type}`, {headers: contentHeaders})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(this.commonService.handleError);
  }
}
