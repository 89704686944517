import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { ActivatedRoute,Router,NavigationEnd} from '@angular/router';
import { CustomersService } from '../../../services/customers.service';
import { Constants } from '../../../common/constants';
import { DropdownConstants } from '../../../common/dropdown_constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-subscriber',
  templateUrl: './edit-subscriber.component.html',
  styleUrls: ['./edit-subscriber.component.scss']
})
export class EditSubscriberComponent implements OnInit {
  pageType : string;
  brandId : string;
  userId : string;


  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private customersService: CustomersService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router) {
      this.router.events.subscribe(event => {
        if(event.constructor.name === "NavigationEnd") {
          this.pageType = this.route.snapshot.paramMap.get('type');
          this.loadPageData(this.pageType);
        }
      });
    }

  ngOnInit(): void {
    this.pageType = this.route.snapshot.paramMap.get('type');
    this.brandId = this.route.snapshot.paramMap.get('brandId');
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loadPageData(this.pageType);
  }

  loadPageData(type: string) {
    let title = "Users";
    if(type=="datawall"){
      title = "Datawall Users";
    } else if(type=="paywall"){
      title = "Paywall Users";
    } else if(type=="churned"){
      title = "Churned Users";
    }
    this.commonService.setTitle(title);
    this.titleService.setTitle(title);
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

}
