import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-all-templates',
  templateUrl: './all-templates.component.html',
  styleUrls: ['./all-templates.component.scss']
})
export class AllTemplatesComponent implements OnInit {

  constructor(private commonService: CommonService, 
    private cdr: ChangeDetectorRef) { }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
  }

  /******************************Method to filter name column based on search string***************************/
  filterSrchStr(event) {

  }

}
