import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoAccessComponent } from './no-access/no-access.component';



@NgModule({
  declarations: [NoAccessComponent],
  imports: [
    CommonModule
  ],
  exports: [NoAccessComponent]
})
export class CommonTempModule { }
