import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CustomersService } from '../../../services/customers.service';

import { customValidators } from '../../../helpers/validator';
import { Constants } from '../../../common/constants';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-change-pwd',
  templateUrl: './change-pwd.component.html',
  styleUrls: ['./change-pwd.component.scss']
})
export class ChangePwdComponent implements OnInit {
  changePwdForm : FormGroup;
  customerId : string;
  brandId : string;
  submitted : boolean = false;
  loading : boolean = false;
  alerts: any[];
  customerData : any[];
  userRolesArr : any[];
  showLoadingSpinner = true;
  userOrderArr: any[];
  data: any[];

  constructor(private commonService : CommonService,
    private cdr: ChangeDetectorRef,
    private customersService: CustomersService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { 

    }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('id');
    this.brandId = this.route.snapshot.paramMap.get('brandid');
    /****************Change Password Form Validation****************** */

    this.changePwdForm = this.formBuilder.group({
      newPwd: ['', [Validators.required, Validators.minLength(6)]],
      confirmPwd: ['', [Validators.required]]
    }, {validator: customValidators.MustMatch('newPwd', 'confirmPwd')});
  }


  // convenience getter for easy access to form fields
  get f() { return this.changePwdForm.controls; }


  /**************************Method to get customer detail*******************************/

  /*******************************Method to submit change password form***************************************** */
  changePwdFormSubmit() {


    this.submitted = true;
    // stop here if form is invalid
    if (this.changePwdForm.invalid) {
        return;
    }
    this.loading = true;
    let dataObj = {
      'password' : this.f.newPwd.value,
      'confirm_password' : this.f.confirmPwd.value
    };
    console.log(dataObj)
    //dataObj['confirm_password'] = this.f.confirmPwd.value;
    
    //const userId = this.route.snapshot.paramMap.get('id');    
    this.customersService.editCustomerPwd(this.customerId,this.brandId, dataObj).then(
      res => {
          this.loading = false;
          let resStatus = res['status']
          if(res['code']==1 && resStatus==1) {//success
            alert(1);
            this.alerts = [{
              type: 'success',
              msg: Constants.UPDATE_ADMIN_PWD_SUCCESS_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          } else {
            let errorMsg = Constants.UPDATE_CUSTOMER_FAILURE_MSG;
            if(resStatus== 2) {
              errorMsg = Constants.UPDATE_CUSTOMER_NO_CHANGE_MSG;
            } else if(resStatus== 3) {
              errorMsg = Constants.UPDATE_CUSTOMER_EXISTS_MSG;
            }
              this.alerts = [{
                type: 'danger',
                msg: errorMsg,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
          }
      },
      error => {
        alert(3);
        this.alerts = [{
          type: 'danger',
          msg: Constants.UPDATE_ADMIN_PWD_FAILURE_MSG,
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        this.loading = false;
      });
  }
}