import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { WidgetsService } from '../../../services/widgets.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-all-widgets',
  templateUrl: './all-widgets.component.html',
  styleUrls: ['./all-widgets.component.scss']
})
export class AllWidgetsComponent implements OnInit {
  selectedOptions = '0';
  rows: any[];
  tmp: any[];
  allWidgetGroups: any[];
  alerts: any[];
  showLoadingSpinner = true;
  selectedGroup  = '1';
  //Role based permission check
  viewWidgetsPrmsn : boolean = true;
  addWidgetsPrmns : boolean = true;
  editWidgetsPrmns : boolean = true;
  selectedBrand: number;//PMV temporary
  allActiveBrands: any[];

  constructor(private commonService: CommonService, 
    private cdr: ChangeDetectorRef,
    private widgetsService: WidgetsService) {
      /****************Permission check based on role******************/
      this.viewWidgetsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_WIDGETS, Constants.PERMISSION_ADD_WIDGETS, Constants.PERMISSION_EDIT_WIDGETS]);
      this.addWidgetsPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_WIDGETS]);
      this.editWidgetsPrmns = commonService.getSecPermissions([Constants.PERMISSION_EDIT_WIDGETS]);
      this.getAllWidgetGroups();
      this.getAllActiveBrands();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }
  
  ngOnInit(): void {
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if (this.viewWidgetsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allActiveBrands = res['result'];
            this.selectedBrand = 0;//All
            this.getAllWidgets(this.selectedBrand)
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }
  /*************************Brand filter change event**************************************************/
  brandChange(brandId) {
    //this.userList = [];
    this.getAllWidgets(brandId);
  }  
  /**********************************API Method to Get All active widget groups*********************/
  getAllWidgetGroups() {
    if(this.viewWidgetsPrmsn) {
      this.widgetsService.getAllWidgetGroups().then(
        res=>{
        if(res['code']==1 && res['status']==1) {
          this.allWidgetGroups = res['result'];
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
      );
    }
  }

  /**********************************API Method to Get All Widgets*********************/
  getAllWidgets(brandId) {
    if(this.viewWidgetsPrmsn) {
      this.showLoadingSpinner = true;
      this.widgetsService.getAllWidgets(brandId).then(
        res=>{
        if(res['code']==1 && res['status']==1) {
            this.rows = res['result'];
            this.tmp = res['result'];
            this.getAllWidgetsByGroup(this.selectedGroup);
        } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
        }
        this.showLoadingSpinner = false;
        },error=>{
            this.alerts = [{
              type: 'danger',
              msg: error['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
            this.showLoadingSpinner = false;
        }
      );
    }
  }

  /******************************Method to update Widget Status (Active/Inactive)***************************/
  updateWidgetStatus( widgetId: number, event: any) {
    if(this.editWidgetsPrmns) {
      const isChecked = this.commonService.getStatusValue(event.target.checked);
      this.widgetsService.updateWidgetStatus(widgetId, isChecked).then(
      res => {
        if(res['code']==1 && res['status']==1) {//Success
          this.alerts = [{
            type: 'success',
            msg: Constants.DEL_BRAND_SUCCESS_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        } else {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DEL_BRAND_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
        //this.getAllWidgetsByGroup(this.selectedGroup);
      },
      error => {
        this.alerts = [{
          type: 'danger',
          msg: error['message'],
          timeout: Constants.DEF_ALERT_MSG_TIMEOUT
        }];
        // this.getAllWidgetsByGroup(this.selectedGroup);
      });
    }
  }

  getAllWidgetsByGroup(groupId) {
    this.rows = this.tmp;
    const temp = this.rows.filter(function (d) {
      return d.widget_group_id == groupId;
    });
    this.rows = temp;
  }

  /******************************Method to filter name column based on search string***************************/
  filterSrchStr(event) {
    const val = event.target.value.toLowerCase();
    if(val) {
      const temp = this.rows.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.rows = temp;
    } else {
      this.getAllWidgetsByGroup(this.selectedGroup);
    }
  }

}
