<div class="padding-20">
    <div class="card ">
        <div class="card-header bg-transparent mb-3 separator p-b-15">
            <div class="row justify-content-between align-items-center">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">  
                    <div class="d-flex flex-row align-items-center">
                        <span>Brands: </span>
                        <select class="form-control m-l-10 m-r-auto" [style.width.px]="300" >
                            <option value="1">HME</option>
                            <option value="2">PMV</option>
                            <option value="3">CAT</option>
                            <option value="4">CWO</option>
                            <option value="5">CID</option>
                            <option value="6">MEP</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">  
                    <div class="d-flex flex-row align-items-center">
                        <span>Group: </span>
                        <select class="form-control m-l-10 m-r-auto" [style.width.px]="300" >
                            <option value="1">Metering</option>
                            <option value="2">Offer</option>
                            <option value="3">Registration</option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setTblSrchDiv text-right">
                    <input class="form-control setTblSrcFld" type="search" placeholder="Search Name" aria-label="Search" (keyup)="filterSrchStr($event)">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-12 setAddNewBtnDiv text-right">
                    <a class="btn btn-complete fs-15 padding-10" routerLink="/templates/add" role="button">+ Add New Template</a>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <div data-pages="card" class="card card-default" id="card-basic" style="background-color: #f3f3f4;">
                  <div class="card-header  ">
                    <div class="card-title">Metering
                    </div>
                    <div class="card-controls">
                      <ul>
                        <li><a data-toggle="collapse" class="card-collapse" href="#"><i class="card-icon card-icon-collapse"></i></a>
                        </li>
                        <li><a data-toggle="refresh" class="card-refresh" href="#"><i class="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>hme-requirelogin</h4>
                  </div>
                  <div class="card-footer">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                      <div class="">
                          <p class="small hint-text m-t-5">Updated by Padmapriya S</p>
                          <p class="small hint-text m-t-5">Apr 24, 2024 — 11:45 AM</p>
                      </div>
                      <div class="">
                          <a data-toggle="edit" class="card-edit" href="#"><i class="pg-icon text-info">edit</i></a>
                      </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div data-pages="card" class="card card-default" id="card-basic" style="background-color: #f3f3f4;">
                  <div class="card-header  ">
                    <div class="card-title">Metering
                    </div>
                    <div class="card-controls">
                      <ul>
                        <li><a data-toggle="collapse" class="card-collapse" href="#"><i class="card-icon card-icon-collapse"></i></a>
                        </li>
                        <li><a data-toggle="refresh" class="card-refresh" href="#"><i class="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>hme-requireentitlement</h4>
                  </div>
                  <div class="card-footer">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                      <div class="">
                          <p class="small hint-text m-t-5">Updated by Padmapriya S</p>
                          <p class="small hint-text m-t-5">Apr 24, 2024 — 11:45 AM</p>
                      </div>
                      <div class="">
                          <a data-toggle="edit" class="card-edit" href="#"><i class="pg-icon text-info">edit</i></a>
                      </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div data-pages="card" class="card card-default" id="card-basic" style="background-color: #f3f3f4;">
                  <div class="card-header  ">
                    <div class="card-title">Metering
                    </div>
                    <div class="card-controls">
                      <ul>
                        <li><a data-toggle="collapse" class="card-collapse" href="#"><i class="card-icon card-icon-collapse"></i></a>
                        </li>
                        <li><a data-toggle="refresh" class="card-refresh" href="#"><i class="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>hme-reqloginwithenttl</h4>
                  </div>
                  <div class="card-footer">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                      <div class="">
                          <p class="small hint-text m-t-5">Updated by Padmapriya S</p>
                          <p class="small hint-text m-t-5">Apr 24, 2024 — 11:45 AM</p>
                      </div>
                      <div class="">
                          <a data-toggle="edit" class="card-edit" href="#"><i class="pg-icon text-info">edit</i></a>
                      </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div data-pages="card" class="card card-default" id="card-basic" style="background-color: #f3f3f4;">
                  <div class="card-header  ">
                    <div class="card-title">Metering
                    </div>
                    <div class="card-controls">
                      <ul>
                        <li><a data-toggle="collapse" class="card-collapse" href="#"><i class="card-icon card-icon-collapse"></i></a>
                        </li>
                        <li><a data-toggle="refresh" class="card-refresh" href="#"><i class="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>hme-reqenttl-prem</h4>
                  </div>
                  <div class="card-footer">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                      <div class="">
                          <p class="small hint-text m-t-5">Updated by Padmapriya S</p>
                          <p class="small hint-text m-t-5">Apr 24, 2024 — 11:45 AM</p>
                      </div>
                      <div class="">
                          <a data-toggle="edit" class="card-edit" href="#"><i class="pg-icon text-info">edit</i></a>
                      </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div data-pages="card" class="card card-default" id="card-basic" style="background-color: #f3f3f4;">
                  <div class="card-header  ">
                    <div class="card-title">Metering
                    </div>
                    <div class="card-controls">
                      <ul>
                        <li><a data-toggle="collapse" class="card-collapse" href="#"><i class="card-icon card-icon-collapse"></i></a>
                        </li>
                        <li><a data-toggle="refresh" class="card-refresh" href="#"><i class="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>hme-reqloginwithenttl-prem</h4>
                  </div>
                  <div class="card-footer">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                      <div class="">
                          <p class="small hint-text m-t-5">Updated by Padmapriya S</p>
                          <p class="small hint-text m-t-5">Apr 24, 2024 — 11:45 AM</p>
                      </div>
                      <div class="">
                          <a data-toggle="edit" class="card-edit" href="#"><i class="pg-icon text-info">edit</i></a>
                      </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div data-pages="card" class="card card-default" id="card-basic" style="background-color: #f3f3f4;">
                  <div class="card-header  ">
                    <div class="card-title">Metering
                    </div>
                    <div class="card-controls">
                      <ul>
                        <li><a data-toggle="collapse" class="card-collapse" href="#"><i class="card-icon card-icon-collapse"></i></a>
                        </li>
                        <li><a data-toggle="refresh" class="card-refresh" href="#"><i class="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>hme-reqenttl-emag</h4>
                  </div>
                  <div class="card-footer">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                      <div class="">
                          <p class="small hint-text m-t-5">Updated by Padmapriya S</p>
                          <p class="small hint-text m-t-5">Apr 24, 2024 — 11:45 AM</p>
                      </div>
                      <div class="">
                          <a data-toggle="edit" class="card-edit" href="#"><i class="pg-icon text-info">edit</i></a>
                      </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div data-pages="card" class="card card-default" id="card-basic" style="background-color: #f3f3f4;">
                  <div class="card-header  ">
                    <div class="card-title">Metering
                    </div>
                    <div class="card-controls">
                      <ul>
                        <li><a data-toggle="collapse" class="card-collapse" href="#"><i class="card-icon card-icon-collapse"></i></a>
                        </li>
                        <li><a data-toggle="refresh" class="card-refresh" href="#"><i class="card-icon card-icon-refresh"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <h4>hme-reqloginwithenttl-emag</h4>
                  </div>
                  <div class="card-footer">
                      <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div class="">
                            <p class="small hint-text m-t-5">Updated by Padmapriya S</p>
                            <p class="small hint-text m-t-5">Apr 24, 2024 — 11:45 AM</p>
                        </div>
                        <div class="">
                            <a data-toggle="edit" class="card-edit" href="#"><i class="pg-icon text-info">edit</i></a>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
