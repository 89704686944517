<!-- New -->
<div class="container-fluid p-l-25 p-r-25 sm-p-l-0 sm-p-r-0" *ngIf="editUsersPrmns || viewUsersPrmsn">
  <div class="inner">
    <ol class="breadcrumb sm-p-b-5">
      <li class="breadcrumb-item"><a href="javascript:void(0)"
          routerLink="/users/subscribers" routerLinkActive="active-link">Paywall Users</a></li>
      <li class="breadcrumb-item active" *ngIf="editUsersPrmns"> Edit User</li>
      <li class="breadcrumb-item active" *ngIf="!editUsersPrmns && viewUsersPrmsn"> View User</li>
    </ol>
  </div>

  <div class="row">
    <div class="col-lg-9 col-xl-7 mx-auto">
      <div class="card ">
        <div class="card-body">
          <h5 class="mb-2" *ngIf="editUsersPrmns">Edit User</h5>
          <h5 class="mb-2" *ngIf="!editUsersPrmns && viewUsersPrmsn">View User</h5>
          <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
          <form class="addNewBrandForm mx-auto" id="form-project" *ngIf="!showLoadingSpinner" [formGroup]="editCustomerForm" (ngSubmit)="editCustomerFormSubmit()" novalidate>
            <div class="">
              <div class="row clearfix">
                <div class="col-md-12">
                  <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2  required"
                    [class.has-error]="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                    <label>Brand Name</label>
                    <!-- <select class="form-control" formControlName="brandName">
                      <option value="">Select Brand</option>
                      <option *ngFor='let brand of allActiveBrands;let i = index' value="{{brand.id}}">
                        {{brand.brand_name}}</option>
                    </select> -->
                    <pg-select style="width: 100%;" formControlName="brandName" [PlaceHolder]="'Select Brand'" AllowClear
                    ShowSearch >
                        <pg-option *ngFor='let brand of allActiveBrands;let i = index' Label="{{brand.brand_name}}"   Value="{{brand.id}}">
                        </pg-option>
                    </pg-select>
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('brandName').invalid && (getFormControl('brandName').dirty || getFormControl('brandName').touched)">
                      Brand Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default  required"
                    [class.has-error]="getFormControl('firstName').invalid && (getFormControl('firstName').dirty || getFormControl('firstName').touched)">
                    <label>First Name</label>
                    <input type="text" class="form-control" formControlName="firstName" placeholder="Enter First Name">
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('firstName').invalid && (getFormControl('firstName').dirty || getFormControl('firstName').touched)">
                      First Name is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default required"
                    [class.has-error]="getFormControl('lastName').invalid && (getFormControl('lastName').dirty || getFormControl('lastName').touched)">
                    <label>Last Name</label>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Enter Last Name">
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('lastName').invalid && (getFormControl('lastName').dirty || getFormControl('lastName').touched)">
                      Last Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default required"
                    [class.has-error]="getFormControl('email').invalid && (getFormControl('email').dirty || getFormControl('email').touched)">
                    <label>Email</label>
                    <input type="email" class="form-control" formControlName="email" placeholder="Enter Email">
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('email').invalid && (getFormControl('email').dirty || getFormControl('email').touched)">
                      Email is required
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2  required"
                    [class.has-error]="getFormControl('industry').invalid && (getFormControl('industry').dirty || getFormControl('industry').touched)">
                    <label>Industry</label>
                    <!-- <select class="form-control" formControlName="industry" >
                      <option value="">Please Select</option>
                      <option *ngFor='let ind of industriesArr;let i = index' value="{{ind.id}}">{{ind.name}}</option>
                    </select> -->
                    <pg-select style="width: 100%;" formControlName="industry" [PlaceHolder]="'Select Industry'" AllowClear
                    ShowSearch >
                        <pg-option *ngFor='let ind of industriesArr;let i = index' Label="{{ind.name}}"   Value="{{ind.id}}">
                        </pg-option>
                    </pg-select>
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('industry').invalid && (getFormControl('industry').dirty || getFormControl('industry').touched)">
                      Industry is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2  required"
                    [class.has-error]="getFormControl('jobTitle').invalid && (getFormControl('jobTitle').dirty || getFormControl('jobTitle').touched)">
                    <label>Job Title</label>
                    <!-- <select class="form-control" formControlName="jobTitle">
                      <option value="">Please Select</option>
                      <option *ngFor='let jobttl of jobTtlArr;let i = index' value="{{jobttl.id}}">{{jobttl.name}}
                      </option>
                    </select> -->
                    <pg-select style="width: 100%;" formControlName="jobTitle" [PlaceHolder]="'Please Select'" AllowClear
                    ShowSearch >
                        <pg-option *ngFor='let jobttl of jobTtlArr;let i = index' Label="{{jobttl.name}}"   Value="{{jobttl.id}}">
                        </pg-option>
                    </pg-select>
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('jobTitle').invalid && (getFormControl('jobTitle').dirty || getFormControl('jobTitle').touched)">
                      Job Title is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2  required"
                    [class.has-error]="getFormControl('country').invalid && (getFormControl('country').dirty || getFormControl('country').touched)">
                    <label>Country</label>
                    <!-- <select class="form-control" formControlName="country">
                      <option value="">Please Select</option>
                      <option *ngFor='let country of countriesArr;let i = index' value="{{country.id}}">{{country.name}}
                      </option>
                    </select> -->
                    <pg-select style="width: 100%;" formControlName="country" [PlaceHolder]="'Please Select'" AllowClear
                    ShowSearch >
                        <pg-option *ngFor='let country of countriesArr;let i = index' Label="{{country.name}}"   Value="{{country.id}}">
                        </pg-option>
                    </pg-select>
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('country').invalid && (getFormControl('country').dirty || getFormControl('country').touched)">
                      Country is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2  required"
                    [class.has-error]="getFormControl('compSize').invalid && (getFormControl('compSize').dirty || getFormControl('compSize').touched)">
                    <label>Company Size</label>
                    <!-- <select class="form-control" formControlName="compSize">
                      <option value="">Please Select</option>
                      <option *ngFor='let compSize of companySizeArr;let i = index' value="{{compSize.id}}">
                        {{compSize.name}}</option>
                    </select> -->
                    <pg-select style="width: 100%;" formControlName="compSize" [PlaceHolder]="'Please Select'" AllowClear
                    ShowSearch >
                        <pg-option *ngFor='let compSize of companySizeArr;let i = index' Label="{{compSize.name}}"   Value="{{compSize.id}}">
                        </pg-option>
                    </pg-select>
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('compSize').invalid && (getFormControl('compSize').dirty || getFormControl('compSize').touched)">
                      Company Size is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default required"
                    [class.has-error]="getFormControl('compName').invalid && (getFormControl('compName').dirty || getFormControl('compName').touched)">
                    <label>Company Name</label>
                    <input type="text" class="form-control" formControlName="compName"  placeholder="Enter Company Name">
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('compName').invalid && (getFormControl('compName').dirty || getFormControl('compName').touched)">
                      Company Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group date input-group form-group-default">
                    <label>DOB</label>
                    <div class="input-group ">
                      <pg-datepicker formControlName="compName"  Placeholder="Enter DOB" [Format]="'DD/MM/YYYY'"></pg-datepicker>
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="pg-icon">calendar</i>
                        </span>
                      </div>
                    </div> 
                    <!-- <input type="date" class="form-control" formControlName="compName" placeholder="Enter DOB"> -->
                  </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <div class="form-group form-group-default" >
                    <label>Gender</label>
                    <div class="form-check form-check-inline success m-b-0">
                      <input type="radio" id="male" class="form-control" value="male" name="gender"
                      formControlName="gender">
                      <label for="male">Male</label>
                    </div>
                    <div class="form-check form-check-inline success m-b-0">
                      <input  type="radio" id="female" class="form-control" formControlName="gender" name="gender"
                      value="female">
                      <label for="female">Female</label>
                    </div>
                    <div class="form-check form-check-inline success m-b-0">
                      <input type="radio" id="n.a" class="form-control" formControlName="gender" name="gender" value="n.a">
                      <label for="n.a">Don't specify</label>
                    </div>
                  </div>    
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>Contact Number</label>
                    <input type="phone" class="form-control" formControlName="phone" placeholder="Enter Contact Number">
                  </div>
                </div>
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>Tax Registration Number</label>
                    <input type="text" class="form-control" formControlName="trn" placeholder="Enter TRN">
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                    <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                      <input type="checkbox" id="markOptin" checked="checked" formControlName="markOptin">
                      <label for="markOptin">Marketing optin</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                    <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                      <input type="checkbox" id="thirdPartyOptin" checked="checked" formControlName="thirdPartyOptin">
                      <label for="thirdPartyOptin">Third party optin</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">     
                <div class="col-md-12">
                  <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                    <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                      <input type="checkbox" id="status" checked="checked" formControlName="status">
                      <label for="status">Status</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h6 class="m-b-15 m-t-15">Delivery address details</h6>
            <div class="">  
              <div class="row clearfix"> 
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>Gift Address 1</label>
                    <input type="text" class="form-control" formControlName="giftAddress1"
                      placeholder="Enter Gift Address 1">
                  </div>
                </div>                         
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>Gift Address 2</label>
                    <input type="text" class="form-control" formControlName="giftAddress2"
                      placeholder="Enter Gift Address 2">
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>Gift Address City</label>
                    <input type="text" class="form-control" formControlName="giftAddressCity"
                      placeholder="Enter Gift Address City">
                  </div>
                </div>
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>Gift Address State</label>
                    <input type="text" class="form-control" formControlName="giftAddressState"
                      placeholder="Enter Gift Address State">
                  </div>
                </div>

              </div>
              <div class="row clearfix">
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default required"
                    [class.has-error]="getFormControl('shipContactNum').invalid && (getFormControl('shipContactNum').dirty || getFormControl('shipContactNum').touched)">
                    <label>Shipping Contact Number</label>
                    <input type="number" class="form-control" formControlName="shipContactNum"
                      placeholder="Enter Shipping Contact Number">
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('shipContactNum').invalid && (getFormControl('shipContactNum').dirty || getFormControl('shipContactNum').touched)">
                      Shipping Contact Number is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div pgFormGroupDefault class="form-group form-group-default form-group-default-select2 ">
                    <label>Shipping Country</label>
                    <!-- <select class="form-control" formControlName="shippingCountry">
                      <option value="">Please Select</option>
                      <option *ngFor='let country of countriesArr;let i = index' value="{{country.id}}">{{country.name}}
                      </option>
                    </select> -->
                    <pg-select style="width: 100%;" formControlName="shippingCountry" [PlaceHolder]="'Please Select'" AllowClear
                    ShowSearch >
                        <pg-option *ngFor='let country of countriesArr;let i = index' Label="{{country.name}}"   Value="{{country.id}}">
                        </pg-option>
                    </pg-select>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-12">
                  <div  class="form-group form-group-default form-check-group d-flex align-items-center">
                    <div class="form-check switch switch-lg success full-width right m-b-0 text-right">
                      <input type="checkbox" id="giftOpted" checked="checked" formControlName="giftOpted">
                      <label for="giftOpted">Gift opted?</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h6 class="m-b-15 m-t-15">Subscription type details</h6>
            <div class="form-group-attached">  
              <div class="row clearfix"> 
                <div class="col-md-12">
                  <div pgFormGroupDefault class="form-group form-group-default  required"
                  [class.has-error]="getFormControl('subType').invalid && (getFormControl('subType').dirty || getFormControl('subType').touched)">
                    <label>Subscription Type</label>
                    <select class="form-control" formControlName="subType" (change)="onSubTypeChange($event.target.value)">
                      <option *ngFor='let role of userRolesArr;let i = index' value="{{role.id}}">{{role.access_role_name}}</option>
                    </select>
                    <!-- <pg-select style="width: 100%;" formControlName="subType" (change)="onSubTypeChange($event.target.value)" [PlaceHolder]="'Please Select'" AllowClear
                    ShowSearch >
                        <pg-option *ngFor='let role of userRolesArr;let i = index' Label="{{role.access_role_name}}"   value="{{role.id}}">
                        </pg-option>
                    </pg-select> -->
                    <div class="invalid-tooltip"
                      *ngIf="getFormControl('subType').invalid && (getFormControl('subType').dirty || getFormControl('subType').touched)">
                      Subscription Type is required
                    </div>
                  </div>
                </div>                         
              </div>
              <div class="row clearfix selSubType" *ngIf="selSubType!=1"> 
                <div class="col-md-6" *ngIf="selSubType!=1">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>Start Date</label>
                    <input type="date" class="form-control" formControlName="subStartDate" placeholder="Select Start Date">
                  </div>
                </div>
                <div class="col-md-6" *ngIf="selSubType!=1">
                  <div pgFormGroupDefault class="form-group form-group-default">
                    <label>End Date</label>
                    <input type="date" class="form-control" formControlName="subEndDate" placeholder="Select End Date">
                  </div>
                </div>
              </div>  
            </div>  

            <h6  class="m-b-15 m-t-15">Order details</h6>

            <div class="form-group row">
              <div class="table-responsive" *ngIf="subProId && selSubType==1">
                <table class="table dataTable selTbl">
                    <thead>
                        <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Product ID</th>
                            <th scope="col">Payment Plan ID</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of userOrderArr; let i = index;">
                          <td>
                            {{order.id}}
                          </td>
                          <td>
                            <a routerLink="/products/edit/{{order.product_id}}" *ngIf="viewProductPrmns">{{order.product_id}}</a>
                            <span *ngIf="!viewProductPrmns">{{order.product_id}}</span>
                          </td>
                          <td>
                            <a routerLink="/plans/edit/{{order.product_plan_id}}" *ngIf="viewPlanPrmns">{{order.product_plan_id}}</a>
                            <span *ngIf="!viewPlanPrmns">{{order.product_plan_id}}</span>
                          </td>
                          <td>
                            {{order.start_date | date: 'dd-MMM-yy'}}
                          </td>
                          <td>
                            {{order.end_date | date: 'dd-MMM-yy'}}
                          </td>
                          <td>
                            {{commonService.getValueFromArr(orderStatusArr,order.status,'name')}}
                          </td>
                          <td class="text-center">
                            <a routerLink="/users/subs/order/{{order.id}}/account/{{customerId}}/brand/{{brandId}}" *ngIf="viewSubscriptionPrmsn">View details</a>
                          </td>
                      </tr>
                    </tbody>
                </table>
                </div>
                <div *ngIf="selSubType!=1 || !subProId" class="col-12">
                  User currently has no subscriptions
                </div>
            </div>
            <div class="row p-t-20">
              <div class=" col-md-12 text-right ">
                <button type="submit" [disabled]="loading"
                  class="btn btn-primary fs-14 padding-10 p-r-30 p-l-30 m-r-10" *ngIf="editUsersPrmns">Update</button>
                <img *ngIf="loading"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30"
                  routerLink="/users/subscribers" *ngIf="editUsersPrmns">Cancel</button>
                  <button type="button" class="btn btn-light fs-14 padding-10 p-r-30 p-l-30"
                  routerLink="/users/subscribers" *ngIf="!editUsersPrmns && viewUsersPrmsn">Back</button>
              </div>
            </div>
          </form>
          <div *ngFor="let alert of alerts" class="p-t-20">
            <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-------No Access Block--------->
<app-no-access *ngIf="!editUsersPrmns && !viewUsersPrmsn"></app-no-access>