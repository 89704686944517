import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers/customers.component';
import { AllCustomersComponent } from './all-customers/all-customers.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { EditUnSubscribersComponent } from './edit-unsubscribers/edit-unsubscribers.component';

import { AddCustomerComponent } from './add-customer/add-customer.component';
import { CustomerOrderDetailsComponent } from './customer-order-details/customer-order-details.component';
import { ChangePwdComponent } from './change-pwd/change-pwd.component'

import { CustomersRoutingModule } from './customers-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//Services
import { CustomersService } from '../../services/customers.service';

import { BrowserModule } from '@angular/platform-browser';
import { CancelModalComponent } from './customer-order-details/cancel-modal/cancel-modal.component';
import { ChangeCardModalComponent } from './customer-order-details/change-card-modal/change-card-modal.component';
import { returnModalComponent } from './customer-order-details/return-modal/return-modal.component';
import { AddNewCardComponent } from './customer-order-details/add-new-card/add-new-card.component';

import { CancelModalSubComponent } from './subscriber-order-details/cancel-modal/cancel-modal.component';
import { ChangeCardModalSubComponent } from './subscriber-order-details/change-card-modal/change-card-modal.component';
import { returnModalSubComponent } from './subscriber-order-details/return-modal/return-modal.component';
import { AddNewCardSubComponent } from './subscriber-order-details/add-new-card/add-new-card.component';

import { Frames } from "frames-angular";
import { pgSelectfx } from '../../components/cs-select/select.module';
import { pgSelectModule } from '../../components/select/select.module';
import { pgDatePickerModule } from '../../components/datepicker/datepicker.module';
import { FormGroupDefaultModule } from '../../directives/form-group-default.module';
import { AllSubscribersComponent } from './all-subscribers/all-subscribers.component';
import { EditSubscriberComponent } from './edit-subscriber/edit-subscriber.component';
import { SubscriberOrderDetailsComponent } from './subscriber-order-details/subscriber-order-details.component';
import { UnSubscribersComponent } from './un-subscribers/un-subscribers.component'

import { CommonTempModule } from '../common-temp/common.module';

@NgModule({
  declarations: [CustomersComponent,AllCustomersComponent, EditCustomerComponent,EditUnSubscribersComponent, ChangePwdComponent, AddCustomerComponent, CustomerOrderDetailsComponent, CancelModalComponent, ChangeCardModalComponent, AddNewCardComponent, AllSubscribersComponent, returnModalComponent, EditSubscriberComponent, SubscriberOrderDetailsComponent, CancelModalSubComponent, ChangeCardModalSubComponent, returnModalSubComponent, AddNewCardSubComponent, UnSubscribersComponent],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    FormGroupDefaultModule,
    pgSelectfx,
    pgSelectModule,
    pgDatePickerModule,
    BrowserModule,
    CommonTempModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    Frames
  ],
  providers: [CustomersService]
})
export class CustomersModule { }
