<div class="container-fluid p-l-25 p-r-25 p-t-0 p-b-25 sm-padding-10 sm-gutter">
    <!-- START ROW -->
    <div class="card card-transparent">
      <div class="card-header p-0">
        <p class="mb-4">This report provides a comprehensive overview of monthly recurring revenue across a 12-month period. It tracks revenue performance month by month, enabling analysis of trends, patterns, and fluctuations. This report is crucial for assessing financial health, forecasting future revenue, and making informed strategic decisions.</p>
        <div class="row align-items-center">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end align-items-center">
            <a (click)="getRevenueData(true)" class="hover m-r-5" title="Refresh">
              <i class="pg-icon m-20">refresh</i>
            </a>
            <span class="text-muted font-size-13">Last synced on: {{ lastSyncTime }}</span>
          </div>
        </div>
      </div>
      <div class="card-body p-l-0 p-r-0">
        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-3">
            <div class="d-flex align-items-center">
              <span class="m-r-5">Brands: </span>
              <pg-select [style.width.px]="300" [PlaceHolder]="'Please Select Brands'"
                         [(ngModel)]="revChartBrand" (ngModelChange)="revChartFltrChange(revChartBrand)" ShowSearch>
                <pg-option [Label]="'All'" [Value]="'all'"></pg-option>
                <pg-option *ngFor="let brand of allActiveBrands" [Label]="brand.brand_display_name" [Value]="brand.brand_name"></pg-option>
              </pg-select>
            </div>
          </div>
        </div>
        <div *ngIf="showTopLoadingSpinner" class="loadingSpinner"></div>
        <div class="col-sm-12 mt-4 mb-4 chart-container">
          <canvas #myCanvas width="500" height="400">{{ periodSubsChart }}</canvas>
        </div>

        <div class="row align-items-center">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 p-b-15">
            <div class="d-flex flex-row align-items-center">
              <span class="m-r-5">Brands: </span>
              <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Brands'"
                [(ngModel)]="selectedBrandOption" (ngModelChange)="brandFilterChange(selectedBrandOption)" ShowSearch>
                <pg-option [Label]="'All'" [Value]="'0'"></pg-option>
                <pg-option *ngFor='let brand of allActiveBrands;let i = index' [select]="brand.select"
                  [Label]="brand.brand_display_name" [Value]="brand.id">
                </pg-option>
              </pg-select>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 p-b-15">
            <div class="d-flex flex-row align-items-center">
              <span class="m-r-5">Month: </span>
              <pg-select style="width: 100%;" [style.width.px]="300" [PlaceHolder]="'Please Select Month'"
                [(ngModel)]="selectedOption" (ngModelChange)="monthFilterChange(selectedOption)" ShowSearch>
                <pg-option *ngFor="let month of lastSixMonths" [Label]="month.label" [Value]="month.value"></pg-option>
                </pg-select>
            </div>
          </div>
        </div>
        <div *ngIf="showLoadingSpinner" class="loadingSpinner"></div>
        <div *ngFor="let alert of alerts">
          <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
        </div>
        <div class="d-flex align-items-end justify-content-end hide">
          <button class="btn btn-secondary downloadBtn" title="Export as CSV" (click)="downloadCSV()"
          *ngIf="revenueData && !showLoadingSpinner">
            Export as CSV <i class="pg-icon text-info">download</i>
          </button>
        </div>
        <div class="col-sm-12 m-t-10 m-b-10 d-flex" *ngIf="!showLoadingSpinner">
          <div class="card  full-height no-border no-margin widget-11 widget-loader-bar">
            <div class="card-body no-padding">
              <div class="widget-11-table auto-overflow ng-tns-c30-37">
                <table class="table table-condensed1 table-hover" id="revenueTbl">
                    <thead>
                        <!--<tr>
                            <th colspan="5" class="text-center"><strong>{{selectedOption}}</strong></th>
                        </tr>-->
                        <tr>
                            <th class="b-r b-dashed b-grey"></th>
                            <th colspan="3" class="text-center b-r b-dashed b-grey">Subscription</th>
                            <th colspan="2" class="text-center">Revenue</th>
                        </tr>
                      <tr>
                          <th class="b-r b-dashed b-grey">Subscription Plans</th>
                          <th class="text-right">Net New Subs</th>
                          <th class="text-right">Total Active</th>
                          <th class="text-right b-r b-dashed b-grey">Growth %</th>
                          <th class="text-right">Revenue</th>
                          <th class="text-right">Revenue %</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="revenueData.length<=0">
                            <td colspan="6" class="text-center">No data available for the selected month.</td>
                        </tr>
                        <tr *ngFor="let rev of revenueData; index as i">
                            <td class="b-r b-dashed b-grey">{{rev.plan_name}}</td>
                            <td class="text-right">{{rev.netNewSub | number}}</td>
                            <td class="text-right">{{rev.totalActive | number}}</td>
                            <td class="text-right b-r b-dashed b-grey">{{rev.growthPer | number}}%</td>
                            <td class="text-right text-primary">{{revenueCurrency}}{{rev.revenue | number}}</td>
                            <td class="text-right text-primary">{{rev.revenuePer | number}}%
                            </td>
                        </tr>
                        <tr *ngIf="revenueData.length>0 && revenueDataTot">
                          <td class="b-r b-dashed b-grey"><strong>TOTAL</strong></td>
                          <td class="text-right"><strong>{{revenueDataTot.netNewSub | number}}</strong></td>
                          <td class="text-right"><strong>{{revenueDataTot.totalActive | number}}</strong></td>
                          <td class="text-right b-r b-dashed b-grey"><strong>{{revenueDataTot.growthPer | number}}
                          </strong></td>
                          <td class="text-right text-primary"><strong>{{revenueCurrency}}{{revenueDataTot.revenue | number}}</strong></td>
                          <td class="text-right text-primary"><strong>{{revenueDataTot.revenuePer | number}}</strong></td>
                        </tr>
                    </tbody>
                  </table>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </div>
  <!-------No Access Block--------->
  <app-no-access *ngIf="!viewSubsReportsPrmsn"></app-no-access>