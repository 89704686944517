<div class="layout" *ngIf="isUserLoggedIn">
  <app-side-nav *ngIf="isUserLoggedIn && !isFullScreenPage"></app-side-nav>

  <div class="page-container">
    <app-top-nav *ngIf="isUserLoggedIn && !isFullScreenPage"></app-top-nav>
    <div class="page-content-wrapper" [ngClass]="{'full-screen': isFullScreenPage}">
      <div class="content mt-4">
        <router-outlet></router-outlet>
      </div>
    </div>    
  </div>
</div>
<router-outlet></router-outlet>