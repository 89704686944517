<div class="modal-header send-email">
   <h4 class="modal-title pull-left">Send Offer</h4>
   <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
   </button>
   <div *ngFor="let alert of alerts" class="p-t-20">
      <alert [type]="alert.type" [dismissible]="true" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
   </div>
</div>
<div class="modal-body pt-4">

   <table class="table table-condensed1 table-hover1 p-t-20">
      <tbody>
         <tr>
            <td>Name</td>
            <td>{{ fullName }}</td>
         </tr>
         <tr>
            <td>Email</td>
            <td>{{ offerDetails?.email }}</td>
         </tr>
         <tr>
            <td>Brand</td>
            <td>{{ offerDetails?.brand_display_name }}</td>
         </tr>
         <tr>
            <td>Status</td>
            <td>{{ offerDetails?.status }}</td>
         </tr>
         <tr>
            <td>Last Payment Date</td>
            <td>{{ offerDetails?.last_payment_date }}</td>
         </tr>
         <tr>
            <td>Plan</td>
            <td>{{ offerDetails?.plan_display_name }}</td>
         </tr>
         <tr>
            <td>Promo Code</td>
            <td>
               <pg-select style="width: 100%;" [(ngModel)]="selectedPromoCode" [PlaceHolder]="'Select Promo Code'"
                  AllowClear ShowSearch>
                  <pg-option *ngFor='let promoData of promo_Datas;let i = index' Label="{{promoData.promo_code}}"
                     Value="{{promoData.promo_code}}">
                  </pg-option>
               </pg-select>
            </td>
         </tr>
      </tbody>
   </table>
   <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-column p-t-10" >
         <div *ngFor="let offeredData of offeredDatas">
            <small>Last offer sent on {{offeredData?.offered_on}} [promo used - {{offeredData?.promo_code_used}}]</small>
         </div>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end align-items-center">
         <a class="btn btn-complete fs-15 padding-10 m-t-15" (click)="SendOffer()"> Send offer</a>
      </div>
   </div>
</div>