import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Title } from '@angular/platform-browser';
import { ReportsService } from '../../../services/reports.service';
import { Constants } from '../../../common/constants';
import * as Chart from 'chart.js';
import moment from 'moment';
import _ from "lodash";
import { BlobOptions } from 'buffer';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  selectedOption = '0';
  selectedDataset = 'newSubs';
  allActiveBrands: any[];
  subsData: any[] = [];
  alerts: any[];
  lastSyncTime;
  selBrand: number = 0;
  showLoadingSpinner = true;
  bsValue = [];
  startDate;
  endDate;
  dataset: string = 'newSubs';
  datasetValue: string = 'newSubs';
  newSubs: number;
  lost: number;
  netSubs: number;
  paid: string;
  churnRate: string;
  activeSubs: number;
  planSubsTot:any[] = [];
  datePlaceholder: string = '';
  periodSubsChart: any;
  noDataAvailable: boolean = false;
  planSubs: any[];
  generateChartDataRes;
  @ViewChild('myCanvas') canvasRef: ElementRef;
   
  ranges: any = [
    {
      value: [new Date(), new Date()],
      label: 'Today'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date(new Date().setDate(new Date().getDate() - 1))],
      label: 'Yesterday'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()],
      label: 'Last 7 Days'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()],
      label: 'Last 30 Days'
    }, {
      value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
      label: 'This Month'
    }, {
      value: [new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
      label: 'Last Month'
    }];
  //Role based permission check
  viewSubsReportsPrmsn: boolean = true;


  constructor(private commonService: CommonService, private cdr: ChangeDetectorRef, private titleService: Title, private reportsService: ReportsService, private elementRef: ElementRef) {
    /****************Permission check based on role******************/
    this.viewSubsReportsPrmsn = commonService.getSecPermissions([Constants.PERMISSION_VIEW_SUBSCRIPTIONS_REPORTS]);
    this.commonService.subNavSelect(Constants.NAV_DASHBOARD);

    this.getAllActiveBrands();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.titleService.setTitle("Subs Performance Overview");
    this.commonService.setTitle("Subs Performance Overview");
  }


  //Filter Grouping
  buildData(weeklyData, keyName) {
    let result = [];
    _.forEach(weeklyData, (val, key) => {

      let newSubs = val.reduce((acc, curr) => {
        return acc + parseInt(curr.newSubs);
      }, 0)

      let activeSubs = val.reduce((acc, curr) => {
        return acc + parseInt(curr.activeSubs);
      }, 0)


      let lostSubs = val.reduce((acc, curr) => {
        return acc + parseInt(curr.lostSubs);
      }, 0)

      let paymentSubs = val.reduce((acc, curr) => {
        return acc + parseInt(curr.paymentSubs);
      }, 0)

      let cancelled = val.reduce((acc, curr) => {
        return acc + parseInt(curr.cancelled);
      }, 0)

      let date = val.reduce((acc, curr) => {
        return curr.date;
      }, 0)
      result.push({ date: date, newSubs: newSubs, activeSubs: activeSubs, lostSubs: lostSubs, paymentSubs: paymentSubs, cancelled: cancelled, key: key, })
    })
    return result;
  }

  onButtonGroupClick($event) {
    let clickedElement = $event.target || $event.srcElement;
    if (clickedElement.nodeName === "BUTTON") {
      let isCertainButtonAlreadyActive = clickedElement.parentElement.querySelector(".active");
      if (isCertainButtonAlreadyActive) {
        isCertainButtonAlreadyActive.classList.remove("active");
      }
      clickedElement.className += " active";
    }
  }

  groupAndBuild(slectedData, dateMethod, groupKey) {
    let monthlyData = this.generateChartDataRes
    monthlyData.forEach(d => {
      d.dateObj = moment(d.date);
    });
    let groupedData = _.groupBy(slectedData, (d) => {
      return d.dateObj[dateMethod]()
    })
    return this.buildData(groupedData, groupKey);
  }

  //Filter fo Daily
  daily() {
    this.generateChartData(this.generateChartDataRes);
  }

  //Filter for Weekly
  weekly() {
    let weeklyData = this.generateChartDataRes
    this.generateChartData(this.groupAndBuild(weeklyData, 'week', 'date'));
  }

  //Filter for Monthly
  monthly() {
    let monthlyData = this.generateChartDataRes
    this.generateChartData(this.groupAndBuild(monthlyData, 'month', 'date'));
  }

  quaterly() {
    let quaterlyData = this.generateChartDataRes
    this.generateChartData(this.groupAndBuild(quaterlyData, 'quarter', 'date'));
  }
  yearly() {
    let yearData = this.generateChartDataRes
    this.generateChartData(this.groupAndBuild(yearData, 'year', 'date'));
  }

  /**********************************Dataset filter change event*********************/
  datasetFilterChange(val) {
    this.datasetValue = val;
    this.getSubscriptionsData(false);
    this.getSubscriptionsDatasets(false);
  }


  generateChartData(data) {
    const labels: string[] = [];

    const cancelled: number[] = [];
    const cancelledFilter: number[] = [];
    const cancelPending: number[] = [];
    const cancelPendingFilter: number[] = [];
    const failed: number[] = [];
    const failedFilter: number[] = [];
    const failedPending: number[] = [];
    const failedPendingFilter: number[] = [];
    const active: number[] = [];
    const activePending: number[] = [];
    const activeSubscriptions: number[] = [];
    const totalPayment: number[] = [];
    const totalLoss: number[] = [];
    for (let item of data) {
      labels.push(item.date);
      cancelled.push(-1 * item.cancelled);
      cancelledFilter.push(item.cancelled);
      cancelPending.push(-1 * item.cancelPending);
      cancelPendingFilter.push(item.cancelPending);
      failed.push(-1 * item.failed);
      failedFilter.push(item.failed);
      failedPending.push(-1 * item.failedPending);
      failedPendingFilter.push(item.failedPending);
      active.push(item.newSubs);
      activePending.push(item.activePending);
      activeSubscriptions.push(item.activeSubs);
      totalPayment.push(item.paymentSubs);
      totalLoss.push(item.lostSubs)
    }
    if (this.periodSubsChart) {
      this.periodSubsChart.destroy();
    }
    let chartData: any = [
      {
        label: 'Active',
        backgroundColor: "#64aef180",
        data: active,
      }, 
      {
        label: 'Active pending',
        backgroundColor: "#87c1f480",
        data: activePending,
      }, 
      {
        label: 'Cancelled',
        backgroundColor: "#f0b6c380",
        data: cancelled,
      }, 
      {
        label: 'Cancel pending',
        backgroundColor: "#f5c0a980",
        data: cancelPending,
      }, 
      {
        label: 'Failed',
        backgroundColor: "#edba3880",
        data: failed,
      }, 
      {
        label: 'Failed pending',
        backgroundColor: "#f6eacb80",
        data: failedPending,
      }
    ]
    if (this.datasetValue === 'activeSubs') {
      chartData =  [
        {
          label: 'Active Subscriptions',
          backgroundColor: "#87c1f480",
          data: activeSubscriptions,
        }
      ]
    }else if(this.datasetValue === 'totPay'){
      chartData =  [
        {
          label: 'Total Payments',
          backgroundColor: "#64aef180",
          data: totalPayment,
        }
      ]
    }else if(this.datasetValue === 'totLost'){
      chartData =  [
        {
          label: 'Cancelled',
          backgroundColor: "#f0b6c380",
          data: cancelledFilter,
        },
        {
          label: 'Cancel Pending',
          backgroundColor: "#f5c0a980",
          data: cancelPendingFilter,
        },
        {
          label: 'Failed',
          backgroundColor: "#edba3880",
          data: failedFilter,
        },
        {
          label: 'Failed Pending',
          backgroundColor: "#f6eacb80",
          data: failedPendingFilter,
        },
      ]
     
    }

    let htmlRef = this.canvasRef.nativeElement.getContext('2d');
    this.periodSubsChart = new Chart(htmlRef, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: chartData,
      },
      options: {
        animation: {
          duration: 1000, // Animation duration in milliseconds
          easing: 'easeInOutQuad',
          onComplete: () => {
            console.log('Animation completed!');
          },
        },
        tooltips: {
          displayColors: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
              const value = Math.abs(tooltipItem.yLabel); // Convert the value to positive
              return `${datasetLabel}: ${value}`;
            },
          },
        },
        scales: {
          xAxes: [{
            // type: 'time',
            // time: {
            //   unit: this.chartFilter,
            //   tooltipFormat: 'DD-MMM-YY', // Tooltip format
            //   displayFormats: {
            //     day: 'DD-MMM-YY', // X-axis label format
            //   }
            // },
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
          }]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'bottom'},
      }
    });
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  getSelPeriodPlaceHolder(startDate, endDate) {
    this.datePlaceholder = this.formatDate(startDate) + ' - ' + this.formatDate(endDate);
  }

  /**********************************API Method to Get All active Brands*********************/
  getAllActiveBrands() {
    if (this.viewSubsReportsPrmsn) {
      this.commonService.getAllActiveBrands(Constants.STATUS_ACTIVE).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            this.allActiveBrands = res['result'];
            this.bsValue = [new Date(new Date().setDate(new Date().getDate() - 29)), new Date()];
          } else {
            this.alerts = [{
              type: 'danger',
              msg: res['message'],
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: error['message'],
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
        }
      );
    }
  }

  /**********************************Date range filter change event*********************/
  dateRangeChange($event) {
    if ($event[0] & $event[1]) {
      const startDate = new Date($event[0]);
      const endDate = new Date($event[1]);
      startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
      endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());
      this.getSelPeriodPlaceHolder(startDate, endDate);
      this.startDate = startDate.toISOString().split('T')[0];
      this.endDate = endDate.toISOString().split('T')[0];
      this.getSubscriptionsData(false);
      this.getSubscriptionsDatasets(false)
    }
  }

  /**********************************Brand filter change event*********************/
  brandFilterChange(val) {
    this.selBrand = val;
    this.getSubscriptionsData(false);
    this.getSubscriptionsDatasets(false);
  }


  /**********************************Click event download subscription data in csv format*********************/
  downloadCSV() {
    const table = document.getElementById('subscriptionTbl') as HTMLTableElement;
    let csv = '';
    for (let i = 0; i < table.rows.length; i++) {
      const row = table.rows.item(i); // Access each row
      if (row) {
        let rowData: string[] = [];
        for (let j = 0; j < row.cells.length; j++) {
          const cell = row.cells.item(j); // Access each cell
          if (cell) {
            rowData.push(cell.innerText); // Use innerText directly
          }
        }
        csv += rowData.join(',') + '\n';
      }
    }
    const timestamp = this.getCurrentTimestamp();
    const filename = `subscription_report_${timestamp}.csv`;
    this.triggerCSVDownload(csv, filename);
  }

  /**********************************Click event to trigger download subscription data in csv format*********************/
  triggerCSVDownload(csv: string, filename: string): void {
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getSubscriptionsDatasets(isRefreshed) {
    if (this.viewSubsReportsPrmsn) {
      this.showLoadingSpinner = true;
      this.noDataAvailable = false;
      this.reportsService.getSubscriptionsDatasets(this.selBrand, this.startDate, this.endDate, this.datasetValue, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {
            if (res['result'].periodSubs) {
              this.generateChartDataRes = res['result'].periodSubs;
              this.lastSyncTime = res['result'].last_sync_time
              this.generateChartData(res['result'].periodSubs);
            } else {
              this.noDataAvailable = true;
            }
          } else {
            this.alerts = [{
              type: 'danger',
              msg: Constants.DASH_DATA_FAILURE_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
          this.showLoadingSpinner = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showLoadingSpinner = false;
        }
      );

    }
  }

  /**********************************API Method to Get dashboard data*********************/
  getSubscriptionsData(isRefreshed) {
    if (this.viewSubsReportsPrmsn) {
      this.showLoadingSpinner = true;
      this.noDataAvailable = false;
      this.reportsService.getSubscriptionsData(this.selBrand, this.startDate, this.endDate, isRefreshed).then(
        res => {
          if (res['code'] == 1 && res['status'] == 1) {

            if (res['result'].planSubs) {
              this.planSubsTot = res['result'].planTotal;
              this.subsData = res['result'].planSubs;
              //this.generateChartDataRes = res['result'].periodSubs;
              let dashboardData = res['result'].subs;
              this.newSubs = dashboardData['newSubs'];
              this.lost = dashboardData['lost'];
              this.netSubs = dashboardData['netSubs'];
              this.paid = dashboardData['paid'];
              this.lastSyncTime = res['result'].last_sync_time
              this.churnRate = dashboardData['churRate'];
              this.activeSubs = dashboardData['totalActiveSubs'];
              //this.generateChartData(res['result'].periodSubs);
              this.planSubs = res['result'].planSubs;
            } else {
              this.noDataAvailable = true;
            }
          } else {
            this.alerts = [{
              type: 'danger',
              msg: Constants.DASH_DATA_FAILURE_MSG,
              timeout: Constants.DEF_ALERT_MSG_TIMEOUT
            }];
          }
          this.showLoadingSpinner = false;
        }, error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.DASH_DATA_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
          this.showLoadingSpinner = false;
        }
      );

    }
  }

  getCurrentTimestamp(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }
}
