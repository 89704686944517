import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { CommonService } from './../../../services/common.service';
import { Router} from '@angular/router';
import { BrandsService } from '../../../services/brands.service';
import { Constants } from '../../../common/constants';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss']
})
export class AddBrandComponent implements OnInit {
  addNewBrandForm: FormGroup;
  submitted = false;
  loading = false;
  alerts: any[];
  showLoadingSpinner = true;
  //Role based permission check
  addBrandsPrmns : boolean = true;
  
  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService, 
    private brandService: BrandsService,
    private cdr: ChangeDetectorRef, 
    private router: Router) {
      /****************Permission check based on role******************/
      this.addBrandsPrmns = commonService.getSecPermissions([Constants.PERMISSION_ADD_BRANDS]);
    }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  meteringPeriods = [
    { value: 'DAY1', label: 'One Day' },
    { value: 'WEEK1', label: 'One Week' },
    { value: 'WEEK2', label: 'Two Weeks' },
    { value: 'MONTH1', label: 'One Month' },
    { value: 'MONTH2', label: 'Two months' }
  ];

  ngOnInit(): void {
    /****************Add New User Form Validation****************** */
    this.addNewBrandForm = this.formBuilder.group({
      brandName: ['', [Validators.required]],
      brandDisplayName: ['', [Validators.required]],
      domainName: ['', [Validators.required]],
      supportEmail: ['', [Validators.required]],
      senderName: ['', [Validators.required]],
      maxLim: ['', [Validators.required]],
      offLim: ['', [Validators.required]],
      meteringPeriod: ['MONTH1', [Validators.required]],
      subAlertEmails: [''],
      brandStatus: [true]
    });
  }
  
  //attachedForm: UntypedFormGroup;
  // convenience getter for easy access to form fields
  get f() { return this.addNewBrandForm.controls; }

  getFormControl(name) {
    return this.addNewBrandForm.controls[name];
  }

  isFieldValid(field: string) {
    return !this.addNewBrandForm.get(field).valid && this.addNewBrandForm.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  } 
  /*******************************Method to submit add new brand form***************************************** */
  addNewBrandFormSubmit() {
    if(this.addBrandsPrmns) {
      this.submitted = true;
      // stop here if form is invalid
      
      for (const i in this.addNewBrandForm.controls) {
        this.addNewBrandForm.controls[i].markAsDirty();
      }
      if (this.addNewBrandForm.invalid) {
        return;
      }
      
      this.loading = true;
      let dataObj = {};
      dataObj['brand_name'] = this.f.brandName.value;
      dataObj['brand_display_name'] = this.f.brandDisplayName.value;
      dataObj['domain_name'] = this.f.domainName.value;
      dataObj['max_limit'] = this.f.maxLim.value;
      dataObj['support_email'] = this.f.supportEmail.value;
      dataObj['sender_name'] = this.f.senderName.value;
      dataObj['offered_limit'] = this.f.offLim.value;
      dataObj['metering_period'] = this.f.meteringPeriod.value;
      dataObj['notify_email'] = this.f.subAlertEmails.value;
      dataObj['status'] = this.commonService.getStatusValue(this.f.brandStatus.value);

      this.brandService.addNewBrand(dataObj).then(
        res => {
            this.loading = false;
            let resStatus = res['status']
            if(res['code']==1 && resStatus==1) {//success
              this.alerts = [{
                type: 'success',
                msg: Constants.ADD_BRAND_SUCCESS_MSG,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
              setTimeout(()=>{
                this.router.navigate(['/brands/all']);
              }, 2000);
            } else {
              let errorMsg = Constants.ADD_BRAND_FAILURE_MSG;
              if(resStatus==3) {
                errorMsg = Constants.UPDATE_BRAND_BRAND_EXISTS_MSG;
              }
              else if(resStatus==2) {
                errorMsg = Constants.UPDATE_BRAND_DOMAIN_EXISTS_MSG;
              }
              this.alerts = [{
                type: 'danger',
                msg: errorMsg,
                timeout: Constants.DEF_ALERT_MSG_TIMEOUT
              }];
            }
        },
        error => {
          this.alerts = [{
            type: 'danger',
            msg: Constants.ADD_BRAND_FAILURE_MSG,
            timeout: Constants.DEF_ALERT_MSG_TIMEOUT
          }];
            this.loading = false;
        });

    }
  }
}
